import React, { useState } from "react";
import { Link, Route, useLocation, useRouteMatch } from "react-router-dom";
import { NavItem, NavLink, Nav } from "reactstrap";
import { URL } from "../../../ServerCall/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SubMenu from "./SubMenu";
import axios from "axios";

const config = {
  headers: {
    "content-type": "application/json",
  },
};

const SideBar = ({ isOpen, toggle, items, resetPage, resetSortPage }) => {
  const { url, path } = useRouteMatch();
  const [activeIndex, setActiveIndex] = useState(1);
  const [list, setList] = useState([]);

  const intentFunct = (id) => {
    setActiveIndex(id);
  };
  const { pathname } = useLocation();
  let location = useLocation();
  React.useEffect(() => {
    axios
      .get(`${URL}/getSortList`, config)
      .then((res) => {
        if (res.data.status === 200) {
          //console.log(location, "url");
          let data = res.data.data;
          const newData = data.map((file) => {
            return { ...file, colapseId: true };
          });

          if (
            location.state !== undefined &&
            location.state.titleHead !== undefined
          ) {
            //console.log(location.state, "location.state");

            var index = newData.findIndex(
              (obj) => obj.name === location.state.titleHead
            );
            newData[index].colapseId = false;
            if (location.state.subCategory !== undefined) {
              if (newData[index].Items !== undefined) {
                var childIndex = newData[index].Items.findIndex(
                  (obj) => obj.child_name === location.state.subCategory
                );
                newData[index].Items[childIndex].activeIndex = true;
                if (
                  newData[index].Items[childIndex].Sub_items !== undefined &&
                  location.state.childCategory !== undefined
                ) {
                  var subChildIndex = newData[index].Items[
                    childIndex
                  ].Sub_items.findIndex(
                    (obj) => obj.subChildName === location.state.childCategory
                  );
                  newData[index].Items[childIndex].Sub_items[
                    subChildIndex
                  ].childActiveIndex = true;
                }
              }
            }
          }
          setList(newData);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const toggleMenu = (colapseId, id) => {
    let newArr = [...list];
    var index = newArr.findIndex((obj) => obj.parentId === id);
    for (var j = 0; j < newArr.length; j++) {
      if (j === index) {
        newArr[j].colapseId = !colapseId;
      } else {
        newArr[j].colapseId = true;
      }
      if (newArr[j].Items !== undefined) {
        for (var k = 0; k < newArr[j].Items.length; k++) {
          newArr[j].Items[k].activeIndex = false;
          if (newArr[j].Items[k].Sub_items !== undefined) {
            for (var l = 0; l < newArr[j].Items[k].Sub_items.length; l++) {
              newArr[j].Items[k].Sub_items[l].childActiveIndex = false;
            }
          }
        }
      }
    }
    setList(newArr);
    resetSortPage();
  };

  const assormentCollapse = () => {
    let newArr = [...list];
    for (var j = 0; j < newArr.length; j++) {
      newArr[j].colapseId = true;
      if (newArr[j].Items !== undefined) {
        for (var k = 0; k < newArr[j].Items.length; k++) {
          newArr[j].Items[k].activeIndex = false;
          if (newArr[j].Items[k].Sub_items !== undefined) {
            for (var l = 0; l < newArr[j].Items[k].Sub_items.length; l++) {
              newArr[j].Items[k].Sub_items[l].childActiveIndex = false;
            }
          }
        }
      }
    }
    setList(newArr);
    resetPage();
  };

  return (
    <div className={isOpen ? "is-open sidebar" : "sidebar"}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
        <div className="logowp my-3">
          <Link
            onClick={assormentCollapse}
            to={{
              pathname: "/ProductOverview",
              state: { default: "defaultId" },
            }}
          >
            <span className="sidebarHeader">SORTIMENT</span>
          </Link>
        </div>
      </div>
      <div className="side-menu ">
        <Nav vertical className="list-unstyled pb-3 sideNv">
          {list &&
            list.map((sortItem, index) => (
              <SubMenu
                key={sortItem.parentId}
                colapseId={sortItem.colapseId}
                toggleMenu={toggleMenu}
                title={sortItem.name}
                linkItem={`${
                  url === "/ProductDetail"
                    ? "/ProductOverview"
                    : "/ProductOverview"
                }/${sortItem.name}/${sortItem.parentId}`}
                id={sortItem.parentId}
                img={sortItem.Img}
                parentIcon={sortItem.parent_icon} // Pass the parent_icon as a prop
                items={sortItem.Items}
                onClick={() => intentFunct(2)}
                className={activeIndex === 2 ? "setActiveTab" : ""}
              />
            ))}
        </Nav>
      </div>
    </div>
  );
};

export default SideBar;
