
import React from 'react';
import groClubBg from '../../../assets/images/groClubBg.jpeg'
import {
    Container ,Row,Col,Button, Card, CardImg, CardBody,
    CardTitle, CardSubtitle
  } from 'reactstrap';
  import grocerryClub from '../../../assets/images/grocerryClub.png'
const ClubDetail = () => {
    return(
        <div>
        <Container fluid>
            <Row>
                <Col>
                <div>
                        <div className="InnerWp"></div>
                        <div className="clubdetailImgwp">
                                <img src={groClubBg} className="clubDetailImg"/>
                        </div>
                    </div>
                    <Row>
                            <Col className="BannerTextWp">
                                <p className="bannerHdTxt">WELCOME TO FAST CLOSE CLUB</p>
                                <p>
                                     Now you can become a member and get access to a store that was previously only open to restaurants
                                </p>
                                <Button>Sign Up</Button>
                            </Col>
                        </Row>
                </Col>
            </Row>
            </Container>
            <Container>
            <Row className="mt-5">
                <Col>
                    <Card>
                        <CardImg top width="100%" src={grocerryClub} alt="Card image cap" className="CardclubDetail" />
                        <CardBody>
                        <CardTitle tag="h5">About Store</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Read about membership and club here.</CardSubtitle>
                        <Button className="btnAddShop">Checkout</Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardImg top width="100%" src={grocerryClub} alt="Card image cap" className="CardclubDetail" />
                        <CardBody>
                        <CardTitle tag="h5">About Store</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Read about membership and club here.</CardSubtitle>
                        <Button className="btnAddShop">Checkout</Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardImg top width="100%" src={grocerryClub} alt="Card image cap" className="CardclubDetail" />
                        <CardBody>
                        <CardTitle tag="h5">About Store</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Read about membership and club here.</CardSubtitle>
                        <Button className="btnAddShop">Checkout</Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </Container>
            </div>
    );
}

export default ClubDetail;