import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Card } from "react-bootstrap";
import Select from "react-select";
import Popover from "@material-ui/core/Popover";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { MultiSelect } from "react-multi-select-component";
import { URL } from "../../../ServerCall/Config";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import _ from "lodash";
import swal from "sweetalert";

import Auth from "../../../ServerCall/Auth";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const generateInitialState = (numberOfSelects) =>
  numberOfSelects.map((item) => null);

const CreateUser = () => {
  const history = useHistory();

  const location = useLocation();
  // console.log("location:",location.state.state)
  // if(location.state!=undefined)
  const [locState, setLocState] = useState();
  const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
  );
  const validName = /^[A-Z][a-z]*$/;
  const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [firmOptions, setFirmOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState(() =>
    // increase the array elements to add more select fields
    generateInitialState([selected])
  );

  const [userID, setUserID] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [falseAccessLevel, setFalseAccessLevel] = useState(false);
  const [state, setstate] = useState({
    firstName: "",
    lastName: "",
    firmNo: "",
    password: "",
    mobPh: "",
    phone: "",
    email: "",
    vismaNo: null,
    confirmPass: "",
    // selectRefFirm:null,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    // firmNo:'',
    password: "",
    mobPh: "",
    phone: "",
    email: "",
    isdefault: "",
    customer: "",
    confPassword: "",
  });

  const firmId = Auth.getFirmNo();
  const userId = Auth.getUserId();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [value, setValue] = useState("Name1");
  const [isdefault, setIsDefault] = useState("");

  const testMail = () => {
    axios.post(`${URL}/users/login`, { email: state.email }).then((res) => {
      // debugger
      // console.log(res)
      if (res.status === 200) {
        if (res.data.message === "Data found successfully ") {
          // setFormErrors({ ...formErrors ,email:"Email already Exist"});
          setFormErrors({ ...formErrors, email: "E-postadress finns redan" });
        } else {
          // debugger
          if (validEmailRegex.test(state.email) === true) {
            setFormErrors({ ...formErrors, email: "" });
          } else {
            // setFormErrors({ ...formErrors ,email:"Valid email address required"});
            setFormErrors({
              ...formErrors,
              email: "Giltig E-postadress krävs",
            });
          }
        }
      }
    });
  };

  const formvalid = () => {
    let fromerror = formErrors;
    if (fromerror.email === "") {
      // validEmailRegex.test(state.email) ? fromerror.email = '' : fromerror.email = 'Valid email address required';
      validEmailRegex.test(state.email)
        ? (fromerror.email = "")
        : (fromerror.email = "Giltig E-postadress krävs");
    }
    // state.password === '' ? fromerror.password = 'Please enter password' : !state.password.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/) ? fromerror.password = 'Please enter secure and strong password' : fromerror.password = "";

    // state.firstName === '' ? fromerror.firstName = 'Please enter first name':validName.test( state.firstName)? fromerror.firstName = '' : fromerror.firstName = 'Valid first name required';
    // state.firstName === '' ? fromerror.firstName = 'Vänligen ange förnamn':validName.test( state.firstName)? fromerror.firstName = '' : fromerror.firstName = 'Giltig förnamn krävs';
    state.firstName === ""
      ? (fromerror.firstName = "Vänligen ange förnamn")
      : (fromerror.firstName = "");

    // state.lastName === '' ? fromerror.lastName = 'Please enter lastname':validName.test(state.lastName)? fromerror.lastName = '' : fromerror.lastName = 'Valid lastname required';;
    // state.lastName === '' ? fromerror.lastName = 'Vänligen ange efternamn':validName.test(state.lastName)? fromerror.lastName = '' : fromerror.lastName = 'Giltigt efternamn krävs ';
    state.lastName === ""
      ? (fromerror.lastName = "Vänligen ange efternamn")
      : (fromerror.lastName = "");

    // state.phone === '' ? fromerror.phone = 'Please enter phone number' : !regex.test(state.phone) ? fromerror.phone = 'Valid phone number required' : fromerror.phone = '';
    state.phone === ""
      ? (fromerror.phone = "Vänligen ange telefonnummer")
      : !regex.test(state.phone)
      ? (fromerror.phone = "Giltigt telefonnummer krävs")
      : (fromerror.phone = "");

    // isdefault!=='' ? fromerror.isdefault = '' : fromerror.isdefault = 'Please choose default cutomer';

    // state.password!==state.confirmPass ? fromerror.confPassword = `Password Dosen't Match` : fromerror.confPassword = '';
    state.password !== state.confirmPass
      ? (fromerror.confPassword = `Lösenord stämmer inte överens `)
      : (fromerror.confPassword = "");

    //state.firmNo !== ''?fromerror.firmNo = '':fromerror.firmNo = 'Please select firm.';
    if (selected.length > 0) {
      fromerror.customer = "";
      if (isdefault !== "") {
        fromerror.isdefault = "";
      } else {
        // fromerror.isdefault = 'Please choose default cutomer';
        fromerror.isdefault = "Vänligen ange standard kund";
      }
    } else {
      // fromerror.customer = 'Please select customers' ;
      fromerror.customer = "Vänligen ange kunder";
    }
    // customerOptions && customerOptions.map(data =>{
    //   if(data === null){

    //   }
    // })
    setFormErrors({ ...formErrors, fromerror });
    if (
      fromerror.phone === "" &&
      fromerror.email === "" &&
      fromerror.firstName === "" &&
      fromerror.lastName === "" &&
      fromerror.isdefault === "" &&
      fromerror.customer === "" &&
      fromerror.confPassword === ""
    ) {
      return true;
    }
    if (state.password !== state.confirmPass) {
      return false;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    let text = e.target.value;
    setstate({ ...state, [e.target.id]: e.target.value });

    // switch (e.target.id) {
    //   case 'firstName':
    //     formErrors.firstName = text!=='' ? '' : "Please Enter First Name.";
    //   setFormErrors({ ...formErrors, [e.target.id]: formErrors.firstName });
    //   break;
    // case 'lastName':
    //   formErrors.lastName = text !== '' ? '' : "Please Enter Last Name.";
    //   setFormErrors({ ...formErrors, [e.target.id]: formErrors.lastName });
    //   break;
    // case 'email':
    //   formErrors.email = validEmailRegex.test(text) ? "" : "Valid Email Address Required.";
    //   setFormErrors({ ...formErrors, [e.target.id]: formErrors.email });
    //   break;
    // case 'password':
    //   formErrors.password = text === '' ? 'Please Enter Password.' : !text.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/) ? "Please enter secure and strong password" : "";
    //   setFormErrors({ ...formErrors, [e.target.id]: formErrors.password });
    //   break;
    // case 'confirmPass':
    //   formErrors.confPassword = text === '' ? 'Please Enter Confirm Password.' :  "";
    //   setFormErrors({ ...formErrors, [e.target.id]: formErrors.confPassword });
    //   break;
    //   case 'phone':
    //     formErrors.phone = text === '' ? 'Please enter phone number.' : !regex.test(text) ? 'Valid phone number required.' : "";
    //     setFormErrors({ ...formErrors, [e.target.id]: formErrors.phone });
    //     break;
    // }
  };

  useEffect(async () => {
    await getfirm();
    await getCustomer();
    getCustomerByFirm();
    await getAccessLevel();
  }, []);

  useEffect(() => {
    if (
      window.location.search !== undefined ||
      window.location.search !== null
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("id");
      userDetailById(id);
      setUserID(id);
    }
  }, [customer]);

  const userDetailById = (Id) => {
    if (Id !== null) {
      axiosApiInstance
        .get(`${URL}/users/getUserDetailsByUserNo/${Id}`)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            // console.log(data)
            setstate({
              firstName: data.SureName,
              lastName: data.LastName,
              firmNo: data.FirmNo,
              password: data.PasswordHash,
              mobPh: data.MobPh,
              phone: data.Phone,
              email: data.Mailad,
              vismaNo: data.VISMA_No,
              confirmPass: data.PasswordHash,
            });

            let customers = data.CustomerDetails;
            let accLevelsByUser = [];
            let selectedCust = customer.filter((o1) =>
              customers.some((o2) => o1.value === o2.CustNo)
            );
            customers &&
              customers.map((item, index) => {
                if (item.isDefault === 1) {
                  for (let i = 0; i < selectedCust.length; i++) {
                    if (item.CustNo === selectedCust[i].value) {
                      setValue(selectedCust[i].label);
                      setIsDefault(item.CustNo);
                      break;
                    }
                  }
                }
                for (let i = 0; i < selectedCust.length; i++) {
                  if (item.CustNo === selectedCust[i].value) {
                    item.Roles &&
                      item.Roles.map((role) => {
                        let newRole = {
                          disabled: 0,
                          label: role.Name,
                          value: role.Access_level,
                        };
                        if (accLevelsByUser[i] !== undefined) {
                          accLevelsByUser[i] =
                            accLevelsByUser[i].concat(newRole);
                        } else {
                          accLevelsByUser[i] = [newRole];
                        }
                      });
                  }
                }
              });

            setSelected(selectedCust);

            setCustomerOptions(accLevelsByUser);

            // console.log(selectedCust, customer, customers, accLevelsByUser);
          }
        });
    }
  };

  const getfirm = () => {
    axiosApiInstance
      .get(`${URL}/users/admin/getAllFirms`)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          setFirmOptions(data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getCustomer = () => {
    axiosApiInstance
      .get(`${URL}/users/getAllCustomers`)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          let customer = [];
          data &&
            data.map((item) => {
              if (item.FirmNo === firmId) {
                customer.push({
                  label: item.Name,
                  value: item.CustNo,
                  FirmNo: item.FirmNo,
                });
              }
            });
          setCustomer(customer);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getCustomerByFirm = () => {
    axiosApiInstance
      .get(`${URL}/users/getAllCustomersByFirmNo/${firmId}`)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          let customer = [];
          data &&
            data.map((item) => {
              if (item.FirmNo === firmId) {
                customer.push({
                  label: item.Name,
                  value: item.CustNo,
                  FirmNo: item.FirmNo,
                });
              }
            });
          setCustomer(customer);
        }
        // console.log(res.data)
      })
      .catch((err) => console.log(err));
  };

  const getAccessLevel = () => {
    axiosApiInstance
      .get(`${URL}/users/getUserRoles`)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          let userlevel = [];
          data &&
            data.map((item) => {
              userlevel.push({
                label: item.descr,
                value: item.Access_level_no,
                disabled: item.status,
              });
            });
          setUserRoles(userlevel);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleRadioChange = (event) => {
    setIsDefault(event.target.name);
    setValue(event.target.value);
    // setFormErrors({ ...formErrors, isdefault: '' });
  };

  // const dropdownHandleChange = (option) => {

  //   var firmNo =  option.FirmNo;
  //   setstate({ ...state,firmNo : firmNo});
  //   setFormErrors({...formErrors,firmNo:''});

  //   // var customers = _.map(customer, function(o) {
  //   //   if (o.FirmNo===firmNo ) return o;
  //   // });
  //   var data = [];
  //   customer&&customer.map(o=>{
  //     if(o.FirmNo === firmNo){
  //       data.push(o)
  //     }
  //   });

  //   setCustomerOptions(data);
  // }

  // const dropdownHandleChange = (option, item, index) => {
  //   setCustomerOptions(customerOptions => [...customerOptions, { custNo: item.value, isDefault: 0, accessLevel: [option] }]);
  // }

  const dropdownHandle = (option) => {
    setSelected(option);
    // setFormErrors({ ...formErrors, customer: '' });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let custDetails = [];
    let isUserAccessLevel = false;
    for (let i = 0; i < customerOptions.length; i++) {
      if (customerOptions[i] === null || customerOptions[i] === undefined) {
        // swal("Please select user access level for each customer",'', 'info');
        swal("Vänligen ange access nivå för varje kund", "", "info");
        isUserAccessLevel = false;
        setFalseAccessLevel(false);
        break;
      } else {
        isUserAccessLevel = true;
        setFalseAccessLevel(true);
      }
    }
    selected &&
      selected.map((item, index) => {
        custDetails.push({
          custNo: item.value,
          isDefault: 0,
          accessLevel: customerOptions[index],
          addedByUserId: userId,
        });
      });
    const elementsIndex = custDetails.findIndex(
      (element) => element.custNo === isdefault
    );
    let newArray = [...custDetails];
    newArray[elementsIndex] = { ...newArray[elementsIndex], isDefault: 1 };
    custDetails = newArray;
    // console.log(custDetails);
    const isValid = formvalid();
    if (isValid && isUserAccessLevel) {
      let data = {
        firstName: state.firstName,
        lastName: state.lastName,
        firmNo: firmId,
        password: state.password,
        mobPh: state.phone,
        phone: state.phone,
        email: state.email,
        vismaNo: 0,
        custDetails: custDetails,
      };
      // swal("done","","success")
      axiosApiInstance
        .post(`${URL}/users/register`, data)
        .then((res) => {
          // console.log("res", res);
          if (res.status === 200) {
            if (res.data.status === 200) {
              //swal("User created!", "", "success");
              swal("Användare skapad!", "", "success");
              clearValue();
            } else if (res.data.status === 400) {
              swal(res.data.message, "", "info");
              //clearValue();
            } else {
              swal("some thing went wrong!", "please try again!", "error");
              clearValue();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // else{
    //   swal("some thing went wrong!", "please try again!", "error");
    // }
  };

  const onUpdate = (e) => {
    e.preventDefault();
    let custDetails = [];
    let isUserAccessLevel = false;
    for (let i = 0; i < customerOptions.length; i++) {
      if (customerOptions[i] === null || customerOptions[i] === undefined) {
        // swal("Please select user access level for each customer",'', 'info');
        swal("Vänligen ange access nivå för varje kund", "", "info");
        isUserAccessLevel = false;
        setFalseAccessLevel(false);
        break;
      } else {
        isUserAccessLevel = true;
        setFalseAccessLevel(true);
      }
    }
    selected &&
      selected.map((item, index) => {
        custDetails.push({
          custNo: item.value,
          isDefault: 0,
          accessLevel: customerOptions[index],
          addedByUserId: userId,
        });
      });
    const elementsIndex = custDetails.findIndex(
      (element) => element.custNo === isdefault
    );
    let newArray = [...custDetails];
    newArray[elementsIndex] = { ...newArray[elementsIndex], isDefault: 1 };
    custDetails = newArray;
    // console.log(custDetails);
    const isValid = formvalid();
    if (isValid && isUserAccessLevel && userID) {
      let data = {
        userNo: userID,
        firstName: state.firstName,
        lastName: state.lastName,
        firmNo: firmId,
        vismaNo: 0,
        mobPh: state.phone,
        phone: state.phone,
        custDetails: custDetails,
      };
      axiosApiInstance
        .put(`${URL}/users/updateUserProfileDetails`, data)
        .then((res) => {
          // console.log("res", res);
          if (res.status === 200) {
            if (res.data.status === 200) {
              // swal("User Updated!", "", "success");
              swal("Användare uppdaterad!", "", "success");
              clearValue();
              history.push("/CreateUser");
            } else if (res.data.status === 400) {
              swal(res.data.message, "", "info");
              //clearValue();
            } else {
              swal("some thing went wrong!", "please try again ..", "error");
              // clearValue();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      swal("some thing went wrong!", "please try again!", "error");
    }
  };

  const clearValue = () => {
    setstate({
      firstName: "",
      lastName: "",
      confirmPass: "",
      password: "",
      mobPh: "",
      phone: "",
      email: "",
      vismaNo: 0,
    });
    setSelected([]);
    setUserID(null);
    // setCustomer([]);
    setCustomerOptions([]);
    // state.selectRefFirm.select.clearValue();
  };

  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => "✔️ " + label)
      : "Ingen kund har valts";
    // : "No Customer Selected";
  };

  const getOptionsToRender = (allSelectedOptions, allOptions) => {
    // convert [[{}, {}], [{}]] -> [{}, {}, {}]
    const filteredOptions = allSelectedOptions.flatMap((options) => options);

    const optionsToRender = allOptions;

    return optionsToRender;
  };

  const CustomSelectComponent = ({ value, options, onSelect }) => {
    return (
      <Select
        value={value}
        options={options}
        isMulti
        onChange={onSelect}
        placeholder={"Set access level"}
        isOptionDisabled={(option) => option.disabled}
      />
    );
  };

  const onSelectValues = (value, index) => {
    // clone state
    const clonedSelectState = JSON.parse(JSON.stringify(customerOptions));

    clonedSelectState[index] = value;
    setCustomerOptions(clonedSelectState);
  };

  return (
    <Container
      className="LoginContainer formWraper mt-2 px-0 UserCreationWp"
      component="main"
      maxWidth="xl"
    >
      <Card className="pl-4 pr-4 pb-5">
        <CssBaseline />
        <div className={classes.paper + " " + "userCreationCard"}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className="signWp">
            {/* User {userID === null ? `Creation` : `Updation`}   */}
            {userID === null ? `Skapande` : `uppdatering`} av användare
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                value={state.firstName}
                variant="outlined"
                required
                onChange={(e) => {
                  handleChange(e);
                }}
                fullWidth
                id="firstName"
                label="Förnamn"
                // label="First Name"
                autoFocus
              />
              {formErrors.firstName !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.firstName}
                </label>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                value={state.lastName}
                onChange={(e) => {
                  handleChange(e);
                }}
                fullWidth
                id="lastName"
                // label="Last Name"
                label="Efternamn"
                name="lastName"
                autoComplete="lname"
              />
              {formErrors.lastName !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.lastName}
                </label>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                value={state.phone}
                onChange={(e) => {
                  handleChange(e);
                }}
                fullWidth
                type={"number"}
                id="phone"
                //label="Phone Number"
                label="Telefonnummer"
                name="phone"
                autoComplete="Phone_number"
              />
              {formErrors.phone !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.phone}
                </label>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={
                  userID !== null || userID !== undefined ? true : false
                }
                variant="outlined"
                required
                value={state.email}
                onChange={(e) => {
                  handleChange(e);
                }}
                fullWidth
                id="email"
                onBlur={testMail}
                label="E-postadress"
                //label="Email Address"
                name="email"
                autoComplete="email"
              />
              {formErrors.email !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.email}
                </label>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={
                  userID !== null || userID !== undefined ? true : false
                }
                variant="outlined"
                //  required
                value={state.password}
                onChange={(e) => {
                  handleChange(e);
                }}
                fullWidth
                name="password"
                label="Lösenord"
                //label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
              {formErrors.password !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.password}
                </label>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                disabled={
                  userID !== null || userID !== undefined ? true : false
                }
                //   required
                fullWidth
                value={state.confirmPass}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="confirmPass"
                label="Bekräfta Lösenord"
                // label="Confirm Password"
                type="password"
                id="confirmPass"
                autoComplete="current-confirmPass"
              />
              {formErrors.confPassword !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.confPassword}
                </label>
              ) : null}
              {/* <Select
                ref={ref => {
                  state.selectRefFirm = ref;
                }}
                onChange={dropdownHandleChange}
                options={firmOptions}
                getOptionLabel={x => x.Name}
                getOptionValue={x => x.FirmNo}
               // defaultValue=""
                placeholder={'Select firm'}
            />
             {
                formErrors.firmNo !== '' ?
                <label style={{ fontSize: 12, color: "red" }}>
                {formErrors.firmNo}</label>: null
             } */}
            </Grid>
            <Grid item xs={12} sm={9}>
              <MultiSelect
                options={customer}
                value={selected}
                onChange={dropdownHandle}
                valueRenderer={customValueRenderer}
                //placeholder={"Select Customer"}
                placeholder={"Välj Kund"}
              />
              {formErrors.customer !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.customer}
                </label>
              ) : null}
            </Grid>
            {/* <Grid item xs={4} className="mt-1">
                <Button  aria-describedby={id} variant="contained" color="primary" className="setasDefaultBtn" onClick={handleClick}>Set as Defualt</Button> */}
            <Grid item xs={12} sm={3} className="mt-1">
              <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                className="setasDefaultBtn"
                onClick={handleClick}
                fullWidth
              >
                {/* is default */}
                Är Standard
              </Button>
              {formErrors.isdefault !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {formErrors.isdefault}
                </label>
              ) : null}
            </Grid>
            {/* </Grid> */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography className="poperContainer">
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">
                    {/* Set default customer */}
                    Ange Standardkund
                  </FormLabel>

                  <RadioGroup
                    aria-label=""
                    name="option"
                    value={value}
                    onChange={handleRadioChange}
                  >
                    {selected &&
                      selected.map((item, index) => {
                        const options = getOptionsToRender(
                          customerOptions,
                          userRoles
                        );
                        return (
                          <>
                            <FormControlLabel
                              value={item.label}
                              name={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                              key={item.value}
                            />
                            <CustomSelectComponent
                              value={customerOptions[index]}
                              options={options}
                              onSelect={(value) => onSelectValues(value, index)}
                              key={index}
                              isOptionDisabled={(option) => option.disabled}
                            />
                          </>
                        );
                      })}
                  </RadioGroup>
                  {/* <Button type="submit" variant="contained"color="primary" className={classes.submit}>Save</Button> */}
                </FormControl>
              </Typography>
            </Popover>
            {userID !== null || userID !== undefined ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onUpdate}
              >
                {/* Update */}
                Uppdatering
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onSubmit}
              >
                {/* Submit */}
                Skicka in
              </Button>
            )}
          </Grid>
        </div>
      </Card>
    </Container>
  );
};
export default CreateUser;
