import React from 'react'
import MaterialTable from "material-table";
import   './Table.css'

const localizationSwedish= {
  error: "fel",
  body: {
    emptyDataSourceMessage: "Det finns inga poster att visa.",
    filterRow: {
      filterPlaceHolder: "",
      filterTooltip: "filtrera",
    },
    editRow: {
      saveTooltip: "ha kvar",
      cancelTooltip: "Annullera",
      deleteText: "Vill du ta bort den här raden?",
    },
    addTooltip: "tillägg",
    deleteTooltip: "radera",
    editTooltip: "redigera",
  },
  header: {
    actions: "handling",
  },
  grouping: {
    groupedBy: "Gruppering:",
    placeholder: "Dra rubriken...",
  },
  pagination: {
    firstTooltip: "första sidan",
    firstAriaLabel: "första sidan",
    previousTooltip: "Föregående sida",
    previousAriaLabel: "Föregående sida",
    nextTooltip: "Följande sida",
    nextAriaLabel: "Följande sida",
    labelDisplayedRows: "{from}-{to} alla {count}",
    labelRowsPerPage: "Antal rader per sida:",
    lastTooltip: "Sista sidan",
    lastAriaLabel: "Sista sidan",
    labelRowsSelect: "linje",
  },
  toolbar: {
    addRemoveColumns: "Lägg till/ta bort kolumner",
    nRowsSelected: "{0} Radval",
    showColumnsTitle: "Visa kolumner",
    showColumnsAriaLabel: "Visa kolumner",
    exportTitle: "produktion",
    exportAriaLabel: "produktion",
    exportCSVName: "CSVproduktion",
    exportPDFName: "PDFproduktion",
    searchTooltip: "Sök",
    searchPlaceholder: "Sök",
    searchAriaLabel: "Sök",
    clearSearchAriaLabel: "klar",
  },
};

 const Table= ({data, columns, headerStyle, rowStyle, search, actions,editable,pageSize,paging,maxBodyHeight,selection,onSelectionChange}) => {
  return (
    <div className="MTable">
      <MaterialTable style={{boxShadow:'none'}} stickyHeader
        options={{
          headerStyle: headerStyle,
          rowStyle: rowStyle, 
          pageSize:pageSize,
          paging:paging,       
          actionsColumnIndex: -1,
          search:search,
          maxBodyHeight:maxBodyHeight,
          searchFieldAlignment:"right",
          searchFieldStyle:{
          // border: "2px solid #e2e2e4" ,
          marginbottom:'20px'
          },
          selection :selection
        }}
        title={null}
        columns={columns}
        data={data}
        //editable={editable}
        className="wrap"
        actions={actions}
        onSelectionChange={onSelectionChange}
        localization={localizationSwedish}
      />
    </div>
  );
}

export default Table;