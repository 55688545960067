import React from 'react'
import{Table } from 'react-bootstrap'
import './Invoice.css'
import logo from '../../assets/images/Logo.png'
import Pdf from "react-to-pdf";
import Auth from "../../ServerCall/Auth";
import moment from 'moment';
import {URL} from '../../ServerCall/Config';
import axiosApiInstance from '../../ServerCall/Axios.Instance';

export default class Invoice extends React.Component{
    constructor() {
        super();
        this.state = {
          name: 'Printer',
          invoice:''
        };
      }
      componentDidMount(){
        
        var orderId = this.props.location.state.orderId;
        axiosApiInstance.post(`${URL}/orders/getInvoice`,{"user_id":Auth.getUserId(),"order_id":orderId})
        .then(res => {
          if(res.status===200){
            let invoiceData= res.data.data[0];
            this.setState({invoice: invoiceData});
          //this.setState({products: newProduct });
          }else{
            console.log("Else");
          }
        }).catch(err => {
          console.log(err);
        })
       // this.setState({orderId:orderId});
      }
      printReceipt() {
        window.print();
      }
     
    render(){
        const ref = React.createRef(); 
        const {invoice} = this.state;
        return(
         
            <div className="invoice-box" ref={ref} >
                <h4 className="text-center mb-3">Invoice</h4>
                <Table cellpadding="0" cellspacing="0" className="text-cenetr">
                    <tr class="top">
                        <td colspan="2">
                            <Table>
                                <tr>
                                    <td class="title">
                                        <img src={logo} style={{width:'50%', maxwidth:'200px'}}/>
                                    </td>
                                    <td>
                                        Invoice #: {invoice.PK_Invoiceid}<br/>
                                        Created: {moment(invoice.InvoiceDate).format('LL')}<br/>
                                        Due: {moment(invoice.InvoiceDate).format('LL')}
                                    </td>
                                </tr>
                            </Table>
                        </td>
                    </tr>
                    
                    <tr className="information">
                        <td colspan="2">
                            <Table>
                                    <tr>
                                        <td>
                                            Sparksuite, Inc.<br/>
                                            12345 Sunny Road<br/>
                                            Sunnyville, CA 12345
                                        </td>
                                        
                                        <td>
                                            Acme Corp.<br/>
                                            {invoice.SureName + '' + invoice.LastName}<br/>
                                            {invoice.Mailad}
                                        </td>
                                    </tr>
                            </Table>
                        </td>
                    </tr>
                    <tr className="heading">
                        <td className="pl-2"> Payment Method</td>
                        <td className="pl-2">Check #</td>
                    </tr>
                    <tr className="details">
                        <td className="pl-2">Check</td>
                        <td className="pl-2">  1000</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{width:'100%',border:'none'}}>
                            <Table className="table table-hover text-right thead-light w-100">
                                <tr className="heading" colSpan={5}>
                                    <td>Id</td>
                                    <td className="text-center">Products</td>
                                    <td>Qty</td>
                                    <td>Unit Cost</td>
                                    <td>Total</td>
                                   
                                </tr>
                               
                                    {invoice.order_items&&invoice.order_items.map((item, i) => 
                                        <>
                                        <tr colSpan={4} key ={i}>
                                            <td>{item.PID}</td>
                                            <td className="text-center">{item.Descr}</td>
                                            <td>{item.OrderQty}</td>
                                            <td>{item.Comp_Price}</td>
                                            <td>{item.OrderPrice} kr</td>
                                           
                                        </tr>
                                        </>
                                     )} 
                               
                                <tfoot>
                                    <tr>
                                        <td colSpan={4}>Total Cost: </td>
                                        <td>{invoice.OrderTotalAmt} kr</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </td>
                    </tr>
                  
                    
                    {/* <tr className="heading">
                        <td>
                            Item
                        </td>
                        
                        <td>
                            Price
                        </td>
                    </tr> */}
                    
                    {/* <tr className="item">
                        <td>
                            Product 1
                        </td>
                        
                        <td>
                            $300.00
                        </td>
                    </tr>
                    
                    <tr className="item">
                        <td>
                            Product 2
                        </td>
                        
                        <td>
                            $75.00
                        </td>
                    </tr>
                    
                    <tr className="item last">
                        <td>
                            Product 3
                        </td>
                        
                        <td>
                            $10.00
                        </td>
                    </tr>
                    
                    <tr className="total">
                        <td></td>
                        
                        <td>
                        Total: $385.00
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td>

                        </td> 
                        <td> <button to="#" className=" hide-on-print btn saveBtn mr-2 mt-2" onClick={this.printReceipt}>Print!</button></td>
                    </tr> */}
                </Table>
                <button className="hide-on-print" onClick={this.printReceipt}>Print</button>
                <Pdf targetRef={ref} filename="Invoice.pdf">
                    {({ toPdf }) => <button  className="hide-on-print ml-2" onClick={toPdf}>Generate Pdf</button>}
                 </Pdf>
        </div>
      

        )

    }
}