import React, { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import "react-day-picker/lib/style.css";

const TotalSummary = (props) => {
  const [final, setFinal] = useState([]);

  useEffect(() => {
    let cartProduct = props.cartProduct;
    let taxData = [];
    let final = [];
    cartProduct &&
      cartProduct.map((data) => {
        taxData.push({ Tax: data.Tax, taxPrice: "", Price: data.Price });
      });
    // .toFixed(2)} Kr
    taxData &&
      taxData.map((data) => {
        let isPresent = final.filter((v, i) => {
          return v.Tax === data.Tax;
        });
        if (isPresent.length) {
          let index = final.indexOf(isPresent[0]);
          final[index].Price = final[index].Price + data.Price;
          final[index].taxPrice = (final[index].Price * final[index].Tax) / 100;
        } else {
          if (data.Tax !== 0) {
            let obj = {
              Tax: data.Tax,
              Price: data.Price,
              taxPrice: (data.Price * data.Tax) / 100,
            };
            final.push(obj);
          }
        }
      });
    if (final.length > 0) {
      setFinal(final);
    } else {
      let obj = {
        Tax: 0,
        taxPrice: "Taxes are not applicable",
      };
      final.push(obj);
      setFinal(final);
    }
    // console.log(final, 'final');
    // console.log(c, "c array");
  }, [props, props.customerNo]);

  return (
    <div className="cart-total-fixed">
      <Row>
        <Col md={3}>Antal Artiklar {props.itemCount}</Col>
        <Col md={3}></Col>
        <Col md={6}>
          Brutto (inkl. moms) {Number(props.totalOrder).toFixed(2)}
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(TotalSummary);
