import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Card } from "react-bootstrap";
import InfoIcon from "@material-ui/icons/Info";
import axios from "axios";
import { URL } from "../../ServerCall/Config";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const Login = (props) => {
  const classes = useStyles();
  const [errorComPassword, setErrorComPassword] = React.useState("");
  const [errorPassword, setErrorPassword] = React.useState("");
  const [state, setstate] = React.useState({
    confirmPass: "",
    password: "",
  });
  const formvalid = () => {
    let isValid = true;
    // state.password !== ''?setErrorPassword(''):setErrorPassword("Valid address Req.");
    if (!state.password) {
      setErrorPassword("Please Enter Password");
      isValid = false;
    } else if (
      !state.password.match(
        /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
      )
    ) {
      setErrorPassword("Please enter secure and strong password");
      isValid = false;
    } else {
      setErrorPassword("");
    }
    if (!state.confirmPass) {
      setErrorComPassword("Please Enter Confirm Password");
      isValid = false;
    } else if (state.confirmPass !== state.password) {
      setErrorComPassword("Password Dosen't Match");
      isValid = false;
    } else {
      setErrorComPassword("");
    }
    return isValid;
  };
  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
    switch (e.target.name) {
      case "password":
        e.target.value === ""
          ? setErrorPassword("Please Enter Password")
          : setErrorPassword("");
        break;
      case "confirmPass":
        e.target.value === ""
          ? setErrorComPassword("Please Enter Confirm Password")
          : setErrorComPassword("");
        break;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { match = {} } = props;
    const { params = {} } = match;
    const resetPasswordToken = params.id;
    const isValid = formvalid();
    let request = {
      token: resetPasswordToken,
      newPassword: state.password,
    };
    if (isValid) {
      axios
        .post(`${URL}/users/resetPassword`, request)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.status === 400) {
              swal("Someting went wrong", "", "warning");
            } else {
              swal("Reset Successfully", "", "success").then(
                (res) => (window.location.href = "/")
              );
            }
          }
        })
        .catch((err) => {
          console.log("Test", err);
        });
    }
  };
  return (
    <Container
      className="LoginContainer formWraper mt-4"
      component="main"
      maxWidth="xs"
    >
      <Card className="pl-4 pr-4 pb-5">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <br />
          <Typography component="h3" variant="h5">
            Reset Password
          </Typography>
          <form
            className={classes.form}
            style={{ position: "relative" }}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              onChange={(e) => {
                handleChange(e);
              }}
              label="Password"
              name="password"
              type="password"
              autoComplete="password"
              autoFocus
            />
            <span className="InfoSpan">
              <InfoIcon />
            </span>
            <div className="validationGuide">
              <span>
                {"1. At least 8 characters or more characters"} <br />
              </span>
              <span>
                {"2. A mixture of both uppercase and lowercase letters.."}
                <br />{" "}
              </span>
              <span>
                {
                  "3. Inclusion of at least one special character,e.g.,! @ # ? ]"
                }{" "}
                <br />
              </span>
              <span>{"4. A mixture of letters and numbers."}</span>
            </div>
            {errorPassword !== "" ? (
              <label style={{ fontSize: 12, color: "red" }}>
                {errorPassword}
              </label>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={(e) => {
                handleChange(e);
              }}
              name="confirmPass"
              label="Confirm Password"
              type="password"
              id="confirmPass"
              autoComplete="current-confirmPass"
            />
            {errorComPassword !== "" ? (
              <label style={{ fontSize: 12, color: "red" }}>
                {errorComPassword}
              </label>
            ) : null}
            <br />
            <br />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-0 mb-3"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Reset
            </Button>
          </form>
        </div>
      </Card>
    </Container>
  );
};
export default Login;
