import React, { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "../../../ServerCall/Config";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  post: {
    marginBottom: theme.spacing(2),
  },
}));

function BlogPage() {
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(`${URL}/getBlogPosts`);
        setPosts(data);
      } catch (error) {
        setError(error);
      }
    }
    fetchData();
  }, []);

  if (error) {
    return <p>An error occurred: {error.message}</p>;
  }

  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
    setModalOpen(false);
  };

  return (
    <div className="nyheter-new">
      {posts.map((post) => (
        <div key={post.id} className={classes.post}>
          <h2 className="nyheter-h2">{post.title}</h2>
          <p>
            {post.content.length > 100
              ? `${post.content.slice(0, 100)}...`
              : post.content}
          </p>
          <Button
            id="nyheter-button"
            variant="outlined"
            color="primary"
            onClick={() => handleOpenModal(post)}
          >
            Läs mer
          </Button>
        </div>
      ))}
      <Dialog
        fullWidth
        maxWidth="md"
        open={modalOpen}
        onClose={handleCloseModal}
      >
        <DialogTitle id="blog-modal-titleid" className="blog-modal-title">
          {selectedPost?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="blog-modal-contentid"
            className="blog-modal-content"
          >
            {selectedPost?.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            stänga
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BlogPage;
