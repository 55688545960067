import {
  SET_CUSTOMER,
  SET_PRODUCTS,
  SET_SEARCH_PRODUCT,
  SET_CART_COUNT,
  SET_ACCESS_LEVEL,
  SET_ADMINACCESS_LEVEL,
  SET_CLIENT,
  SET_REF_TOP_NAV,
  SET_ADMIN_SECTION,
  SET_TOTAL_PRICE
} from '../action/customerAction'

const initialState = {
  customerNo:'',
  products: [],
  searchName: '',
  count:0,
  accesslvl:'',
  adminAccess:'',
  purchase:'',
  refresh: false,
  isAdminSection: false,
  totalPrice: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER:
        return{
          ...state,
          customerNo: action.payload
        }

    case SET_PRODUCTS:
      return{
        ...state,
        products: action.payload
      }

    case SET_SEARCH_PRODUCT:
      return{
        ...state,
        searchName: action.payload
      }

    case SET_CART_COUNT:
      return{
        ...state,
        count: action.payload
      }

    case SET_ACCESS_LEVEL:
      return{
        ...state,
        accesslvl: action.payload
      }

    case SET_ADMINACCESS_LEVEL:
      return{
        ...state,
        adminAccess: action.payload
      }

    case SET_CLIENT:
      return{
        ...state,
        purchase: action.payload
      }
      
    case SET_REF_TOP_NAV:
      return{
        ...state,
        refresh: action.payload
      }
    
    case SET_ADMIN_SECTION:
      return{
        ...state,
        isAdminSection: action.payload
      }

    case SET_TOTAL_PRICE:
      return{
        ...state,
        totalPrice: action.payload
      }

   
    default:
      return state
  }
}
