import React from 'react'
import { Container ,Col,Row,Card} from 'react-bootstrap'

import Header from '../../components/header/Header'
import OrderItemList from './Components/OrderItemList'
import CheckIcon from '@material-ui/icons/Check';
import Footer from '../../components/footer/Footer';
import axiosApiInstance from '../../ServerCall/Axios.Instance';
import Auth from "../../ServerCall/Auth";
import {URL} from '../../ServerCall/Config';

//const emailId = Auth.getEmail();

export default class OrderConfirmation extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            orderProduct:'',
            itemCount:0,
            total:0,
            shipping:0,
            tax:0,
            OrderTaxAmt:0
        }
       // this.handleChangeList = this.handleChangeList.bind(this);
    }
    componentDidMount(){
      var orderId = this.props.location.search.slice(1);
        let id = Auth.getUserId();
        let orderData = {
          "User_id":id,
          "OrderID":orderId
         };
         axiosApiInstance.post(`${URL}/orders/getOrders`,orderData)
        .then(res => {
          if(res.status === 200){
            this.setState({orderProduct: res.data.data[0]});
           // console.log("res.data.data[0]",res.data.data[0]);
            // let Count = res.data.data;
            // var value = 0;
            // var Tax = 0;
            // for(let i = 0; i < Count.length; i++) {
            //     value += Count[i].Base_price;
            //     Tax += Count[i].Tax;
            //     this.setState({itemCount:Count.length,total:value,tax:Tax});
            // }
          }else{
            console.log("else");
          }
        }).catch(err => {
          console.log(err);
        })
      }
    render()
    {
        return(
            <div>
                 <Header/>
                <Container fluid className="Wraper mb-5" >
                  <Card className="pt-4 pb-4">
                    <Row>
                        <Col  md={11}  sm={12} xs={12} style={{margin:'0 auto'}}>
                            <h3 className="text-left pb-2"><CheckIcon className="Chekicon"/>  
                            {/* Your Order is Confirmed */}
                            Din Beställning Är Bekräftad
                            </h3>
                            <p className="text-left pl-4">
                              {/* An email has been sent to your mail address  */}
                              {/* Ett e-postmeddelande har skickats till din e-postadress "{emailId}" med en bekräftelse på er order. Tack så mycket.  */}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={11} sm={12} xs={12} style={{margin:'0 auto'}}>
                            <OrderItemList orderProduct={this.state.orderProduct} itemCount={this.state.itemCount} total={this.state.total} shipping={this.state.shipping} tax={this.state.tax} OrderTaxAmt={this.state.OrderTaxAmt}/>
                        </Col>
                    </Row>
                    </Card>
                </Container>
                <Footer/>
            </div>
        );
    }
}
   
