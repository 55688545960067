import React from 'react'
import { Container ,Col,Row} from 'react-bootstrap'
import Panel from './Components/Panel'
import Header from '../../components/header/Header'
import CartSummary from '../../pages/Cart/Components/CartSummary'
import Footer from '../../components/footer/Footer'

export default class Checkout extends React.Component{
    render()
    {
        return(
            <div>
                  <Header/>
                <Container fluid className="Wraper mb-5" >
                    <Row>
                        <Col md={8}>
                            <Panel/>
                        </Col>
                        <Col md={4} className="checkoutcartsummry">
                            <CartSummary/>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        );
    }
}
   

