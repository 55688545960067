import React, { useState } from "react";
import classNames from "classnames";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAppleAlt,
  faBlender,
  faChevronRight,
  faBlenderPhone,
  faCarrot,
  // Add more icons you need in your project
} from "@fortawesome/free-solid-svg-icons";

import { Collapse, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubMenu = (props) => {
  library.add(
    faBlenderPhone,
    faAppleAlt,
    faCarrot
    // Add more icons you need in your project
  );
  const [collapsed, setCollapsed] = useState(true);
  const [menucollapsed, setmenuCollapsed] = useState([]);
  const [subMenu, setSubMenu] = useState([]);

  const toggleCollapse = (index, activeIndex) => {
    let newArr = [...subMenu];
    for (var j = 0; j < newArr.length; j++) {
      if (j === index) {
        newArr[j].activeIndex = !activeIndex;
      } else {
        newArr[j].activeIndex = false;
      }
      if (newArr[j].Sub_items !== undefined) {
        for (var k = 0; k < newArr[j].Sub_items.length; k++) {
          newArr[j].Sub_items[k].childActiveIndex = false;
        }
      }
    }
    setSubMenu(newArr);
    let collapseCopy = [...menucollapsed];
    collapseCopy[index] = !collapseCopy[index];
    setmenuCollapsed(collapseCopy);
  };
  const {
    icon,
    title,
    items,
    linkItem,
    id,
    img,
    colapseId,
    toggleMenu,
    onClick,
  } = props;

  React.useEffect(() => {
    setSubMenu(items);
  }, []);

  const toggle = (index, activeIndex) => {
    let newArr = [...subMenu];
    for (var j = 0; j < newArr.length; j++) {
      if (j === index) {
        newArr[j].activeIndex = true;
      } else {
        newArr[j].activeIndex = false;
      }
    }
    setSubMenu(newArr);
  };

  const activeInnerChlidMenu = (index, subChildID, childActiveIndex) => {
    let newArr = [...subMenu];
    for (var j = 0; j < newArr.length; j++) {
      if (newArr[j].Sub_items !== undefined) {
        for (var k = 0; k < newArr[j].Sub_items.length; k++) {
          // if (k === index) {
          if (newArr[j].Sub_items[k].subChildId === subChildID) {
            newArr[j].Sub_items[k].childActiveIndex = true;
          } else {
            newArr[j].Sub_items[k].childActiveIndex = false;
          }
        }
      }
    }
    setSubMenu(newArr);
  };

  return (
    <div>
      <NavItem
        id={id}
        onClick={() => toggleMenu(colapseId, id)}
        className={classNames({ "menu-open": !colapseId })}
      >
        <NavLink
          className=" topDropdown dropdown-toggle"
          tag={Link}
          to={{
            pathname: linkItem,
            state: { ItemId: id, image: img, titleHead: title, page: 1 },
          }}
        >
          {props.parentIcon && (
            <FontAwesomeIcon
              className="parent-icon-submenu"
              icon={["fas", props.parentIcon.replace("fa-", "")]}
            />
          )}

          {/* Render the parent_icon */}
          {title}
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!colapseId}
        navbar
        className={classNames("items-menu ", { "mb-1": !colapseId })}
      >
        {subMenu &&
          subMenu.map((item, index) => (
            <>
              <NavItem
                key={index}
                id={index}
                onClick={() => {
                  item.Sub_items
                    ? toggleCollapse(index, item.activeIndex)
                    : toggle(index, item.activeIndex);
                  if (item.Sub_items && item.activeIndex === true) {
                    onClick();
                  }
                }}
                className={
                  item.activeIndex === true
                    ? "menu-open Submenuopen setActiveTab "
                    : null
                }
              >
                <NavLink
                  key={index}
                  id={index}
                  tag={Link}
                  to={{
                    pathname: `${linkItem}/${item.child_name}?${item.childId}`,
                    state: {
                      subCategory: item.child_name,
                      titleHead: title,
                      page: 1,
                    },
                  }}
                  className={
                    item.Sub_items
                      ? " dropMenunav NewDrop dropdown-toggle"
                      : null
                  }
                >
                  {item.child_icon && (
                    <FontAwesomeIcon
                      className="child-icon-submenu"
                      icon={["fas", item.child_icon.replace("fa-", "")]}
                    />
                  )}

                  {/* Render the child_icon */}
                  {item.child_name}
                </NavLink>
              </NavItem>
              {item.Sub_items && item.activeIndex === true ? (
                <Collapse
                  isOpen={item.activeIndex}
                  navbar
                  className={classNames(
                    "items-menu submenucollpse submenudown",
                    { "mb-1": item.activeIndex }
                  )}
                >
                  {item.Sub_items &&
                    item.Sub_items.map((m, i) => (
                      <NavItem
                        key={i}
                        id={i}
                        onClick={() => {
                          activeInnerChlidMenu(
                            i,
                            m.subChildId,
                            m.childActiveIndex
                          );
                          onClick();
                        }}
                        className={
                          m.childActiveIndex === true ? " setActiveTab " : ""
                        }
                      >
                        <NavLink
                          tag={Link}
                          to={{
                            pathname: `${linkItem}/${item.child_name}/${m.subChildName}?${m.subChildId}`,
                            state: {
                              childCategory: m.subChildName,
                              subCategory: item.child_name,
                              titleHead: title,
                              page: 1,
                            },
                          }}
                        >
                          {m.subChildName}
                        </NavLink>
                      </NavItem>
                    ))}
                </Collapse>
              ) : null}
            </>
          ))}
      </Collapse>
    </div>
  );
};

export default SubMenu;
