import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../Common/Table';
import Auth from '../../../ServerCall/Auth'
import { BrowserRouter,Redirect,Route, useHistory} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Switch , Checkbox } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import { URL } from '../../../ServerCall/Config';
import { useEffect } from 'react';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import { Col, Form, Row } from 'reactstrap';
import swal from 'sweetalert';

const headerStyle = {
    backgroundColor: '#659f2f',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 'normal',
    padding: '5px',
    position: 'sticky', 
    top: 0 ,
}

const UserList = (props) =>{
    const id = Auth.getUserId();
    const history = useHistory();
    const [data, setData] = useState([]);
//     FirmNo: 1
// LastName: "Mishra"
// Mailad: "varsha.mishra@softlabsgroup.com"
// MobPh: "9876543210"
// Phone: "9876543210"
// SureName: "varsha"
// UsrNo: 4932
// VISMA_No: 6156

    const [column, setColumn] = useState([
        { title: "Sr no", field: 'tableData.id', render:rowData => <p>{rowData.tableData.id+1}</p>},
        { title: "Förnamn", field: "SureName"},
        { title: "Efternamn", field: "LastName"},
        { title: "Telefonnummer", field: "Phone"},
        { title: "E-postadress", field: "Mailad"},
        { title: "User No", field: "UsrNo", hidden:true},
        { title: "Aktiv/Inaktiv", field: "Status", render: rowData => <p><Switch className="switchbutton" checked={rowData.Status} onChange={()=>onSwitch(rowData.Status,rowData.UsrNo)}/></p>},
    ]);
    const [details,setDetails] = useState({})

    useEffect(()=>{
        getUserList();
    }, []);

    const onSwitch = (status, userNo) =>{
        swal('Är du säker?', `Du vill ${status === true ? 'vara inaktiv' : 'aktivera aktiv'} användare`, 'warning',{
            buttons: ['Nej','Ja'],
            dangerMode: true
        })
        .then(yes =>{
            if(yes){
                let reqBody = {
                    "userNo": userNo,
                    "status": !status
                }
                axiosApiInstance.put(`${URL}/users/updateUserStatus`,reqBody)
                .then(res =>{
                    if(res.status === 200){
                        if(res.data.status === 200){
                            getUserList();
                        }
                        else{
                            swal('something went wrong', '', 'info');
                        }
                    }
                })
                .catch(err => console.log(err))
            }
        })
        .catch(err => console.log(err));
    }

    const getUserList = () =>{
        // 4979
        axiosApiInstance.get(`${URL}/users/getUserListByUserNo/${id}`)
        .then(res=>{
            // console.log(res);
            if(res.status === 200){    
                let UsersData = res.data.data;
                setData(UsersData);
            }
            // else{

            // }
        })
        .catch(err=>{console.log(err);})
    }
    
    return(
        <Container fluid className="p-0 userlisttblwrap" component="main" >
            <Card className="shadow cardHeight">
                <Row>
                    <Col lg='12'>
                    <h3 className="text-center blueText mt-2">
                        {/* User List */}
                        Användarlista
                    </h3>
                    </Col>
                </Row>
                <Form>
                    <Col>
                        <Table data={data} 
                            columns={column} 
                            maxBodyHeight="400px" 
                            pageSize={5} 
                            paging={true} 
                            search={true}
                            headerStyle={headerStyle}
                            actions={[
                                {
                                icon: "edit",
                                tooltip: "Edit",
                                onClick: (event, rowData) => {history.push(`/CreateUser?id=${rowData.UsrNo}`);},
                                },
                            ]}
                        />
                    </Col>
                </Form>
            </Card>  
        </Container>
    );
}

export default UserList;