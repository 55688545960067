import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Card} from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Signup = () => {
  const classes = useStyles();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPass: "",
    wantEmailNotify: true
  });
  
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");


  const handleChange = (e) => {
    const {name, value} = e.target;
    setForm({ ...form, [name]: value});
    const validName = RegExp('[a-z]{1,10}');
    switch (name){
      // case 'firstName':
      //   value
      // break
    }
  }

  const validateForm = () => {
    const isValid = true;
    const {firstName, lastName, email, password, confirmPass} = form; 
    if(!firstName){
      setErrorFirstName('Please Emter First Name');
    }
    else{
      setErrorFirstName('');
    }
    if(!lastName){
      setErrorLastName('Please Emter Last Name');
    }
    else{
      setErrorLastName('');
    }
    if(!email){
      setErrorEmail('Please Emter Email');
    }
    else{
      setErrorEmail('');
    }
    if(!password){
      setErrorPassword('Please Emter Password');
    }
    else{
      setErrorPassword('');
    }
    if(!confirmPass){
      setErrorConfirmPass('Please Emter Confirm Password');
    }
    else{
      setErrorConfirmPass('');
    }
  }

  const onSubmit= (e) =>{
    e.preventDefault();
    const isValid = validateForm();
    if(isValid){
      console.log(form);
    }
  }

  return (
    <Container className="LoginContainer formWraper mt-4" component="main" maxWidth="sm">
     <Card className="pl-4 pr-4 pb-5">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="signWp">
          Sign Up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                onChange={(e)=>handleChange(e)}
                label="First Name"
                autoFocus
              />
              {
                errorFirstName !== "" ?
                <label style={{ fontSize: 12, color: "red" }}>
                { errorFirstName}</label>: null
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                onChange={(e)=>handleChange(e)}
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
              {
                errorLastName !== "" ?
                <label style={{ fontSize: 12, color: "red" }}>
                { errorLastName}</label>: null
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                onChange={(e)=>handleChange(e)}
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              {
                errorEmail !== "" ?
                <label style={{ fontSize: 12, color: "red" }}>
                { errorEmail}</label>: null
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                onChange={(e)=>handleChange(e)}
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {
                errorPassword !== "" ?
                <label style={{ fontSize: 12, color: "red" }}>
                { errorPassword}</label>: null
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPass"
                onChange={(e)=>handleChange(e)}
                label="Confirm Password"
                type="password"
                id="confirmPass"
                autoComplete="current-password"
              />
              {
                errorConfirmPass !== "" ?
                <label style={{ fontSize: 12, color: "red" }}>
                { errorConfirmPass}</label>: null
              }
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // className={classes.submit}
            onClick={(e)=>onSubmit(e)}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/?login"   variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
     
      </Card>
    </Container>
  );
}
export default Signup;