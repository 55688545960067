import React, { useState, useEffect } from "react";
import { Button, Row, Col, Modal, Container } from "react-bootstrap";
import { useHistory, withRouter } from "react-router";
/* import { CardTitle, Input, FormGroup } from 'reactstrap'
import swal from 'sweetalert'; */

/* import DayPicker from 'react-day-picker'; */
/* import 'react-day-picker/lib/style.css'; */
/* import moment from 'moment'; */
/* 
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment'; */

// Make sure moment.js has the required locale data
import "moment/locale/sv";
import "moment/locale/ar";
import "moment/locale/it";
/* 
import { URL } from '../../../ServerCall/Config';
import axiosApiInstance from '../../../ServerCall/Axios.Instance'; */
import { connect } from "react-redux";
import { setRefTopNav } from "../../../redux/action/customerAction";

const CartSummary = (props) => {
  const [final, setFinal] = useState([]);

  /*     const [showHide, setShowHide] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState('');
    const [disableDaysOfWeek, setDisableDaysOfWeek] = useState([]);
    const [allDeliveryData, setAllDeliveryData] = useState([]);
    const [disableDays, setDisableDays] = useState([]);
    const [pickUpDates, setPickUpDates] = useState([]);
    const [typeOfDelivery, setTypeOfDelivery] = useState('');
    const [isAvailable, setIsAvailable] = useState(false); */

  useEffect(() => {
    let cartProduct = props.cartProduct;
    let taxData = [];
    let final = [];
    cartProduct &&
      cartProduct.map((data) => {
        taxData.push({ Tax: data.Tax, taxPrice: "", Price: data.Price });
      });
    // .toFixed(2)} Kr
    taxData &&
      taxData.map((data) => {
        let isPresent = final.filter((v, i) => {
          return v.Tax === data.Tax;
        });
        if (isPresent.length) {
          let index = final.indexOf(isPresent[0]);
          final[index].Price = final[index].Price + data.Price;
          final[index].taxPrice = (final[index].Price * final[index].Tax) / 100;
        } else {
          if (data.Tax !== 0) {
            let obj = {
              Tax: data.Tax,
              Price: data.Price,
              taxPrice: (data.Price * data.Tax) / 100,
            };
            final.push(obj);
          }
        }
      });
    if (final.length > 0) {
      setFinal(final);
    } else {
      let obj = {
        Tax: 0,
        taxPrice: "Taxes are not applicable",
      };
      final.push(obj);
      setFinal(final);
    }
    // console.log(final, 'final');
    // console.log(c, "c array");
    const scheduleDate = localStorage.getItem("scheduleDate");
    // debugger
    if (
      scheduleDate !== "null" &&
      scheduleDate !== null &&
      scheduleDate !== ""
    ) {
      // setNewDate(scheduleDate);
      props.receiveDate(scheduleDate);
    } else {
      props.receiveDate("");
    }
  }, [props, props.customerNo]);
  /* 
    const modifiers = {
        // pickUpDay: ([new Date(2018, 9, 4),new Date(2018, 9, 8)]),
        pickUpDay: (pickUpDates),
        // birthday: new Date(2018, 9, 30),
    };
     */
  /*     const getDeliveryDates = () =>{
        axiosApiInstance.get(`${URL}/getDeliveryTerm/${props.customerNo}`)
        .then(res=>{
          // debugger
          if(res.status === 200){
            // console.log(res);
            if(res.data.status === 200){
            let periodOfDelivery = res.data.data;
            // setStartDate(periodOfDelivery[0].DeliveryDate);
            // setEndDate(periodOfDelivery[periodOfDelivery.length-1].DeliveryDate);
            setAllDeliveryData(periodOfDelivery);
            let allDateInBetween = [];
            let date = new Date(periodOfDelivery[0].DeliveryDate);
            while (date <= new Date(periodOfDelivery[periodOfDelivery.length-1].DeliveryDate)){
              allDateInBetween.push(new Date(date));
              date.setDate(date.getDate()+1);
            }
            let availableDays = [];
            let pickUp = [];
            periodOfDelivery && periodOfDelivery.map(data=>{
              availableDays.push(new Date(data.DeliveryDate))
              if(data.PicupOnly === true){
                pickUp.push(new Date(data.DeliveryDate))
              }
            })
            let allDayFormated = allDateInBetween && allDateInBetween.map(date =>{
              return moment(date).format();
            })
            let availableDayFormated = availableDays && availableDays.map(date =>{
              return moment(date).format();
            })
            let disableDates = allDayFormated.filter(val => !availableDayFormated.includes(val));
            disableDates.forEach((element, index) => {
              disableDates[index] = new Date(element);
            });
            //const xyz = disableDates.join();
            disableDates.push(
              {
                before: new Date(periodOfDelivery[0].DeliveryDate),
                after: new Date(periodOfDelivery[periodOfDelivery.length-1].DeliveryDate)
              }
            )
            setPickUpDates(pickUp);
            setDisableDays(disableDates);
            // console.log(periodOfDelivery);
            }
            else{
              let disableContent = [];
              disableContent.push({ daysOfWeek: disableDaysOfWeek },
                {
                  before: new Date(),
                  // after: new Date()
                }
              )
              setDisableDays(disableContent);
            }
          }
        })
        .catch(err=>{console.log(err)})
    } */

  /*     const handleModalShowHide = () => {
        setShowHide(!showHide);
        setAllDeliveryData([]);
        setPickUpDates([]);
        setIsAvailable('');
        setDeliveryDate('');
    }

    const checkAvailability = (day, isPickUp) =>{
        let stopTimeDate = '';
        allDeliveryData && allDeliveryData.map(data=>{
          if(moment(data.DeliveryDate).format('DD-MM-YYYY') === moment(day).format('DD-MM-YYYY')){
            stopTimeDate = data.StopDateTime;
          }
        })
        let isPickUpOn = isPickUp === true ? true : false;
        let request = {
          "custNo": props.customerNo,
          "stopDateTime": stopTimeDate,
          "orderDeliveryDate": moment(day).format('YYYY-MM-DD'),
          "isPicupOnly": isPickUpOn
        }
        // console.log(request);
        axiosApiInstance.post(`${URL}/orders/newCheckOrderDateAndTime`, request)
        .then(res=>{
          if(res.status === 200){
            // debugger
            const isAvailable = res.data.isDateAvailable;
            setIsAvailable(isAvailable);
          }
        })
        .catch(err=>{console.log(err)})
    } 

    const handleDayClick = (day, { selected, pickUpDay, disabled }, e) => {
        // e.target.style.background = 'red';
        e.target.setAttribute('isavai',true);
        if (disabled) {
        setDeliveryDate('');
        setIsAvailable(false);
        // Day is disabled, do nothing
        return;
        }
        if (selected) {
        // Unselect the day if already selected
        setDeliveryDate(day);
        return;
        }
        else {
        setDeliveryDate(day);
        checkAvailability(day, pickUpDay);
        //this.setState({ deliveryDate: day });
        }
        if(pickUpDay){
        setTypeOfDelivery('Pick-Up');
        }else{
        setTypeOfDelivery('Delivery');
        }
    }

    const receiveDeliveryDate = (e, date) => {
      // setNewDate(date.toISOString());
      props.receiveDate(date.toISOString());
      handleModalShowHide();
      localStorage.setItem('scheduleDate', date);
      if(isAvailable){
          props.checkOutHandle(e, date);
      }else{
          // swal('Something went wrong','with delivery date','warning')
          swal('Något gick fel','med leveransdatum','warning')
      }
      // swal(`${typeOfDelivery} Schedule on ${moment(date).format('ll')}`, `checkout to complete your order ..`, "success")
    } */

  return (
    <Col className="align-self-end">
      <div className="card cart-summary border-0">
        <div className="cart-detailed-totals">
          <div className="card-block py-1 text-right">
            {/* <div className="cart-summary-line row">
                        <small className="label col delitxt">Delivery Schedule Date</small>
                        <small className="value col delitxt">
                            {props.deliveryDate!==''
                             ? moment(props.deliveryDate).format('LL')
                             : 'Not Schedule Yet !'
                            }
                        </small>
                        </div>
                        <br/> */}

            {/* <div className="cart-summary-line row cart-total mb-2">
                            <span className="label col">Item Value:</span>
                            <span className="value col">{parseFloat((props.total).toFixed(2))} kr</span>
                        </div> */}
            <div className="cart-summary-line row cart-total mb-2">
              <span className="label col">
                {/* Total (tax excl.) */}
                Netto (exkl. moms)
              </span>
              <span className="value col">
                {parseFloat(props.total.toFixed(2))} kr
              </span>
            </div>
            <div className=" mb-3">
              {final &&
                final.map((data, i) => (
                  <span className="cart-summary-line row mb-2" key={i}>
                    <span className="label col">
                      {/* Tax */} Moms {data.Tax === 0 ? null : `${data.Tax} %`}
                    </span>
                    <span className="value col">
                      {props.cartProduct == ""
                        ? `0 kr`
                        : data.Tax === 0
                        ? "Skatt är inte tillämplig"
                        : `${data.taxPrice.toFixed(2)} kr`}
                    </span>
                    {/*"Tax is not applicable"*/}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

const mapStoreToProps = (state) => {
  // console.log(state,"state");
  return {
    cartCount: state.customer.count,
    customerNo: state.customer.customerNo,
    refresh: state.customer.refresh,
  };
};

const mapDispatchToProps = (dispatch) => ({
  //setCartCount: (count) => dispatch(setCartCount(count)),
  setRefTopNav: (refresh) => dispatch(setRefTopNav(refresh)),
});

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(CartSummary)
);
