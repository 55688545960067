import React from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";

import ListCart from "./ListCart";

import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import Auth from "../../../ServerCall/Auth";
import { URL } from "../../../ServerCall/Config";

import {
  setCartCount,
  setTotalPrice,
} from "../../../redux/action/customerAction";
import { connect } from "react-redux";
import Loader from "../../../../src/pages/Common/Loader";

function SearchBar(props) {
  const [cartCount, setCart] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const acessToken = Auth.getToken();

  React.useEffect(() => {
    let id = Auth.getUserId();
    if (id !== undefined) {
      setLoading(true);
      let customer =
        !acessToken || props.customerNo === "" ? 0 : props.customerNo;
      axiosApiInstance
        .get(`${URL}/getCart/${id}/${customer}`)
        .then((res) => {
          // debugger
          if (res.data.status === 200) {
            // setCart(res.data.data.length);
            const newProduct =
              res.data.data &&
              res.data.data.map((file) => {
                if (file.purchased_Unit === "Kart") {
                  return {
                    ...file,
                    Tax: !file.isKampanj ? file.Tax : 0,
                    Price: file.Price_cart * file.Qty * file.Conv_facor_CART,
                    Base_price: file.Price_cart * file.Conv_facor_CART,
                    hiddenText: true,
                  };
                }
                if (file.purchased_Unit === "Kartplus") {
                  return {
                    ...file,
                    Tax: !file.isKampanj ? file.Tax : 0,
                    Price: file.Price_cart * file.Qty * 1,
                    Base_price: file.Price_cart * file.Conv_facor_CART,
                    hiddenText: true,
                  };
                } else if (file.purchased_Unit === "Styck") {
                  return {
                    ...file,
                    Tax: !file.isKampanj ? file.Tax : 0,
                    Price: file.Price_broken * file.Conv_facor_ST * file.Qty,
                    Base_price: file.Price_broken * file.Conv_facor_ST,
                    hiddenText: true,
                  };
                } else if (file.purchased_Unit === "Kg") {
                  return {
                    ...file,
                    Tax: !file.isKampanj ? file.Tax : 0,
                    Price: file.Comp_Price * file.Conv_facor_KG * file.Qty,
                    Base_price: file.Comp_Price * file.Conv_facor_KG,
                    hiddenText: true,
                  };
                }
              });
            let totalOrder = 0;
            for (let i = 0; i < newProduct.length; i++) {
              if (newProduct[i] !== undefined) {
                totalOrder +=
                  (newProduct[i].Tax / 100) * newProduct[i].Price +
                  newProduct[i].Price;
              }
            }
            setPrice(totalOrder);
            props.setTotalPrice(totalOrder);
            props.setCartCount(res.data.data.length);
            setLoading(false);
          } else {
            setLoading(false);
            props.setCartCount(0);
            props.setTotalPrice(0);
          }
        })
        .catch((err) => {
          setLoading(false);
          props.setCartCount(0);
          props.setTotalPrice(0);
          console.log(err);
        });
    } else {
      setLoading(false);
      setPrice(0);
      setCart(0);
      props.setCartCount(0);
      props.setTotalPrice(0);
    }
  }, [props.customerNo, props.cartCount]);
  return (
    <div>
      <Row>
        {loading ? <Loader /> : null}
        <Col md={12}>
          <ListCart price={props.totalPrice} cartCount={props.cartCount} />
        </Col>
      </Row>
    </div>
  );
}

const mapStoreToProps = (state) => {
  return {
    cartCount: state.customer.count,
    totalPrice: state.customer.totalPrice,
    customerNo: state.customer.customerNo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCartCount: (count) => dispatch(setCartCount(count)),
  setTotalPrice: (price) => dispatch(setTotalPrice(price)),
});

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(SearchBar)
);
