import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import SectionBanner from "./components/SectionBanner";
import TopSellingProduct from "./components/TopSellingProduct";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import BlogContent from "./components/BlogContent";
import CatalogTreWay from "./components/CatalogTreWay";
import Auth from "../../ServerCall/Auth";
import { URL } from "../../ServerCall/Config";
import axiosApiInstance from "../../ServerCall/Axios.Instance";
import { setAccessLevel } from "../../redux/action/customerAction";
import { connect } from "react-redux";

const config = {
  headers: {
    "content-type": "application/json",
  },
};

const HomePage = (props) => {
  const [customerNo, setCustomerNo] = useState();
  const [products, setProducts] = useState([]);
  const [condition, setCondition] = useState(true);
  const [menu, setMenu] = useState([]);

  const access_level = Auth.getAccess_level();
  var accesslvl = "";

  useEffect(async () => {
    let CustNo = props.customerNo;
    if (access_level !== undefined) {
      accesslvl = access_level.find((obj) => obj === 3);
      if (accesslvl !== undefined) {
        await props.setAccessLevel(accesslvl);
        axiosApiInstance
          .get(`${URL}/users/getMenusByAccessLevel/${accesslvl}`)
          .then((res) => {
            if (res.data.status === 200) {
              //  console.log(res.data.data);
              setMenu(res.data.data);
            } else {
              setMenu([]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    await getAllProducts(CustNo);
  }, [props.customerNo, props.accesslvl]);

  const getAllProducts = async (CustNo) => {
    if (CustNo === null) {
      await setCustomerNo(0);
      CustNo = 0;
    } else {
      await setCustomerNo(CustNo);
    }
    axios
      .get(`${URL}/campaignProducts`, config)
      .then((res) => {
        if (res.status === 200) {
          const newProduct = res.data.data.map((file) => {
            if (file.Standard_orderunit === "Kg") {
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "Kg",
                newCompPrice: file.Comp_Price,
                Comp_Price: file.Comp_Price,
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                newPrice: file.Comp_Price,
                default_price: file.Comp_Price,
                default_value: file.Conv_facor_KG,
                unite_price: file.Comp_Price,
                box_com_cart_price: file.comp_Price_cart,
              };
            } else if (file.Standard_orderunit === "Kartplus")
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "Kart",
                newCompPrice: file.Comp_Price,
                Comp_Price: file.Conv_facor_KG * file.Price_cart,
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                newPrice: file.Price_cart,
                default_price: file.Price_cart,
                default_value: file.Conv_facor_CART,
                unite_price: file.Price_cart,
                box_com_cart_price: file.comp_Price_cart,
              };
            else if (file.Standard_orderunit === "Kart")
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "Kart",
                newCompPrice: file.Comp_Price,
                Comp_Price: file.Conv_facor_KG * file.Price_cart,
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                newPrice: file.Price_cart,
                default_price: file.Price_cart,
                default_value: file.Conv_facor_CART,
                unite_price: file.Price_cart,
                box_com_cart_price: file.comp_Price_cart,
              };
            else if (file.Standard_orderunit === "Styck")
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "Styck",
                Comp_Price: file.Comp_Price,
                newCompPrice: file.Comp_Price,
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                newPrice: file.Price_broken,
                default_price: file.Price_broken,
                default_value: file.Conv_facor_ST,
                unite_price: file.Price_broken,
                box_com_cart_price: file.comp_Price_cart,
              };
            else
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "",
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                default_price: file.Comp_Price,
                newPrice: file.Comp_Price,
                unite_price: file.Comp_Price,
                box_com_cart_price: file.comp_Price_cart,
              };
          });
          setProducts(newProduct);
          setCondition(false);
        } else {
          // console.log("Else");
          setCondition(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setCondition(false);
      });
  };

  const onChangeUnit = (e, i, unit, kg, st, box, cKg, cSt, cBox, price) => {
    const selectedIndex = e.target.options.selectedIndex;
    let purchased_Unit = e.target.options[selectedIndex].getAttribute("keyid");
    const { name, value } = e.target;
    let newArr = [...products];
    if (purchased_Unit !== "Kart") {
      newArr[i].Comp_Price = newArr[i].newCompPrice;
      kg = newArr[i].newCompPrice;
    } else {
      newArr[i].Comp_Price = newArr[i].Price_cart;
    }
    // else if(unit === "KG" && purchased_Unit === "Kart" ){
    //   newArr[i].Comp_Price = box/cBox;
    // }
    // else if(unit === "Styck" && purchased_Unit === "Kart" ){
    //   let stWeight =  cSt/cKg;
    //   let weightPerBox = cBox*stWeight;
    //   newArr[i].Comp_Price = box/weightPerBox;
    // }
    // else if(unit === "Kart" && purchased_Unit === "Kart" ){

    //   newArr[i].Comp_Price = cKg*box;
    // }
    if (selectedIndex !== 0) {
      if (purchased_Unit === "Kg") {
        let changePrice = newArr[i].Comp_Price * cKg;
        newArr[i].newPrice = changePrice;
      } else if (purchased_Unit === "Styck") {
        let changePrice = newArr[i].Price_broken * cSt;
        newArr[i].newPrice = changePrice;
      } else {
        let changePrice = newArr[i].Price_cart * cBox;
        newArr[i].newPrice = changePrice;
      }
    } else {
      let prePrice =
        purchased_Unit === "Kg"
          ? newArr[i].Comp_Price
          : purchased_Unit === "Styck"
          ? newArr[i].Price_broken
          : newArr[i].Price_cart;
      newArr[i].newPrice = prePrice;
    }

    purchased_Unit === "Kg"
      ? (newArr[i].default_price = kg)
      : purchased_Unit === "Styck"
      ? (newArr[i].default_price = st)
      : (newArr[i].default_price = box);
    newArr[i].default_value = value;
    newArr[i].purchased_Unit = purchased_Unit;
    if (
      (purchased_Unit === "Kart" && newArr[i].Min_Quantity < 1) ||
      (purchased_Unit === "Kartplus" && newArr[i].Min_Quantity < 1)
    ) {
      newArr[i].newQuantity = 1;
      newArr[i].Min_Quantity = 1;
    } else {
      newArr[i].newQuantity = newArr[i].Min_Quantity;
    }
    // newArr[i].newPrice = newArr[i].default_price;
    // newArr[i].default_price = newArr[i].newPrice;
    newArr[i].unite_price = newArr[i].newPrice;
    setProducts(newArr);
  };

  const IncrementItem = (qty, i, ProdNo, amt, minqnty, Max_Quantity) => {
    let quantity = parseFloat(qty) + minqnty;
    var price = amt;
    var newArr = [...products];
    var index = newArr.findIndex((obj) => obj.ProdNo === ProdNo);

    let defaultAmt = newArr[i].unite_price;
    if (Max_Quantity === 0) {
    } else if (quantity > Max_Quantity) {
      quantity = Max_Quantity;
      // price = newArr[index].unite_price * Max_Quantity;
      newArr[index].hiddenText = true;
    } else if (quantity > 0) {
      // if (quantity === 1) {
      //   price = amt * 2;
      // } else {
      //   price = defaultAmt * quantity;
      // }
    } else {
      price = amt;
      quantity = minqnty;
    }
    if (quantity < 1) {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity.toFixed(2);
    } else if (quantity > 1) {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity;
    } else {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity;
    }
    setProducts(newArr);
  };

  const DecreaseItem = (qty, i, amt, minqnty) => {
    let quantity = parseFloat(qty) - minqnty;
    var price = amt;

    let newArr = [...products];
    let defaultAmt = newArr[i].unite_price;
    //let fact_qty = 0;
    if (quantity > 0) {
      // price = amt - defaultAmt;
    } else {
      quantity = minqnty;
      price = amt;
    }

    if (quantity < 1) {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity.toFixed(2);
    } else if (quantity > 1) {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity;
    } else {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity;
    }
    // products[i].stdOrder_Unit = fact_qty;
    setProducts(newArr);
  };

  const onclcikEvent = (e, i) => {
    setTimeout(() => {
      e.target.select();
    }, 20);
    let newArr = [...products];
    newArr[i].hiddenText = false;
    setProducts(newArr);
  };

  const onMouseLeaveEvent = (e, i) => {
    let newArr = [...products];
    newArr[i].hiddenText = true;
    setProducts(newArr);
  };

  const onChangeQty = (e, i, amt, minqnty, purchaseUnit, max) => {
    var price = amt;
    if (purchaseUnit === "Kg") {
      var quantity = e.target.value.replace(/KG/g, "").trim();
    } else {
      var quantity = e.target.value.replace(/ST/g, "").trim();
      quantity = parseFloat(Math.floor(quantity));
      if (isNaN(quantity) === true || quantity === 0) {
        quantity = 0;
      }
    }
    if (max === 0) {
    } else if (quantity > max) {
      quantity = max;
    } else if (quantity > 0) {
      price = amt * quantity;
    } else {
      price = amt;
    }

    let newArr = [...products];
    // newArr[i].newPrice = price;
    const re = /^[0-9.,]+$/;
    if (quantity === "") {
      newArr[i].newQuantity = 0;
    }
    if (re.test(quantity)) {
      newArr[i].newQuantity = quantity;
    }
    // newArr[i].newPrice = price;
    setProducts(newArr);
  };
  return (
    <div>
      <Header />
      <div style={{ overflow: "hidden" }}>
        <div>
          <SectionBanner />
        </div>
        {/*         <div className="topselColWp wrapper">
         <TopSellingProduct condition={!condition} onMouseLeaveEvent={onMouseLeaveEvent} onclcikEvent={onclcikEvent} custNo={customerNo} products={products} onChangeQty={onChangeQty} onChangeUnit={onChangeUnit} DecreaseItem={DecreaseItem} IncrementItem={IncrementItem} /></div> */}
        {/* <div><ListingItems/></div> */}
        {/* <div><DealOftheDay/></div>
        <div><TrendingProducts/></div> */}
        {/* <div><ShopNow/></div>
        <div><ClientTestimonial/></div> */}
        {/* <div><DialyDiscount/></div>
        <div><LatestBlog/></div> */}
      </div>
      {/*         <div className="potential-blog-content">
          <BlogContent/>
        </div> */}
      {/*         <div className="catalog-tre-way">
          <CatalogTreWay/>
        </div> */}
      <Footer />
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    customerNo: state.customer.customerNo,
    accesslvl: state.customer.accesslvl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAccessLevel: (accesslvl) => dispatch(setAccessLevel(accesslvl)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(HomePage);
