import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import SessionTimer from "./pages/Login/sessionTimer";
import Content from "./pages/Common/Content";
import ResponsiveDrawer from "./pages/AdminDashboard/ResponsiveDrawer";
import Auth from "../src/ServerCall/Auth";
import { DeliveryDateProvider } from "./DateContext";

function App() {
  const acessToken = Auth.getToken();
  let currentDate = new Date();
  let schDate = localStorage.getItem("scheduleDate");
  let isAdmin = localStorage.getItem("isAdmin");
  let scheduleDate = new Date(schDate);
  window.onbeforeunload = () => {
    if (currentDate < scheduleDate) {
    } else {
      localStorage.removeItem("scheduleDate");
    }
  };
  return (
    <Router>
      {acessToken ? <SessionTimer /> : null}
      <DeliveryDateProvider>
        <div className="App">
          {isAdmin === "true" ? <ResponsiveDrawer /> : <Content />}
        </div>
      </DeliveryDateProvider>
    </Router>
  );
}

export default App;
