import React, { createContext, useState } from "react";

const DeliveryDateContext = createContext();

const DeliveryDateProvider = ({ children }) => {
  const [deliveryDate, setDeliveryDate] = useState("");

  return (
    <DeliveryDateContext.Provider value={{ deliveryDate, setDeliveryDate }}>
      {children}
    </DeliveryDateContext.Provider>
  );
};

export { DeliveryDateProvider, DeliveryDateContext };
