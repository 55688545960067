import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  CardBody,
  CardTitle,
  Row,
  Col,
  Container,
  Card,
  CardText,
} from "reactstrap";
import { Table } from "reactstrap";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const NavTabs = ({
  type,
  onMonthChange,
  handleTabChange,
  tableRows,
  products,
  label,
  label2,
  label3,
  totalorder,
  totalMonthlyList,
  totalYearlyList,
  totalorderList,
  scheduleCount,
  deliveryList,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const monthHandler = (e) => {
    setSelectedDate(e.target.value);
    onMonthChange(moment(e.target.value).format("MMMM YYYY"));
  };

  const LinkTab = (props) => {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
          handleTabChange(props.label);
        }}
        {...props}
      />
    );
  };

  const callProductTable = (products) => {
    return typeof products !== "string" ? (
      <Table className="topfiveTable mt-2">
        {products.length > 0 ? (
          products.map((product) => (
            <tr>
              <td>{product.ProdNo}</td>
              <td className="el" data-tip={product.Descr}>
                <ReactTooltip />
                {product.Descr}
              </td>
              <td>{product.TotalAmount}kr</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colspan="3">
              <span className="ordertext">{products}</span>
            </td>
          </tr>
        )}
      </Table>
    ) : (
      <Table className="topfiveTable ordertext mt-2">
        <tr>
          <td colspan="3">
            <span className="ordertext">{products}</span>
          </td>
        </tr>
      </Table>
    );
  };

  const callCustomerTable = (tableRows) => {
    return typeof tableRows !== "string" ? (
      <Table className="topfiveTable mt-2">
        {tableRows.length > 0 ? (
          tableRows.map((data) => (
            <tr>
              <td>{data.CustNo}</td>
              <td className="el" data-tip={data.Name}>
                <ReactTooltip /> {data.Name}
              </td>
              <td>{data.TotalAmount}kr</td>
            </tr>
          ))
        ) : (
          <tr>
            <td>{tableRows}</td>
          </tr>
        )}
      </Table>
    ) : (
      <Table className="topfiveTable ordertext mt-2">
        <tr>
          <td colspan="3">
            <span className="ordertext">{tableRows}</span>
          </td>
        </tr>
      </Table>
    );
  };

  const callScheduleList = (scheduleCount) => {
    return typeof scheduleCount !== "string" ? (
      scheduleCount.length > 0 ? (
        scheduleCount.map((list) => (
          <CardText className="cardSubText mb-2 ">
            {list.Count + " " + list.List_Descr}
          </CardText>
        ))
      ) : (
        <CardText className="cardSubText mb-2  ">{scheduleCount}</CardText>
      )
    ) : (
      <CardText className="cardSubText mb-2 ">{scheduleCount}</CardText>
    );
  };

  const callTodyasDelivery = (deliveryList) => {
    return typeof deliveryList !== "string" ? (
      <Table className="todaysDeliveryTbl">
        <thead>
          <tr>
            <th>Order No</th>
            <th>Customer</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {deliveryList.length > 0 ? (
            deliveryList.map((list) => (
              <tr>
                <td>{list.OrderID}</td>
                <td className="el" data-tip={list.Name}>
                  <ReactTooltip />
                  {list.Name}
                </td>
                <td>{list.OrderTotalAmt}kr</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="3">{deliveryList}</td>
            </tr>
          )}
        </tbody>
      </Table>
    ) : (
      <Table className="todaysDeliveryTbl">
        <tr>
          <td colspan="3">
            <span className="ordertext pt-3">{deliveryList}</span>
          </td>
        </tr>
      </Table>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab label={label} href="/drafts" {...a11yProps(0)} />
          <LinkTab label={label2} href="/trash" {...a11yProps(1)} />
          <LinkTab label={label3} href="/spam" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {type === "List" ? (
        <div>
          <TabPanel value={value} index={0}>
            <CardText className="totlOrders totlOrders mb-1">
              {totalorderList}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CardText className="totlOrders totlOrders mb-1">
              {totalMonthlyList}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CardText className="totlOrders totlOrders  mb-1">
              {totalYearlyList}
            </CardText>
          </TabPanel>
        </div>
      ) : type === "topfiveCustomers" ? (
        <div>
          <TabPanel value={value} index={0}>
            {callCustomerTable(tableRows)}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {callCustomerTable(tableRows)}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Row>
              <Col className="text-center">
                <TextField
                  variant="outlined"
                  label="Välj en månad"
                  type="date"
                  defaultValue={selectedDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => monthHandler(e)}
                />
                {callCustomerTable(tableRows)}
              </Col>
            </Row>
          </TabPanel>
        </div>
      ) : type === "TotalSales" ? (
        <div>
          <TabPanel value={value} index={0}>
            <CardText className="mainHeadrText mb-1 text-center">
              Till {moment().format("MMMM YYYY")}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CardText className="mainHeadrText mb-1 text-center">
              {moment().format("YYYY")}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Row>
              <Col className="text-center">
                <TextField
                  variant="outlined"
                  label="Välj en månad"
                  type="date"
                  defaultValue={selectedDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => monthHandler(e)}
                />
              </Col>
            </Row>
            <CardText className="cardSubText mainHeadrText  mb-1 text-center">
              Feb 2021
            </CardText>
          </TabPanel>
        </div>
      ) : type === "ScheduleList" ? (
        <div>
          <TabPanel value={value} index={0}>
            {callScheduleList(scheduleCount)}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {callScheduleList(scheduleCount)}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {callScheduleList(scheduleCount)}
          </TabPanel>
        </div>
      ) : type === "todaysdelivery" ? (
        <div>
          <TabPanel value={value} index={0}>
            {callTodyasDelivery(deliveryList)}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {callTodyasDelivery(deliveryList)}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {callTodyasDelivery(deliveryList)}
          </TabPanel>
        </div>
      ) : type === "topfiveProducts" ? (
        <div>
          <TabPanel value={value} index={0}>
            {callProductTable(products)}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {callProductTable(products)}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Row>
              <Col className="text-center">
                <TextField
                  variant="outlined"
                  label="Välj en månad"
                  type="date"
                  defaultValue={selectedDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => monthHandler(e)}
                />
                {callProductTable(products)}
              </Col>
            </Row>
          </TabPanel>
        </div>
      ) : type === "totalCompletedOrders" ? (
        <div>
          <TabPanel value={value} index={0}>
            <CardText className="mainHeadrText mb-1 text-center">
              Till {moment().format("MMMM YYYY")}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CardText className="mainHeadrText mb-1 text-center">
              {moment().format("YYYY")}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Row>
              <Col className="text-center">
                <TextField
                  variant="outlined"
                  label="Välj en månad"
                  type="date"
                  defaultValue={selectedDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => monthHandler(e)}
                />
              </Col>
            </Row>
            <CardText className="cardSubText mainHeadrText mb-1 text-center">
              {moment(selectedDate).format("MMMM YYYY")}
            </CardText>
          </TabPanel>
        </div>
      ) : type === "graph" ? (
        <div>
          <TabPanel value={value} index={0}>
            <CardText className="mainHeadrText mb-1 text-center">
              Till {moment().format("MMMM YYYY")}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CardText className="mainHeadrText mb-1 text-center">
              {moment().format("YYYY")}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TextField
              variant="outlined"
              label="Välj en månad"
              type="date"
              defaultValue={selectedDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => monthHandler(e)}
            />
            <CardText className="cardSubText mainHeadrText mb-1 text-center">
              {moment(selectedDate).format("MMMM YYYY")}
            </CardText>
          </TabPanel>
        </div>
      ) : (
        <div>
          <TabPanel value={value} index={0}>
            <CardText className="totlOrders text-center mb-1">
              {totalorder}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CardText className="totlOrders text-center mb-1">
              {totalorder}
            </CardText>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CardText className="totlOrders text-center mb-1">
              {totalorder}
            </CardText>
          </TabPanel>
        </div>
      )}
    </div>
  );
};

export default NavTabs;
