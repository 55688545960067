import axios from 'axios';
import React, { useRef,useState } from 'react'
import IdleTimer from "react-idle-timer";
import Swal from 'sweetalert2'
import Auth from '../../ServerCall/Auth';

function SessionTimer() {
    const [loggedin,setLoggedin] = useState(true)
    const idleTimerRef = useRef(null);
    let currUser = JSON.parse(localStorage.getItem("userData"));

    const refreshAccessToken = () => {
  
        let body = JSON.stringify({
          refreshToken: currUser.RefreshToken
        });
        let headers = {
          headers: {
            'Content-Type': 'application/json'
          }
        }
        return new Promise((resolve, reject) => {
          axios.post(`${URL}/token`, body, headers)
            .then(async response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    const onIdle = () => {
        // console.log('session timed out');
        Swal.fire(
            {
                title:"Ingen aktivitet upptäckt",
                text:`Loggar ut`,
                showConfirmButton:true,
                confirmButtonText:'Fortsätt vara inloggad',
                icon:'warning',
                dangerMode:true,
                timer:10000,
                timerProgressBar:true,
                timerProgressBarColor:'#659f2f',
                confirmButtonColor: '#659f2f',
            }
        ).then(login=>{
            if(login.isConfirmed)
            {
                refreshAccessToken();
                return  setLoggedin(true)
            }
            if(login.dismiss==='backdrop'){
                refreshAccessToken();
                return setLoggedin(true)    
            }
            if(login.dismiss==='timer'){
                Auth.logout()
                return setLoggedin(false)    
            }
        })
    }
    return (
        <div>
            {/* {!loggedin?console.log('logged out'):null} */}
            <IdleTimer 
            ref={idleTimerRef} 
            timeout={20 * 60 * 1000} //20 minutes idle time
            onIdle={onIdle} />
        </div>
    )
}

export default SessionTimer


