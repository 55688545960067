import React from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Label ,Form ,Row,Col,FormGroup,Input,Button} from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
function StyledRadio(props) {
	const classes = useStyles();
	return (
	  <Radio
		className={classes.root}
		disableRipple
		color="default"
		checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
		icon={<span className={classes.icon} />}
		{...props}
	  />
	);
  }
  const useStyles = makeStyles({
	root: {
	  '&:hover': {
		backgroundColor: 'transparent',
	  },
	},
	icon: {
	  borderRadius: '50%',
	  width: 16,
	  height: 16,
	  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	  backgroundColor: '#f5f8fa',
	  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	  '$root.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	  },
	  'input:hover ~ &': {
		backgroundColor: '#ebf1f5',
	  },
	  'input:disabled ~ &': {
		boxShadow: 'none',
		background: 'rgba(206,217,224,.5)',
	  },
	},
	checkedIcon: {
	  backgroundColor: '#659f2f',
	  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	  '&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	  },
	  'input:hover ~ &': {
		backgroundColor: '#659f2f',
	  },
	},
  });
const OrderAsGuest =() =>{
    return(
        <div>
            <Form className="mt-5">
				<Row>
                    <Col md={1}></Col>
					<Col className="text-left" md={3}>
						<FormGroup>
							<Label className="text-left">Social Title</Label>
						</FormGroup>
					</Col>
					<Col className="text-left">
						<FormControl component="fieldset">
						<RadioGroup defaultValue="Mr" aria-label="Social Title" name="customized-radios">
							<FormControlLabel value="Mr" control={<StyledRadio />} label="Mr"/>
							<FormControlLabel value="Mrs" control={<StyledRadio />} label="Mrs" />
						</RadioGroup>
						</FormControl>
					</Col>
				</Row>
                <Row>
                <Col md={1}></Col>
                    <Col md={3} className="text-left">
                        <FormGroup>
                            <Label>First Name</Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <Input type="text" />
                    </Col>
                </Row>
                <Row>
                <Col md={1}></Col>
                    <Col className="text-left" md={3}>
                        <FormGroup>
                            <Label  className="">Last  Name</Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                        <Input type="text" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                <Col md={1}></Col>
                    <Col className="text-left" md={3}>
                        <FormGroup>
                            <Label  className="">Email</Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Input type="text" />
                         </FormGroup>
                    </Col>
                </Row>
                <Row>
                <Col md={1}></Col>
                    <Col className="text-left" md={3}>
                        <FormGroup>
                            <Label >Mobile</Label>
                        </FormGroup>
                    
                    </Col>
                    <Col>
                    <FormGroup>
                        <Input type="Number" />
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
						<Col><Button className="float-right BtnPrimaryContact mt-4">Continue</Button></Col>
					</Row>
			</Form>
        </div>
    )
}
export default OrderAsGuest;