import React from 'react'
import {Row,Col,Container,Card,Button, Label} from 'reactstrap';
import Header from '../../components/header/Header'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Captcha from './Captcha'
import Select from 'react-select';
import { URL } from '../../ServerCall/Config';
import swal from 'sweetalert';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
const Registration = () => {
    const classes = useStyles();
    // const [age, setAge] = React.useState('');
    // const [value, setValue] = React.useState('CashorCard');
    const [checked, setChecked] = React.useState(false);
    // const [state, setState] = React.useState({
    //   age: '',
    //   name: 'hai',
    // });

    //Form useState
    const [form, setForm] = React.useState({
        CorporateNumber: "",
        BusinessName: "",
        PostalCode: "",
        Address: "",
        postalAddress: "",
        PhoneBusiness: "",
        FirstNameContactPerson: "",
        Email: "",
        LastNameContactPerson: "",
        MobileNumberContactPerson: "",
        NearestTown: "",
        TypeOfBusiness: "",
        ECommerce: false,
        SendOffers: "",
    });

    //Errors useState
    const [corNum, setCorNum] = React.useState("");
    const [businessName, setBusinessName] = React.useState("");
    const [posCode, setPosCode] = React.useState("");
    const [add, setAdd] = React.useState("");
    const [posAdd, setPosAdd] = React.useState("");
    const [businessPhone, setBusinessPhone] = React.useState("");
    const [fNConPerson, setFNConPerson] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [lNConPerson, setLNConPerson] = React.useState("");
    const [conPersonMob, setConPersonMob] = React.useState("");
    const [nearTown, setNearTown] = React.useState("");
    const [typeBusi, setTypeBusi] = React.useState("");
    
    
    const [nearestTown, setNearestTown] = React.useState([
        {id: 1, label: "Södertälje"},
        {id: 2, label: "Solna"},
        {id: 3, label: "Stockholm"},
        {id: 4, label: "Uppsala"},
    ]);

    const [typesBusiness, setTypesBusiness] = React.useState([
        {id: 1, label: "Sole trader"},
        {id: 2, label: "Limited company"},
        {id: 3, label: "Trading partnership"},
        {id: 4, label: "Economic association"},
        {id: 5, label: "Non-profit association"},
        {id: 6, label: "Community association"},
    ]);

    const [selectedTown, setSelectedTown] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState([]);
    
    
    
     
          
    
    
    
    

        
   
    
    
    

    const validation = () =>{
        let isValid = true;
        const {CorporateNumber, BusinessName, PostalCode, Address, postalAddress, PhoneBusiness, FirstNameContactPerson, Email, LastNameContactPerson, MobileNumberContactPerson, NearestTown, TypeOfBusiness, ECommerce, SendOffers,} = form;
        const validName=RegExp(/^[A-Z][a-z]*$/);
        const validNumber = RegExp(/@"^\d$"/);
        const validEmail = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);
        const validPhone = RegExp(/^\d{10}$/);
        const validMobile = RegExp('/A\+?(?:0{0,2}[46]*){1}7{1}[0-9]{10}/z');
        const validPostalCode = RegExp(/^\d{5}$/);


        if(!CorporateNumber){
            // setCorNum("Please Enter Corporate Number");
            setCorNum("Vänligen ange organisationsnummer");
            isValid = false;
        }
        // else if(!validNumber.test(CorporateNumber)){
        //     setCorNum("Please Enter Valid Corporate Number");
        //     isValid = false;
        // }
        else{
            setCorNum("");
        }
        if(!BusinessName){
            // setBusinessName("Please Enter Business Name");
            setBusinessName("Vänligen ange företagsnamn");
            isValid = false;
        }
        else{
            setBusinessName("");
        }
        if(!PostalCode){
            // setPosCode("Please Enter Postal Code");
            setPosCode("Vänligen ange postkod");
            isValid = false;
        }else if(!validPostalCode.test(PostalCode)){
            // setPosCode("Please Enter Valid Postal Code");
            setPosCode("Vänligen ange giltigt postnummer");
            isValid = false;
        }
        else{
            setPosCode("");
        }
        if(!Address){
            // setAdd("Please Enter Address");
            setAdd("Vänligen ange adress");
            isValid = false;
        }
        else{
            setAdd("");
        }
        if(!postalAddress){
            // setPosAdd("Please Enter Postal Address");
            setPosAdd("Vänligen ange postadress");
            isValid = false;
        }
        else{
            setPosAdd("");
        }
        if(!PhoneBusiness){ 
            // setBusinessPhone("Please Enter Business Phone");
            setBusinessPhone("Vänligen ange telefonnummer");
            isValid = false;
        }
        else if(!validPhone.test(PhoneBusiness)){
            // setBusinessPhone("Please Enter Valid Business Phone");
            setBusinessPhone("Vänligen ange giltig telefonnummer");
            isValid = false;
        }
        else{
            setBusinessPhone("");
        }
        if(!FirstNameContactPerson){
            // setFNConPerson("Please Enter First Name");
            setFNConPerson("Vänligen ange förnamn");
            isValid = false;
        }
        else if(!validName.test(FirstNameContactPerson)){
            // setFNConPerson("Please Enter Valid First Name");
            setFNConPerson("Vänligen ange ett giltigt förnamn");
            isValid = false;
        }
        else{
            setFNConPerson("");
        }
        if(!Email){ 
            // setEmail("Please Enter Email");
            setEmail("Vänligen ange e-post");
            isValid = false;
        }else if(!validEmail.test(Email)){ 
            // setEmail("Please Enter Valid Email");
            setEmail("Vänligen ange giltig e-postadress");
            isValid = false;
        }
        else{
            setEmail("");
        }
        if(!LastNameContactPerson){ 
            // setLNConPerson("Please Enter Last Name");
            setLNConPerson("Vänligen ange efternamn");
            isValid = false;
        }
        else if(!validName.test(LastNameContactPerson)){
            // setLNConPerson("Please Enter Valid Last Name");
            setLNConPerson("Vänligen ange ett giltigt efternamn");
            isValid = false;
        }
        else{
            setLNConPerson("");
        }
        if(!MobileNumberContactPerson){
            // setConPersonMob("Please Enter Mobile Number");
            setConPersonMob("Vänligen ange mobilnummer");
            isValid = false;
        }
        else if(!validPhone.test(MobileNumberContactPerson)){
            // setConPersonMob("Please Enter Valid Mobile Phone");
            setConPersonMob("Vänligen ange giltig mobiltelefon");
            isValid = false;
        }
        else{
            setConPersonMob("");
        }
        if(!NearestTown){ 
            // setNearTown("Please Select Nearest Town");
            setNearTown("Vänligen ange närmast ort");
            isValid = false;
        }
        else{
            setNearTown("");
        }
        if(!TypeOfBusiness){ 
            // setTypeBusi("Please Select Business Type");
            setTypeBusi("Vänligen ange typ av verksamhet");
            isValid = false;
        }
        else{
            setTypeBusi("");
        }


        return isValid;
    }

    const onSubmit = (e) =>{
        e.preventDefault();

        const {CorporateNumber, BusinessName, PostalCode, Address, postalAddress, PhoneBusiness, FirstNameContactPerson, Email, LastNameContactPerson, MobileNumberContactPerson, NearestTown, TypeOfBusiness, ECommerce, SendOffers,} = form;

        const name = BusinessName;
        const address1 = Address;
        const address2 = postalAddress;
        const postCode = PostalCode;
        const mobile1 = MobileNumberContactPerson;
        const phone1 = PhoneBusiness;
        const mail1 = Email;
        const Bsno = CorporateNumber;
        let offerViaEmail = 0;
        const firstNameContactPerson = FirstNameContactPerson;
        const lastNameContactPerson = LastNameContactPerson;
        const townId = NearestTown;
        const bussinessId = TypeOfBusiness;
        let offerViaTextMessage = 0;
        let isEcommerceCust = 0;

        if(SendOffers === "viaEmail"){
            offerViaEmail = 1;
        }else if(SendOffers === "viaText"){
            offerViaTextMessage = 1;
        }

        if(ECommerce === true){
            isEcommerceCust = 1;
        }else if (ECommerce === false){
            isEcommerceCust = 0;
        }
        
        const isValid = validation();
        if(isValid){
            // console.log("submit success");
            const request = {name ,address1 ,address2 ,postCode ,mobile1 ,phone1 ,mail1 ,Bsno ,offerViaEmail,
                 firstNameContactPerson, lastNameContactPerson, townId, bussinessId, offerViaTextMessage, isEcommerceCust};
            // console.log("/submit",request);
            // console.log("formData",form);
            // swal("Registerd Successfully", "your application has been submitted", "success");

            axios.post(`${URL}/users/createCustomerEnq`, request)
            .then((res) => {
            // console.log("response",res);
            if (res.status === 200) {
                if (res.data.status === 400) {
                    // swal("Something Went Wrong", "", "info");
                    swal("Något gick fel", "", "info");
                }
                else{
                    // swal("Registered Successfully", "Your application has been submitted", "success");
                    swal("Registrering Lyckades", "Din ansökan har skickats", "success");
                    setForm({
                        CorporateNumber: "",
                        BusinessName: "",
                        PostalCode: "",
                        Address: "",
                        postalAddress: "",
                        PhoneBusiness: "",
                        FirstNameContactPerson: "",
                        Email: "",
                        LastNameContactPerson: "",
                        MobileNumberContactPerson: "",
                        NearestTown: "",
                        TypeOfBusiness: "",
                        ECommerce: false,
                        SendOffers: "",
                    });
                    setChecked(false);

                }
                

            }
            }).catch(err => {
            console.log(err);
            })
        }
    }

    // const []

    // const handleChange = (event) => {
    //   setValue(event.target.value);
    // };
    const handleChangeCheck = (event) => {
      setChecked(event.target.checked);
    };

    const handleSelectTown = (value) => {
        setSelectedTown(value);
        setDataIntoForm("NearestTown",value.id);
    }

    const handleSelectType = (value) => {
        setSelectedType(value);
        setDataIntoForm("TypeOfBusiness",value.id);
    }

    const handleCheckbox = (event) => {
        setForm({ ...form, [event.target.name]: event.target.checked });
    };

    const setDataIntoForm = (name, value) =>{
        setForm({ ...form, [name]: value});
    }

    const handleChange = (event) => {
        const { name, value }= event.target;
        // switch(name){
        //     case '':
        //     break;
        // }
        setForm({ ...form, [name]: value});
        // console.log(form);
      };
     return(
         <div>
             <Header/>
                <Container fluid className="Wraper formWraper">
                        <Row>
                         <Col lg={6} className="ContactUsWp RegistrationWp">
                            <Card className="col-md-12 conatctUSCard p-3">   
                                <Row>
                                    <Col>
                                        <h4 className="text-center pb-2">
                                            {/* Become a Customer  */}
                                            SKAPA KONTO 
                                        </h4>
                                        <p className="storeDetailText text-center">
                                            {/* Welcome to apply for a customer card from our Comapny. The customer card is a requirement to be able to shop with us, 
                                        but we do not impose any purchase requirements on you as a customer.
                                         With us you are welcome to shop as it suits you! */}
                                        Välkommen att ansöka om webbportalen från vårt företag. 
                                        Webbportalen är inget krav för att kunna handla hos oss och vi ställer inga köpkrav på dig som kund. 
                                        Hos oss är du välkommen att handla som det passar dig! 
                                        </p>
                                        
                                    </Col>
                                </Row>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                 <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">1.Ange Företagets Organisationsnummer </h3>
                                     </Col>
                                 </Row>
                                 <form className={classes.form} noValidate>
                                 <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="Corporate Number"
                                            onChange={(e) => { handleChange(e) }}
                                            value={form.CorporateNumber}
                                            name="CorporateNumber"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            type= "number"
                                            id="Corporate Number"
                                            label="Org. nr"
                                            // autoFocus
                                        />
                                        {
                                            corNum !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { corNum}</label>: null
                                        }
                                        </Grid>
                                        {/* <Grid item xs={12} sm={6} className="text-left mt-1">
                                            <Button className="BtnPrimaryContact pl-4 pr-4">Get Tasks</Button>
                                        </Grid> */}
                                 </Grid>
                                </form>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">2.Ange leveransadress</h3>
                                        <p className="noteText">Notera! Endast om det avviker från företagets adress ovan. </p>
                                     </Col>
                                 </Row>
                                 <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="Business Name"
                                            onChange={(e) => { handleChange(e) }}
                                            value={form.BusinessName}
                                            name="BusinessName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="Business Name"
                                            label="Företagsnamn"
                                            // autoFocus
                                        />
                                        {
                                            businessName !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { businessName}</label>: null
                                        }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="Postal Code"
                                            onChange={(e) => { handleChange(e) }}
                                            value={form.PostalCode}
                                            name="PostalCode"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            type= "number"
                                            id="PostalCode"
                                            label="Postnummer"
                                            // autoFocus
                                        />
                                        {
                                            posCode !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { posCode}</label>: null
                                        }
                                        </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="Address"
                                                onChange={(e) => { handleChange(e) }}
                                                value={form.Address}
                                                name="Address"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="Address"
                                                label="Adress"
                                                // autoFocus
                                            />
                                            {
                                                add !== "" ?
                                                <label style={{ fontSize: 12, color: "red" }}>
                                                { add}</label>: null
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="Address"
                                                onChange={(e) => { handleChange(e) }}
                                                value={form.postalAddress}
                                                name="postalAddress"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="Address"
                                                label="Postadress"
                                                // autoFocus
                                            />
                                            {
                                                posAdd !== "" ?
                                                <label style={{ fontSize: 12, color: "red" }}>
                                                { posAdd}</label>: null
                                            }
                                        </Grid>
                                 </Grid>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                 <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt pb-2">3.Fyll i dina kontaktuppgifter</h3>
                                     </Col>
                                 </Row>
                                 <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="Phone No Business"
                                            onChange={(e) => { handleChange(e) }}
                                            value={form.PhoneBusiness}
                                            name="PhoneBusiness"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            type= "number"
                                            id="PhoneBusinessId"
                                            label="Telefonnummer företag"
                                            // autoFocus
                                        />
                                        {
                                            businessPhone !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { businessPhone}</label>: null
                                        }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="Email"
                                                onChange={(e) => { handleChange(e) }}
                                                value={form.Email}
                                                name="Email"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                type = "email"
                                                id="Email"
                                                label="E-post"
                                                // autoFocus
                                            />
                                            {
                                                email !== "" ?
                                                <label style={{ fontSize: 12, color: "red" }}>
                                                { email}</label>: null
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="First Name Contact Person"
                                            onChange={(e) => { handleChange(e) }}
                                            value={form.FirstNameContactPerson}
                                            name="FirstNameContactPerson"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="FirstNameContactPerson"
                                            label="Förnamn kontaktperson"
                                            // autoFocus
                                        />
                                        {
                                            fNConPerson !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { fNConPerson}</label>: null
                                        }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="LastNameContactPerson"
                                                onChange={(e) => { handleChange(e) }}
                                                value={form.LastNameContactPerson}
                                                name="LastNameContactPerson"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="LastNameContactPerson"
                                                label="Efternamn kontaktperson"
                                                // autoFocus
                                            />
                                            {
                                                lNConPerson !== "" ?
                                                <label style={{ fontSize: 12, color: "red" }}>
                                                { lNConPerson}</label>: null
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="MobileNumberContactPerson"
                                                onChange={(e) => { handleChange(e) }}
                                                value={form.MobileNumberContactPerson}
                                                name="MobileNumberContactPerson"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                type= "number"
                                                id="MobileNumberContactPerson"
                                                label="Mobilnummer kontaktperson"
                                                // autoFocus
                                            />
                                            {
                                                conPersonMob !== "" ?
                                                <label style={{ fontSize: 12, color: "red" }}>
                                                { conPersonMob}</label>: null
                                            }
                                        </Grid>
                                 </Grid>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">4.Välj butik och typ av verksamhet </h3>
                                     </Col>
                                 </Row>
                                 <Grid container spacing={3}>
                                 <Grid item xs={12} sm={6}  className="SelectGrid">
                                 <FormControl variant="outlined" className={classes.formControl}>
                                        <Label htmlFor="NearestTown">Närmsta stad *</Label>
                                        {/* <Select
                                            native
                                            value={form.NearestTown}
                                            onChange={handleSelectTown}
                                            label="Nearest Town"
                                            inputProps={{
                                                name: 'NearestTown',
                                                id: 'outlined-age-native-simple',
                                            }}
                                        >
                                        <option aria-label="None" value="" />
                                        <option value={10}>Ten</option>
                                        <option value={20}>Twenty</option>
                                        <option value={30}>Thirty</option>
                                        </Select> */}
                                        <Select 
                                        name= 'NearestTown'
                                        value={selectedTown}
                                        onChange={handleSelectTown}
                                        label="Nearest Town"
                                        placeholder="välj stad..."
                                        options={nearestTown} />
                                        {
                                            nearTown !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { nearTown}</label>: null
                                        }
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={12} sm={6}  className="SelectGrid">
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Label htmlFor="outlined-age-native-simple">Typ av verksamhet *</Label>
                                        {/* <Select
                                            native
                                            value={form.typeofBusiness}
                                            onChange={handleSelectType}
                                            label="Type Of Business"
                                            inputProps={{
                                                name: 'TypeOfBusiness',
                                                id: 'outlined-age-native-simple',
                                            }}
                                        >
                                        <option aria-label="None" value="" />
                                        <option value={10}>Ten</option>
                                        <option value={20}>Twenty</option>
                                        <option value={30}>Thirty</option>
                                        </Select> */}
                                        <Select 
                                        name= 'TypeOfBusiness'
                                        value={selectedType}
                                        onChange={handleSelectType}
                                        placeholder="välj typ..."
                                        label="Type Of Business"
                                        // onChange={handleChange}
                                        options={typesBusiness} />
                                        {
                                            typeBusi !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { typeBusi}</label>: null
                                        }
                                    </FormControl>
                                 </Grid>
                                 </Grid>

                             </Card>

                             {/* <Card className="mt-3 corporateCard">
                                <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">5.Specify desire Payment Method</h3>
                                     </Col>
                                 </Row>
                                 <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="" value={value} onChange={handleChange}>
                                    <FormControlLabel value="CashorCard" control={<Radio />} label="Card or Cash" />
                                    <FormControlLabel value="Credit" control={<Radio />} label="Credit" />
                                </RadioGroup>
                                </FormControl>
                             </Card> */}
                             <Card className="mt-3 corporateCard">
                                <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">5. Bli en e-handelskund</h3>
                                     </Col>
                                 </Row>
                                 <FormControlLabel
                                        control={<Checkbox checked={form.ECommerce} onChange={handleCheckbox} name="ECommerce"  />}
                                        label="Ja, jag vill ansöka om att bli e-handelskund och få mina varor levererade"
                                    />
                                 {/* <FormControl component="fieldset">
                                <RadioGroup aria-label="" name="ECommerce" value={form.ECommerce} onChange={handleChange}>
                                    <FormControlLabel value="eCommerceAgree" control={<Radio />} label="Yes, I want to apply to become an e-commerce customer and have my goods delivered" />
                                </RadioGroup>
                                </FormControl> */}
                             </Card>
                             {/* <Card className="mt-3 corporateCard">
                             <Row>
                                <Col>
                                <h3 className="StepsHeadtxt">7. Shop Strong Beer, Wine AND Spirits</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <p className="mt-2">
                                    In order to buy strong beer, beer, wine and spirits at Grönsakskompaniet, a serving permit is required.
                                    Attach this or come in to your local quickgross and hand in a copy, or post it.
                                </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BrowseFile/>
                                </Col>
                            </Row>
                             </Card> */}
                             {/* <Card className="mt-3 corporateCard">
                                 <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">8. Trade Tobacco</h3>
                                     </Col>

                                 </Row>
                                 <Row>
                                     <Col>
                                     <p>In order to buy tobacco at Grönsakskompaniet, a tobacco permit is required.
                                          Attach this or come in to your local quickgross and hand in a copy, or post it.</p>
                                     </Col>
                                 </Row>
                                 <Row className="mt-2 mb-3">
                                 <Col>
                                    <BrowseFile/>
                                </Col>
                                 </Row>
                                 <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="ActorId"
                                            name="ActorId"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="ActorId"
                                            label="ActorID"
                                            autoFocus
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="FacilityId"
                                            name="FacilityId"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="FacilityId"
                                            label="FacilityId"
                                            autoFocus
                                        />
                                        </Grid>
                                    </Grid>
                             </Card> */}
                             <Card className="mt-3 corporateCard text-left">
                                <Row>
                                    <Col>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="customerChoice" name="SendOffers" value={form.SendOffers} onChange={handleChange}>
                                            <FormControlLabel value="viaEmail" control={<Radio />} label="Ja tack, jag vill hålla mig uppdaterad och få erbjudanden via e-post" />
                                            <FormControlLabel value="viaText" disabled control={<Radio />} label="Ja tack, jag vill hålla mig uppdaterad och få erbjudanden via sms " />
                                        </RadioGroup>
                                        </FormControl>
                                    </Col>
                                </Row>
                             </Card>
                             <Card  className="mt-3 corporateCard text-left">
                                 <Row>
                                     <Col>
                                     <Checkbox
                                            checked={checked}
                                            onChange={handleChangeCheck}
                                            inputProps={{ 'aria-label': ' checkbox' }}
                                            label="Uncontrolled"
                                        /> 
                                        <span className="tcConWp">Jag Godkänner Villkor Jag accepterar Grönsakskompaniet AB:s <a href="/TermsConditions" className="termcondnWp">allmänna villkor</a> och intygar att de uppgifter jag lämnat i samband med min kundansökan är korrekta och aktuella</span>
                                     </Col>
                                 </Row>
                                    <br/>
                                 <Row>
                                     <Col className="text-center">
                                         <Button disabled={checked === true ? false : true} 
                                         className="btnAddShop pl-3 pr-3" onClick={onSubmit}>
                                          Skicka 
                                         </Button>
                                    </Col>
                                 </Row>
                             </Card>
                        </Col>
                    </Row>
                </Container>
        </div>
     );
}
export default Registration;
