import React from "react";

// import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";

import HomePage from "../dashboard/HomePage";
import ProductDetail from "../ProductDetails/ProductDetail";
import ProductOverview from "../ProductDetails/ProductOverview";
import ContactUs from "../ContactUs/ContactUs";
import BecomeACustomer from "../ContactUs/BecomeACustomer";
import Login from "../Login/Login";
import AdminLogin from "../Login/AdminLogin";
import Signup from "../Login/Signup";
import Registration from "../Login/Registration";
import Cart from "../Cart/Cart";
import Checkout from "../Checkout/Checkout";
import OrderConfirmation from "../OrderConfirmation/OrderConfirmation";
import ClubDetail from "../ProductDetails/Components/ClubDetail";
import FindStore from "../FindStoreandRestoDays/FindStore";
import SavedList from "../SubscribeSave/SavedList";
import SaveMultiList from "../SubscribeSave/SaveMultiList";
import InvoiceList from "../Invoice/InvoiceList";
import Invoice from "../Invoice/Invoice";
import OrderDetail from "../OrderDetail/OrderDetail";
import ProductOrderDetails from "../OrderConfirmation/ProductOrderDetails";
import Auth from "../../ServerCall/Auth";
import ScrollToTop from "../Common/ScrollToTop";
import List from "../Common/List";
import ResetPassword from "../Login/ResetPassword";
import AboutUs from "../Information/AboutUs";
import PrivacyPolicy from "../Information/PrivacyPolicy";
import TermsConditions from "../Information/TermsConditions";
import TestNewLayout from "./TestNewLayout";
// import CustomerList from "../AdminDashboard/Components/CustomerList";
// import CustomerDetails from "../AdminDashboard/Components/CustomerDetails";
import ForgotPassword from "../Login/ForgotPassword";
import ChangePassword from "../Login/ChangePassword";

const Content = () => {
  const access_level = Auth.getAccess_level();
  var accesslvl = "";
  var gkAdminAccessLevel = "";
  if (access_level !== undefined) {
    accesslvl = access_level.find((obj) => obj === 4);
    gkAdminAccessLevel = access_level[0];
  }
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={HomePage} />
            {gkAdminAccessLevel === 0 || gkAdminAccessLevel === 6 ? null : (
              <Route path="/ProductDetail" component={ProductDetail} />
            )}
            {gkAdminAccessLevel === 0 || gkAdminAccessLevel === 6 ? null : (
              <Route path="/ProductOverview" component={ProductOverview} />
            )}
            <Route exact path="/ContactUs" component={ContactUs} />
            <Route exact path="/BecomeACustomer" component={BecomeACustomer} />
            <Route exact path="/Login/:id" component={Login} />
            <Route exact path="/Signup" component={Signup} />
            <Route path="/ResetPassword" component={ResetPassword} />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <Route exact path="/NewDesign" component={TestNewLayout} />
            <Route exact path="/Checkout" component={Checkout} />
            <Route exact path="/ClubDetail" component={ClubDetail} />
            <Route exact path="/FindStore" component={FindStore} />
            {/* <Route exact path='/SavedList' component={SavedList} /> */}
            {/* {gkAdminAccessLevel === 0 || gkAdminAccessLevel === 6 ? null : <Route exact path="/SaveMultiList" component={SaveMultiList} />} */}
            {/* {gkAdminAccessLevel === 0 || gkAdminAccessLevel === 6 ? null : <Route exact path="/SaveMultiList/:id" component={SaveMultiList} />} */}
            <Route exact path="/InvoiceList" component={InvoiceList} />
            <Route exact path="/Invoice" component={Invoice} />
            <Route exact path="/Registration" component={Registration} />
            {/* {gkAdminAccessLevel === 0 || gkAdminAccessLevel === 6 ? null : <Route exact path="/OrderDetail" component={OrderDetail} />} */}
            {/* {gkAdminAccessLevel === 0 || gkAdminAccessLevel === 6 ? null : <Route exact path="/ProductOrderDetails" component={ProductOrderDetails} />} */}
            <Route exact path="/AdminLogin" component={AdminLogin} />
            {/* {gkAdminAccessLevel === 0 || gkAdminAccessLevel === 6 ? null : <Route exact path="/List" component={List} />} */}
            <Route exact path="/AboutUs" component={AboutUs} />
            <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
            <Route exact path="/TermsConditions" component={TermsConditions} />

            {access_level ? (
              <Switch>
                <Route exact path="/SavedList" component={SaveMultiList} />
                <Route path="/Cart" component={Cart} />
                <Route
                  path="/OrderConfirmation"
                  component={OrderConfirmation}
                />
                <Route exact path="/OrderDetail" component={OrderDetail} />
                <Route
                  exact
                  path="/ProductOrderDetails"
                  component={ProductOrderDetails}
                />
                <Route
                  exact
                  path="/ChangePassword"
                  component={ChangePassword}
                />
              </Switch>
            ) : null}

            {/* <Route exact path="/SideDrawer" component={SideDrawer}/> */}

            {/* {accesslvl === 4?
                  <Route exact path="/Dashboard" component={Dashboard}/>
                  :null} */}
            {/* <Route exact path="/CreateUser" component={CreateUser} />
                  <Route exact path="/CustomerList" component={CustomerList} />
                  <Route exact path="/CustomerDetails/:id" component={CustomerDetails} /> */}
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
};
export default Content;
