
  export const setCustomer = (customerNo) => dispatch => {
     dispatch({
       type:SET_CUSTOMER,
       payload:customerNo
     })
   }
 
   export const setProducts = (products) => dispatch => {
     dispatch({
       type:SET_PRODUCTS,
       payload: products
     })
   }
 
   export const setSearchProduct = (searchName) => dispatch => {
     dispatch({
       type:SET_SEARCH_PRODUCT,
       payload: searchName
     })
   }
 
   export const setCartCount = (count) => dispatch => {
     dispatch({
       type:SET_CART_COUNT,
       payload: count
     })
   }
 
   export const setAccessLevel = (accesslvl) => dispatch => {
     dispatch({
       type:SET_ACCESS_LEVEL,
       payload: accesslvl
     })
   }

   export const setAdminAccess = (adminAccess) => dispatch => {
    dispatch({
      type:SET_ADMINACCESS_LEVEL,
      payload: adminAccess
    })
  }


  export const setClient = (purchase) => dispatch => {
    dispatch({
      type:SET_CLIENT,
      payload: purchase
    })
  }
  
  export const setRefTopNav = (isRefresh) => dispatch => {
    dispatch({
      type:SET_REF_TOP_NAV,
      payload: isRefresh
    })
  }
 
  export const setAdminSection = (isAdmin) => dispatch => {
    dispatch({
      type:SET_ADMIN_SECTION,
      payload: isAdmin
    })
  }

  export const setTotalPrice = (totalPrice) => dispatch => {
    dispatch({
      type:SET_TOTAL_PRICE,
      payload: totalPrice
    })
  }

 export const SET_CUSTOMER='SET_CUSTOMER';
 
 export const SET_PRODUCTS='SET_PRODUCTS';
 
 export const SET_SEARCH_PRODUCT= 'SET_SEARCH_PRODUCT';
 
 export const SET_CART_COUNT = 'SET_CART_COUNT';
 
 export const SET_ACCESS_LEVEL = 'SET_ACCESS_LEVEL';

 export const SET_ADMINACCESS_LEVEL = 'SET_ADMINACCESS_LEVEL';

 export const SET_CLIENT = 'SET_CLIENT';

 export const SET_REF_TOP_NAV = 'SET_REF_TOP_NAV';

 export const SET_ADMIN_SECTION = 'SET_ADMIN_SECTION';

 export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';

 