// import { render } from '@testing-library/react';
import React from 'react';
import TabPanel from './NavTabs'
import { Table } from 'reactstrap';
import {CardBody,CardTitle,Row,Col,Container,Card, CardText} from 'reactstrap'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import CanvasGraph from './CanvasGraph'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import StarIcon from '@material-ui/icons/Star';
import ViewListIcon from '@material-ui/icons/ViewList';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DvrIcon from '@material-ui/icons/Dvr';
import ListAltIcon from '@material-ui/icons/ListAlt';
import {URL} from '../../../ServerCall/Config';
//import axios from 'axios';
import Googlechart from './Googlechart'
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import  {setAccessLevel} from '../../../redux/action/customerAction';
import { connect } from 'react-redux';
import Auth from '../../../ServerCall/Auth';

class Dashboard extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      todaysOrders: [],
      totalPendingDelivery: {},
      noPendingDelivery: "",
      totalCompletedOrders: {},
      noCompletedOrders: "",
      topCustomers: {},
      noTopCustomers: "",
      topProduct: {},
      noTopProducts: "",
      totalSale: {},
      noSale: "",      
      scheduleList: {},
      noScheduleList: "",
      todaysDelivery: {},
      noTodaysDelivery: "",
      completedOrdersMonth: moment().format('MMMM YYYY'),
      customersMonth: moment().format('MMMM YYYY'),
      salesMonth: moment().format('MMMM YYYY'),
      productsMonth: moment().format('MMMM YYYY')
    }
  }

  async componentDidMount(){
    const access_level = Auth.getAccess_level();
    if (access_level !== undefined) {
      var accesslvl = access_level.find(obj => obj === 4);
      await this.props.setAccessLevel(accesslvl);
    }
    await this.getTodaysOrders();
    await this.getTotalPendingDeliveryOrders("Overall");
    await this.getTotalCompletedOrders("Overall");
    await this.getTopCustomers("Overall");
    await this.getTopProducts("Overall");
    await this.getTotalScale("Overall");
    await this.getScheduleListCount("Overall");
    await this.getTodaysDeliveryOrder("Overall");
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.completedOrdersMonth !== this.state.completedOrdersMonth){
      this.getTotalCompletedOrders("Month");
    }
    if(prevState.customersMonth !== this.state.customersMonth){
      this.getTopCustomers("Month");
    }
    if(prevState.salesMonth !== this.state.salesMonth){
      this.getTotalScale("Month");
    }
    if(prevState.productsMonth !== this.state.productsMonth){
      this.getTopProducts("Month");
    }
  }

  getTodaysOrders = ()=> {
    axiosApiInstance.get(`${URL}/orders/admin/getTodaysOrders/${new Date().toISOString()}`)
    .then(res => {
      if(res.data.status === 200){
        this.setState({todaysOrders: res.data.data});
      }else{
        this.setState({noTodayOrders: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  getTotalPendingDeliveryOrders = (type)=> {
    axiosApiInstance.get(`${URL}/orders/admin/getTotalPendingDeliveryOrders/${type}`)
    .then(res => {
      if(res.data.status === 200){
        this.setState({totalPendingDelivery: res.data.data, noPendingDelivery: res.data.message});
      }else{
        this.setState({totalPendingDelivery: res.data.data, noPendingDelivery: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  getTotalCompletedOrders = (type) => {
    const {completedOrdersMonth} = this.state;
    let text = 0;
    if(type === "Year"){
      text = new Date().getFullYear();
    }else if(type === "Month"){
      text = completedOrdersMonth;
    }

    axiosApiInstance.get(`${URL}/orders/admin/getTotalCompletedOrders/${type}/${text}`)
    .then(res => {
      if(res.data.status === 200){
        this.setState({totalCompletedOrders: res.data.data, noCompletedOrders: res.data.message});
      }else{
        this.setState({totalCompletedOrders: res.data.data, noCompletedOrders: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  getTopCustomers = (type)=> {
    const {customersMonth} = this.state;
    let text = 0;
    if(type === "Year"){
      text = new Date().getFullYear();
    }else if(type === "Month"){
      text = customersMonth;
    }
    axiosApiInstance.get(`${URL}/users/admin/getTopCustomers/${type}/${text}`)
    .then(res => {
      if(res.data.status === 200){
        this.setState({topCustomers: res.data.data, noTopCustomers: res.data.message});
      }else{
        this.setState({topCustomers: res.data.data, noTopCustomers: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  getTopProducts = (type)=> {
    const {productsMonth} = this.state;
    let text = 0;
    if(type === "Year"){
      text = new Date().getFullYear();
    }else if(type === "Month"){
      text = productsMonth;
    }
    axiosApiInstance.get(`${URL}/products/admin/getTopProducts/${type}/${text}`)
    .then(res => {
      if(res.data.status === 200){
        this.setState({topProduct: res.data.data, noTopProducts: res.data.message});
      }else{
        this.setState({topProduct: res.data.data, noTopProducts: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  getTotalScale = (type) => {
    const {salesMonth} = this.state;
    let text = 0;
    if(type === "Year"){
      text = new Date().getFullYear();
    }else if(type === "Month"){
      text = salesMonth;
    }
    axiosApiInstance.get(`${URL}/orders/admin/getTotalCompletedOrders/${type}/${text}`)
    .then(res => {
      if(res.data.status === 200){
        this.setState({totalSale: res.data.data, noSale: res.data.message});
      }else{
        this.setState({totalSale: res.data.data, noSale: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  getScheduleListCount = (type)=> {
    axiosApiInstance.get(`${URL}/list/admin/getScheduleListCount/${type}`)
    .then(res => {
      if(res.data.status === 200){
        this.setState({scheduleList: res.data.data, noScheduleList: res.data.message});
      }else{
        this.setState({scheduleList: res.data.data, noScheduleList: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  getTodaysDeliveryOrder = (type)=> {
    axiosApiInstance.get(`${URL}/orders/admin/getTodaysDeliveryOrder/${type}/${new Date().toISOString()}`)
    .then(res => {
      if(res.data.status === 200){
        this.setState({todaysDelivery: res.data.data, noTodaysDelivery: res.data.message});
      }else{
        this.setState({todaysDelivery: res.data.data, noTodaysDelivery: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  render(){
    const {todaysOrders, noTodayOrders, totalPendingDelivery, noPendingDelivery, totalCompletedOrders, noCompletedOrders,
      topCustomers, noTopCustomers, topProduct, noTopProducts, totalSale, noSale, scheduleList, noScheduleList, todaysDelivery, noTodaysDelivery} = this.state;
    return(
      <Container fluid className="pl-0 pr-0 AdminDashWp">
        <Row>
          <Col lg={3} md={4}  xs={12} className="dbCardCol grid-margin stretch-card card-tile">
            <Card>
              <CardBody> 
                <CardTitle className="d-flex CardHedr justify-content-between">
                  {/* Total Pending Delivery */}
                  Total väntande leverans
                  </CardTitle>
                  <span className="cardIcnWpr"> <AccessTimeIcon className="float-right colorIcn"/></span>
                  <Row>
                    <Col className="pl-0 pr-0">
                      <TabPanel label="Övergripande" 
                      // label2="Zone1" 
                      // label3="Zone2" 
                      handleTabChange={(val) => this.getTotalPendingDeliveryOrders(val)}
                        totalorder={Object.keys(totalPendingDelivery).length > 0 ? totalPendingDelivery.orderCount + " Orders" : noPendingDelivery}                        
                        />
                    </Col>
                  </Row>
                  <CardTitle className="TotlText  pl-4">{/*Total Value*/} Totala värdet <span className="totalValueText pl-5">{Object.keys(totalPendingDelivery).length > 0 ? totalPendingDelivery.totalValue : 0}kr</span></CardTitle>                  
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={4} xs={12} className=" dbCardCol grid-margin stretch-card card-tile">
            <Card>
              <CardBody>
                <CardTitle className="d-flex CardHedr justify-content-between">
                  {/* Total Completed Orders */}
                  Totalt slutförda beställningar 
                  </CardTitle>
                <span className="cardIcnWpr"> <CheckCircleIcon className="float-right colorIcn"/></span>
                <Row>
                  <Col className="pl-0 pr-0">
                    <TabPanel label="Övergripande" label2="År" label3="Månad" type="totalCompletedOrders"
                    handleTabChange={(val) => this.getTotalCompletedOrders(val)} onMonthChange={(val) => this.setState({completedOrdersMonth: val})} />
                  </Col>
                </Row>
                <CardText className="ordertext cardSubText text-center">{Object.keys(totalCompletedOrders).length > 0 ? totalCompletedOrders.orderCount + " Orders" : noCompletedOrders}</CardText>
                <CardTitle className="TotlText pl-4">{/*Total Value*/} Totala värdet <span className="totalValueText pl-5">{Object.keys(totalCompletedOrders).length > 0 ? totalCompletedOrders.totalValue : 0}kr</span></CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={4} xs={12} className="dbCardCol grid-margin stretch-card card-tile">
            <Card>
              <CardBody>
                <CardTitle className="d-flex CardHedr justify-content-between">
                  {/* Top 5 Customers */}
                  Topp 5 kunder
                  </CardTitle>
                <span className="cardIcnWpr"> <StarIcon className="float-right colorIcn"/></span>
                <Row>
                  <Col className="pl-0 pr-0">
                    <TabPanel label="Övergripande" label2="År" label3="Månad" type="topfiveCustomers"
                    handleTabChange={(val) => this.getTopCustomers(val)} onMonthChange={(val) => this.setState({customersMonth: val})}
                    tableRows={Object.keys(topCustomers).length > 0 ? topCustomers.customers : noTopCustomers} />
                  </Col>
                </Row>
                <CardTitle className="TotlText pl-2 text-center">{/*Total Value*/} Totala värdet <span className="totalValueText pl-2">{Object.keys(topCustomers).length > 0 ? topCustomers.totalValue : 0}kr</span></CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={4} xs={12} className=" dbCardCol grid-margin stretch-card card-tile">
            <Card>
              <CardBody>
                <CardTitle className="d-flex CardHedr justify-content-between">
                  {/* Total Sales */}
                  Total försäljning
                  </CardTitle>
                <span className="cardIcnWpr"><AccessTimeIcon className="float-right colorIcn"/></span>
                <Row>
                  <Col className="pl-0 pr-0">
                    <TabPanel label="Övergripande" label2="År" label3="Månad" type="totalCompletedOrders"
                    handleTabChange={(val) => this.getTotalScale(val)} onMonthChange={(val) => this.setState({salesMonth: val})}/>
                  </Col>
                </Row>
                <CardText className="ordertext cardSubText text-center">{Object.keys(totalSale).length > 0 ? totalSale.orderCount + " Orders" : noSale}</CardText>
                <CardTitle className="TotlText pl-4">{/*Total Value*/} Totala värdet <span className="totalValueText pl-5">{Object.keys(totalSale).length > 0 ? totalSale.totalValue : 0}kr</span></CardTitle>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className=" mainCardRow">
          <Col lg={3} md={4} xs={12} className=" dbCardCol grid-margin stretch-card card-tile">
            <Card>
              <CardBody>
                <CardTitle className="d-flex CardHedr justify-content-between">
                  {/* Schedule List */}
                  Schemalista
                  </CardTitle>
                <span className="cardIcnWpr"><ViewListIcon className="float-right colorIcn"/></span>
                <Row>
                  <Col className="pl-0 pr-0">
                    <TabPanel label="Övergripande" 
                    // label2="Zone1" label3="Zone2" 
                    type="ScheduleList"
                      handleTabChange={(val) => this.getScheduleListCount(val)}
                      scheduleCount={Object.keys(scheduleList).length > 0 ? scheduleList.list : noScheduleList}
                    />
                  </Col>
                </Row>
                <CardText className="TotlText  mb-1 pl-4">{/*Total List*/} Totala listor<span className="totalValueText pl-5">{Object.keys(scheduleList).length > 0 ? scheduleList.totalCount : 0}</span></CardText>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={4} xs={12} className=" dbCardCol grid-margin stretch-card card-tile">
            <Card>
              <CardBody>
                <CardTitle className="d-flex CardHedr justify-content-between">
                  {/* Today's Order */}
                  Dagens ordrar
                  </CardTitle>
                <span className="cardIcnWpr"> <CalendarTodayIcon className="float-right colorIcn"/></span>
                <Table className="todaysDeliveryTbl">
                  <thead>
                    <tr>
                      <th>
                        {/* Order No */}
                        Order nr
                      </th>
                      <th>
                        {/* Customer */}
                        Kund
                      </th>
                      <th>
                        {/* Value */}
                        värde
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {todaysOrders.length > 0 ?
                  todaysOrders.map(order =>
                    <tr>
                      <td>{order.OrderID}</td>
                      <td className="el" data-tip={order.Name}><ReactTooltip />{order.Name}</td>
                      <td>{order.OrderTotalAmt}kr</td>
                    </tr>) : <tr><td colspan="3"><span className="ordertext mt-3">{noTodayOrders}</span></td></tr>}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={4} xs={12} className="dbCardCol grid-margin stretch-card card-tile">
            <Card>
              <CardBody> 
                <CardTitle className="d-flex CardHedr justify-content-between">
                  {/* Top 5 Products */}
                  Top 5 produkter
                </CardTitle>
                <span className="cardIcnWpr"> <DvrIcon className="float-right colorIcn"/></span>
                <Row>
                  <Col className="pl-0 pr-0">
                    <TabPanel label="Övergripande" label2="År" label3="Månad" type="topfiveProducts"
                    handleTabChange={(val) => this.getTopProducts(val)} onMonthChange={(val) => this.setState({productsMonth: val})}
                    products={Object.keys(topProduct).length > 0 ? topProduct.products : noTopProducts}/>
                  </Col>
                </Row>
                <CardTitle className="TotlText pl-4">{/*Total Value*/} Totala värdet <span className="totalValueText pl-5">{Object.keys(topProduct).length > 0 ? topProduct.totalValue : 0}kr</span></CardTitle>                
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={4} xs={12} className="dbCardCol grid-margin stretch-card card-tile">
            <Card>
              <CardBody> 
                <CardTitle className="d-flex CardHedr justify-content-between">
                  {/* Todays Deliveries */}
                  Dagens leveranser
                  </CardTitle>
                <span className="cardIcnWpr"><ListAltIcon className="float-right colorIcn"/></span>
                <Row>
                  <Col className="pl-0 pr-0">
                    <TabPanel label="Övergripande" 
                    // label2="Zone1" label3="Zone2" 
                    type="todaysdelivery"
                    handleTabChange={(val) => this.getTodaysDeliveryOrder(val)}
                    deliveryList={todaysDelivery.length > 0 ? todaysDelivery : noTodaysDelivery}/>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card  className="mt-4 pl-3 pr-3">
          <Row>
            <Col><Googlechart/></Col>
          </Row>
        </Card>
      </Container>
    )
  }
}

const mapStoreToProps = (state) => {
  return{
    accesslvl: state.customer.accesslvl
  }
}

const mapDispatchToProps = dispatch => ({
  setAccessLevel:(accesslvl)=>dispatch(setAccessLevel(accesslvl)),
})

export default connect(mapStoreToProps, mapDispatchToProps)(Dashboard);
