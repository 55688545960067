import React from "react";
import { useLocation } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Card } from "react-bootstrap";
// import axios from 'axios';
import { URL } from "../../ServerCall/Config";
import swal from "sweetalert";
import Auth from "../../ServerCall/Auth";
import axiosApiInstance from "../../ServerCall/Axios.Instance";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const ChangePassword = (props) => {
  let userId = Auth.getUserId();
  //const location = useLocation();
  const classes = useStyles();
  const [errorOldPassword, setErrorOldPassword] = React.useState("");
  const [errorComPassword, setErrorComPassword] = React.useState("");
  const [errorPassword, setErrorPassword] = React.useState("");
  const [state, setstate] = React.useState({
    oldPass: "",
    confirmPass: "",
    password: "",
  });

  const formvalid = () => {
    let isValid = true;
    // state.password !== ''?setErrorPassword(''):setErrorPassword("Valid address Req.");
    if (!state.oldPass) {
      setErrorComPassword("Vänligen ange aktuellt lösenord");
      isValid = false;
    }
    if (!state.password) {
      setErrorPassword("Vänligen ange nytt lösenord");
      isValid = false;
    } else if (
      !state.password.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/)
    ) {
      setErrorPassword("Vänligen ange ett säkert och starkt lösenord");
      isValid = false;
    } else {
      setErrorPassword("");
    }
    if (!state.confirmPass) {
      setErrorComPassword("Vänligen bekräfta nytt lösenord");
      isValid = false;
    } else if (state.confirmPass !== state.password) {
      setErrorComPassword("Lösenordet stämmer inte överens");
      isValid = false;
    } else {
      setErrorComPassword("");
    }
    return isValid;
  };

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
    switch (e.target.name) {
      case "oldPass":
        e.target.value === ""
          ? setErrorOldPassword("Vänligen ange gammalt lösenord")
          : setErrorOldPassword("");
        break;
      case "password":
        e.target.value === ""
          ? setErrorPassword("Vänligen ange nytt lösenord")
          : setErrorPassword("");
        break;
      case "confirmPass":
        e.target.value === ""
          ? setErrorComPassword("Vänligen bekräfta nytt lösenord")
          : setErrorComPassword("");
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = formvalid();
    let request = {
      userId: userId,
      oldPassword: state.oldPass,
      newPassword: state.password,
    };
    if (isValid) {
      // debugger
      axiosApiInstance
        .post(`${URL}/users/changePassword`, request)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            if (res.data.status === 400) {
              if (res.data.message === "In-correct user or old-pasword ") {
                //old password is not matching
                // swal("Please Check Current Password","Your current password is't matching","error");
                swal(
                  "Kontrollera aktuellt lösenord",
                  "Ditt aktuella lösenord matchar inte",
                  "error"
                );
              } else if (
                res.data.message ===
                "Old password and new password cannot be same"
              ) {
                //old password is same as new password
                // swal("Please Choose A New Password","Your new password can not be same as your current password","error");
                swal(
                  "Vänligen välj ett nytt lösenord",
                  "Ditt nya lösenord kan inte vara detsamma som ditt nuvarande lösenord",
                  "error"
                );
              } else {
                // swal("Someting went wrong", "", "warning");
                swal("Något gick fel", "", "warning");
              }
            } else {
              // swal("Change Successfully", "","success")
              swal("Ändringen lyckades", "", "success").then((res) => {
                Auth.logout();
                window.location.href = "/";
              });
            }
          }
        })
        .catch((err) => {
          console.log("Test", err);
        });
    }
  };
  return (
    <div>
      <Header />
      <Container
        className="LoginContainer formWraper mt-4"
        component="main"
        maxWidth="xs"
      >
        <Card className="pl-4 pr-4 pb-5">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar>
              <LockOutlinedIcon />
            </Avatar>
            <br />
            <Typography component="h3" variant="h5">
              {/* Change Password  */}
              Ändra lösenord
            </Typography>

            <form
              className={classes.form}
              style={{ position: "relative" }}
              noValidate
            >
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="oldPass"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  label="Nuvarande lösenord"
                  name="oldPass"
                  type="password"
                  autoComplete="password"
                  autoFocus
                />
                {errorOldPassword !== "" ? (
                  <label style={{ fontSize: 12, color: "red" }}>
                    {errorOldPassword}
                  </label>
                ) : null}
              </div>
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  label="Nytt lösenord                    "
                  name="password"
                  type="password"
                  autoComplete="password"
                />
                {/* <span className="InfoSpan"><InfoIcon/></span>
                                <div className="validationGuide">
                                    <span>{"1. At least 8 characters or more characters"} <br/></span>
                                    <span>{"2. A mixture of both uppercase and lowercase letters.."}<br/> </span>
                                    <span>{"3. Inclusion of at least one special character,e.g.,! @ # ? ]"} <br/></span>
                                    <span>{"4. A mixture of letters and numbers."}</span>
                                </div> */}
                {errorPassword !== "" ? (
                  <label style={{ fontSize: 12, color: "red" }}>
                    {errorPassword}
                  </label>
                ) : null}
              </div>
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="confirmPass"
                  label="Bekräfta nytt lösenord"
                  type="password"
                  id="confirmPass"
                  autoComplete="current-confirmPass"
                />
                {errorComPassword !== "" ? (
                  <label style={{ fontSize: 12, color: "red" }}>
                    {errorComPassword}
                  </label>
                ) : null}
              </div>
              <br />
              {/* <br/> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="mt-0 mb-3"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {/* Change */}
                Förändra
              </Button>
            </form>
          </div>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};
export default ChangePassword;
