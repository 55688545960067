import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import { Container, Row, Col, Card } from "react-bootstrap";
import { Button } from "reactstrap";
import swal from "sweetalert";
import moment from "moment";
import TotalSummary from "../../../pages/Cart/Components/TotalSummary";
import CartSummary from "../../../pages/Cart/Components/MiniCartSummary";
import ProductList from "../../../pages/Cart/Components/MiniCartProductList";

import Auth from "../../../ServerCall/Auth";
import { URL } from "../../../ServerCall/Config";
import { connect } from "react-redux";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import {
  setCartCount,
  setTotalPrice,
} from "../../../redux/action/customerAction";

const Cart = (props) => {
  let history = useHistory();

  const [cartProduct, setCartProduct] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [tax, setTax] = useState(0);
  const [taxPer, setTaxPer] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [customerNo, setCustomerNo] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allDeliveryData, setAllDeliveryData] = useState([]);

  useEffect(() => {
    setCustomerNo(props.customerNo);
    getCartData();
    getDeliveryTerms();
    // getTaxAmout();
  }, [props.customerNo, props.cartCount]);

  const getDeliveryTerms = () => {
    axiosApiInstance
      .get(`${URL}/getDeliveryTerm/${props.customerNo}`)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res);
          let periodOfDelivery = res.data.data;
          setAllDeliveryData(periodOfDelivery);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getTaxAmout = () => {
  //   axiosApiInstance.get(`${URL}/list/getOverAllTax`)
  //   .then(res => {
  //       if(res.data.status === 200){
  //         setTaxPer(res.data.data.taxPer);
  //         }
  //    else {

  //     }
  //   }).catch(err => {
  //     console.log(err);
  //   })
  // }

  const getCartData = () => {
    setLoading(true);
    let id = Auth.getUserId();
    if (id !== undefined) {
      axiosApiInstance
        .get(`${URL}/getCart/${id}/${props.customerNo}`)
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.data.length > 0) {
              const newProduct =
                res.data.data &&
                res.data.data.map((file) => {
                  if (file.purchased_Unit === "Kart") {
                    return {
                      ...file,
                      Tax: file.Tax,
                      Price: file.Price_cart * file.Qty * file.Conv_facor_CART,
                      Base_price: file.Price_cart * file.Conv_facor_CART,
                      hiddenText: true,
                    };
                  } else if (file.purchased_Unit === "Kartplus") {
                    return {
                      ...file,
                      Tax: file.Tax,
                      Price: file.Price_cart * file.Qty * 1,
                      Base_price: file.Price_cart * 1,
                      hiddenText: true,
                    };
                  } else if (
                    file.purchased_Unit === "Styck" &&
                    file.baseUnit === "Kg"
                  ) {
                    return {
                      ...file,
                      Tax: file.Tax,
                      Price: file.Comp_Price * file.Pieces_to_KG * file.Qty,
                      Base_price: file.Price_broken * file.Pieces_to_KG,
                      hiddenText: true,
                    };
                  } else if (file.purchased_Unit === "Styck") {
                    return {
                      ...file,
                      Tax: file.Tax,
                      Price: file.Price_broken * file.Conv_facor_ST * file.Qty,
                      Base_price: file.Price_broken * file.Conv_facor_ST,
                      hiddenText: true,
                    };
                  } else if (
                    file.purchased_Unit === "Kg" &&
                    file.Base_qty > 1
                  ) {
                    return {
                      ...file,
                      Tax: file.Tax,
                      Price: file.Comp_Price * file.Qty,
                      Base_price: file.Comp_Price,
                      hiddenText: true,
                    };
                  } else if (
                    file.purchased_Unit === "Kg" &&
                    file.baseUnit === "Kg"
                  ) {
                    return {
                      ...file,
                      Tax: file.Tax,
                      Price: file.comp_Price_cart * file.Conv_facor_KG * file.Qty,
                      Base_price: file.comp_Price_cart * file.Conv_facor_KG,
                      hiddenText: true,
                    };
                  } else if (file.purchased_Unit === "Kg") {
                    return {
                      ...file,
                      Tax: file.Tax,
                      Price: file.comp_Price_cart * file.Conv_facor_ST * file.Qty,
                      Base_price: file.comp_Price_cart * file.Conv_facor_ST,
                      hiddenText: true,
                    };
                  }
                });
              // console.log(newProduct);
              setCartProduct(newProduct);
              let totalOrder = 0;
              for (let i = 0; i < newProduct.length; i++) {
                if (newProduct[i] !== undefined) {
                  totalOrder +=
                    (newProduct[i].Tax / 100) * newProduct[i].Price +
                    newProduct[i].Price;
                }
              }
              setTotalOrder(totalOrder);
              props.setTotalPrice(totalOrder);
              let Count = newProduct;
              var value = 0;
              var Tax = 0;
              for (let i = 0; i < Count.length; i++) {
                if (newProduct[i] !== undefined) {
                  value += Count[i].Price;
                  Tax += Count[i].Tax;
                  setItemCount(Count.length);
                  setTotal(value);
                  setTax(Tax);
                }
                props.setCartCount(Count.length);
              }
            } else {
              setCartProduct("");
              setTotalOrder(0);
              setItemCount(0);
              setTotal(0);
              setTax(0);
            }
          } else {
            setCartProduct("");
            setItemCount(0);
            setTotalOrder(0);
            setTotal(0);
            setTax(0);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setCartProduct("");
    }
  };

  const deleteCartItem = (cartID, userId) => {
    let data = {
      Cart_id: cartID,
      User_id: userId,
      custNo: props.customerNo,
    };
    axiosApiInstance
      .post(`${URL}/deleteFromCart`, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 400) {
            setTotalOrder(0);
            setItemCount(0);
            setTotal(0);
            setTax(0);
            props.setCartCount(0);
          } else {
            getCartData();
          }
        } else {
          console.log("else");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const IncrementItem = (
    i,
    qty,
    cartNo,
    amt,
    minQty,
    purchaseUnit,
    pcPerBox,
    Max_Quantity
  ) => {
    var product = [...cartProduct];
    let quantity = parseFloat(qty) + minQty;
    var price = 0;
    if (Max_Quantity === 0) {
      // console.log(quantity,'this is');
    } else if (quantity > Max_Quantity) {
      quantity = Max_Quantity;
      // price = product[i].unite_price * Max_Quantity;
      product[i].hiddenText = true;
    } else if (quantity > 0) {
      if (purchaseUnit === "Kart") {
        price = amt * quantity * pcPerBox;
      } else {
        price = amt * quantity;
      }
    } else {
      if (purchaseUnit === "Kart") {
        price = amt * pcPerBox;
        quantity = minQty;
      } else {
        price = amt;
        quantity = minQty;
      }
    }
    if (product[i].baseUnit === "Kg") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].kgUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].kgUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].kgUnit =
          Math.round(
            (quantity * product.Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    if (product[i].baseUnit === "Styck") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    if (product[i].baseUnit === "Kartplus") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kartplus") {
        product[i].BoxUnit =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    if (product[i].baseUnit === "Kart") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    let data = {
      User_id: Auth.getUserId(),
      PK_Cartno: cartNo,
      Price: price,
      Qty: quantity,
      CustNo: props.customerNo,
      KgUnit: product[i].KgUnit,
      StUnit: product[i].StUnit,
      BoxUnit: product[i].BoxUnit,
    };
    axiosApiInstance
      .put(`${URL}/updateCart`, data)
      .then((res) => {
        if (res.status === 200) {
          getCartData();
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DecreaseItem = (
    i,
    qty,
    cartNo,
    amt,
    minQty,
    purchaseUnit,
    pcPerBox
  ) => {
    var product = [...cartProduct];
    let quantity = parseFloat(qty) - minQty;
    var price = 0;
    if (quantity > 0) {
      if (purchaseUnit === "Kart") {
        price = amt * quantity * pcPerBox;
      } else {
        price = amt * quantity;
      }
    } else {
      if (purchaseUnit === "Kart") {
        price = amt * pcPerBox;
        quantity = minQty;
      } else {
        price = amt;
        quantity = minQty;
      }
    }
    if (product[i].baseUnit === "Kg") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].kgUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].kgUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].kgUnit =
          Math.round(
            (quantity * product[i].Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    if (product[i].baseUnit === "Styck") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    if (product[i].baseUnit === "Kart") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    if (product[i].baseUnit === "Kartplus") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kartplus") {
        product[i].BoxUnit =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    let data = {
      User_id: Auth.getUserId(),
      PK_Cartno: cartNo,
      Price: price,
      Qty: quantity,
      CustNo: props.customerNo,
      KgUnit: product[i].KgUnit,
      StUnit: product[i].StUnit,
      BoxUnit: product[i].BoxUnit,
    };
    axiosApiInstance
      .put(`${URL}/updateCart`, data)
      .then((res) => {
        // debugger
        if (res.status === 200) {
          getCartData();
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onclcikEvent = (e, i) => {
    setTimeout(() => {
      e.target.select();
    }, 20);
    let newArr = [...cartProduct];
    newArr[i].hiddenText = false;
    setCartProduct(newArr);
  };

  const onMouseLeaveEvent = (e, i) => {
    var newArr = [...cartProduct];
    newArr[i].hiddenText = true;
    setCartProduct(newArr);
  };

  const onChangeQty = (
    e,
    i,
    cartNo,
    amt,
    minQty,
    purchaseUnit,
    pcPerBox,
    Max_Quantity
  ) => {
    var product = [...cartProduct];
    var price = amt;
    if (purchaseUnit === "Kg") {
      var quantity = e.target.value.replace(/KG/g, "").trim();
      quantity = parseFloat(Math.floor(quantity));
      if (isNaN(quantity) === true || quantity === 0) {
        quantity = 0;
      }
    } else {
      var quantity = e.target.value.replace(/ST/g, "").trim();
      quantity = parseFloat(Math.floor(quantity));
      if (isNaN(quantity) === true || quantity === 0) {
        quantity = 0;
      }
    }
    if (Max_Quantity === 0) {
      // console.log(quantity,'this is');
    } else if (quantity > Max_Quantity) {
      quantity = Max_Quantity;
      // price = product[i].unite_price * Max_Quantity;
      product[i].hiddenText = true;
    } else if (quantity > 0) {
      if (purchaseUnit === "Kart") {
        price = amt * quantity * pcPerBox;
      } else {
        price = amt * quantity;
      }
    } else {
      if (purchaseUnit === "Kart") {
        price = amt * pcPerBox;
      } else {
        price = amt;
      }
    }
    if (product[i].baseUnit === "Kg") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].kgUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].kgUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].kgUnit =
          Math.round(
            (quantity * product[i].Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    if (product[i].baseUnit === "Styck") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].StUnit =
          Math.round(
            (quantity * product[i].Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    if (product[i].baseUnit === "Kart") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kart") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_CART + Number.EPSILON) * 100
          ) / 100;
      }
    }
    if (product[i].baseUnit === "Kartplus") {
      if (product[i].purchased_Unit === "Kg") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_KG + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Styck") {
        product[i].BoxUnit =
          Math.round(
            (quantity * product[i].Conv_facor_ST + Number.EPSILON) * 100
          ) / 100;
      }
      if (product[i].purchased_Unit === "Kartplus") {
        product[i].BoxUnit =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    const re = /^[0-9.,]+$/;
    if (quantity === "") {
      quantity = 1;
    }
    if (re.test(quantity)) {
      let data = {
        User_id: Auth.getUserId(),
        PK_Cartno: cartNo,
        Price: price,
        Qty: quantity,
        CustNo: props.customerNo,
        KgUnit: product[i].KgUnit,
        StUnit: product[i].StUnit,
        BoxUnit: product[i].BoxUnit,
      };
      axiosApiInstance
        .put(`${URL}/updateCart`, data)
        .then(async (res) => {
          if (res.status === 200) {
            const newProduct =
              res.data.data &&
              res.data.data.map((file) => {
                if (file.purchased_Unit === "Kart") {
                  return {
                    ...file,
                    Tax: !file.isKampanj ? file.Tax : 0,
                    Price: file.Price_cart * file.Qty * file.Conv_facor_CART,
                    Base_price: file.Price_cart * file.Conv_facor_CART,
                    hiddenText: true,
                  };
                } else if (file.purchased_Unit === "Kartplus") {
                  return {
                    ...file,
                    Tax: !file.isKampanj ? file.Tax : 0,
                    Price: file.Price_cart * 1,
                    Base_price: file.Price_cart * 1,
                    hiddenText: true,
                  };
                } else if (file.purchased_Unit === "Styck") {
                  return {
                    ...file,
                    Tax: !file.isKampanj ? file.Tax : 0,
                    Price: file.Price_broken * file.Conv_facor_ST * file.Qty,
                    Base_price: file.Price_broken * file.Conv_facor_ST,
                    hiddenText: true,
                  };
                } else if (file.purchased_Unit === "Kg") {
                  return {
                    ...file,
                    Tax: !file.isKampanj ? file.Tax : 0,
                    Price: file.Comp_Price * file.Conv_facor_KG * file.Qty,
                    Base_price: file.Comp_Price * file.Conv_facor_KG,
                    hiddenText: true,
                  };
                }
              });
            newProduct[i].hiddenText = false;
            await setCartProduct(newProduct);
            let totalOrder = 0;
            for (let i = 0; i < newProduct.length; i++) {
              totalOrder +=
                (newProduct[i].Tax / 100) * newProduct[i].Price +
                newProduct[i].Price;
            }
            await setTotalOrder(totalOrder);
            let Count = newProduct;
            var value = 0;
            var Tax = 0;
            for (let i = 0; i < Count.length; i++) {
              value += Count[i].Price;
              Tax += Count[i].Tax;
              await setItemCount(Count.length);
              await setTotal(value);
              await setTax(Tax);
            }
            props.setCartCount(Count.length);
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const receiveDate = (date) => {
    setDeliveryDate(date);
  };

  const checkOutHandle = (e, scheduleDate) => {
    e.preventDefault();
    let customerNo = props.customerNo;
    // let schDate = deliveryDate === '' ? scheduleDate : deliveryDate;
    if (scheduleDate !== "") {
      // var val = Math.floor(1000 + Math.random() * 9000);
      //console.log(val);
      // var discout = 20;
      //let discountPer = parseFloat(((discout/this.state.total) *100).toFixed(3));
      //((pEarned/pPos) * 100).toFixed(3);
      // let taxPerAmt = parseFloat(((taxPer / 100) * total).toFixed(2));

      // let totalOrder = (total + taxPerAmt);
      let totalOrder = 0;

      for (let i = 0; i < cartProduct.length; i++) {
        totalOrder +=
          (cartProduct[i].Tax / 100) * cartProduct[i].Price +
          cartProduct[i].Price;
      }

      let orderHead = {
        // OrderNo: val,
        OrderDate: moment(scheduleDate).format("YYYY-MM-DD[T]HH:mm:ss"),
        CustNo: customerNo,
        OrderAmount: total,
        OrderDiscPerc: 0,
        OrderDiscAmt: 0,
        OrderTaxPerc: 0,
        OrderTaxAmt: 0,
        OrderTotalAmt: totalOrder,
        User_id: Auth.getUserId(),
      };

      let cartItem = cartProduct;
      // console.log(cartItem);
      setLoading(true);
      let stopTimeDate = "";
      let isPickUpOn = "";
      allDeliveryData &&
        allDeliveryData.map((data) => {
          if (
            moment(data.DeliveryDate).format("DD-MM-YYYY") ===
            moment(scheduleDate).format("DD-MM-YYYY")
          ) {
            stopTimeDate = data.StopDateTime;
            isPickUpOn = data.PicupOnly;
          }
        });
      let request = {
        custNo: props.customerNo,
        stopDateTime: stopTimeDate,
        orderDeliveryDate: moment(scheduleDate).format("YYYY-MM-DD"),
        isPicupOnly: isPickUpOn,
      };
      axiosApiInstance
        .post(`${URL}/orders/newCheckOrderDateAndTime`, request)
        .then((res) => {
          if (res.status === 200) {
            // debugger
            const isAvailable = res.data.isDateAvailable;
            if (isAvailable) {
              axiosApiInstance
                .post(`${URL}/orders/orderHead`, orderHead)
                .then((res) => {
                  if (res.status === 200) {
                    let OrderSrNo = res.data.data[0].OrderNo;
                    var orderId = res.data.data[0].OrderID;
                    // let total_order = res.data.data[0].OrderTotalAmt;
                    // setOrderId(res.data.data[0].OrderID);
                    //  var SetTaxPer = res.data.data[0].OrderTaxPerc;
                    //  var OrderTaxAmt = res.data.data[0].OrderTaxAmt;
                    var items = [];
                    cartItem.map((item) => {
                      items.push({
                        OrderSrNo: OrderSrNo,
                        ProdNo: item.Product_id,
                        OrderQty: item.Qty,
                        OrderPrice: item.Price,
                        OrderTaxPerc: item.Tax,
                        OrderTaxAmt: (item.Price * item.Tax) / 100,
                        LineTotal: (item.Tax / 100) * item.Price + item.Price,
                        OrderUnit: item.purchased_Unit,
                        BaseUnit: item.baseUnit,
                        PurchaseUnitCount:
                          item.purchased_Unit === "Styck"
                            ? 1
                            : item.purchased_Unit === "Kg"
                            ? item.KgUnit
                            : item.pcPerBox,
                        UnitCount:
                          item.baseUnit === "Styck"
                            ? item.StUnit
                            : item.baseUnit === "Kg"
                            ? item.KgUnit
                            : item.BoxUnit,
                        BasePrice: item.Base_price,
                      });
                    });
                    let order_lines = {
                      OrderID: res.data.data[0].OrderID,
                      Items: items,
                      cust_no: customerNo,
                      invoiceDate: new Date().toISOString(),
                    };
                    axiosApiInstance
                      .post(`${URL}/orders/orderLine`, order_lines)
                      .then((res) => {
                        if (res.status === 200) {
                          let dataToSend = {
                            User_id: Auth.getUserId(),
                            custNo: customerNo,
                          };
                          axiosApiInstance
                            .post(`${URL}/deleteFromCartByUserId`, dataToSend)
                            .then((res) => {
                              setLoading(false);
                              if (res.status === 200) {
                                setCartProduct("");
                                setItemCount(0);
                                setTotal(0);
                                setTax(0);
                                setDeliveryDate("");
                                localStorage.removeItem("scheduleDate");
                                history.push(`/OrderConfirmation?${orderId}`);
                              }
                            })
                            .catch((err) => {
                              setLoading(false);
                              console.log(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                })
                .catch((err) => {
                  console.log(err, "error");
                });
            } else {
              swal(
                "Someting Wrong with Delivery Date",
                "Please re-select delivery date",
                "info"
              );
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      // swal("Please choose the schedule for delivery!", '', 'info');
      swal("Vänligen ange leveransdatum!", "", "info");
    }
  };

  return (
    <div>
      <Card className="no-borders-please">
        <ProductList
          onMouseLeaveEvent={onMouseLeaveEvent}
          onclcikEvent={onclcikEvent}
          deleteCartItem={deleteCartItem}
          onChangeQty={onChangeQty}
          customerNo={customerNo}
          DecreaseItem={DecreaseItem}
          IncrementItem={IncrementItem}
          cartProduct={cartProduct}
          receiveDate={receiveDate}
          tax={taxPer}
          total={total}
          itemCount={itemCount}
        />
        <CartSummary
          deliveryDate={deliveryDate}
          receiveDate={receiveDate}
          checkOutHandle={checkOutHandle}
          orderId={orderId}
          cartProduct={cartProduct}
          itemCount={itemCount}
          totalOrder={totalOrder}
          total={total}
          shipping={shipping}
          //  tax={taxPer}
          // OrderTaxAmt={getTaxAmout}
        />
      </Card>
      <div className="total-order-summary">
        <TotalSummary itemCount={itemCount} totalOrder={totalOrder} />
      </div>
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    customerNo: state.customer.customerNo,
    cartCount: state.customer.count,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCartCount: (count) => dispatch(setCartCount(count)),
  setTotalPrice: (price) => dispatch(setTotalPrice(price)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Cart);
