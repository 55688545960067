import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MiniCart from "./MiniCart";

export default function ListCart(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div id="cart-button-update-mini" className="ListWp cartIcnList">
        <List id="cart-button-update-list">
          <ListItem id="cart-button-update-list-item">
            <ListItemAvatar>
              <div onClick={handleShow} className="shopping-cart-icon-div">
                {" "}
                {/* Add Link component here */}
                <Badge badgeContent={props.cartCount} color="secondary">
                  <ShoppingCartIcon className="shoppincrtIcn" />
                </Badge>
              </div>
            </ListItemAvatar>
            <ListItemText
              onClick={handleShow}
              secondary={
                props.price.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                }) + " kr"
              }
              className="CartPriceText"
            />{" "}
          </ListItem>
        </List>
      </div>
      <Modal show={show} onHide={handleClose} scrollable id="cart-modal">
        <Modal.Header closeButton>
          <Modal.Title>Varukorg</Modal.Title>
        </Modal.Header>

        <Modal.Body className="mini-cart-modal-body">
          <MiniCart />
        </Modal.Body>

        <Modal.Footer>
          <Link to="/ProductOverview">
            <Button
              className="mini-cart-forsatt-button"
              variant="secondary"
              onClick={handleClose}
            >
              Fortsätt Handla
            </Button>
          </Link>
          <Link to="/Cart">
            <Button
              className="minicart-go-button"
              variant="primary"
              onClick={handleClose}
            >
              Gå till kassan
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
