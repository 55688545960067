import React, { useContext, useState } from "react";
import { DeliveryDateContext } from "../../../DateContext";
import { FormGroup } from "reactstrap";
import { URL } from "../../../ServerCall/Config";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import swal from "sweetalert";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { Button, Modal, Row, Col } from "react-bootstrap";
import Auth from "../../../ServerCall/Auth";

const LeveransdatumButton = (props) => {
  const [showHide, setShowHide] = useState(false);
  const { deliveryDate, setDeliveryDate } = useContext(DeliveryDateContext);
  const [disableDaysOfWeek, setDisableDaysOfWeek] = useState([]);
  const [allDeliveryData, setAllDeliveryData] = useState([]);
  const [disableDays, setDisableDays] = useState([]);
  const [pickUpDates, setPickUpDates] = useState([]);
  const [typeOfDelivery, setTypeOfDelivery] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);
  const [stopDateTime, setStopDateTime] = useState("");
  const acessToken = Auth.getToken();

  const modifiers = {
    pickUpDay: pickUpDates,
  };
  const moment = require("moment-timezone");
  const getDeliveryDates = async () => {
    try {
      const {
        data: { status, data: deliveryData },
      } = await axiosApiInstance.get(
        `${URL}/getDeliveryTerm/${props.customerNo}`
      );
      if (status === 200) {
        setAllDeliveryData(deliveryData);
        const timezone = "Europe/Stockholm";
        const allDates = deliveryData.reduce((acc, curr) => {
          const date = moment.tz(curr.DeliveryDate, timezone);
          while (
            date <=
            moment.tz(
              deliveryData[deliveryData.length - 1].DeliveryDate,
              timezone
            )
          ) {
            acc.push(date.clone().toDate());
            date.add(1, "days");
          }
          return acc;
        }, []);
        const availableDays = deliveryData.map((data) =>
          moment.tz(data.DeliveryDate, timezone).toDate()
        );
        const pickUpDates = deliveryData
          .filter((data) => data.PicupOnly === true)
          .map((data) => moment.tz(data.DeliveryDate, timezone).toDate());
        const disableDates = allDates.filter((date) => {
          const dateStart = moment.tz(date, timezone).startOf("day");
          const dateEnd = moment.tz(date, timezone).endOf("day");
          if (!deliveryData[dateStart.day() - 1]?.StopDateTime) {
            return !availableDays.some((availableDay) =>
              moment
                .tz(availableDay, timezone)
                .isBetween(dateStart, dateEnd, null, "[]")
            );
          }
          const now = moment.tz(timezone);
          if (dateStart.isSame(now, "day")) {
            const stopTime = moment.tz(
              dateStart.format("YYYY-MM-DD") +
                " " +
                deliveryData[dateStart.day() - 1].StopDateTime.slice(11),
              timezone
            );
            if (now.isAfter(stopTime)) {
              return true;
            }
          }
          return !availableDays.some((availableDay) =>
            moment
              .tz(availableDay, timezone)
              .isBetween(dateStart, dateEnd, null, "[]")
          );
        });

        setPickUpDates(pickUpDates);
        setDisableDays([
          ...disableDates,
          {
            before: moment.tz(deliveryData[0].DeliveryDate, timezone).toDate(),
            after: moment
              .tz(deliveryData[deliveryData.length - 1].DeliveryDate, timezone)
              .toDate(),
          },
        ]);
      } else {
        setDisableDays([
          { daysOfWeek: disableDaysOfWeek },
          { before: new Date() },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleModalShowHide = () => {
    if (!showHide) {
      // check if modal is being opened
      setDeliveryDate("");
    }
    setShowHide(!showHide);
    setAllDeliveryData([]);
    setPickUpDates([]);
    setIsAvailable("");
  };

  const showSchleduleModal = () => {
    let customerNo = props.customerNo;
    console.log("customerNo", customerNo);
    setDeliveryDate("");
    axiosApiInstance
      .get(`${URL}/getOrderDeliveryDays/${customerNo}`)
      .then((res) => {
        if (res.data.status === 200) {
          setShowHide(!showHide);
          let days = res.data.data[0];
          var daysEnable = [];
          if (days !== undefined) {
            if (days.Sunday === false) {
              daysEnable.push(0);
            }
            if (days.Monday === false) {
              daysEnable.push(1);
            }
            if (days.Tuesday === false) {
              daysEnable.push(2);
            }
            if (days.Wednesday === false) {
              daysEnable.push(3);
            }
            if (days.Thursday === false) {
              daysEnable.push(4);
            }
            if (days.Friday === false) {
              daysEnable.push(5);
            }
            if (days.Saturday === false) {
              daysEnable.push(6);
            }
          }
          setDisableDaysOfWeek(daysEnable);
        } else {
          setDisableDaysOfWeek([]);
          swal("Inga leveransvillkor tillagda ännu!", "", "info");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getDeliveryDates();
  };

  const checkAvailability = (day, isPickUp) => {
    let stopTimeDate = "";
    allDeliveryData &&
      allDeliveryData.map((data) => {
        if (
          moment(data.DeliveryDate).format("DD-MM-YYYY") ===
          moment(day).format("DD-MM-YYYY")
        ) {
          stopTimeDate = data.StopDateTime;
        }
      });
    let isPickUpOn = isPickUp === true ? true : false;
    let request = {
      custNo: props.customerNo,
      stopDateTime: stopTimeDate,
      orderDeliveryDate: moment(day).format("YYYY-MM-DD"),
      isPicupOnly: isPickUpOn,
    };
    axiosApiInstance
      .post(`${URL}/orders/newCheckOrderDateAndTime`, request)
      .then((res) => {
        if (res.status === 200) {
          const isAvailable = res.data.isDateAvailable;
          setIsAvailable(isAvailable);
          setStopDateTime(stopTimeDate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDayClick = (day, { selected, pickUpDay, disabled }, e) => {
    e.target.setAttribute("isavai", true);
    if (disabled) {
      setDeliveryDate("");
      setIsAvailable(false);
      return;
    }
    if (selected) {
      setDeliveryDate(day);
      return;
    } else {
      setDeliveryDate(day);
      checkAvailability(day, pickUpDay);
    }
    if (pickUpDay) {
      setTypeOfDelivery("Pick-Up");
    } else {
      setTypeOfDelivery("Delivery");
    }
  };

  return (
    <div>
      {acessToken ? (
        moment(deliveryDate).format("YYYY-MM-DD") === "Invalid date" ? (
          <button
            id="searchbutton-valj"
            className="btn btn-danger"
            onClick={() => showSchleduleModal()}
          >
            Välj leveransdatum
          </button>
        ) : (
          <button
            className="btn btn-success"
            id="searchbutton-valj"
            onClick={() => showSchleduleModal()}
          >
            {" "}
            Valt Datum: {moment(deliveryDate).format("YYYY-MM-DD")}{" "}
          </button>
        )
      ) : null}
      <Modal
        show={showHide}
        onHide={() => handleModalShowHide()}
        size="md"
        className="deliverydtmodal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Välj Leveransdatum</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pl-4 pr-4 pb-4">
          <p className="selectTimeDeliveryText">
            Kan du inte välja leveranstid? Vänligen{" "}
            <a
              onClick={() => {
                window.scrollTo(0, document.body.scrollHeight);
                setShowHide(false);
              }}
              href="tel:+4684648050"
              style={{
                color: "#138857",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              kontakta oss{" "}
            </a>{" "}
            för mer information.
          </p>
          <hr />
          <Row
            id="explaination-boxes-row-dates"
            className="explanation-row-dates"
          >
            <Col id="explanation-boxes-dates" className="notation">
              {" "}
              <input
                type="checkbox"
                id="upphamtningdagar-frontend"
                checked
                name="pickUp"
              />{" "}
              &nbsp; Upphämtningsdagar
            </Col>
            <Col className="notation">
              {" "}
              <input
                type="checkbox"
                id="leveransdagar-frontend"
                checked
                name="delivery"
              />{" "}
              &nbsp; Leveransdagar
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={8} style={{ margin: "0 auto" }}>
              <FormGroup style={{ border: "1px solid #d8d8d8" }}>
                <DayPicker
                  onDayClick={handleDayClick}
                  selectedDays={deliveryDate}
                  localeUtils={MomentLocaleUtils}
                  locale="sv"
                  firstDayOfWeek={1}
                  modifiers={modifiers}
                  disabledDays={disableDays}
                />
              </FormGroup>
            </Col>
          </Row>
          {deliveryDate !== "" ? (
            <Row>
              <Col className="text-center">
                {typeOfDelivery === "Delivery" ? "Leverans" : "Upphämtning"}{" "}
                {moment(deliveryDate).format("YYYY-MM-DD")} är{" "}
                {isAvailable ? (
                  <>
                    <span className="text-success">tillgänglig</span>
                    {stopDateTime && (
                      <p>
                        fram till kl: {moment(stopDateTime).format("HH:mm")}
                      </p>
                    )}
                  </>
                ) : (
                  <span className="text-danger">ej tillgänglig</span>
                )}
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer className="accept-leverans-container">
          <Button
            className="btn btn-success"
            onClick={() => handleModalShowHide()}
          >
            Acceptera
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LeveransdatumButton;
