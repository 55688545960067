import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "../../../App.css";
import { Button, Row, Col, Container } from "react-bootstrap";
import { Input } from "reactstrap";
import { ListGroup } from "react-bootstrap";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import "react-day-picker/lib/style.css";
import moment from "moment";

import missingImage from "../../../assets/images/picture_missing_text.png";

import { URL } from "../../../ServerCall/Config";
import Auth from "../../../ServerCall/Auth";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import { connect } from "react-redux";
import { setRefTopNav } from "../../../redux/action/customerAction";
import { Link } from "react-router-dom";

function ProductList(props) {
  const [User_id, setUser_id] = useState(Auth.getUserId());
  const [List_Descr, setList_Descr] = useState("");
  // const [List, setList] = useState([]);
  //  const [cartProduct, setCartProduct] = useState([]);
  const [DataList, setDataList] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState("");
  //const [cartCount, setCartCount] = useState(0);
  const [allDeliveryData, setAllDeliveryData] = useState([]);
  const [disableDays, setDisableDays] = useState([]);
  const [typeOfDelivery, setTypeOfDelivery] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);
  const [newDate, setNewDate] = useState("");

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getList();
    const scheduleDate = localStorage.getItem("scheduleDate");
    // debugger
    if (
      scheduleDate !== "null" &&
      scheduleDate !== null &&
      scheduleDate !== ""
    ) {
      setNewDate(scheduleDate);
      props.receiveDate(scheduleDate);
    } else {
      props.receiveDate("");
    }
    if (props.customerNo) {
      setDisableDays([]);
    }
  }, [props.customerNo, props.cartCount]);

  const getList = () => {
    if (User_id !== undefined) {
      let CustNo = props.customerNo;
      // let CustNo = props.customerNo;
      if (
        props.customerNo !== "" &&
        props.customerNo !== null &&
        props.customerNo !== undefined
      )
        axiosApiInstance
          .get(`${URL}/list/${User_id}/${props.customerNo}`)
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              let optionsList = [];
              setDataList(data);
              data &&
                data.map((item, i) => {
                  if (
                    (item.scheduleActiveList && !item.IsDeleted) ||
                    item.scheduleActiveList === null
                  ) {
                    optionsList.push({
                      value: item.PK_Listno,
                      label: item.List_Descr,
                      scheduleId: item.ScheduleId,
                    });
                  }
                });
              setOptions(optionsList);
            } else {
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
    } else {
      setDataList([]);
    }
  };
  const checkAvailability = (day, isPickUp) => {
    let stopTimeDate = "";
    allDeliveryData &&
      allDeliveryData.map((data) => {
        if (
          moment(data.DeliveryDate).format("DD-MM-YYYY") ===
          moment(day).format("DD-MM-YYYY")
        ) {
          stopTimeDate = data.StopDateTime;
        }
      });
    let isPickUpOn = isPickUp === true ? true : false;
    let request = {
      custNo: props.customerNo,
      stopDateTime: stopTimeDate,
      orderDeliveryDate: moment(day).format("YYYY-MM-DD"),
      isPicupOnly: isPickUpOn,
    };
    // console.log(request);
    axiosApiInstance
      .post(`${URL}/orders/newCheckOrderDateAndTime`, request)
      .then((res) => {
        if (res.status === 200) {
          // debugger
          const isAvailable = res.data.isDateAvailable;
          setIsAvailable(isAvailable);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDayClick = (day, { selected, pickUpDay, disabled }, e) => {
    // e.target.style.background = 'red';
    e.target.setAttribute("isavai", true);
    if (disabled) {
      setDeliveryDate("");
      setIsAvailable(false);
      // Day is disabled, do nothing
      return;
    }
    if (selected) {
      // Unselect the day if already selected
      setDeliveryDate(day);
      return;
    } else {
      setDeliveryDate(day);
      checkAvailability(day, pickUpDay);
      //this.setState({ deliveryDate: day });
    }
    if (pickUpDay) {
      setTypeOfDelivery("Pick-Up");
    } else {
      setTypeOfDelivery("Delivery");
    }
  };
  let Price = 0;

  const clearInput = () => {
    setList_Descr("");
  };
  return (
    <Container fluid>
      <Row>
        <Col md={12} className="cart-overview">
          <ul className="CartItems">
            {props.cartProduct.length > 0 ? (
              props.cartProduct &&
              props.cartProduct.map((item, index) => (
                <li className="cartItem pt-2">
                  <Row>
                    <Col lg={2} md={2}>
                      <div className="mini-cart product-image cartprodImg media-middle text-center">
                        {/* <img src={item.Small_Img}/> */}
                        <img
                          className="mini-cart-img-product"
                          src={
                            item.Pic_Small === ""
                              ? missingImage
                              : item.Pic_Small
                          }
                        />
                        {item.product_status == "3" ? (
                          <span className="svara-bestall-cartmini">
                            Beställningsvara
                            <span className="svara-bestall-cartmini-hovertooltip">
                              Vanligt vis är ledtiden 2-3 dagar för att leverera
                              beställningsvaror. <br />
                              Kontakta kontoret för mer information
                            </span>
                          </span>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={4} md={4} className="ProductLineInfoMiniCart">
                      <div className="product-line-info-mini">
                        <Link
                          to={{
                            pathname: `/ProductDetail`,
                            search: `?id=${item.Product_id}`,
                            state: {
                              productType:
                                item.isKampanj === false
                                  ? "Product"
                                  : "Kampanj",
                              Products: props.cartProduct,
                              pageToLoad: 1,
                              customerNo: props.customerNo,
                            },
                          }}
                          className="product_name"
                          href=""
                          data-id_customization="0"
                        >
                          {item.Product_name}{" "}
                          <p>
                            {" "}
                            {item.Price_type_name === "Kampanj" ||
                            item.Price_type_name === "Tillfälligt Parti" ? (
                              <span className="redClass">
                                {" "}
                                {item.Base_price.toFixed(2)} kr
                              </span>
                            ) : (
                              <span className="priceDefault">
                                {" "}
                                {item.Base_price.toFixed(2)} kr
                              </span>
                            )}
                          </p>
                          <p className="mini-cart-p-text">
                            <strong>{item.BrandName}</strong>{" "}
                            {item.Size_Contains}{" "}
                            {item.purchased_Unit === "Kg"
                              ? "VIKT"
                              : item.purchased_Unit === "Styck"
                              ? "1ST"
                              : item.purchased_Unit === "Kart" &&
                                item.baseUnit === "Kg"
                              ? `Kolli (${item.pcPerBox} Kg)`
                              : item.purchased_Unit === "Kartplus"
                              ? `Kolli (${item.pcPerBox} St)`
                              : item.purchased_Unit === "Kart" &&
                                item.baseUnit !== "KG"
                              ? `Kolli (${item.pcPerBox} St)`
                              : `Kolli (${item.pcPerBox} Kg)`}
                          </p>
                        </Link>
                      </div>
                    </Col>
                    <Col lg={3} md={3} className="Qty-minicart-new">
                      <ListGroup horizontal className="minicart-listgroup-new">
                        <ListGroup.Item
                          className="MiniCartListItemOne"
                          disabled={item.Base_qty === item.Qty ? true : false}
                          onClick={() =>
                            props.DecreaseItem(
                              index,
                              item.Qty,
                              item.PK_Cartno,
                              item.Base_price,
                              item.Base_qty,
                              item.purchased_Unit,
                              item.pcPerBox
                            )
                          }
                        >
                          -
                        </ListGroup.Item>
                        {item.purchased_Unit === "Kg" ? (
                          <Input
                            type={"text"}
                            className="incrementText-minicart"
                            // id="depositedAmount"
                            id={index}
                            data-min="1"
                            onChange={(e) =>
                              props.onChangeQty(
                                e,
                                index,
                                item.PK_Cartno,
                                item.Base_price,
                                item.Base_qty,
                                item.purchased_Unit,
                                item.pcPerBox,
                                item.Max_Quantity
                              )
                            }
                            value={
                              item.hiddenText ? item.Qty + " KG" : item.Qty
                            }
                            onClick={(e) => props.onclcikEvent(e, index)}
                            onBlur={(e) => props.onMouseLeaveEvent(e, index)}
                          />
                        ) : item.purchased_Unit === "Kart" ||
                          item.purchased_Unit === "Kartplus" ? (
                          <Input
                            type="text"
                            data-min="1"
                            autoComplete="off"
                            className="incrementText-minicart"
                            size="5"
                            name={item.Qty}
                            data-max="999"
                            id={index}
                            onChange={(e) =>
                              props.onChangeQty(
                                e,
                                index,
                                item.PK_Cartno,
                                item.Base_price,
                                item.Base_qty,
                                item.purchased_Unit,
                                item.pcPerBox,
                                item.Max_Quantity
                              )
                            }
                            value={
                              item.hiddenText ? item.Qty + " KOLLI" : item.Qty
                            }
                            onClick={(e) => props.onclcikEvent(e, index)}
                            onBlur={(e) => props.onMouseLeaveEvent(e, index)}
                          />
                        ) : (
                          <Input
                            type="text"
                            data-min="1"
                            autoComplete="off"
                            className="incrementText-minicart"
                            size="5"
                            name={item.Qty}
                            data-max="999"
                            id={index}
                            onChange={(e) =>
                              props.onChangeQty(
                                e,
                                index,
                                item.PK_Cartno,
                                item.Base_price,
                                item.Base_qty,
                                item.purchased_Unit,
                                item.pcPerBox,
                                item.Max_Quantity
                              )
                            }
                            value={
                              item.hiddenText ? item.Qty + " ST" : item.Qty
                            }
                            onClick={(e) => props.onclcikEvent(e, index)}
                            onBlur={(e) => props.onMouseLeaveEvent(e, index)}
                          />
                        )}
                        <ListGroup.Item
                          className="MiniCartListItemSecond"
                          onClick={() =>
                            props.IncrementItem(
                              index,
                              item.Qty,
                              item.PK_Cartno,
                              item.Base_price,
                              item.Base_qty,
                              item.purchased_Unit,
                              item.pcPerBox,
                              item.Max_Quantity
                            )
                          }
                        >
                          +
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      className="Total (tax excl.) price text-center my-2"
                    >
                      <span fluid className="Total (tax excl.) text-center">
                        <strong>
                          {item.Price_type_name === "Kampanj" ||
                          item.Price_type_name === "Tillfälligt Parti" ? (
                            <span className="redClass">
                              {item.Price.toFixed(2)} kr
                            </span>
                          ) : (
                            <span className="priceDefault-cart-mininew">
                              {item.Price.toFixed(2)} kr
                            </span>
                          )}
                        </strong>
                      </span>
                    </Col>
                    <Col
                      lg={1}
                      md={1}
                      className="cart-line-product-actions text-right my-2 text-sm-center"
                    >
                      <Button
                        className="remove-from-cart btn btn-light"
                        rel="nofollow"
                        onClick={() =>
                          props.deleteCartItem(item.PK_Cartno, item.User_id)
                        }
                      >
                        <DeleteOutlineIcon className="deleteOutlineIcn" />
                      </Button>
                    </Col>
                  </Row>
                </li>
              ))
            ) : (
              <div className="tom-varukorg">
                <div className="tom-varukorg-icon">
                  <ShoppingCartIcon fontSize="large" />
                </div>
                <div className="tom-varukorg-header">
                  <h3>Din varukorg är tom</h3>
                </div>
                <div className="tom-varukorg-text">
                  <p>Du har inga varor i din varukorg.</p>
                </div>
              </div>
            )}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="cart-overview">
          <ul className="CartItems">
            <li className="cartItem">
              <Row>
                <Col lg={2} md={2} className="product-line-grid-left">
                  <div className="product-image cartprodImg media-middle text-center"></div>
                </Col>
              </Row>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

const mapStoreToProps = (state) => {
  // console.log(state,"state");
  return {
    cartCount: state.customer.count,
    customerNo: state.customer.customerNo,
    refresh: state.customer.refresh,
  };
};

const mapDispatchToProps = (dispatch) => ({
  //setCartCount: (count) => dispatch(setCartCount(count)),
  setRefTopNav: (refresh) => dispatch(setRefTopNav(refresh)),
});

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(ProductList)
);
