import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button, Row, Col } from "react-bootstrap";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { Container, Input } from "reactstrap";
const useStyles = makeStyles({
  avatar: {
    backgroundColor: "#659f2f",
    // color: blue[600],
  },
});

const SaveListModal = (props) => {
  const [show, setShow] = useState(false);
  const [saveList, setSaveList] = useState();
  const { onClose, selectedValue, open } = props;
  const [addForm, setaddForm] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setShow(false);
    setaddForm(false);
    setSaveList("");
    props.clearInput();
  };
  const handleShow = () => {
    props.getList();
    setShow(true);
  };
  const [state, setState] = React.useState({
    list: false,
  });

  const onClick = () => {
    setShow(false);
    handleClose();
    setaddForm(false);
    props.CreatehandleSubmit(
      props.productNo,
      props.price,
      props.Qty,
      props.purchased_Unit,
      props.Base_price,
      props.pcPerBox,
      props.UnitCount,
      props.BaseUnit,
      props.Kampanj,
      props.IsMultipleProduct
    );
  };
  const { Week1, Week2, Week3 } = state;
  const error = [Week1, Week2, Week3].filter((v) => v).length !== 2;

  // let options = props.options;

  const handelSavedList = () => {
    setShow(false);
    const PK_Listno = saveList.selected.value;
    const schId = saveList.selected.scheduleId;
    if (props.IsMultipleProduct) {
      props.onAnswerSelectedAll(PK_Listno, props.IsMultipleProduct, schId);
    } else {
      props.onAnswerSelected(
        PK_Listno,
        props.productNo,
        props.price,
        props.Qty,
        props.purchased_Unit,
        props.Base_price,
        props.pcPerBox,
        props.UnitCount,
        props.BaseUnit,
        props.Kampanj,
        props.IsMultipleProduct,
        schId
      );
    }
  };

  const handleToggle = () => {
    if (addForm === false) {
      setaddForm(true);
    } else {
      setaddForm(false);
    }
  };
  return (
    <div>
      <Button
        className="saveBtn-spara-alla mb-3"
        color="primary"
        onClick={handleShow}
        onChange={props.getList}
      >
        <SaveAltIcon className="delete-icon-smaller" /> Spara alla i lista
      </Button>
      <Modal show={show} onHide={handleClose} className="m-0 p-0" centered>
        <Modal.Header className="pt-3 pb-2" closeButton>
          <Modal.Title className="pb-0">
            {addForm !== true ? `LÄGG TILL I LISTA ` : `SKAPA EN NY LISTA`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="align-self-center">
          <Container>
            <Row>
              {addForm !== true ? (
                <Col xl={12} md={12}>
                  <Select
                    options={props.options}
                    className="savedListMod"
                    onChange={(selected) => {
                      setSaveList({ selected });
                    }}
                    placeholder="Välj befintlig lista"
                  />
                </Col>
              ) : (
                <Col>
                  <Input
                    type="text"
                    name="List_Descr"
                    id=""
                    placeholder="Ange listans namn"
                    onChange={props.handleChangeList}
                    value={props.List_Descr}
                    className="savedListMod"
                  />
                </Col>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer id="saveListFooter " className="justify-content-center">
          <div className="m-0 p-0">
            {addForm !== true ? (
              <Button
                id="savebtn1"
                className="ml-3 btn btn-success"
                onClick={handelSavedList}
                disabled={saveList ? false : true}
                centered
              >
                Spara
              </Button>
            ) : props.List_Descr.trim() !== undefined &&
              props.List_Descr.trim() !== null &&
              props.List_Descr.trim() !== "" ? (
              <Button
                id="savebtn2"
                className="ml-3 btn btn-success"
                onClick={onClick}
              >
                Spara
              </Button>
            ) : (
              <Button
                id="savebtn3"
                className="ml-3 btn btn-success disabled px-5"
              >
                Spara
              </Button>
            )}
          </div>
          <Button onClick={handleToggle} className="ml-3 btn btn-success">
            {addForm !== true
              ? `Eller skapa en ny`
              : `Eller lägg till befintliga`}
          </Button>
        </Modal.Footer>
        {/* {props.DataList && props.DataList.map((item, index) =>
                                        <div className="dataListCheck">
                                            <FormGroup check disabled>
                                                <Label check>
                                                    <Input type="checkbox" name="radio1" id="horizontal-list"
                                                        //disabled = {props.disableOption}
                                                        id={index}
                                                        value={item}
                                                        onClick={() =>
                                                            props.onAnswerSelected(
                                                                item.PK_Listno,
                                                                props.productNo,
                                                                props.price,
                                                                props.Qty,
                                                                props.purchased_Unit,
                                                                props.Base_price,
                                                                props.pcPerBox,
                                                                props.UnitCount,
                                                                props.BaseUnit
                                                            )}
                                                    //onClick={props.onAnswerSelected()}
                                                    />{' '}
                                                    {item.List_Descr}
                                                </Label>
                                            </FormGroup>
                                            {/* <FormControlLabel
                                key={item.}
                                control={<Checkbox checked={state.list} name={item.List_Descr} onChange={(e) => handleChange(e)} name="gilad" />}
                                label={item.List_Descr}/> */}
        {/* <FormControlLabel control={<Checkbox  checked={ques.answer.includes(op)}  name={ques.options[j]} color="primary" /> } label={ques.options[j]}/> */}
        {/* </div> */}
        {/* ) */}
        {/* }  */}
        {/* </div> */}
        {/* <FormControlLabel
                            control={<Checkbox checked={Week2} onChange={handleChange} name="jason" />}
                            label="Week 2 List"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={Week3} onChange={handleChange} name="antoine" />}
                            label="Week 3 List"
                        /> */}
        {/* </FormGroup>
                        </FormControl> */}
        {/* <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                    {
                        this.props.DataList.map((List) =>(

                            <Row>
                            <Col>
                            <FormControlLabel
                            control={<Checkbox checked={Week1} onChange={handleChange} name="gilad" key={ props.DataList.User_id} />}
                            label={props.DataList.List_Descr}  />
                            </Col>
                        </Row>
                        ))
                    }
                       
                      
                        </FormGroup>
                    </FormControl> */}
        {/* <hr className="mb-1 mt-0 "/> */}

        {/* </div>
                </Modal.Body> */}
        {/* <Modal.Footer className="savetolist">
                    <Button className="createPlayListbtn mx-0 px-0" onClick={() => setaddForm(true)}><AddIcon className="mr-1" />Create New List
                    </Button>
                    {addForm ?
                        <div>
                            <FormGroup>
                                <Label className="ListLbl">Name</Label>
                                <Input type="text" name="List_Descr" id="" placeholder="Enter list Name" onChange={props.handleChangeList} value={props.List_Descr} />
                            </FormGroup>

                          {(props.List_Descr).trim() !== undefined && (props.List_Descr).trim()!== null && (props.List_Descr).trim()!==""? 
                          <Button className="saveBtn mt-3" onClick={onClick}>Create</Button>
                          :<Button  className="saveBtn mt-3 disabled">Create</Button>} 
                        </div>
                        :
                        null
                    }
                </Modal.Footer> */}
      </Modal>
    </div>
  );
};
export default SaveListModal;
SaveListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
