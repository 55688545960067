
import React from "react";
// import '../Course/CourseMaster.css'
import { Col,Row,Container } from 'reactstrap';
import Table from '../../../pages/Common/Table'
import Auth from '../../../ServerCall/Auth';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import { withRouter, Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/sv'
import {URL} from '../../../ServerCall/Config';
moment.locale('sv')
const headerStyle = {
    backgroundColor: '#1EA140',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky', top: 0 ,
  
  }
  const rowStyle={
    fontSize:'10px',
    padding:'10px',
    color:'#818698',
  
  }

export default  class OrderDetailAdmin extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        columns: [  
            // { title: "Id", field: "id" , hidden: true }
            // { title: "Sr No", render: rowData => <span>{rowData.tableData.id+1}</span> },
            // { title: "Id", render: rowData => <span>{rowData.tableData.id+1}</span> },
            { title: "Order Nr", field: "OrderNo",render: rowData =>   <Link to={{ pathname: `/ProductOrderDetailsAdmin`,
            state: {Items: rowData}}}  className="linkTextClass">{rowData.OrderNo}</Link>},
            { title: "Kundnamn", field: "Name" },
            { title: "User Name", field: "Contact_name" },
            { title: "Phone, Nummer", field: "Contactnumber" },
            { title: "Total Belopp", field: "OrderTotalAmt",render: rowData => <span>{(rowData.OrderTotalAmt).toFixed(2)}</span>},
            // { title: "Status", field: "Status"},
            { title: "Beställning skapad", field: "Created_At", render:rowData => moment(rowData.Created_At).format('LLL')},
            { title: "Orderdatum", field: "OrderDate", render:rowData => moment(rowData.OrderDate).format('LLL')},
            { title: "Order Status", field: "Order_status"},
           
          ],
          Data : [],
        }  
  }

  componentDidMount(){
    this.orderHistoryHandle();
  }

  orderHistoryHandle =()=>{
    
    axiosApiInstance.get(`${URL}/orders/getOrderHistorySuper`)
    .then(res=>{
      
      //console.log("res",res);
      this.setState({Data:res.data.data});
    })
    .catch(err=>{
      console.log("err",err);
    })
  }

  render(){
    const{Data,columns} =this.state;
    return(
        <div id="kok">

        <Container fluid className="Wraper FormWp wrapper">
            
            <Row>
              <Col className="">
              <h3 className="text-center blueText">Order</h3>
              </Col>
            </Row>
            <Row className="">
                <Col className="tableWp">
                    <Row>
                        <Col className="DashboardTbl">
                        <Table data={Data} pageSize={10} paging={true} columns={columns} search={true} headerStyle={headerStyle}
                            rowStyle={rowStyle} maxBodyHeight='500px' 
                        />
                        </Col>
                    </Row>            
                </Col>
            </Row>
       
        </Container>

    </div>

    );
}

}