import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from '../../components/header/Header';
import InfoBnr from '../../assets/images/infoBanner.jpg';
//import SubscribeOffers from '../../pages/ProductDetails/Components/SubscribeOffers';
import Footer from '../../components/footer/Footer';

const PrivacyPolicy = () => {
    return(
        <div>
        <Header />
        <Container fluid className="InfoWrap wrapper">
            <Row>
            {/* <Col md={3} className="ProdsidebarWp">
                <Sidebar />
            </Col> */}
            <Col md={12}>
                <Row>
                <Col className="">
                    <div className="InfoBannerBg">
                    <img src={InfoBnr} />
                    </div>
                    <h2 className="InfoBannerHeadText">{"INTEGRITETSPOLICY"}</h2>
                    {/* <p className="detailText">{"Here you will find gk selection from the range within Bread, Pastries &amp; Ice Cream.If you are missing something, you can contact your store.We lcome to our range! Browse our categories and find the items that suit you."} </p> */}
                </Col>
                </Row>
                <Row id="box">
                <Col md={12} className="InfoTxt">
                    <p className="text-center"><strong>INTEGRITETSPOLICY OCH BEHANDLING AV PERSONUPPGIFTER I ENLIGHET MED DATASKYDDSFÖRORDNINGEN (2016/679/EG) (GDPR)</strong></p>
                    <p>Grabbarnas Grönt AB, 556913-4009, (”Grönsakskompniet”) är personuppgiftsansvarig för de personuppgifter som Grönsakskompaniet erhåller i samband med vår verksamhet. Grönsakskompaniet behandlar uppgifterna för att genomföra vår verksamhet och strävar alltid efter att ge dig som registrerad den bästa möjliga personuppgiftshanteringen. </p>
                    <p>Personuppgifter är alla uppgifter som direkt eller indirekt, ensam eller i kombination med andra, kan identifiera en fysisk person. Sådana uppgifter kan exempelvis vara namn, kontaktuppgifter eller lokaliseringsuppgifter, även företags- mail och telefonnummer utgör personuppgifter om de kan kopplas till en fysisk person.</p>
                    <p>Dina personuppgifter behandlas på grundval av en eller flera punkter nedan</p>
                    <ul>
                        <li>att det är nödvändigt för att vi ska kunna fullgöra avtal mellan dig och Grönsakskompaniet, </li>
                        <li>att det finns samtycke mellan dig och Grönsakskompaniet,</li>
                        <li>att det är nödvändigt för att vi ska fullgöra en rättslig förpliktelse eller</li>
                        <li>att det är tillåtet efter intresseavvägning mellan Grönsakskompaniet och dig som registrerad. </li>
                    </ul>
                    <p>Personuppgifterna till en företrädare för en juridisk person kan komma att behandlas, denna behandling sker på grundval av vårt berättigade intresse av att fullgöra avtal mellan Grönsakskompaniet och den juridiska personen.</p>
                    <p>Personuppgifterna kan även komma att användas för marknasföringssyfte, vilket sker på grundval av Grönsakskompaniet berättigade intresse av att kommunicera med våra kunder. </p>
                    <p>Grönsakskompaniet lämnar inte ut personuppgifter till utomstående annat än i dem nedanstående fallen </p>
                    <ul>
                        <li>då levererande restaurang eller budfirma behöver uppgifterna för att fullgöra sitt uppdrag till följd av avtal mellan kunden och Grönsakskompaniet,</li>
                        <li>då utomstående tjänsteleverantörer utför uppdrag för Grönsakskompaniet räkning,</li>
                        <li>då det särskilt överenskommits mellan Grönsakskompaniet och dig, </li>
                        <li>då det är nödvändigt för att tillvarata dina rättigheter eller</li>
                        <li>då det är nödvändigt för att vi skall fullgöra lagstadgad skyldighet eller efterkomma myndighetsbeslut eller beslut av domstol.</li>
                    </ul>
                    <p>Personuppgifterna sparas under den tid som Grönsakskompaniet finner det nödvändigt beroende på ändamålet med registreringen. Uppgifter som behandlas i syfte att marknadsföra Grönsakskompaniet verksamhet sparas under en tid om 3 år efter den senaste kontakten. Om du avanmäler dig från marknadsföringsutskick eller liknande kommer uppgifterna omedelbart att raderas. </p>
                    <p>Du har alltid rätt att kostnadsfritt begära information om Grönsakskompaniet behandling av personuppgifter som rör dig. Vi kommer på din begäran 
                        eller på eget initiativ rätta eller radera uppgifter som är felaktiga eller begränsa behandlingen av sådana uppgifter. Om vi behandlar dina uppgifter 
                        på grundval av ditt samtycke har du när som helst rätt att återkalla ditt samtycke. Grönsakskompaniet har då inte längre laglig grund för behandlingen 
                        och ska omedelbart upphöra med behandlingen. Du har vidare rätt att begära att dina uppgifter inte behandlas för direktmarknadsföringsändamål. 
                        Du har ocksårätt att få del av dina personuppgifter i ett maskinläsbart format och, om det är tekniskt möjligt, att få uppgifterna överförda till en 
                        tredje part om du begär det. Om du är missnöjd med vår behandling av dina personuppgifter kan du lämna in ett klagomål till en tillsynsmyndighet, i Sverige 
                        är tillsynsmyndigheten Datainspektionen (www.datainspektionen.se). Du kan också vända dig till tillsynsmyndigheten i det land inom EU där du bor eller arbetar.</p>
                    <p className="text-center"><strong>Kontakta oss på gdpr@gronsakskompaniet.se eller adressen nedan om du har några frågor rörande vår personuppgiftsbehandling.</strong></p>
                    <p><strong>Personuppgiftsansvarig är:</strong><br />
                    Grabbarnas Grönt AB <br />
                    org.nr: 556913-4009 <br />
                    Adress: Konsumentvägen 9, 125 30 Älvsjö. <br />
                    Telefon: 08-464 80 50 <br />
                    www.gronsakskompaniet.se</p>
                </Col>
                </Row>
            </Col>
            </Row>
            {/* <Row >
            <Col>
                <SubscribeOffers />
            </Col>
            </Row> */}
        </Container>
        <Footer />
        </div>
    );
}

export default PrivacyPolicy;