import React from 'react'
import {Row,Col,Container,Card,Button} from 'reactstrap'
import Sidebar from '../../components/header/components/Sidebar'
import Header from '../../components/header/Header'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FileUploader from '../../pages/Common/FileUploader';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        // marginTop: theme.spacing(2),
      },
  }));
  
const BecomeACustomer = () => {
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState('CashorCard');
    const [checked, setChecked] = React.useState(true);
    const handleChange = (event) => {
      setValue(event.target.value);
    };
    const handleChangeCheck = (event) => {
      setChecked(event.target.checked);
    };
     return(
         <div>
             <Header/>
                <Container fluid className="Wraper">
                        <Row>
                        <Col lg={3} className="ProdsidebarWp">
                            <Sidebar/>
                         </Col>
                         <Col lg={6} className="ContactUsWp">
                            <Card className="col-md-12 conatctUSCard">   
                                <Row>
                                    <Col>
                                        <h3>Become a Customer of snabgross</h3>
                                        <p className="storeDetailText">Welcome to apply for a customer card from our Comapny. The customer card is a requirement to be able to shop with us, 
                                        but we do not impose any purchase requirements on you as a customer.
                                         With us you are welcome to shop as it suits you!</p>
                                        
                                    </Col>
                                </Row>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                 <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">1. Fill In The Corporate ID number</h3>
                                     </Col>
                                 </Row>
                                 <form className={classes.form} noValidate className="mt-3">
                                 <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="Company Name"
                                            name="CompanyName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="Company Name"
                                            label="Comapny Name"
                                            autoFocus
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="text-left mt-1">
                                            <Button className="BtnPrimaryContact pl-4 pr-4">Collect Information</Button>
                                        </Grid>
                                 </Grid>
                                </form>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">1.Enter Delivery Address</h3>
                                     </Col>
                                 </Row>
                                 <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="Company Name"
                                            name="CompanyName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="Company Name"
                                            label="Comapny Name"
                                            autoFocus
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="Zip Code"
                                            name="ZipCode"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="ZipCode"
                                            label="Zip Code"
                                            autoFocus
                                        />
                                        </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="Address"
                                                name="Address"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="Address"
                                                label="Address"
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="Postal"
                                                name="postal"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="postal"
                                                label="Postal Address"
                                                autoFocus
                                            />
                                        </Grid>
                                 </Grid>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                 <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">1.Fill In Contact Details</h3>
                                     </Col>
                                 </Row>
                                 <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="Phone No Buisness"
                                            name="PhoneBuisness"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="PhoneBuisnessId"
                                            label="Phone Number Buisness"
                                            autoFocus
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="First Name Contact Person"
                                            name="FirstPersonContactPerson"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="FirstPersonContactPerson"
                                            label="First Name Contact Person"
                                            autoFocus
                                        />
                                        </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="Email"
                                                name="Email"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="Email"
                                                label="Email"
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="LastNameContactPersn"
                                                name="LastNameContactPersn"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="LastNameContactPersn"
                                                label="LastNameContactPersn"
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="MobileNumberContactPersn"
                                                name="MobileNumberContactPersn"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="MobileNumberContactPersn"
                                                label="MobileNumberContactPersn"
                                                autoFocus
                                            />
                                        </Grid>
                                 </Grid>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">4.Choose Store and Type of Buisness</h3>
                                     </Col>
                                 </Row>
                                 <Grid container spacing={3}>
                                 <Grid item xs={12} sm={6}  className="SelectGrid">
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Nearest Place</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        onChange={handleChange}
                                        >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={12} sm={6}  className="SelectGrid">
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Type Of buisness</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        onChange={handleChange}
                                        >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                 </Grid>
                                 </Grid>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">4.Indicate the desire Payment Method</h3>
                                     </Col>
                                 </Row>
                                 <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="" value={value} onChange={handleChange}>
                                    <FormControlLabel value="CashorCard" control={<Radio />} label="Card or Cash" />
                                    <FormControlLabel value="Credit" control={<Radio />} label="Credit" />
                                </RadioGroup>
                                </FormControl>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">6.Become An E-Commerce Customer</h3>
                                     </Col>
                                 </Row>
                                 <FormControl component="fieldset">
                                <RadioGroup aria-label="" name="" value={value} onChange={handleChange}>
                                    <FormControlLabel value="Credit" control={<Radio />} label="Yes, I want to apply to become an e-commerce customer and have my goods delivered" />
                                </RadioGroup>
                                </FormControl>
                             </Card>
                             <Card className="mt-3 corporateCard">
                             <Row>
                                <Col>
                                <h3 className="StepsHeadtxt">7. Trade Starcol, Wine And Spirit</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <p>
                                    To be able to buy strong beer, beer, wine and spirits at gronsakskompaniet, a serving permit is required. 
                                    Attach this or come to your local fast wholesaler and submit a copy, or mail it in.
                                </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FileUploader/>
                                </Col>
                                
                            </Row>
                             </Card>
                             <Card className="mt-3 corporateCard">
                                 <Row>
                                     <Col>
                                        <h3 className="StepsHeadtxt">7. Trade Tobacco</h3>
                                     </Col>

                                 </Row>
                                 <Row>
                                     <Col>
                                     <p>To be able to buy tobacco at gronsakskompaniet, a tobacco permit is required.
                                          Attach this or come to your local fast wholesaler and submit a copy, or mail it in.</p>
                                     </Col>
                                 </Row>
                                 <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="ActorId"
                                            name="ActorId"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="ActorId"
                                            label="ActorID"
                                            autoFocus
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="FacilityId"
                                            name="FacilityId"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="FacilityId"
                                            label="FacilityId"
                                            autoFocus
                                        />
                                        </Grid>
                                    </Grid>
                             </Card>
                             <Card className="mt-3 corporateCard text-left">
                                <Row>
                                    <Col>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender" value={value} onChange={handleChange}>
                                            <FormControlLabel value="UpdatedEmail" control={<Radio />} label="Yes please, I want to stay updated and receive offers via email" />
                                            <FormControlLabel value="UpdatedText" disabled control={<Radio />} label="Yes please, I want to stay updated and get offers via text message" />
                                        </RadioGroup>
                                        </FormControl>
                                    </Col>
                                </Row>
                             </Card>
                             <Card  className="mt-3 corporateCard text-left">
                                 <Row>
                                     <Col>
                                     <Checkbox
                                            checked={checked}
                                            onChange={handleChangeCheck}
                                            inputProps={{ 'aria-label': ' checkbox' }}
                                            label="Uncontrolled"
                                        />
                                        <span>I Agree Terms and Conditions</span>
                                     </Col>
                                 </Row>
                                 <Row>
                                     <Col className="text-center"><Button className="btnAddShop pl-3 pr-3">Send</Button></Col>
                                 </Row>
                             </Card>
                        </Col>
                        </Row>
                </Container>
        </div>
     );
}
export default BecomeACustomer;
