import react, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Auth from "../../../ServerCall/Auth";
import BlogPosts from "./BlogPosts";

import ajdar from "../../../assets/images/sallad.png";
import ajdartwo from "../../../assets/images/coke.png";
import ajdarthree from "../../../assets/images/tomatos.png";
import ajdarfour from "../../../assets/images/avacoda.png";
import ajdarfive from "../../../assets/images/arla.png";
import "animate.css";
import Typewriter from "typewriter-effect";
import LoginPage from "./LoginPage";

const token = Auth.getToken();

const SectionBanner = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [ajdar, ajdartwo, ajdarthree, ajdarfour, ajdarfive];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 6000);
    return () => clearInterval(interval);
  }, [currentImageIndex]);

  const history = useHistory();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const actShopNow = () => {
    // token
    // ?
    history.push({
      pathname: "/ProductOverview",
      state: { default: "defaultId" },
    });
    // : history.push("/")
  };

  return (
    <div className="banner d-flex align-items-center">
      <Container fluid>
        <Row>
          <Col
            xs={12}
            /*             md={8} */
            className="banner-left d-flex justify-content-center align-items-center background-main-page"
          >
            <div className="banner-left-inner d-flex flex-column justify-content-center align-items-center">
              {/* <img
                src={images[currentImageIndex]}
                alt="Photo"
                className="banner-p hoto"
              /> */} {/* activate this after jul is over */}
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Välkommen till")
                    .pauseFor(3000)
                    .deleteAll()
                    .typeString("Grönsakskompaniet")
                    .start();
                }}
              />
              <br />
              {token ? (
                <h1 className="banner-header text-center">
                  Handla alla <br />
                  våra produkter
                </h1>
              ) : (
                <h1 className="banner-header text-center banner-text-jul">
                  Logga in för att handla <br />
                  alla våra produkter
                  {/* Öppetider Under Jul och Nyår: <br />
                  Måndag 25 December: 08:00 - 15:00 <br />
                  Tisdag 26 December: 08:00 - 15:00 <br />
                  Måndag 1 Januari: Stängt(ingen leverans) */}
                </h1>
                
                
              )}

              <br />
              {token ? (
                <Button className="btn btn-info" onClick={actShopNow}>
                  {/* Shop Now */}
                  Handla nu
                </Button>
              ) : (
                <LoginPage />
              )}
            </div>
          </Col>
          {/*           <Col
            xs={12}
            md={4}
            className="banner-right d-flex justify-content-center align-items-center background-main-page-blog-section"
          >
            <div className="banner-right-inner d-flex flex-column justify-content-center align-items-center">
              <h1 className="nyheter-nyheter banner-header text-center">
                Nyheter
              </h1>
              <BlogPosts />
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};
export default SectionBanner;
