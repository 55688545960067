import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { DeliveryDateContext } from "../../../DateContext";
import { withRouter } from "react-router";
import { FormGroup } from "reactstrap";
import swal from "sweetalert";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/sv";
import "moment/locale/ar";
import "moment/locale/it";
import { URL } from "../../../ServerCall/Config";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import { connect } from "react-redux";
import { setRefTopNav } from "../../../redux/action/customerAction";

const CartSummary = (props) => {
  const [final, setFinal] = useState([]);
  const [showHide, setShowHide] = useState(false);
  const { deliveryDate, setDeliveryDate } = useContext(DeliveryDateContext);
  const [disableDaysOfWeek, setDisableDaysOfWeek] = useState([]);
  const [allDeliveryData, setAllDeliveryData] = useState([]);
  const [disableDays, setDisableDays] = useState([]);
  const [pickUpDates, setPickUpDates] = useState([]);
  const [typeOfDelivery, setTypeOfDelivery] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);
  const [stopDateTime, setStopDateTime] = useState("");

  useEffect(() => {
    let cartProduct = props.cartProduct;
    let taxData = [];
    let final = [];
    cartProduct &&
      cartProduct.map((data) => {
        taxData.push({ Tax: data.Tax, taxPrice: "", Price: data.Price });
      });
    taxData &&
      taxData.map((data) => {
        let isPresent = final.filter((v, i) => {
          return v.Tax === data.Tax;
        });
        if (isPresent.length) {
          let index = final.indexOf(isPresent[0]);
          final[index].Price = final[index].Price + data.Price;
          final[index].taxPrice = (final[index].Price * final[index].Tax) / 100;
        } else {
          if (data.Tax !== 0) {
            let obj = {
              Tax: data.Tax,
              Price: data.Price,
              taxPrice: (data.Price * data.Tax) / 100,
            };
            final.push(obj);
          }
        }
      });
    if (final.length > 0) {
      setFinal(final);
    } else {
      let obj = {
        Tax: 0,
        taxPrice: "Taxes are not applicable",
      };
      final.push(obj);
      setFinal(final);
    }
    const scheduleDate = localStorage.getItem("scheduleDate");
    if (
      scheduleDate !== "null" &&
      scheduleDate !== null &&
      scheduleDate !== ""
    ) {
      props.receiveDate(scheduleDate);
    } else {
      props.receiveDate("");
    }
    if (props.customerNo) {
      setDisableDays([]);
    }
  }, [props, props.customerNo]);
  const modifiers = {
    pickUpDay: pickUpDates,
  };
  const moment = require("moment-timezone");
  const getDeliveryDates = async () => {
    try {
      const {
        data: { status, data: deliveryData },
      } = await axiosApiInstance.get(
        `${URL}/getDeliveryTerm/${props.customerNo}`
      );
      if (status === 200) {
        setAllDeliveryData(deliveryData);
        const timezone = "Europe/Stockholm";
        const allDates = deliveryData.reduce((acc, curr) => {
          const date = moment.tz(curr.DeliveryDate, timezone);
          while (
            date <=
            moment.tz(
              deliveryData[deliveryData.length - 1].DeliveryDate,
              timezone
            )
          ) {
            acc.push(date.clone().toDate());
            date.add(1, "days");
          }
          return acc;
        }, []);
        const availableDays = deliveryData.map((data) =>
          moment.tz(data.DeliveryDate, timezone).toDate()
        );
        const pickUpDates = deliveryData
          .filter((data) => data.PicupOnly === true)
          .map((data) => moment.tz(data.DeliveryDate, timezone).toDate());
        const disableDates = allDates.filter((date) => {
          const dateStart = moment.tz(date, timezone).startOf("day");
          const dateEnd = moment.tz(date, timezone).endOf("day");
          if (!deliveryData[dateStart.day() - 1]?.StopDateTime) {
            return !availableDays.some((availableDay) =>
              moment
                .tz(availableDay, timezone)
                .isBetween(dateStart, dateEnd, null, "[]")
            );
          }
          const now = moment.tz(timezone);
          if (dateStart.isSame(now, "day")) {
            const stopTime = moment.tz(
              dateStart.format("YYYY-MM-DD") +
                " " +
                deliveryData[dateStart.day() - 1].StopDateTime.slice(11),
              timezone
            );
            if (now.isAfter(stopTime)) {
              return true;
            }
          }
          return !availableDays.some((availableDay) =>
            moment
              .tz(availableDay, timezone)
              .isBetween(dateStart, dateEnd, null, "[]")
          );
        });

        setPickUpDates(pickUpDates);
        setDisableDays([
          ...disableDates,
          {
            before: moment.tz(deliveryData[0].DeliveryDate, timezone).toDate(),
            after: moment
              .tz(deliveryData[deliveryData.length - 1].DeliveryDate, timezone)
              .toDate(),
          },
        ]);
      } else {
        setDisableDays([
          { daysOfWeek: disableDaysOfWeek },
          { before: new Date() },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleModalShowHide = () => {
    if (!showHide) {
      // check if modal is being opened
      setDeliveryDate("");
    }
    setShowHide(!showHide);
    setAllDeliveryData([]);
    setPickUpDates([]);
    setIsAvailable("");
  };

  const showSchleduleModal = () => {
    let customerNo = props.customerNo;
    setDeliveryDate("");
    axiosApiInstance
      .get(`${URL}/getOrderDeliveryDays/${customerNo}`)
      .then((res) => {
        if (res.data.status === 200) {
          setShowHide(!showHide);
          let days = res.data.data[0];
          var daysEnable = [];
          if (days !== undefined) {
            if (days.Sunday === false) {
              daysEnable.push(0);
            }
            if (days.Monday === false) {
              daysEnable.push(1);
            }
            if (days.Tuesday === false) {
              daysEnable.push(2);
            }
            if (days.Wednesday === false) {
              daysEnable.push(3);
            }
            if (days.Thursday === false) {
              daysEnable.push(4);
            }
            if (days.Friday === false) {
              daysEnable.push(5);
            }
            if (days.Saturday === false) {
              daysEnable.push(6);
            }
          }
          setDisableDaysOfWeek(daysEnable);
        } else {
          setDisableDaysOfWeek([]);
          swal("Inga leveransvillkor tillagda ännu!", "", "info");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    getDeliveryDates();
  };

  const checkAvailability = (day, isPickUp) => {
    let stopTimeDate = "";
    allDeliveryData &&
      allDeliveryData.map((data) => {
        if (
          moment(data.DeliveryDate).format("DD-MM-YYYY") ===
          moment(day).format("DD-MM-YYYY")
        ) {
          stopTimeDate = data.StopDateTime;
        }
      });
    let isPickUpOn = isPickUp === true ? true : false;
    let request = {
      custNo: props.customerNo,
      stopDateTime: stopTimeDate,
      orderDeliveryDate: moment(day).format("YYYY-MM-DD"),
      isPicupOnly: isPickUpOn,
    };
    axiosApiInstance
      .post(`${URL}/orders/newCheckOrderDateAndTime`, request)
      .then((res) => {
        if (res.status === 200) {
          const isAvailable = res.data.isDateAvailable;
          setIsAvailable(isAvailable);
          setStopDateTime(stopTimeDate);
        }
      })
      .catch((err) => {
        // moved catch here
        console.log(err);
      });
    console.log(day);
  };

  const handleDayClick = (day, { selected, pickUpDay, disabled }, e) => {
    e.target.setAttribute("isavai", true);
    if (disabled) {
      setDeliveryDate("");
      setIsAvailable(false);
      return;
    }
    if (selected) {
      setDeliveryDate(day);
      return;
    } else {
      setDeliveryDate(day);
      checkAvailability(day, pickUpDay);
    }
    if (pickUpDay) {
      setTypeOfDelivery("Pick-Up");
    } else {
      setTypeOfDelivery("Delivery");
    }
  };

  const handleCheckOut = (e, date) => {
    props.checkOutHandle(e, date);
  };

  return (
    <Col md={12} className="antal-artiklar-col">
      <div className="card cart-summary border-0">
        <div className="cart-detailed-totals">
          <div className="card-block pb-0 pl-0">
            <div className="cart-summary-linet" id="cart-subtotal-products">
              <span className="label js-subtotal">
                Antal Artiklar {props.itemCount}
              </span>
            </div>
          </div>
          <hr class="separator" />
          <div className="card-block py-1">
            <div className="cart-summary-line row cart-total mb-2">
              <span className="label col">Netto (exkl. moms)</span>
              <span className="value col">
                {parseFloat(props.total.toFixed(2))} kr
              </span>
            </div>
            <div className=" mb-3">
              {final &&
                final.map((data, i) => (
                  <span className="cart-summary-line row mb-2" key={i}>
                    <span className="label col">
                      Moms {data.Tax === 0 ? null : `${data.Tax} %`}
                    </span>
                    <span className="value col">
                      {props.cartProduct === ""
                        ? `0 kr`
                        : data.Tax === 0
                        ? "Skatt är inte tillämplig"
                        : `${data.taxPrice.toFixed(2)} kr`}
                    </span>
                    {/*"Tax is not applicable"*/}
                  </span>
                ))}
            </div>
            <div className="cart-summary-line row mb-2">
              <span className="label col" style={{ fontSize: "18px" }}>
                Brutto (inkl. moms)
              </span>
              <span className="value col" style={{ fontSize: "18px" }}>
                {Number(props.totalOrder).toFixed(2)}
                kr
              </span>
            </div>
          </div>
          <hr class="separator" />
        </div>
        <div class="checkout cart-detailed-actions card-block px-2 pt-1">
          <div className="row">
            <div className="col-md-2"> </div>

            <div className="col-md-4">
              {moment(deliveryDate).format("YYYY-MM-DD") === "Invalid date" ? (
                <Button
                  className="btn btn-danger mb-5"
                  onClick={() => showSchleduleModal()}
                  disabled={props.cartProduct ? false : true}
                >
                  {" "}
                  Välj Leveransdatum
                </Button>
              ) : (
                <Button
                  className="btn btn-success mb-5"
                  onClick={() => showSchleduleModal()}
                >
                  {" "}
                  Valt datum: {moment(deliveryDate).format("YYYY-MM-DD")}{" "}
                </Button>
              )}
            </div>
            <div className="col-md-4">
              <Button
                className="btn btn-success"
                onClick={(e) => handleCheckOut(e, deliveryDate)}
                disabled={
                  moment(deliveryDate).format("YYYY-MM-DD") === "Invalid date"
                    ? true
                    : false
                }
              >
                Bekräfta Order
              </Button>
            </div>
            <div className="col-md-2"> </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      <Modal
        show={showHide}
        onHide={() => handleModalShowHide()}
        size="md"
        className="deliverydtmodal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Välj Leveransdatum</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pl-4 pr-4 pb-4">
          <p className="selectTimeDeliveryText">
            Kan du inte välja leveranstid? Vänligen{" "}
            <a
              onClick={() => {
                window.scrollTo(0, document.body.scrollHeight);
                setShowHide(false);
              }}
              href="tel:+4684648050"
              style={{ color: "#138857", fontWeight: "600", cursor: "pointer" }}
            >
              kontakta oss{" "}
            </a>{" "}
            för mer information.
          </p>
          <hr />
          <Row>
            <Col className="notation">
              {" "}
              <input type="checkbox" checked name="pickUp" /> &nbsp;
              Upphämtningsdagar
            </Col>
            <Col className="notation">
              {" "}
              <input type="checkbox" checked name="delivery" /> &nbsp;
              Leveransdagar
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={8} style={{ margin: "0 auto" }}>
              <FormGroup style={{ border: "1px solid #d8d8d8" }}>
                <DayPicker
                  onDayClick={handleDayClick}
                  selectedDays={deliveryDate}
                  localeUtils={MomentLocaleUtils}
                  locale="sv"
                  firstDayOfWeek={1}
                  modifiers={modifiers}
                  disabledDays={disableDays}
                />
              </FormGroup>
            </Col>
          </Row>
          {deliveryDate !== "" ? (
            <Row>
              <Col className="text-center">
                {typeOfDelivery === "Delivery" ? "Leverans" : "Upphämtning"}{" "}
                {moment(deliveryDate).format("YYYY-MM-DD")} är{" "}
                {isAvailable ? (
                  <>
                    <span className="text-success">tillgänglig</span>
                    {stopDateTime && (
                      <p>
                        fram till kl: {moment(stopDateTime).format("HH:mm")}
                      </p>
                    )}
                  </>
                ) : (
                  <span className="text-danger">ej tillgänglig</span>
                )}
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer className="accept-leverans-container">
          <Button
            className="btn btn-success"
            onClick={() => handleModalShowHide()}
          >
            Acceptera
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

const mapStoreToProps = (state) => {
  return {
    cartCount: state.customer.count,
    customerNo: state.customer.customerNo,
    refresh: state.customer.refresh,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setRefTopNav: (refresh) => dispatch(setRefTopNav(refresh)),
});

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(CartSummary)
);
