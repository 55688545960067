import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import TopNav from "./components/TopNav";
import Menu from "./components/Menu";
import MobViewDrawer from "./components/MobViewDrawer";
import { setAccessLevel } from "../../redux/action/customerAction";
import { connect } from "react-redux";
import Auth from "../../ServerCall/Auth";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = (props) => {
  const access_level = Auth.getAccess_level();
  var accesslvl = "";

  useEffect(async () => {
    if (access_level !== undefined) {
      accesslvl = access_level.find((obj) => obj === 3);
      await props.setAccessLevel(accesslvl);
    }
  }, [props.accesslvl, props.cutomerNo]);

  return (
    <Container fluid className=" headerClass">
      <MobViewDrawer />
      <TopNav />
      {/* <SearchBar/> */}
      <Menu />
      {/* <div className="desktopdisplyNone"> <SideMenubar/></div> */}
    </Container>
  );
};

const mapStoreToprops = (state) => {
  return {
    accesslvl: state.customer.accesslvl,
    cutomerNo: state.customer.cutomerNo,
  };
};

const mapDispatchToprops = (dispatch) => ({
  setAccessLevel: (accesslvl) => dispatch(setAccessLevel(accesslvl)),
});

export default connect(mapStoreToprops, mapDispatchToprops)(Header);
