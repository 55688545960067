import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "../../../App.css";
import { Button, Row, Col, Card, Modal, Container } from "react-bootstrap";
import { CardTitle, Input, FormGroup } from "reactstrap";
import { ListGroup } from "react-bootstrap";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";

import missingImage from "../../../assets/images/picture_missing_text.png";
import SaveListModal from "./SaveListModal";
import SaveListModalAll from "./SaveListModalAll";

import { URL } from "../../../ServerCall/Config";
import Auth from "../../../ServerCall/Auth";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import { connect } from "react-redux";
import { setRefTopNav } from "../../../redux/action/customerAction";
import { Link } from "react-router-dom";

function ProductList(props) {
  const [User_id, setUser_id] = useState(Auth.getUserId());
  const [List_Descr, setList_Descr] = useState("");
  const [Recipe_amount, setRecipe_amount] = useState(0);
  const [Recipe_unit, setRecipe_unit] = useState("");
  const [Active_List, setActive_List] = useState(1);
  const [Last_Buy, setLast_Buy] = useState(new Date().toISOString());
  const [chdt, setChdt] = useState(new Date().toISOString());
  const [chtm, setChtm] = useState("0000");
  // const [List, setList] = useState([]);
  //  const [cartProduct, setCartProduct] = useState([]);
  const [DataList, setDataList] = useState([]);
  const [close, setClose] = useState(true);
  const [show, setShow] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState("");
  //const [cartCount, setCartCount] = useState(0);
  const [disableDaysOfWeek, setDisableDaysOfWeek] = useState([]);
  const [allDeliveryData, setAllDeliveryData] = useState([]);
  const [disableDays, setDisableDays] = useState([]);
  const [pickUpDates, setPickUpDates] = useState([]);
  const [typeOfDelivery, setTypeOfDelivery] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);
  const [newDate, setNewDate] = useState("");

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getList();
    const scheduleDate = localStorage.getItem("scheduleDate");
    // debugger
    if (
      scheduleDate !== "null" &&
      scheduleDate !== null &&
      scheduleDate !== ""
    ) {
      setNewDate(scheduleDate);
      props.receiveDate(scheduleDate);
    } else {
      props.receiveDate("");
    }
    if (props.customerNo) {
      setDisableDays([]);
    }
  }, [props.customerNo, props.cartCount]);

  const getAllProductList = () => {
    let allProductList = [];

    props.cartProduct &&
      props.cartProduct.map((item, index) => {
        let unitCount =
          item.baseUnit === "Styck"
            ? item.StUnit
            : item.baseUnit === "Kg"
            ? item.KgUnit
            : item.BoxUnit;
        allProductList.push({
          purchased_Unit: item.purchased_Unit,
          productNo: item.Product_id,
          Qty: item.Qty,
          price: item.Price,
          pcPerBox: item.pcPerBox,
          BaseUnit: item.baseUnit,
          UnitCount: unitCount,
          Kampanj: item.isKampanj,
          product_name: item.Product_name,
        });
      });

    return allProductList;
  };

  const getList = () => {
    if (User_id !== undefined) {
      let CustNo = props.customerNo;
      // let CustNo = props.customerNo;
      if (
        props.customerNo !== "" &&
        props.customerNo !== null &&
        props.customerNo !== undefined
      )
        axiosApiInstance
          .get(`${URL}/list/${User_id}/${props.customerNo}`)
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              let optionsList = [];
              setDataList(data);
              data &&
                data.map((item, i) => {
                  if (
                    (item.scheduleActiveList && !item.IsDeleted) ||
                    item.scheduleActiveList === null
                  ) {
                    optionsList.push({
                      value: item.PK_Listno,
                      label: item.List_Descr,
                      scheduleId: item.ScheduleId,
                    });
                  }
                });
              setOptions(optionsList);
            } else {
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
    } else {
      setDataList([]);
    }
  };

  const modifiers = {
    // pickUpDay: ([new Date(2018, 9, 4),new Date(2018, 9, 8)]),
    pickUpDay: pickUpDates,
    // birthday: new Date(2018, 9, 30),
  };

  const CreatehandleSubmit = (
    prodctNo,
    price,
    qty,
    purchased_Unit,
    base_Price,
    pcPerBox,
    UnitCount,
    BaseUnit,
    Kampanj,
    IsMultipleProduct
  ) => {
    let CustNo = props.customerNo;
    if (
      List_Descr.trim() !== undefined &&
      List_Descr.trim() !== null &&
      List_Descr.trim() !== ""
    ) {
      axiosApiInstance
        .post(`${URL}/list`, {
          User_id,
          List_Descr,
          Recipe_amount,
          Recipe_unit,
          Active_List,
          Last_Buy,
          CustNo,
          chdt,
          chtm,
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setDataList(data);
            setList_Descr("");
            // swal("List created successfully!", "", "info").then(()=>props.setRefTopNac(!refresh));
            if (data.length > 0) {
              const listno = data.length - 1;
              const pkListNo = data[listno].PK_Listno;
              const listDesc = data[listno].List_Descr;
              const scheduleId = data[listno].scheduleId;
              if (IsMultipleProduct) {
                onAnswerSelectedAll(pkListNo, scheduleId, listDesc);
              } else {
                onAnswerSelected(
                  pkListNo,
                  prodctNo,
                  price,
                  qty,
                  purchased_Unit,
                  base_Price,
                  pcPerBox,
                  UnitCount,
                  BaseUnit,
                  Kampanj,
                  scheduleId,
                  listDesc
                );
              }
            }
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // swal("Please enter name for the list", "", "info")
      swal("Ange namn för listan", "", "info");
    }
  };

  const chekProductAdd = (listNo, item, scheduleId, listDesc) => {
    return new Promise(function (resolve, reject) {
      // setTimeout(() => {
      let saveListApiDatas = [];
      let message = ``;
      let title = ``;
      let icon = "";
      let say = 0;
      let isAdd = false;

      let isKampanjPro = item.Kampanj === true ? 1 : 0;
      let insertIntoTemp = "";
      let isEndDate = "";
      let nearestDate = null;
      let isAlreadyExist = "";
      let isScheduleExist = "";

      let date = new Date().toISOString();

      let checkBeforeSaveData = {
        PK_Listno: listNo,
        Prodno: item.productNo,
        Recipe_unit: item.purchased_Unit,
        CustNo: props.customerNo,
        UserId: User_id,
      };
      let checkDateData = {
        scheduleId: scheduleId,
        userId: User_id,
        custNo: props.customerNo,
      };
      let saveListApiData = {
        UserId: User_id,
        PK_Listno: listNo,
        Prodno: item.productNo,
        StartingFrom: nearestDate,
        Recipe_amount: item.price,
        Recipe_unit: item.purchased_Unit,
        Active_Product: 1,
        Alternativ_prodno: "",
        Last_Buy_Amount: 0,
        Quantity: item.Qty,
        Base_price: item.base_Price,
        Last_Buy_Date: date,
        UnitCount: item.UnitCount,
        BaseUnit: item.BaseUnit,
        PurchaseUnitCount:
          item.purchased_Unit === "Styck"
            ? 1
            : item.purchased_Unit === "Kg"
            ? item.UnitCount
            : item.pcPerBox,
        isKampanj: isKampanjPro,
      };
      axiosApiInstance
        .post(`${URL}/checkScheduleAndProductExistInList`, checkBeforeSaveData)
        .then((res) => {
          // console.log('Check Before Save',res.data)

          isAlreadyExist = res.data.isAlreadyExist;
          isScheduleExist = res.data.isScheduleExist;

          if (!isAlreadyExist && isScheduleExist) {
            // debugger
            axiosApiInstance
              .put(`${URL}/checkDatesForQtyTesting`, checkDateData)
              .then((res) => {
                // console.log('Check Dates',res.data)
                if (res.status === 200) {
                  say = say + 1;
                  insertIntoTemp = res.data.data.insertIntoTemp;
                  isEndDate = res.data.data.isEndDate;
                  nearestDate = res.data.data.nearestDate;

                  if (!insertIntoTemp && isEndDate && nearestDate) {
                    // title=`Product Added!`
                    // message=`Product added to list `
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      item.product_name +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  } else if (insertIntoTemp && !isEndDate && nearestDate) {
                    title = `Är du säker?`;
                    // message=`Product will be added to list on: ${nearestDate}`
                    message +=
                      `\n` +
                      item.product_name +
                      ` Produkten kommer att läggas till i listan på: ${nearestDate}`;
                    icon = "warning";
                  } else if (!insertIntoTemp && !isEndDate && nearestDate) {
                    // title=`Product Added!`
                    // message=`Product added to list `
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      item.product_name +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  } else if (
                    !insertIntoTemp &&
                    isEndDate &&
                    nearestDate === null
                  ) {
                    // title=`Can not add Product`
                    // message=`Product cannot be added to list End Date is passed for list`
                    title = `Kan inte lägga till produkt`;
                    message +=
                      `\n` +
                      item.product_name +
                      ` Produkten kan inte läggas till i listan. Slutdatumet har passerat för listan`;
                    icon = "error";
                  } else {
                    // title='Product Added!'
                    // message=`Product added to list`
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      item.product_name +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  }
                  // debugger
                  if (icon === "success") {
                    saveListApiData = {
                      ...saveListApiData,
                      StartingFrom: nearestDate,
                    };
                    saveListApiDatas.push(saveListApiData);
                    isAdd = true;
                  } else if (icon === "warning") {
                    saveListApiData = {
                      ...saveListApiData,
                      StartingFrom: nearestDate,
                    };
                    saveListApiDatas.push(saveListApiData);
                    isAdd = true;
                  } else {
                    // swal(title, message, icon)
                    //   .then(() => {props.setRefTopNav(!props.refresh);});
                  }
                } else {
                  //swal("Något gick fel","","error",{timer:1500,buttons:false})
                }
              });
          } else if (!isAlreadyExist && !isScheduleExist) {
            say = say + 1;
            message +=
              `\n` + item.product_name + ` Produkten har lagts till i listan`;
            saveListApiDatas.push(saveListApiData);
            isAdd = true;
          } else {
            say = say + 1;
            //message+=`\nProduct already exists !`
            message += `\n` + item.product_name + ` Produkten finns redan!`;
          }

          const result = {
            saveListApiData: saveListApiData,
            message: message,
            title: title,
            icon: icon,
            isAdd: isAdd,
          };
          console.log(`Resolving: ${result}`);
          resolve(result);
        });
    });
  };

  const saveListItems = (saveListApiDatas, message, title, icon, listDesc) => {
    if (saveListApiDatas && saveListApiDatas.length > 0) {
      axiosApiInstance
        .post(`${URL}/add_items_to_list`, saveListApiDatas)
        .then((res) => {
          // console.log(`AFTER SAVE:::`,res)
          if (res.status === 200) {
            let data = res.data.data;
            if (listDesc) {
              // swal("List created successfully!", "Product added!", "success").then(
              //swal("Listan har skapats", "Produkten har lagts till", "success").then(
              setLoading(false);
              swal("Listan har skapats", message, "success").then(() => {
                props.setRefTopNav(!props.refresh);
                setLoading(false);
              });
            } else {
              if (res.data.status === 200) {
                // swal("Product added!", "", "success")
                //swal("Produkten har lagts till", "", "success")
                setLoading(false);
                swal("Produkten har lagts till", message, "success").then(
                  () => {
                    props.setRefTopNav(!props.refresh);
                    setLoading(false);
                  }
                );
              } else {
                // swal("Product already exists !", "", "warning")
                //swal("Produkten finns redan!", "", "warning")
                setLoading(false);
                swal("Produkten finns redan!", message, "warning").then(() => {
                  props.setRefTopNav(!props.refresh);
                  setLoading(false);
                });
              }
            }
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setLoading(false);
      swal("Produkten finns redan!", message, "warning").then(() => {
        props.setRefTopNav(!props.refresh);
        setLoading(false);
      });
    }
  };

  const onAnswerSelectedAll = (listNo, scheduleId, listDesc) => {
    setClose(true);
    setLoading(true);

    let saveListApiDatas = [];

    let message = ``;
    let title = ``;
    let icon = "";
    let say = 0;

    let allProductList = Object.values(getAllProductList());

    if (allProductList) {
      // Promise.all(
      allProductList.map((item, index) => {
        chekProductAdd(listNo, item, scheduleId, listDesc).then((result) => {
          say += 1;
          if (result && result.isAdd) {
            saveListApiDatas.push(result.saveListApiData);
          }
          message += result.message;
          title = result.title;
          icon = result.icon;

          if (say === allProductList.length) {
            //save data
            saveListItems(saveListApiDatas, message, title, icon, listDesc);
          }
        });
      });
      // )
    }
  };

  const onAnswerSelected = (
    listNo,
    prodctNo,
    price,
    qty,
    purchased_Unit,
    base_Price,
    pcPerBox,
    UnitCount,
    BaseUnit,
    Kampanj,
    scheduleId,
    listDesc
  ) => {
    setClose(true);
    setLoading(true);
    let insertIntoTemp = "";
    let isEndDate = "";
    let nearestDate = null;
    let isAlreadyExist = "";
    let isScheduleExist = "";

    let message = ``;
    let title = ``;
    let isKampanjPro = Kampanj === true ? 1 : 0;

    let date = new Date().toISOString();

    let checkBeforeSaveData = {
      PK_Listno: listNo,
      Prodno: prodctNo,
      Recipe_unit: purchased_Unit,
      CustNo: props.customerNo,
      UserId: User_id,
    };
    let checkDateData = {
      scheduleId: scheduleId,
      userId: User_id,
      custNo: props.customerNo,
    };
    let saveListApiData = {
      UserId: User_id,
      PK_Listno: listNo,
      Prodno: prodctNo,
      StartingFrom: nearestDate,
      Recipe_amount: price,
      Recipe_unit: purchased_Unit,
      Active_Product: 1,
      Alternativ_prodno: "",
      Last_Buy_Amount: 0,
      Quantity: qty,
      Base_price: base_Price,
      Last_Buy_Date: date,
      UnitCount: UnitCount,
      BaseUnit: BaseUnit,
      PurchaseUnitCount:
        purchased_Unit === "Styck"
          ? 1
          : purchased_Unit === "Kg"
          ? UnitCount
          : pcPerBox,
      isKampanj: isKampanjPro,
    };
    axiosApiInstance
      .post(`${URL}/checkScheduleAndProductExistInList`, checkBeforeSaveData)
      .then((res) => {
        // console.log('Check Before Save',res.data)

        isAlreadyExist = res.data.isAlreadyExist;
        isScheduleExist = res.data.isScheduleExist;

        if (!isAlreadyExist && isScheduleExist) {
          // debugger
          axiosApiInstance
            .put(`${URL}/checkDatesForQtyTesting`, checkDateData)
            .then((res) => {
              // console.log('Check Dates',res.data)
              if (res.status === 200) {
                insertIntoTemp = res.data.data.insertIntoTemp;
                isEndDate = res.data.data.isEndDate;
                nearestDate = res.data.data.nearestDate;
                let icon = "";

                if (!insertIntoTemp && isEndDate && nearestDate) {
                  // title=`Product Added!`
                  // message=`Product added to list `
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                } else if (insertIntoTemp && !isEndDate && nearestDate) {
                  title = `Är du säker?`;
                  // message=`Product will be added to list on: ${nearestDate}`
                  message = `Produkten kommer att läggas till i listan på: ${nearestDate}`;
                  icon = "warning";
                } else if (!insertIntoTemp && !isEndDate && nearestDate) {
                  // title=`Product Added!`
                  // message=`Product added to list `
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                } else if (
                  !insertIntoTemp &&
                  isEndDate &&
                  nearestDate === null
                ) {
                  // title=`Can not add Product`
                  // message=`Product cannot be added to list End Date is passed for list`
                  title = `Kan inte lägga till produkt`;
                  message = `Produkten kan inte läggas till i listan. Slutdatumet har passerat för listan`;
                  icon = "error";
                } else {
                  // title='Product Added!'
                  // message=`Product added to list`
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                }
                // debugger
                if (icon === "success") {
                  saveListApiData = {
                    ...saveListApiData,
                    StartingFrom: nearestDate,
                  };
                  axiosApiInstance
                    .post(`${URL}/list_lines`, saveListApiData)
                    .then((res) => {
                      if (res.status === 200) {
                        swal(title, message, icon).then(() => {
                          props.setRefTopNav(!props.refresh);
                        });
                      }
                    });
                } else if (icon === "warning") {
                  saveListApiData = {
                    ...saveListApiData,
                    StartingFrom: nearestDate,
                  };
                  swal(title, message, icon, { buttons: ["Nej", "Ja"] }).then(
                    (res) => {
                      if (res) {
                        axiosApiInstance
                          .post(`${URL}/list_lines`, saveListApiData)
                          .then((res) => {
                            if (res.status === 200) {
                              swal("Produkt tillagd!", message, "success").then(
                                () => {
                                  props.setRefTopNav(!props.refresh);
                                }
                              );
                            }
                          });
                      }
                    }
                  );
                } else {
                  swal(title, message, icon).then(() => {
                    props.setRefTopNav(!props.refresh);
                  });
                }
              } else {
                swal("Något gick fel", "", "error", {
                  timer: 1500,
                  buttons: false,
                });
              }
            });
          setLoading(false);
        } else if (!isAlreadyExist && !isScheduleExist) {
          axiosApiInstance
            .post(`${URL}/list_lines`, saveListApiData)
            .then((res) => {
              // console.log(`AFTER SAVE:::`,res)
              if (res.status === 200) {
                let data = res.data.data;
                if (listDesc) {
                  // swal("List created successfully!", "Product added!", "success").then(
                  swal(
                    "Listan har skapats",
                    "Produkten har lagts till",
                    "success"
                  ).then(() => {
                    props.setRefTopNav(!props.refresh);
                  });
                } else {
                  if (res.data.status === 200) {
                    // swal("Product added!", "", "success")
                    swal("Produkten har lagts till", "", "success").then(() => {
                      props.setRefTopNav(!props.refresh);
                    });
                  } else {
                    // swal("Product already exists !", "", "warning")
                    swal("Produkten finns redan!", "", "warning").then(() => {
                      props.setRefTopNav(!props.refresh);
                    });
                  }
                }
              } else {
                console.log("Error");
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setLoading(false);
        } else {
          swal("Produkten finns redan !", "", "warning").then(() => {
            props.setRefTopNav(!props.refresh);
          });
          setLoading(false);
        }
      });
  };

  const handlemodalClose = () => {
    setShow(!show);
  };

  const handleModalShowHide = () => {
    setShowHide(!showHide);
    setAllDeliveryData([]);
    setPickUpDates([]);
    setIsAvailable("");
    setDeliveryDate("");
  };

  const handleChangeList = (e) => {
    const { name, value } = e.target;
    setList_Descr(value);
  };

  const receiveDeliveryDate = (e, date) => {
    // setNewDate(date.toISOString());
    props.receiveDate(date.toISOString());
    handleModalShowHide();
    localStorage.setItem("scheduleDate", date);
    if (isAvailable) {
      props.checkOutHandle(e);
    } else {
      swal("Something went wrong", "with delivery date", "warning");
    }
    // swal(`${typeOfDelivery} Schedule on ${moment(date).format('ll')}`, `checkout to complete your order ..`, "success")
  };

  const checkAvailability = (day, isPickUp) => {
    let stopTimeDate = "";
    allDeliveryData &&
      allDeliveryData.map((data) => {
        if (
          moment(data.DeliveryDate).format("DD-MM-YYYY") ===
          moment(day).format("DD-MM-YYYY")
        ) {
          stopTimeDate = data.StopDateTime;
        }
      });
    let isPickUpOn = isPickUp === true ? true : false;
    let request = {
      custNo: props.customerNo,
      stopDateTime: stopTimeDate,
      orderDeliveryDate: moment(day).format("YYYY-MM-DD"),
      isPicupOnly: isPickUpOn,
    };
    // console.log(request);
    axiosApiInstance
      .post(`${URL}/orders/newCheckOrderDateAndTime`, request)
      .then((res) => {
        if (res.status === 200) {
          // debugger
          const isAvailable = res.data.isDateAvailable;
          setIsAvailable(isAvailable);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDayClick = (day, { selected, pickUpDay, disabled }, e) => {
    // e.target.style.background = 'red';
    e.target.setAttribute("isavai", true);
    if (disabled) {
      setDeliveryDate("");
      setIsAvailable(false);
      // Day is disabled, do nothing
      return;
    }
    if (selected) {
      // Unselect the day if already selected
      setDeliveryDate(day);
      return;
    } else {
      setDeliveryDate(day);
      checkAvailability(day, pickUpDay);
      //this.setState({ deliveryDate: day });
    }
    if (pickUpDay) {
      setTypeOfDelivery("Pick-Up");
    } else {
      setTypeOfDelivery("Delivery");
    }
  };

  const clearInput = () => {
    setList_Descr("");
  };

  return (
    <Col md={12} className="p-0">
      <Container fluid>
        <Row>
          <Col>
            <CardTitle className="card-block">
              {/* Shopping Cart  */}
              Din Varukorg
            </CardTitle>
            <p className="din-varukorg-p-text">
              Här granskar du din varukorg. I nästa steg kan du välja
              leveransdatum.
            </p>
          </Col>
          {/* {!showHide && deliveryDate !== '' ?
            <Col md={3}>
              <CardTitle>Delivery date: {moment(newDate).format('LL')}</CardTitle>
            </Col> : null
          } */}
          {/* <Col md={2} className="py-3"> <Button className="btnAddShop float-right" onClick={() => showSchleduleModal()} disabled={props.itemCount > 0 ? false : true}>Schedule</Button></Col> */}
        </Row>
        <Row>
          <Col md={6} xs={6} id="spara-alla-button" className="cart-overview">
            {props.itemCount > 0 ? (
              <div className="SaveasListWp">
                <SaveListModalAll
                  close={close}
                  CreatehandleSubmit={CreatehandleSubmit}
                  onAnswerSelectedAll={onAnswerSelectedAll}
                  handleChangeList={handleChangeList}
                  List_Descr={List_Descr}
                  User_id={User_id}
                  IsMultipleProduct={true}
                  options={options}
                  getList={getList}
                  clearInput={clearInput}
                />
              </div>
            ) : null}
          </Col>
          {props.itemCount > 0 ? (
            <Col
              md={6}
              xs={6}
              id="tom-varukorg-button"
              className="cart-overview"
            >
              <Button
                className="saveBtn-spara-alla"
                color="primary"
                onClick={() => props.deleteAllCartItem(User_id)}
              >
                <DeleteOutlineIcon className="delete-icon-smaller" /> Töm
                Varukorg
              </Button>
            </Col>
          ) : null}
        </Row>

        <hr />
        <Row className="headerTextRow">
          <Col lg={1} id="should-be-hidden">
            <h3 className="CartHdr text-center">{/* All */}</h3>
          </Col>
          <Col lg={2}>
            {" "}
            <h3 className="CartHdr text-center" id="should-be-hidden">
              {/* All */}
              Produktbild
            </h3>
          </Col>
          <Col lg={2}>
            <h3 className="CartHdr" id="should-be-hidden">
              {/* Artical Name */}
              Artikel Namn
            </h3>
          </Col>
          <Col lg={3} className="Qty text-center" id="should-be-hidden">
            <h3 className="CartHdr qtag">
              {/* Quantity/Weight */}
              Antal/Vikt
            </h3>
          </Col>
          <Col lg={1} className=" text-center" id="should-be-hidden"></Col>
          <Col lg={1} className=" text-center" id="should-be-hidden">
            <h3 className="CartHdr">
              {/* Price */}
              Pris
            </h3>
          </Col>
          {/* <Col lg={1} className=" text-center"><h3 className="CartHdr">Total (tax excl.)</h3></Col> */}
          {/* <Col lg={1} className="text-center"><h3 className="CartHdr">Tax %</h3></Col> */}
          {/* <Col lg={1} className=" text-center"><h3 className="CartHdr">Tax Amt</h3></Col> */}
          <Col lg={1} className=" text-center" id="should-be-hidden">
            <h3 className="CartHdr">
              {/* Row/Sum (tax excl.) */}
              Summering
            </h3>
          </Col>
          <Col lg={1} className=" text-center" id="should-be-hidden"></Col>
          {/*           <Col lg={1} className=" text-center" id="tom-varukorg-id"></Col> */}
        </Row>
        <hr className="mb-0 pl-0" />
        <Row>
          <Col md={12} className="cart-overview center-block">
            <ul className="CartItems">
              {props.cartProduct.length > 0 ? (
                props.cartProduct &&
                props.cartProduct.map((item, index) => (
                  <li className="cartItem pt-3">
                    <Row>
                      <Col lg={1} md={1} className="">
                        <div className="SaveasListWp mt-3">
                          <SaveListModal
                            close={close}
                            CreatehandleSubmit={CreatehandleSubmit}
                            purchased_Unit={item.purchased_Unit}
                            productNo={item.Product_id}
                            Qty={item.Qty}
                            price={item.Price}
                            pcPerBox={item.pcPerBox}
                            onAnswerSelected={onAnswerSelected}
                            handleChangeList={handleChangeList}
                            List_Descr={List_Descr}
                            User_id={User_id}
                            // DataList={DataList}
                            options={options}
                            getList={getList}
                            UnitCount={
                              item.baseUnit === "Styck"
                                ? item.StUnit
                                : item.baseUnit === "Kg"
                                ? item.KgUnit
                                : item.BoxUnit
                            }
                            BaseUnit={item.baseUnit}
                            clearInput={clearInput}
                            Kampanj={item.isKampanj}
                          />
                          {/* <Button className="SaveList btnAddShop  btn btn-secondary pl-3" >Save as List</Button> */}
                          {/* <SaveAsListDialogue CreatehandleSubmit={this.CreatehandleSubmit} productNo={item.Product_id} List_Descr={List_Descr} User_id={User_id}
                                              Recipe_amount={Recipe_amount} Recipe_unit={Recipe_unit} Active_List={Active_List} Last_Buy={Last_Buy} chdt={chdt} chtm={chtm}
                                              /> */}
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        className="product-line-grid-left pt-0"
                      >
                        <div className="product-image cartprodImg media-middle text-center">
                          {/* <img src={item.Small_Img}/> */}
                          <img
                            src={
                              item.Pic_Small === ""
                                ? missingImage
                                : item.Pic_Small
                            }
                          />
                          {item.product_status == "3" ? (
                            <span className="svara-bestall-cart">
                              Beställningsvara
                              <span className="svara-bestall-cart-hovertooltip">
                                Vanligt vis är ledtiden 2-3 dagar för att
                                leverera beställningsvaror. <br />
                                Kontakta kontoret för mer information
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={2} md={3} className="">
                        <div className="product-line-info">
                          <Link
                            to={{
                              pathname: `/ProductDetail`,
                              search: `?id=${item.Product_id}`,
                              state: {
                                productType:
                                  item.isKampanj === false
                                    ? "Product"
                                    : "Kampanj",
                                Products: props.cartProduct,
                                pageToLoad: 1,
                                customerNo: props.customerNo,
                              },
                            }}
                            className="product_name"
                            href=""
                            data-id_customization="0"
                          >
                            {item.Product_name}
                          </Link>
                          <i>{item.BrandName}</i>
                        </div>
                        {/* <div className="product-line-info product-price-and-shipping product-price h5 ">
                                                <span className="price">{item.Base_price} kr</span>
                                            </div> */}
                      </Col>

                      <Col lg={4} md={6} className="Qty">
                        <Row className="justify-content-center">
                          <Col md={3} xs={3} className="antal-vikt-text-col">
                            <p className="my-3 new-cart-summary-small-text">
                              {item.purchased_Unit === "Kg"
                                ? "VIKT"
                                : item.purchased_Unit === "Styck"
                                ? "1ST"
                                : item.purchased_Unit === "Kart" &&
                                  item.baseUnit === "Kg"
                                ? `Kolli (${item.pcPerBox} Kg)`
                                : item.purchased_Unit === "Kartplus"
                                ? `Kolli (${item.pcPerBox} St)`
                                : item.purchased_Unit === "Kart" &&
                                  item.baseUnit !== "KG"
                                ? `Kolli (${item.pcPerBox} St)`
                                : `Kolli (${item.pcPerBox} Kg)`}
                            </p>
                            {/* <p className="my-3">{item.baseUnit === "Kg" ? `Weight(${item.KgUnit})` : item.baseUnit === "Styck" ? `${item.StUnit}ST` : item.baseUnit === "Kart" ? `Box(${item.BoxUnit}) `: null}</p> */}
                          </Col>
                          <Col md={6} xs={6} className="px-0">
                            <ListGroup
                              horizontal
                              className="my-2 justify-content-sm-center"
                            >
                              <ListGroup.Item
                                className="cart-listitemincrement-first"
                                disabled={
                                  item.Base_qty === item.Qty ? true : false
                                }
                                onClick={() =>
                                  props.DecreaseItem(
                                    index,
                                    item.Qty,
                                    item.PK_Cartno,
                                    item.Base_price,
                                    item.Base_qty,
                                    item.purchased_Unit,
                                    item.pcPerBox
                                  )
                                }
                              >
                                -
                              </ListGroup.Item>
                              {item.purchased_Unit === "Kg" ? (
                                <>
                                  <Input
                                    type={"text"}
                                    className="incrementText-new-checkout"
                                    // id="depositedAmount"
                                    id={index}
                                    data-min="1"
                                    onChange={(e) =>
                                      props.onChangeQty(
                                        e,
                                        index,
                                        item.PK_Cartno,
                                        item.Base_price,
                                        item.Base_qty,
                                        item.purchased_Unit,
                                        item.pcPerBox,
                                        item.Max_Quantity
                                      )
                                    }
                                    value={
                                      item.hiddenText
                                        ? item.Qty + " KG"
                                        : item.Qty
                                    }
                                    onClick={(e) =>
                                      props.onclcikEvent(e, index)
                                    }
                                    onBlur={(e) =>
                                      props.onMouseLeaveEvent(e, index)
                                    }
                                  />
                                </>
                              ) : (
                                <Input
                                  type="text"
                                  data-min="1"
                                  autoComplete="off"
                                  className="incrementText-new-checkout"
                                  size="5"
                                  name={item.Qty}
                                  data-max="999"
                                  id={index}
                                  onChange={(e) =>
                                    props.onChangeQty(
                                      e,
                                      index,
                                      item.PK_Cartno,
                                      item.Base_price,
                                      item.Base_qty,
                                      item.purchased_Unit,
                                      item.pcPerBox,
                                      item.Max_Quantity
                                    )
                                  }
                                  value={
                                    item.hiddenText
                                      ? item.Qty + " ST"
                                      : item.Qty
                                  }
                                  onClick={(e) => props.onclcikEvent(e, index)}
                                  onBlur={(e) =>
                                    props.onMouseLeaveEvent(e, index)
                                  }
                                />
                              )}
                              <ListGroup.Item
                                className="cart-listitemincrement-second"
                                onClick={() =>
                                  props.IncrementItem(
                                    index,
                                    item.Qty,
                                    item.PK_Cartno,
                                    item.Base_price,
                                    item.Base_qty,
                                    item.purchased_Unit,
                                    item.pcPerBox,
                                    item.Max_Quantity
                                  )
                                }
                              >
                                +
                              </ListGroup.Item>
                            </ListGroup>
                          </Col>
                          <Col
                            md={3}
                            sm={3}
                            xs={3}
                            className="price text-center pl-0 my-2"
                          >
                            {/* {item.Price_type_name === undefined ? null : <span className="product-price">
                            {item.Price_type_name}</span>} */}
                            {item.Price_type_name === "Kampanj" ||
                            item.Price_type_name === "Tillfälligt Parti" ? (
                              <span className="product-price redClass">
                                {item.Price_type_name}
                              </span>
                            ) : item.Price_type_name === "undefined" ? null : (
                              <span className="product-price plm10">
                                {item.Price_type_name}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        lg={1}
                        md={4}
                        sm={4}
                        xs={4}
                        className="price text-center my-2"
                      >
                        <span fluid className="product-price text-center">
                          {/* { item.purchased_Unit === "Kart" ? (item.Base_price * item.pcPerBox).toFixed(2) : item.Base_price.toFixed(2)} kr */}
                          {item.Price_type_name === "Kampanj" ||
                          item.Price_type_name === "Tillfälligt Parti" ? (
                            <span className="redClass">
                              {item.Base_price.toFixed(2)} kr
                            </span>
                          ) : (
                            <span className="priceDefault">
                              {item.Base_price.toFixed(2)} kr
                            </span>
                          )}
                        </span>
                      </Col>
                      <Col
                        lg={1}
                        md={4}
                        sm={4}
                        xs={4}
                        className="Total (tax excl.) price text-center my-2"
                      >
                        <span
                          fluid
                          className="Total (tax excl.) text-center pl-0"
                        >
                          {item.Price_type_name === "Kampanj" ||
                          item.Price_type_name === "Tillfälligt Parti" ? (
                            <span className="redClass">
                              {item.Price.toFixed(2)} kr
                            </span>
                          ) : (
                            <span className="priceDefault">
                              {item.Price.toFixed(2)} kr
                            </span>
                          )}
                        </span>
                      </Col>
                      {/* <Col lg={1} md={2} sm={2} xs={4} className="price text-center Tax % my-2">
                      <span className="Tax % text-center pl-0">
                        <strong>
                          {(item.Tax).toFixed(2)} %
                        </strong>
                      </span>
                    </Col> */}
                      {/* <Col lg={1} md={2} sm={2} xs={4} className="price text-center Tax Amount my-2">
                      <span fluid noGutters className="Tax Amount text-center pl-0">
                        <strong>
                          {(((item.Price) * (item.Tax)) / 100).toFixed(2)} Kr
                        </strong>
                      </span>
                    </Col> */}
                      {/* <Col lg={1} md={2} sm={6} xs={4} className="price text-center my-2">
                      <span fluid noGutters className="product-price text-cente pl-0">
                        <strong>
                          {parseFloat((((item.Tax / 100) * item.Price) + item.Price).toFixed(2))} Kr
                        </strong>
                      </span>
                    </Col> */}
                      <Col
                        lg={1}
                        md={3}
                        sm={3}
                        xs={3}
                        className="cart-line-product-actions text-right my-2 text-sm-center"
                      >
                        <Button
                          className="remove-from-cart btn btn-light"
                          rel="nofollow"
                          onClick={() =>
                            props.deleteCartItem(item.PK_Cartno, item.User_id)
                          }
                        >
                          <DeleteOutlineIcon className="deleteOutlineIcn" />
                        </Button>
                      </Col>
                    </Row>
                  </li>
                ))
              ) : (
                <div className="tom-varukorg">
                  <div className="tom-varukorg-icon">
                    <ShoppingCartIcon fontSize="large" />
                  </div>
                  <div className="tom-varukorg-header">
                    <h3>Din varukorg är tom</h3>
                  </div>
                  <div className="tom-varukorg-text">
                    <p>Du har inga varor i din varukorg.</p>
                  </div>
                </div>
              )}
            </ul>
          </Col>
        </Row>

        <Row>
          {/*           <Col md={6} className="cart-overview">
            <ul className="CartItems-new">
              <li className="cartItem pt-3">
              <Row>

                </Row>
                
              </li>
            </ul>
          </Col> */}
          <Col md={12} id="reference-nummer" className="cart-overview">
  <div className="OrdRef-class">
    <form noValidate>
      <TextField
        className="form-cart-said"
        name="OrdRef"
        variant="outlined"
        rowsMax={5}
        onChange={(e) => props.handleRefChange(e)}
        value={props.OrdRef}
        fullWidth
        id="Reference"
        label="Ange Referensnummer"
        inputProps={{ maxLength: 300,
          wrap: 'soft',
          style: { whiteSpace: 'pre-wrap' } }}
          multiline
  rows={3}
        error={props.OrdRef && props.OrdRef.length > 300}
        helperText={props.OrdRef && props.OrdRef.length > 300
          ? "Max 300 tecken tillåtna"
          : ""}
      />
    </form>
  </div>
</Col>

          
        </Row>
      </Container>
      <Modal show={showHide} onHide={() => handleModalShowHide()} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Välj Leveransdatum</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pl-4 pr-4 pb-4">
          <p className="selectTimeDeliveryText">
            Select Date of Delivery. can not choose Delivery Date? please{" "}
            <a
              onClick={() => {}}
              style={{ color: "#659f2f", fontWeight: "600" }}
            >
              Contact Your store{" "}
            </a>{" "}
            for more information. Your goods are Delivered to.
          </p>
          {/* <h4 className="konsumentText">KONSUMENTVAGEN 9,12530 ALVSJO</h4> */}
          <hr />
          <Row>
            <Col className="notation">
              {" "}
              <input type="checkbox" checked name="pickUp" /> &nbsp; Pick-Up
              Days
            </Col>
            <Col className="notation">
              {" "}
              <input type="checkbox" checked name="delivery" /> &nbsp; Delivery
              Days
            </Col>
            {/* <Col className="notation"> <input type="checkbox" checked /> &nbsp; Pick-Up</Col> */}
          </Row>
          <br />

          {/* <p className="placeOrderText">You can palce orders</p> */}
          <Row>
            <FormGroup style={{ border: "1px solid #d8d8d8" }}>
              {/* <Label> Schedule Date</Label> */}
              <DayPicker
                className="day-picker-cart-custom"
                onDayClick={handleDayClick}
                selectedDays={deliveryDate}
                // initialMonth={new Date(2021, 2)}
                locale="en-GB"
                firstDayOfWeek={1}
                modifiers={modifiers}
                disabledDays={disableDays}
              />
            </FormGroup>
          </Row>
          {deliveryDate !== "" ? (
            <Row>
              <Col className="text-center">
                {typeOfDelivery} on {moment(deliveryDate).format("DD-MM-YYYY")}{" "}
                is{" "}
                {isAvailable ? (
                  <span className="text-success">Available</span>
                ) : (
                  <span className="text-danger">Not-Available</span>
                )}
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleModalShowHide()}>
            Stänga
          </Button>
          <Button
            variant="primary"
            className="saveBtn "
            disabled={isAvailable ? false : true}
            onClick={(e) => receiveDeliveryDate(e, deliveryDate)}
          >
            Bekräfta leverans
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

const mapStoreToProps = (state) => {
  // console.log(state,"state");
  return {
    cartCount: state.customer.count,
    customerNo: state.customer.customerNo,
    refresh: state.customer.refresh,
  };
};

const mapDispatchToProps = (dispatch) => ({
  //setCartCount: (count) => dispatch(setCartCount(count)),
  setRefTopNav: (refresh) => dispatch(setRefTopNav(refresh)),
});

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(ProductList)
);
