import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Card} from 'react-bootstrap';
import axios from 'axios';
import {URL} from '../../ServerCall/Config';
import swal from 'sweetalert';
//${URL}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




const AdminLogin = ({productId,ProductOverview}) => {

  const classes = useStyles();
  const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);
  const [errorPassword,formErrorsPassword] = React.useState('');
  const [errorEmail,formErrorsEmail] = React.useState('');
  const [state, setstate] = React.useState({
    password : '',
    email : ''
})

  const formvalid = () =>{
    
    validEmailRegex.test(state.email)?formErrorsEmail(''):formErrorsEmail("Valid email address Req.");
    state.password !== ''?formErrorsPassword(''):formErrorsPassword("Please enter password");
    if(errorEmail === '' && errorPassword === ''){
      return true;
    }
    else{
      return false;
    }
  }

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value});
    switch(e.target.name){
      case 'email':
        validEmailRegex.test(e.target.value)?formErrorsEmail(""):formErrorsEmail("Valid email address Required!");
      break;
      case 'password':
        e.target.value !==""?formErrorsPassword(""):formErrorsPassword("password Required!");
     break;
  }
}

  const handleSubmit = e =>{
    e.preventDefault();
    
    const isValid = formvalid();
    let credential = {
      "email":state.email,
      "password":state.password
     }
    if(isValid){
        
        axios.post(`${URL}/users/login`,credential)
        .then((res)=>{
            if(res.status === 200 ){
                if(res.data.status === 400){
                  swal("Felaktig e-postadress eller lösenord!", "", "info");
                }
                else{
                  localStorage.setItem('userData', JSON.stringify(res.data.data));
                      if(res.data.data.FrimNo !== 0){
                        window.location.href = "/";
                      }
                      else{
                        window.location.href = "/Dashboard";
                      }
                    }
            }
        }).catch(err=>{
            console.log("Test",err);
           // swal(, "Something Went Wrong", 'error');
        })
    }
}

  return (
    <Container className="LoginContainer formWraper mt-4" component="main" maxWidth="xs">
     <Card className="pl-4 pr-4 pb-5">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            onChange={(e) => { handleChange(e) }}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {
              errorEmail !== "" ?
              <label style={{ fontSize: 12, color: "red" }}>
              { errorEmail}</label>: null
          }
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={(e) => { handleChange(e) }}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
           {
              errorPassword !== "" ?
              <label style={{ fontSize: 12, color: "red" }}>
              { errorPassword}</label>: null
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="mt-0 mb-3"
            onClick={(e) => { handleSubmit(e) }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="Signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
     
      </Card>
    </Container>
  );
}
export default AdminLogin;