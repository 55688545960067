import React, { useRef, useState } from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { ListGroup, ListGroupItem } from "reactstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Label, Input } from "reactstrap";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import "react-datepicker/dist/react-datepicker.css";
import enGb from "date-fns/locale/en-GB";
import sv from "date-fns/locale/sv";
import DatePicker, { registerLocale } from "react-datepicker";
import { Switch, Checkbox } from "@material-ui/core";
import swal from "sweetalert";
import { connect } from "react-redux";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import missingImage from "../../assets/images/picture_missing_text.png";
import moment from "moment";
import Auth from "../../ServerCall/Auth";
import axiosApiInstance from "../../ServerCall/Axios.Instance";
import { URL } from "../../ServerCall/Config";
import { setRefTopNav } from "../../redux/action/customerAction";
import { setCartCount, setTotalPrice } from "../../redux/action/customerAction";
registerLocale("en-gb", enGb);
registerLocale("sv", sv);

const SaveMultiList = (props) => {
  let history = useHistory();
  let location = useLocation();
  const [show, setShow] = useState(false);
  const [days, setDays] = useState("");
  const [pickDays, setPickDays] = useState("");
  const [deliveryDay, setDeliveryDay] = useState([]);
  const [value, setValue] = React.useState();
  const [item, setItem] = useState([]);
  const [list, setList] = useState([]);
  const [scheduleOption, setScheduleOption] = React.useState("W");
  const [scheduleList, setScheduleList] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMacDate] = useState("");
  const [errEndDate, setErrEndDate] = useState("");
  const [custNo, setCustomer] = useState("");
  const [listID, setListID] = useState("");
  const [schDays, setSchDays] = useState("");
  const [schStartDay, setSchStartDay] = useState("");
  const [schEndDate, setSchEndDay] = useState("");
  const [loading, setLoading] = useState(false);
  const id = Auth.getUserId();

  const [allDeliveryData, setAllDeliveryData] = useState([]);
  const [disableDays, setDisableDays] = useState([]);
  const [pickUpDates, setPickUpDates] = useState([]);

  const [count, setCount] = useState([]);
  const [tax, setTax] = useState([]);
  const [latestData, setLatestData] = useState([]);

  function saveToCart(product) {
    let variable = Number.NaN;
    var kgUnit = 0;
    var stUnit = 0;
    var boxUnit = 0;
    var pcPerBox = 0;
    if (
      product.Quantity === variable ||
      parseFloat(product.Quantity) === 0 ||
      product.Quantity === ""
    ) {
      // swal("Please select Minimum quantity", '', 'info');
      swal("Vänligen välj minsta kvantitet", "", "info");
    } else {
      if (product.Standard_orderunit === "Kg") {
        pcPerBox = product.Conv_facor_CART;
        if (product.Recipe_unit === "Kg") {
          kgUnit =
            Math.round(
              (product.Quantity * product.Conv_facor_KG + Number.EPSILON) * 100
            ) / 100;
          if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
            product.stdOrder_Unit = product.Max_Quantity;
            product.Quantity = product.Max_Quantity;
          } else {
            product.stdOrder_Unit = kgUnit;
          }
        }
        if (product.Recipe_unit === "Styck") {
          kgUnit =
            Math.round(
              (product.Quantity * product.Conv_facor_ST + Number.EPSILON) * 100
            ) / 100;
          if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
            product.stdOrder_Unit = product.Max_Quantity;
            product.Quantity = product.Max_Quantity;
          } else {
            product.stdOrder_Unit = kgUnit;
          }
        }
        if (product.Recipe_unit === "Kart") {
          kgUnit =
            Math.round(
              (product.Quantity * product.Conv_facor_CART + Number.EPSILON) *
                100
            ) / 100;
          if (product.Max_Quantity !== null && product.Max_Quantity > 0) {
            if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
              product.stdOrder_Unit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              product.stdOrder_Unit = kgUnit;
            }
          }
        }
      }

      if (product.Standard_orderunit === "Styck") {
        pcPerBox = product.Conv_facor_CART;
        if (product.Recipe_unit === "Kg") {
          stUnit =
            Math.round(
              (product.Quantity * product.Conv_facor_KG + Number.EPSILON) * 100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.Quantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
        if (product.Recipe_unit === "Styck") {
          stUnit =
            Math.round(
              (product.Quantity * product.Conv_facor_ST + Number.EPSILON) * 100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.Quantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
        if (product.Recipe_unit === "Kart") {
          stUnit =
            Math.round(
              (product.Quantity * product.Conv_facor_CART + Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.Quantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
      }

      if (
        product.Standard_orderunit === "Kart" ||
        product.Standard_orderunit === "Kartplus"
      ) {
        pcPerBox = product.Conv_facor_CART;
        if (product.Recipe_unit === "Kg") {
          boxUnit =
            Math.round(
              (product.Quantity * product.Conv_facor_KG + Number.EPSILON) * 100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.Quantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product.Recipe_unit === "Styck") {
          boxUnit =
            Math.round(
              (product.Quantity * product.Conv_facor_ST + Number.EPSILON) * 100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.Quantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (
          product.Recipe_unit === "Kart" ||
          product.Recipe_unit === "Kartplus"
        ) {
          boxUnit =
            Math.round((product.Quantity * 1 + Number.EPSILON) * 100) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.Quantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
      }

      let Cartdata = {
        Product_name: product.Descr,
        Product_id: product.ProdNo,
        BoxUnit: boxUnit,
        StUnit: stUnit,
        KgUnit: product.stdOrder_Unit,
        pcPerBox: pcPerBox,
        //  orderWeight_Unit: product.stdOrder_Unit,
        purchased_Unit: product.Recipe_unit,
        baseUnit: product.Standard_orderunit,
        Price: product.newPrice * product.Quantity,
        Qty: parseFloat(product.Quantity),
        Base_price: product.basePrice,
        Base_qty: product.Min_Quantity,
        User_id: Auth.getUserId(),
        Tax: product.Tax_rate === null ? 0 : product.Tax_rate,
        CustNo: props.customerNo,
        isKampanj: 0,
      };
      // console.log("Cartdata", Cartdata);
      axiosApiInstance
        .post(`${URL}/addToCart`, Cartdata)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            data &&
              data.map((pro) => {
                if (
                  pro.Product_id === parseInt(product.ProdNo) &&
                  product.Recipe_unit === pro.purchased_Unit
                ) {
                  setLatestData(pro);
                }
              });
            var cartCount = props.cartCount;
            let Count = data;
            let value = 0;
            let Tax = 0;
            for (let i = 0; i < Count.length; i++) {
              value += Count[i].Price;
              Tax += Count[i].Tax;
              // setCount(cartCount + Count.length);
              setTax(Tax);
            }
            let totalOrder = 0;
            for (let i = 0; i < data.length; i++) {
              if (data[i] !== undefined) {
                totalOrder +=
                  ((data[i].Price * data[i].Tax) / 100 + data[i].Price) *
                  data[i].Qty;
              }
            }
            // console.log(totalOrder)
            setCount(data.length);
            // props.setCartCount(cartCount + Count.length);
            props.setCartCount(data.length);
            props.setTotalPrice(totalOrder);
            //handleShow();

            swal(
              "Läggs till i korgen",
              "Dina varor har lagts till i varukorgen",
              "success"
            ).then(() => {
              props.setRefTopNav(!props.refresh);
            });
          } else {
            swal("Något gick fel", "", "info").then(() => {
              props.setRefTopNav(!props.refresh);
            });
          }
        })
        .catch((err) => {
          // swal('something went wrong',"","info")
          swal("Något gick fel", "", "info");
          console.log(err);
        });
    }
  }

  const chekProductAdd = (product) => {
    return new Promise(function (resolve, reject) {
      // setTimeout(() => {

      let message = ``;
      let title = ``;
      let icon = "";

      let variable = Number.NaN;
      var kgUnit = 0;
      var stUnit = 0;
      var boxUnit = 0;
      var pcPerBox = 0;
      if (
        product.Quantity === variable ||
        parseFloat(product.Quantity) === 0 ||
        product.Quantity === ""
      ) {
        // swal("Please select Minimum quantity", '', 'info');
        swal("Vänligen välj minsta kvantitet", "", "info");
      } else {
        if (product.Standard_orderunit === "Kg") {
          pcPerBox = product.Conv_facor_CART;
          if (product.Recipe_unit === "Kg") {
            kgUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_KG + Number.EPSILON) *
                  100
              ) / 100;
            if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
              product.stdOrder_Unit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              product.stdOrder_Unit = kgUnit;
            }
          }
          if (product.Recipe_unit === "Styck") {
            kgUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_ST + Number.EPSILON) *
                  100
              ) / 100;
            if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
              product.stdOrder_Unit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              product.stdOrder_Unit = kgUnit;
            }
          }
          if (product.Recipe_unit === "Kart") {
            kgUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_CART + Number.EPSILON) *
                  100
              ) / 100;
            if (product.Max_Quantity !== null && product.Max_Quantity > 0) {
              if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
                product.stdOrder_Unit = product.Max_Quantity;
                product.Quantity = product.Max_Quantity;
              } else {
                product.stdOrder_Unit = kgUnit;
              }
            }
          }
        }

        if (product.Standard_orderunit === "Styck") {
          pcPerBox = product.Conv_facor_CART;
          if (product.Recipe_unit === "Kg") {
            stUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_KG + Number.EPSILON) *
                  100
              ) / 100;
            if (
              stUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              stUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              stUnit = stUnit;
            }
          }
          if (product.Recipe_unit === "Styck") {
            stUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_ST + Number.EPSILON) *
                  100
              ) / 100;
            if (
              stUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              stUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              stUnit = stUnit;
            }
          }
          if (product.Recipe_unit === "Kart") {
            stUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_CART + Number.EPSILON) *
                  100
              ) / 100;
            if (
              stUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              stUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              stUnit = stUnit;
            }
          }
        }

        if (product.Standard_orderunit === "Kartplus") {
          pcPerBox = product.Conv_facor_CART;
          if (product.Recipe_unit === "Kg") {
            boxUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_KG + Number.EPSILON) *
                  100
              ) / 100;
            if (
              boxUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              boxUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              boxUnit = boxUnit;
            }
          }
          if (product.Recipe_unit === "Styck") {
            boxUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_ST + Number.EPSILON) *
                  100
              ) / 100;
            if (
              boxUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              boxUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              boxUnit = boxUnit;
            }
          }
          if (
            product.Recipe_unit === "Kart" ||
            product.Recipe_unit === "Kartplus"
          ) {
            boxUnit =
              Math.round((product.Quantity * 1 + Number.EPSILON) * 100) / 100;
            if (
              boxUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              boxUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              boxUnit = boxUnit;
            }
          }
        }
        if (product.Standard_orderunit === "Kart") {
          pcPerBox = product.Conv_facor_CART;
          if (product.Recipe_unit === "Kg") {
            boxUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_KG + Number.EPSILON) *
                  100
              ) / 100;
            if (
              boxUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              boxUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              boxUnit = boxUnit;
            }
          }
          if (product.Recipe_unit === "Styck") {
            boxUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_ST + Number.EPSILON) *
                  100
              ) / 100;
            if (
              boxUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              boxUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              boxUnit = boxUnit;
            }
          }
          if (product.Recipe_unit === "Kart") {
            boxUnit =
              Math.round(
                (product.Quantity * product.Conv_facor_CART + Number.EPSILON) *
                  100
              ) / 100;
            if (
              boxUnit > product.Max_Quantity &&
              product.Max_Quantity !== 0 &&
              product.Max_Quantity !== null
            ) {
              boxUnit = product.Max_Quantity;
              product.Quantity = product.Max_Quantity;
            } else {
              boxUnit = boxUnit;
            }
          }
        }

        let Cartdata = {
          Product_name: product.Descr,
          Product_id: product.ProdNo,
          BoxUnit: boxUnit,
          StUnit: stUnit,
          KgUnit: product.stdOrder_Unit,
          pcPerBox: pcPerBox,
          //  orderWeight_Unit: product.stdOrder_Unit,
          purchased_Unit: product.Recipe_unit,
          baseUnit: product.Standard_orderunit,
          Price: product.newPrice * product.Quantity,
          Qty: parseFloat(product.Quantity),
          Base_price: product.basePrice,
          Base_qty: product.Min_Quantity,
          User_id: Auth.getUserId(),
          Tax: product.Tax_rate === null ? 0 : product.Tax_rate,
          CustNo: props.customerNo,
          isKampanj: 0,
        };

        const result = {
          cartData: Cartdata,
          message: message,
          title: title,
          icon: icon,
        };
        console.log(`Resolving: ${result}`);
        resolve(result);
      }
    });
  };

  const saveToCartMulti = (items) => {
    setLoading(true);

    let cartDatas = [];

    let message = ``;
    let title = ``;
    let icon = "";
    let say = 0;

    if (items) {
      // Filter out items with product_status = 5
      const filteredItems = items.filter(
        (item) => item.product_status !== 3 && item.product_status !== 5
      );
      // Process remaining items
      filteredItems.map((item, index) => {
        chekProductAdd(item).then((result) => {
          say += 1;
          if (result) {
            cartDatas.push(result.cartData);
          }
          message += result.message;
          title = result.title;
          icon = result.icon;

          if (say === filteredItems.length) {
            //save data
            saveListItems(cartDatas, message, title, icon);
          }
        });
      });
    }
  };

  const saveListItems = (cartdatas, message, title, icon) => {
    axiosApiInstance
      .post(`${URL}/addItemsToCart`, cartdatas)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.data;
          // data && data.map(pro =>{
          //     if(pro.Product_id === parseInt(product.ProdNo) && product.Recipe_unit === pro.purchased_Unit){
          //         setLatestData(pro);
          //     }
          // })
          var cartCount = props.cartCount;
          let Count = data;
          let value = 0;
          let Tax = 0;
          for (let i = 0; i < Count.length; i++) {
            value += Count[i].Price;
            Tax += Count[i].Tax;
            // setCount(cartCount + Count.length);
            setTax(Tax);
          }
          let totalOrder = 0;
          for (let i = 0; i < data.length; i++) {
            if (data[i] !== undefined) {
              totalOrder +=
                ((data[i].Price * data[i].Tax) / 100 + data[i].Price) *
                data[i].Qty;
            }
          }
          // console.log(totalOrder)
          setCount(data.length);
          // props.setCartCount(cartCount + Count.length);
          props.setCartCount(data.length);
          props.setTotalPrice(totalOrder);
          //handleShow();
          setLoading(false);
          swal(
            "Läggs till i korgen",
            "Dina varor har lagts till i varukorgen",
            "success"
          ).then(() => {
            props.setRefTopNav(!props.refresh);
          });
        } else {
          setLoading(false);
          swal("Något gick fel", "", "info").then(() => {
            props.setRefTopNav(!props.refresh);
          });
        }
      })
      .catch((err) => {
        // swal('something went wrong',"","info")
        setLoading(false);
        swal("Något gick fel", "", "info");
        console.log(err);
      });
  };

  const IncrementItem = (
    itemIndex,
    i,
    qty,
    basePrice,
    listLineNo,
    maxQty,
    scheduleId,
    Temp
  ) => {
    let newArr = [...item];
    let TempData = Temp;
    // newArr[itemIndex].Items[i].hiddenText = false;
    let customerNo = props.customerNo;
    let quantity = parseFloat(qty) + 1;
    var price = basePrice;
    if (maxQty === 0) {
    } else if (quantity > maxQty) {
      quantity = maxQty;
      newArr[i].hiddenText = true;
    } else if (quantity > 0) {
      var price = basePrice * quantity;
    } else {
      price = basePrice;
      quantity = 1;
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kg") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Styck") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kartplus") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (newArr[itemIndex].Items[i].newQuantity *
              newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (
        newArr[itemIndex].Items[i].Recipe_unit === "Kart" ||
        newArr[itemIndex].Items[i].Recipe_unit === "Kartplus"
      ) {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kart") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (newArr[itemIndex].Items[i].newQuantity *
              newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    // console.log("Send Data on confirm", data)
    let data1 = {
      scheduleId: scheduleId,
      userId: id,
      custNo: customerNo,
    };
    axiosApiInstance
      .put(`${URL}/checkDatesForQtyTesting`, data1)
      .then((res) => {
        if (res.data.message === "Data Found") {
          // console.log(res);
          let showMessage;
          let nextDate = res.data.data.nearestDate;
          let insertIntoTemp = res.data.data.insertIntoTemp;
          let isEndDate = res.data.data.isEndDate;
          let data = {
            listLineNo: listLineNo,
            listNo: listID,
            price: price,
            nextQty: quantity,
            userId: id,
            custNo: customerNo,
            unitCount: newArr[itemIndex].Items[i].UnitCount,
            scheduleId: scheduleId,
            nextDate: nextDate,
            insertIntoTemp: insertIntoTemp,
            isEndDate: isEndDate,
          };
          // debugger
          // console.log(res);

          if (insertIntoTemp && !isEndDate && nextDate) {
            // debugger
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate) {
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // debugger
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && isEndDate && nextDate === null) {
            // debugger
            showMessage = `Kan inte ändra kvantitet, ändra slutdatum`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate === null) {
            // debugger
            showMessage = `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate === undefined) {
            // debugger
            showMessage = `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`;
            // console.log(showMessage, typeof (showMessage))
          } else if (insertIntoTemp && isEndDate && nextDate) {
            // debugger
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          } else {
            showMessage = `Förändringar kommer att genomföras idag ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          }
          swal({
            title: "Är du säker?",
            text: `${showMessage}`,
            icon: "warning",
            buttons: ["Nej", "Ja"],
            dangerMode: true,
          }).then((willChange) => {
            if (willChange) {
              // debugger
              if (showMessage === `Kan inte ändra kvantitet, ändra slutdatum`) {
                return null;
              } else if (
                showMessage ===
                `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`
              ) {
                getList(listID, customerNo);
              } else {
                axiosApiInstance
                  .put(`${URL}/updateListLineQtyDetails`, data)
                  .then((res) => {
                    if (res.status === 200) {
                      let customerNo = props.customerNo;
                      getList(listID, customerNo);
                      swal("Kvantitet uppdaterad genomförd!", {
                        icon: "success",
                        timer: 2000,
                      });
                    } else {
                      swal("Något gick fel", {
                        icon: "info",
                        timer: 2000,
                      });
                    }
                  })
                  .catch((err) => {
                    swal("Något gick fel", {
                      icon: "info",
                      timer: 2000,
                    });
                    console.log(err);
                  });
              }
            }
          });
        } else {
          swal("Något gick fel", {
            icon: "info",
            timer: 2000,
          });
        }
      })
      .catch((res) => {
        swal("Error", res.message, "error", { buttons: false, timer: 1500 });
      });
  };

  const DecreaseItem = (
    itemIndex,
    i,
    qty,
    basePrice,
    listLineNo,
    scheduleId,
    Temp
  ) => {
    let TempData = Temp;
    let newArr = [...item];
    // newArr[itemIndex].Items[i].hiddenText = false;
    let customerNo = props.customerNo;
    let quantity = parseFloat(qty) - 1;
    var price = basePrice;
    if (quantity > 0) {
      price = basePrice * quantity;
    } else {
      quantity = 1;
      price = basePrice;
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kg") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Styck") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kartplus") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (newArr[itemIndex].Items[i].newQuantity *
              newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (
        newArr[itemIndex].Items[i].Recipe_unit === "Kart" ||
        newArr[itemIndex].Items[i].Recipe_unit === "Kartplus"
      ) {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kart") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (newArr[itemIndex].Items[i].newQuantity *
              newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    // console.log("Send Data on confirm", data)
    let data1 = {
      scheduleId: scheduleId,
      userId: id,
      custNo: customerNo,
    };
    // debugger
    axiosApiInstance
      .put(`${URL}/checkDatesForQtyTesting`, data1)
      .then((res) => {
        if (res.data.message === "Data Found") {
          let nextDate = res.data.data.nearestDate;
          let insertIntoTemp = res.data.data.insertIntoTemp;
          let isEndDate = res.data.data.isEndDate;
          // let isStopTimeAvailable=res.data.data.isStopTimeAvailable
          let showMessage;
          let data = {
            listLineNo: listLineNo,
            listNo: listID,
            price: price,
            nextQty: quantity,
            userId: id,
            custNo: customerNo,
            unitCount: newArr[itemIndex].Items[i].UnitCount,
            scheduleId: scheduleId,
            nextDate: nextDate,
            insertIntoTemp: insertIntoTemp,
            isEndDate: isEndDate,
          };
          // console.log(res);

          if (insertIntoTemp && !isEndDate && nextDate) {
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate) {
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // debugger
            // console.log(showMessage, typeof (showMessage))
          } else if (insertIntoTemp && isEndDate && nextDate) {
            // debugger
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate === null) {
            // debugger
            showMessage = `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate === undefined) {
            // debugger
            showMessage = `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && isEndDate && nextDate === null) {
            showMessage = `Kan inte ändra kvantitet, ändra slutdatum`;
            // console.log(showMessage, typeof (showMessage))
          } else {
            showMessage = `Förändringar kommer att genomföras idag ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          }
          swal({
            title: "Är du säker?",
            text: showMessage,
            icon: "warning",
            buttons: ["Nej", "Ja"],
            dangerMode: true,
          }).then((willChange) => {
            if (willChange) {
              if (showMessage === `Kan inte ändra kvantitet, ändra slutdatum`) {
                getList(listID, customerNo);
              } else if (
                showMessage ===
                `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`
              ) {
                getList(listID, customerNo);
              } else {
                axiosApiInstance
                  .put(`${URL}/updateListLineQtyDetails`, data)
                  .then((res) => {
                    if (res.status === 200) {
                      let customerNo = props.customerNo;
                      getList(listID, customerNo);
                      swal("Kvantitet uppdaterad genomförd!", {
                        icon: "success",
                        timer: 2000,
                      });
                    } else {
                      console.log("Error");
                      swal("Något gick fel!", {
                        icon: "info",
                        timer: 2000,
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    swal("Något gick fel!", {
                      icon: "info",
                      timer: 2000,
                      text: err,
                    });
                  });
              }
            }
          });
        }
      })
      .catch((res) => {
        swal("Error", res.message, "error", { buttons: false, timer: 1500 });
      });
  };

  //old increment
  const IncrementItemWithoutSchedule = (
    itemIndex,
    i,
    qty,
    basePrice,
    listLineNo,
    maxQty
  ) => {
    let newArr = [...item];
    // newArr[itemIndex].Items[i].hiddenText = false;
    let customerNo = props.customerNo;
    let quantity = parseFloat(qty) + 1;
    var price = basePrice;
    if (maxQty === 0) {
    } else if (quantity > maxQty) {
      quantity = maxQty;
      newArr[i].hiddenText = true;
    } else if (quantity > 0) {
      var price = basePrice * quantity;
    } else {
      price = basePrice;
      quantity = 1;
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kg") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Styck") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kartplus") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (newArr[itemIndex].Items[i].newQuantity *
              newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (
        newArr[itemIndex].Items[i].Recipe_unit === "Kart" ||
        newArr[itemIndex].Items[i].Recipe_unit === "Kartplus"
      ) {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kart") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (newArr[itemIndex].Items[i].newQuantity *
              newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    let data = {
      listLineNo: listLineNo,
      price: price,
      qty: quantity,
      user_id: id,
      cust_no: customerNo,
      UnitCount: newArr[itemIndex].Items[i].UnitCount,
    };
    //
    swal({
      title: "Är du säker?",
      text: `Vill du öka kvantiteten?`,
      icon: "warning",
      buttons: ["Nej", "Ja"],
      dangerMode: true,
    }).then((willChange) => {
      if (willChange) {
        axiosApiInstance
          .put(`${URL}/updateListLinePriceAndQty`, data)
          .then((res) => {
            if (res.status === 200) {
              let customerNo = props.customerNo;
              getList(listID, customerNo);
              swal("Kvantitet uppdaterad genomförd!", {
                icon: "success",
                timer: 2000,
              });
            } else {
              console.log("Error");
              swal("Något gick fel!", {
                icon: "info",
                timer: 2000,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  //old decrement
  const DecreaseItemWithoutSchedule = (
    itemIndex,
    i,
    qty,
    basePrice,
    listLineNo
  ) => {
    let newArr = [...item];
    // newArr[itemIndex].Items[i].hiddenText = false;
    let customerNo = props.customerNo;
    let quantity = parseFloat(qty) - 1;
    var price = basePrice;
    if (quantity > 0) {
      price = basePrice * quantity;
    } else {
      quantity = 1;
      price = basePrice;
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kg") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Styck") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kartplus") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (newArr[itemIndex].Items[i].newQuantity *
              newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (
        newArr[itemIndex].Items[i].Recipe_unit === "Kart" ||
        newArr[itemIndex].Items[i].Recipe_unit === "Kartplus"
      ) {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kart") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (newArr[itemIndex].Items[i].newQuantity *
              newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    let data = {
      listLineNo: listLineNo,
      price: price,
      qty: quantity,
      user_id: id,
      cust_no: customerNo,
      UnitCount: newArr[itemIndex].Items[i].UnitCount,
    };
    swal({
      title: "Är du säker?",
      text: `Vill du minska kvantiteten?`,
      icon: "warning",
      buttons: ["Nej", "Ja"],
      dangerMode: true,
    }).then((willChange) => {
      if (willChange) {
        axiosApiInstance
          .put(`${URL}/updateListLinePriceAndQty`, data)
          .then((res) => {
            if (res.status === 200) {
              let customerNo = props.customerNo;
              getList(listID, customerNo);
              swal("Kvantitet uppdaterad genomförd!", {
                icon: "success",
                timer: 2000,
              });
            } else {
              console.log("Error");
              swal("Något gick fel!", {
                icon: "info",
                timer: 2000,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const onChangeQty = (
    e,
    itemIndex,
    i,
    basePrice,
    listLineNo,
    Recipe_unit,
    maxQty,
    scheduleId
  ) => {
    let newArr = [...item];
    var price = basePrice;
    let customerNo = props.customerNo;
    if (Recipe_unit === "Kg") {
      var quantity = e.target.value.replace(/KG/g, "").trim();
    } else {
      var quantity = e.target.value.replace(/ST/g, "").trim();
      quantity = parseFloat(Math.floor(quantity));
      if (isNaN(quantity) === true || quantity === 0) {
        quantity = 0;
      }
    }
    if (maxQty === 0) {
    } else if (quantity > maxQty) {
      quantity = maxQty;
      newArr[itemIndex].hiddenText = true;
    } else if (quantity > 0) {
      price = basePrice * quantity;
    } else {
      price = basePrice;
    }

    const re = /^[0-9.,]+$/;
    if (quantity === "") {
      quantity = 0;
    }

    if (newArr[itemIndex].Items[i].BaseUnit === "Kg") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Styck") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kartplus") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (
        newArr[itemIndex].Items[i].Recipe_unit === "Kart" ||
        newArr[itemIndex].Items[i].Recipe_unit === "Kartplus"
      ) {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kart") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (re.test(quantity)) {
      let data = {
        list_no: listID,
        listLineNo: listLineNo,
        price: price,
        qty: quantity,
        user_id: id,
        cust_no: customerNo,
        UnitCount: newArr[itemIndex].Items[i].UnitCount,
      };
      axiosApiInstance
        .put(`${URL}/updateListLinePriceAndQty`, data)
        .then(async (res) => {
          if (res.status === 200) {
            let data = res.data.ListItem;
            let newList = data.map((ele) => {
              let Items =
                ele.Items &&
                ele.Items.map((c) => {
                  if (c.Recipe_unit === "Kartplus") {
                    return {
                      ...c,
                      newPrice: c.Price_cart * c.Quantity * 1,
                      basePrice: c.Price_cart * c.Conv_facor_CART,
                      hiddenText: true,
                    };
                  }
                  if (c.Recipe_unit === "Kart") {
                    return {
                      ...c,
                      newPrice: c.Price_cart * c.Quantity * c.Conv_facor_CART,
                      basePrice: c.Price_cart * c.Conv_facor_CART,
                      hiddenText: true,
                    };
                  } else if (c.Recipe_unit === "Styck") {
                    return {
                      ...c,
                      newPrice: c.Price_broken * c.Quantity * c.Conv_facor_ST,
                      basePrice: c.Price_broken * c.Conv_facor_ST,
                      hiddenText: true,
                    };
                  } else if (c.Recipe_unit === "Kg") {
                    return {
                      ...c,
                      newPrice: c.Comp_Price * c.Quantity * c.Conv_facor_KG,
                      basePrice: c.Comp_Price * c.Conv_facor_KG,
                      hiddenText: true,
                    };
                  }
                });
              ele = { ...ele, Items };
              return ele;
            });
            newList[itemIndex].Items[i].hiddenText = false;
            for (var j = 0; j < newList.length; j++) {
              let sum = 0;
              if (newList[j].Items !== null) {
                for (var k = 0; k < newList[j].Items.length; k++) {
                  sum = sum + newList[j].Items[k].newPrice;
                }
                newList[j].totalAmount = sum;
              }
            }
            // console.log(newList);
            await setItem(newList);
            // getList(listID);
          } else {
            console.log("else");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeQuantity = (
    e,
    itemIndex,
    i,
    basePrice,
    listLineNo,
    Recipe_unit,
    maxQty,
    scheduleId
  ) => {
    let newArr = [...item];
    var price = basePrice;
    let customerNo = props.customerNo;
    if (Recipe_unit === "Kg") {
      var quantity = e.target.value.replace(/KG/g, "").trim();
    } else {
      var quantity = e.target.value.replace(/ST/g, "").trim();
      quantity = parseFloat(Math.floor(quantity));
      if (isNaN(quantity) === true || quantity === 0) {
        quantity = 1;
      }
    }
    if (maxQty === 0) {
    } else if (quantity > maxQty) {
      quantity = maxQty;
      newArr[itemIndex].hiddenText = true;
    } else if (quantity > 0) {
      price = basePrice * quantity;
    } else {
      price = basePrice;
    }

    const re = /^[0-9.,]+$/;
    if (quantity === "") {
      quantity = 0;
    }

    if (newArr[itemIndex].Items[i].BaseUnit === "Kg") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Styck") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kartplus") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (
        newArr[itemIndex].Items[i].Recipe_unit === "Kartplus" ||
        newArr[itemIndex].Items[i].Recipe_unit === "Kartplus"
      ) {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
      if (
        newArr[itemIndex].Items[i].Recipe_unit === "Kart" ||
        newArr[itemIndex].Items[i].Recipe_unit === "Kartplus"
      ) {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round((quantity * 1 + Number.EPSILON) * 100) / 100;
      }
    }
    if (newArr[itemIndex].Items[i].BaseUnit === "Kart") {
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kg") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_KG +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Styck") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_ST +
              Number.EPSILON) *
              100
          ) / 100;
      }
      if (newArr[itemIndex].Items[i].Recipe_unit === "Kart") {
        newArr[itemIndex].Items[i].UnitCount =
          Math.round(
            (quantity * newArr[itemIndex].Items[i].Conv_facor_CART +
              Number.EPSILON) *
              100
          ) / 100;
      }
    }
    // debugger
    if (re.test(quantity)) {
      newArr[itemIndex].Items[i].Quantity = quantity;
    }
    setItem(newArr);
  };

  const onclcikEvent = (e, itemIndex, i) => {
    let newArr = [...item];
    newArr[itemIndex].Items[i].hiddenText = false;
    setItem(newArr);
  };

  const inputOnFocus = (e, itemIndex, i) => {
    let newArr = [...item];
    newArr[itemIndex].Items[i].hiddenText = false;
    setItem(newArr);
  };
  const onMouseLeaveEvent = (
    e,
    itemIndex,
    i,
    basePrice,
    listLineNo,
    Recipe_unit,
    maxQty
  ) => {
    // console.log("PREV QTY",e.target.value);
    let customerNo = props.customerNo;
    var price = basePrice;
    let newArr = [...item];
    newArr[itemIndex].Items[i].hiddenText = true;
    let quantity = e.target.value;
    setItem(newArr);
    let data1 = {
      userId: id,
      custNo: customerNo,
    };
    // debugger
    axiosApiInstance
      .put(`${URL}/checkDatesForQtyTesting`, data1)
      .then((res) => {
        if (res.data.message === "Data Found") {
          let nextDate = res.data.data.nearestDate;
          let insertIntoTemp = res.data.data.insertIntoTemp;
          let isEndDate = res.data.data.isEndDate;
          // let isStopTimeAvailable=res.data.data.isStopTimeAvailable
          let showMessage;
          let data = {
            listLineNo: listLineNo,
            listNo: listID,
            price: price,
            nextQty: quantity,
            userId: id,
            custNo: customerNo,
            unitCount: newArr[itemIndex].Items[i].UnitCount,
            nextDate: nextDate,
            insertIntoTemp: insertIntoTemp,
            isEndDate: isEndDate,
          };
          // console.log(res);
          if (insertIntoTemp && !isEndDate && nextDate) {
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate) {
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // debugger
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate === null) {
            // debugger
            showMessage = `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && !isEndDate && nextDate === undefined) {
            // debugger
            showMessage = `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`;
            // console.log(showMessage, typeof (showMessage))
          } else if (insertIntoTemp && isEndDate && nextDate) {
            // debugger
            showMessage = `Ändringar kommer att tillämpas från din nästa beställning: ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && isEndDate && nextDate === null) {
            showMessage = `Kan inte ändra kvantitet, ändra slutdatum`;
            // console.log(showMessage, typeof (showMessage))
          } else {
            showMessage = ``;
            // console.log(showMessage, typeof (showMessage))
          }
          swal({
            title: "Är du säker?",
            text: showMessage,
            icon: "warning",
            buttons: ["Nej", "Ja"],
            dangerMode: true,
          }).then((isYes) => {
            if (isYes) {
              if (showMessage === `Kan inte ändra kvantitet, ändra slutdatum`) {
                getList(listID, customerNo);
              } else if (
                showMessage ===
                `Kan inte ändra kvantitet, nästa leveransdatum är inte tillgängligt`
              ) {
                getList(listID, customerNo);
              } else {
                axiosApiInstance
                  .put(`${URL}/updateListLineQtyDetails`, data)
                  .then((res) => {
                    if (res.status === 200) {
                      let customerNo = props.customerNo;
                      getList(listID, customerNo);
                      swal("Kvantitet uppdaterad genomförd!", {
                        icon: "success",
                        timer: 2000,
                      });
                    } else {
                      console.log("Error");
                      swal("Något gick fel!", {
                        icon: "info",
                        timer: 2000,
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    swal("Något gick fel!", {
                      icon: "info",
                      timer: 2000,
                      text: err,
                    });
                  });
              }
            } else {
              getList(listID, customerNo);
            }
          });
        }
      })
      .catch((res) => {
        swal("Error", res.message, "error", { buttons: false, timer: 1500 });
      });
  };

  // const matchRadioandToggle = (PK_Listno) => {
  //   togArray && togArray.map((data, i) => {
  //     if (data.key === PK_Listno) {
  //       if (data.value === true) {
  //         setdisplaybtn(true)
  //       } else {
  //         setdisplaybtn(false)
  //       }
  //     }
  //   })
  // }

  /*  const onClickToggle = (event, listNo) => {
    let listItems=[];
    // console.log(item[0].Items)
    item[0].Items&&item[0].Items.map(data=>{
      listItems.push({"listLineNo":data.PK_ListLineNo,"nextQty":data.Quantity,"previousQty":data.Quantity})
    })
    // console.log(listItems)
    const isToggle = event.target.checked;
    let customerNo = props.customerNo;
    let data1 = {
      "userId": id,
      "custNo": customerNo
    }
    // debugger
    axiosApiInstance.put(`${URL}/checkDatesForQtyTesting`, data1).then(res => {

      if (res.data.status === 200) {
        // console.log('Check Date',res.data.data)
        let insertIntoTemp = res.data.data.insertIntoTemp;
        let isEndDate = res.data.data.isEndDate;
        let nearestDate = res.data.data.nearestDate;
        let showMessage
        if (insertIntoTemp && !isEndDate && nearestDate) {
          showMessage = `Prenumerationen ${isToggle?'börjar':'upphör'} den ${nearestDate}`
        }
        else if (!insertIntoTemp && !isEndDate && nearestDate) {
          showMessage = ` Prenumerationen ${isToggle?'börjar':'upphör'}`
        }
        else if(!insertIntoTemp && !isEndDate && nearestDate===null) {
          showMessage = `Kan inte ${isToggle?'starta':'stoppa'} prenumerationen`
        }
        else if(!insertIntoTemp && !isEndDate && nearestDate===undefined) {
          showMessage = `Kan inte ${isToggle?'starta':'stoppa'} prenumerationen, leveransvillkor inte uppdaterat`
        }
        else if(insertIntoTemp && isEndDate && nearestDate) {
          showMessage = `Prenumerationen ${isToggle?'börjar':'upphör'} den ${nearestDate}`
        }
        else if(!insertIntoTemp && isEndDate && nearestDate===null) {
          showMessage = `Kan inte ${isToggle?'starta':'stoppa'} prenumerationen`
        }
        else{
          showMessage = ``
        }
        swal({
          title: "Är du säker?",
          text: showMessage,
          icon: "warning",
          buttons: [
            'Nej, avbryt det!',
            'Ja jag är säker!'
          ],
          dangerMode: true,
        })
          .then(function (isConfirm) {
            if (isConfirm) {
              let data = {
                "listNo": listNo,
                "userId": id,
                "custNo": customerNo,
                "insertIntoTemp": insertIntoTemp,
                "isEndDate": isEndDate,
                "nextDate": nearestDate,
                "subscribe": isToggle ? 1 : 0,
                "prevSubscribe":isToggle ? 0 : 1
              }
              // debugger
              axiosApiInstance.put(`${URL}/newUpdateSubscription`, data)
                .then(res => {
                  if (res.status===200) {
                    let customerNo = props.customerNo;
                    getList(listID, customerNo);
                    getListItem(customerNo);
                    swal({
                      title: `Prenumerationen  ${isToggle===true ? "startad!" : "stoppad!"}`,
                      text: `Prenumerationen har ${isToggle===true ? "startat" : "stoppats"}`,
                      icon: 'success'
                    })
                  }
                })
                .catch(err => {
                  console.log("err", err);
                })

            } else {
              swal("Inställt", `Prenumerationen har säker`, "info");            }
          });
      }
    }).catch(res => {
      swal('Error',res.message,'error',{buttons:false,timer:1500})
    })

    //oldCode
    //-------------------------------------------------------------------------------------
    //     let dataToSend = {
    //       PK_Listno: listNo,
    //       ActiveList: isToggle===true ? 1 : 0
    //     };
    //     axiosApiInstance.put(`${URL}/updateAutoScheduleActive`, dataToSend)
    //       .then(res => {
    //         if (res.status===200) {
    //           swal({
    //             title: `Subscription ${isToggle===true ? "started!" : "stopped!"}`,
    //             text: `Subscription ${isToggle===true ? "start" : "stop"} successfully!`,
    //             icon: 'success'
    //           })
    //           let customerNo = props.customerNo;
    //           getList(listID,customerNo);
    //           getListItem(customerNo);
    //         }
    //       })
    //       .catch(err => {
    //         console.log("err", err);
    //       })

    // let arr = togArray;
    // togArray && togArray.map((data, i) => {
    //   if (data.key === listNo) {
    //     arr[i].value = isToggle
    //   }
    // });
    // matchRadioandToggle(listNo);
  } */

  // const handleDropdownChange = (e) => {
  //   const selectedIndex = e.target.options.selectedIndex;
  //   let textValue = e.target.options[selectedIndex].getAttribute('data-key');
  //   const { name, value } = e.target;
  //   setScheduleList(value);
  // }
  const deleteListNoSchedule = (PK_Listno) => {
    // console.log(item);
    if (item[0].scheduleActive === true) {
      swal("Listan är schemalagd", "", "warning", { timer: 2000 });
    } else {
      let data = {
        User_id: Auth.getUserId(),
        PK_Listno: PK_Listno,
        Active_List: 0,
        StatementType: "DELETE_LIST",
        cust_no: custNo,
      };
      swal({
        title: "Är du säker?",
        text: "Listan kommer att tas bort",
        icon: "warning",
        buttons: ["Nej", "Ja"],
        dangerMode: true,
      }).then((isYes) => {
        if (isYes) {
          axiosApiInstance
            .post(`${URL}/updateListAndItems`, data)
            .then((res) => {
              if (res.status === 200) {
                // let customerNo = props.customerNo;
                // getList(listID);
                history.goBack();
                props.setRefTopNav(!props.refresh);
              } else {
                console.log("else");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
  };

  const deleteList = (PK_Listno) => {
    // debugger
    let scheduleActive = item[0].scheduleActive;
    if (scheduleActive !== null) {
      let listNo = PK_Listno;
      let customerNo = props.customerNo;
      let checkDateData = {
        userId: id,
        custNo: customerNo,
      };
      axiosApiInstance
        .put(`${URL}/checkDatesForQtyTesting`, checkDateData)
        .then((res) => {
          let checkData = res.data.data;
          // console.log(checkData);
          let insertIntoTemp = checkData.insertIntoTemp;
          let isEndDate = checkData.isEndDate;
          let nearestDate = checkData.nearestDate;
          let showMessage = ``;
          let listItems = [];
          item[0].Items &&
            item[0].Items.map((data) => {
              listItems.push({
                listLineNo: data.PK_ListLineNo,
                nextQty: data.Quantity,
                previousQty: data.Quantity,
              });
            });
          if (scheduleActive !== null) {
            if (!insertIntoTemp && !isEndDate && nearestDate === null) {
              showMessage = `Vill du ta bort listan?`;
            } else if (!insertIntoTemp && isEndDate && nearestDate === null) {
              showMessage = `Vill du ta bort listan?`;
            } else if (
              !insertIntoTemp &&
              !isEndDate &&
              nearestDate === undefined
            ) {
              showMessage = `Vill du ta bort listan?`;
            } else if (insertIntoTemp && !isEndDate && nearestDate) {
              showMessage = `Listan kommer att raderas den ${nearestDate}`;
            } else if (insertIntoTemp && isEndDate && nearestDate) {
              showMessage = `Listan kommer att raderas den ${nearestDate}`;
            } else {
              showMessage = `Listan kommer att raderas den ${nearestDate}`;
            }
          } else {
            showMessage = `Listan kommer att tas bort`;
          }
          swal({
            title: "Are You Sure?",
            text: showMessage,
            icon: "warning",
            dangerMode: true,
            buttons: ["Nej", "Ja"],
          }).then((yes) => {
            if (yes) {
              let DeleteAPIData = {
                listNo: listNo,
                userId: id,
                custNo: custNo,
                insertIntoTemp: insertIntoTemp,
                isEndDate: isEndDate,
                nextDate: nearestDate,
              };
              axiosApiInstance
                .put(`${URL}/newDeleteAllListDetails`, DeleteAPIData)
                .then((res) => {
                  if (res.status === 200) {
                    console.log(res);
                    getList(listID);
                    history.goBack();
                    props.setRefTopNav(!props.refresh);
                  } else {
                    console.log("else");
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        })
        .catch((res) => {
          swal("Error", res.message, "error", { buttons: false, timer: 1500 });
        });
    } else {
      deleteListNoSchedule(PK_Listno);
    }
  };

  const deleteItem = (
    itemIndex,
    i,
    qty,
    basePrice,
    listLineNo,
    maxQty,
    scheduleId
  ) => {
    // alert(custNo);
    let newArr = [...item];
    let customerNo = props.customerNo;
    let quantity = parseFloat(qty) + 1;
    var price = basePrice;
    let data1 = {
      userId: id,
      custNo: customerNo,
    };
    // debugger
    axiosApiInstance
      .put(`${URL}/checkDatesForQtyTesting`, data1)
      .then((res) => {
        if (res.data.message === "Data Found") {
          let nextDate = res.data.data.nearestDate;
          let insertIntoTemp = res.data.data.insertIntoTemp;
          let isEndDate = res.data.data.isEndDate;
          let showMessage;
          if (insertIntoTemp && !isEndDate && nextDate) {
            // debugger
            showMessage = `Changes will be reflected on next nearest date: ${nextDate}`;
            // console.log(res,"dataaadasdasdasda")
          } else if (!insertIntoTemp && !isEndDate && nextDate) {
            showMessage = `Changes will be reflected on next nearest date: ${nextDate}`;
            // debugger
            // console.log(showMessage, typeof (showMessage))
          } else if (!insertIntoTemp && isEndDate && nextDate === null) {
            // debugger
            showMessage = `Kan inte ändra kvantitet, ändra slutdatum`;
            // console.log(showMessage, typeof (showMessage))
          } else if (insertIntoTemp && isEndDate && nextDate) {
            // debugger
            showMessage = `Changes will be reflected on next nearest date: ${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          } else {
            showMessage = `Förändringar kommer att genomföras idag :${nextDate}`;
            // console.log(showMessage, typeof (showMessage))
          }

          swal({
            title: "Är du säker?",
            text: showMessage,
            icon: "warning",
            buttons: ["Nej", "Ja"],
            dangerMode: true,
          }).then((isYes) => {
            if (isYes) {
              // alert('yes')
              let data = {
                listLineNo: listLineNo,
                listNo: listID,
                price: price,
                nextQty: quantity,
                userId: id,
                custNo: customerNo,
                unitCount: newArr[itemIndex].Items[i].UnitCount,
                nextDate: nextDate,
                insertIntoTemp: insertIntoTemp,
                isEndDate: isEndDate,
                deleteListItem: 1,
              };
              axiosApiInstance
                .put(`${URL}/deleteListLineDetails`, data)
                .then((res) => {
                  if (res.status === 200) {
                    // let customerNo = props.customerNo;
                    // getList(listID,customerNo);
                    // props.setRefTopNav(!props.refresh);
                    let customerNo = props.customerNo;
                    getList(listID, customerNo);
                    // console.log("Delete Resp",res)
                  } else {
                    console.log("else");
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        }
      })
      .catch((res) => {
        swal("Error", res.message, "error", { buttons: false, timer: 1500 });
      });
  };

  const deleteItemSchedule = (PK_ListLineNo, PK_Listno) => {
    // alert(custNo);
    let data = {
      User_id: Auth.getUserId(),
      PK_Listno: PK_Listno,
      PK_ListLineNo: PK_ListLineNo,
      Active_Product: 0,
      StatementType: "DELETE_LISTITEM",
      cust_no: custNo,
    };
    swal({
      title: "Är du säker?",
      text: "Vill du ta bort det här objektet?",
      icon: "warning",
      buttons: ["Nej", "Ja"],
      dangerMode: true,
    }).then((isYes) => {
      if (isYes) {
        axiosApiInstance
          .post(`${URL}/updateListAndItems`, data)
          .then((res) => {
            if (res.status === 200) {
              let customerNo = props.customerNo;
              getList(listID, customerNo);
              props.setRefTopNav(!props.refresh);
            } else {
              console.log("else");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // function onClickRadio(value, PK_Listno) {
  //   // setdisplaybtn(true);
  //   setValue(value);
  //   setScheduleList(PK_Listno);

  // }

  let params = useParams();

  React.useEffect(() => {
    // console.log(params);
    let customerNo = props.customerNo;
    setCustomer(customerNo);
    let id;
    const urlParams = new URLSearchParams(location.search);
    for (const [key, value] of urlParams) {
      if (key === "id") {
        id = value;
      }
    }
    let listID = id;
    setListID(listID);
    getList(listID, customerNo);
    getListItem(customerNo);
  }, [props.customerNo, params]);

  const [firstCall, setFirstCall] = useState(false);

  React.useEffect(() => {
    // console.log(props.customerNo, previousCustomer)
    // getListItem(props.customerNo);
    if (firstCall) {
      if (props.customerNo) {
        getListByCustChng(props.customerNo);
      }
    } else {
      setFirstCall(true);
    }
  }, [props.customerNo]);

  window.onload = (e) => {
    setFirstCall(false);
    // console.log('Stop this');
    e.preventDefault();
    e.returnValue = "";
  };

  const getList = (id, customerNo) => {
    // axiosApiInstance.get(`${URL}/list_items/${id}/${customerNo}`)
    setLoading(true);
    // debugger
    axiosApiInstance
      .get(`${URL}/getListAndItemsById/${id}/${customerNo}`)
      .then((res) => {
        setLoading(false);
        // debugger
        if (res.data.status === 200) {
          let data = res.data.ListItem;
          // let DateDD = []
          // console.log("LIST::: ",data);
          // if(data[0].scheduleActive===true){
          //   setIsToggle(true)
          // }

          let newList =
            data &&
            data.map((ele) => {
              let Items =
                ele.Items &&
                ele.Items.map((c) => {
                  let Temp = JSON.parse(c.TempDetails);
                  // console.log("TempDetails",Temp);
                  // Temp && Temp.map(data =>{
                  //   debugger
                  //   for(let i = 0; i <= DateDD.length; i++){
                  //     if(DateDD.length===0){
                  //       DateDD.push({value:Temp[i].NextScheduleDate, label:DateDD.length+1})
                  //     }
                  //     else if(data.NextScheduleDate!==DateDD[i].value){
                  //       DateDD.push({value:Temp[i].NextScheduleDate, label:DateDD.length+1})
                  //       break
                  //     }
                  //   }
                  // }
                  // )
                  if (c.Recipe_unit === "Kart") {
                    return {
                      ...c,
                      scheduleId: ele.ScheduleId,
                      newPrice: c.Price_cart * c.Quantity * c.Conv_facor_CART,
                      basePrice: c.Price_cart * c.Conv_facor_CART,
                      hiddenText: true,
                      Min_Quantity: c.Min_Quantity === 0 ? 1 : c.Min_Quantity,
                      isDeleted: Temp && Temp[0].DeleteListItem,
                      TempDate:
                        Temp === null
                          ? c.StartingFrom
                          : Temp && Temp[0].NextScheduleDate,
                      nearestDateQty: Temp && Temp[0].NextQty,
                    };
                  } else if (c.Recipe_unit === "Kartplus") {
                    return {
                      ...c,
                      scheduleId: ele.ScheduleId,
                      newPrice: c.Price_cart * c.Quantity * 1,
                      basePrice: c.Price_cart * 1,
                      hiddenText: true,
                      Min_Quantity: c.Min_Quantity === 0 ? 1 : c.Min_Quantity,
                      isDeleted: Temp && Temp[0].DeleteListItem,
                      TempDate:
                        Temp === null
                          ? c.StartingFrom
                          : Temp && Temp[0].NextScheduleDate,
                      nearestDateQty: Temp && Temp[0].NextQty,
                    };
                  } else if (c.Recipe_unit === "Styck") {
                    return {
                      ...c,
                      scheduleId: ele.ScheduleId,
                      newPrice: c.Price_broken * c.Quantity,
                      basePrice: c.Price_broken,
                      hiddenText: true,
                      Min_Quantity: c.Min_Quantity === 0 ? 1 : c.Min_Quantity,
                      isDeleted: Temp && Temp[0].DeleteListItem,
                      TempDate:
                        Temp === null
                          ? c.StartingFrom
                          : Temp && Temp[0].NextScheduleDate,
                      nearestDateQty: Temp && Temp[0].NextQty,
                    };
                  } else if (c.Recipe_unit === "Kg") {
                    return {
                      ...c,
                      scheduleId: ele.ScheduleId,
                      newPrice: c.Comp_Price * c.Quantity,
                      basePrice: c.Comp_Price,
                      hiddenText: true,
                      Min_Quantity: c.Min_Quantity === 0 ? 1 : c.Min_Quantity,
                      isDeleted: Temp && Temp[0].DeleteListItem,
                      TempDate:
                        Temp === null
                          ? c.StartingFrom
                          : Temp && Temp[0].NextScheduleDate,
                      nearestDateQty: Temp && Temp[0].NextQty,
                    };
                  }
                });
              ele = { ...ele, Items };
              return ele;
            });
          for (var j = 0; j < newList.length; j++) {
            let sum = 0;
            if (newList[j].Items !== null) {
              for (var k = 0; k < newList[j].Items.length; k++) {
                sum = sum + newList[j].Items[k].newPrice;
              }
              newList[j].totalAmount = sum;
            }
          }
          if (
            data[0].Days !== null ||
            data[0].ScheduleStartDate !== null ||
            data[0].ScheduleEndDate !== null
          ) {
            let schDay = [];
            data[0].Days.forEach((element, index) => {
              for (const [key, value] of Object.entries(element)) {
                if (key !== "isPicup") {
                  if (value === "Monday") {
                    schDay.push("måndag");
                  } else if (value === "Tuesday") {
                    schDay.push("tisdag");
                  } else if (value === "Wednesday") {
                    schDay.push("onsdag");
                  } else if (value === "Thursday") {
                    schDay.push("torsdag");
                  } else if (value === "Friday") {
                    schDay.push("fredag");
                  } else if (value === "Saturday") {
                    schDay.push("lördag");
                  } else if (value === "Sunday") {
                    schDay.push("söndag");
                  }
                }
              }
            });
            const days = schDay.join(", ");
            setSchDays(days);
            setSchStartDay(data[0].ScheduleStartDate);
            setSchEndDay(data[0].ScheduleEndDate);
          }
          // console.log(DateDD);

          setValue(newList[0].List_Descr);
          setScheduleList(newList[0].PK_Listno);
          setItem(newList);
          // console.log("New List From GET:::",newList)
        } else {
          setItem("");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getListByCustChng = (customerNo) => {
    if (customerNo !== "" && customerNo !== null && customerNo !== undefined)
      axiosApiInstance
        .get(`${URL}/list/${id}/${customerNo}`)
        .then((res) => {
          // debugger
          if (res.status === 200) {
            let data = res.data.data;
            // let filterList = [];
            // data && data.map(detail => {
            //   if (detail.scheduleActiveList===1 || detail.scheduleActiveList===null) {
            //     filterList.push(detail);
            //   }
            // })
            if (data.length > 0) {
              let custFirstList = data[0].PK_Listno;
              // getList(custFirstList, props.customerNo);
              history.push(`/SavedList?id=${custFirstList}`);
            } else {
              history.push("/");
            }
          }
        })
        .catch((err) => console.log(err));
  };

  const getListItem = (customerNo) => {
    if (customerNo !== "" && customerNo !== null && customerNo !== undefined)
      axiosApiInstance
        .get(`${URL}/list/${id}/${customerNo}`)
        .then((res) => {
          // debugger
          if (res.status === 200) {
            let data = res.data.data;
            // let filterList = [];
            // data && data.map(detail => {
            //   if (detail.scheduleActiveList===1 || detail.scheduleActiveList===null) {
            //     filterList.push(detail);
            //   }
            // })
            setList(data);
            // let toggleKey = [];
            // data && data.map(detail => {
            //   toggleKey.push({ key: detail.PK_Listno, value: true })
            // });
            // setTogArray(toggleKey);
            // console.log(toggleKey, 'j');
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const handleCheckDays = (e) => {
    const day = e.target.name;
    deliveryDay.forEach((element, index) => {
      for (const [key, value] of Object.entries(element)) {
        if (key === day && key !== "isPickUp") {
          deliveryDay[index] = {
            [day]: e.target.checked,
            isPickUp: deliveryDay[index].isPickUp,
          };
        }
        // console.log(key, value);
      }
    });
    // setDeliveryDay(.deliveryDay);
    // console.log(deliveryDay);
  };

  const multiDays = (dateStart) => {
    let returnDays = [];
    const startDay = moment(dateStart).format("dddd");
    // debugger
    let day = Object.assign({}, deliveryDay);
    // console.log(day);
    // let DeliveryDays = {};
    // for (const [key, value] of Object.entries(day)) {
    //   for (const [key2, value2] of Object.entries(value)) {
    //     DeliveryDays[key2] = value2;
    //   }
    //   // console.log(key, value);
    // }
    // let dayArr = [];
    // dayArr.push(DeliveryDays);
    // console.log(dayArr);
    // debugger
    if (days !== "") {
      if (days.Monday === true) {
        returnDays.push(
          <FormControlLabel
            className=""
            control={
              <Checkbox
                color={pickDays.Monday === true ? "secondary" : "primary"}
                className={pickDays.Monday === true ? "isPickUpCheck" : null}
                onChange={(e) => handleCheckDays(e)}
                name="Monday"
              />
            }
            label="måndag"
          />
        );
      }
      if (days.Tuesday === true) {
        returnDays.push(
          <FormControlLabel
            className=""
            control={
              <Checkbox
                color={pickDays.Tuesday === true ? "secondary" : "primary"}
                className={pickDays.Tuesday === true ? "isPickUpCheck" : null}
                onChange={(e) => handleCheckDays(e)}
                name="Tuesday"
              />
            }
            label="tisdag"
          />
        );
      }
      if (days.Wednesday === true) {
        returnDays.push(
          <FormControlLabel
            className=""
            control={
              <Checkbox
                color={pickDays.Wednesday === true ? "secondary" : "primary"}
                className={pickDays.Wednesday === true ? "isPickUpCheck" : null}
                onChange={(e) => handleCheckDays(e)}
                name="Wednesday"
              />
            }
            label="onsdag"
          />
        );
      }
      if (days.Thursday === true) {
        returnDays.push(
          <FormControlLabel
            className=""
            control={
              <Checkbox
                color={pickDays.Thursday === true ? "secondary" : "primary"}
                className={pickDays.Thursday === true ? "isPickUpCheck" : null}
                onChange={(e) => handleCheckDays(e)}
                name="Thursday"
              />
            }
            label="torsdag"
          />
        );
      }
      if (days.Friday === true) {
        returnDays.push(
          <FormControlLabel
            className=""
            control={
              <Checkbox
                color={pickDays.Friday === true ? "secondary" : "primary"}
                className={pickDays.Friday === true ? "isPickUpCheck" : null}
                onChange={(e) => handleCheckDays(e)}
                name="Friday"
              />
            }
            label="fredag"
          />
        );
      }
      if (days.Saturday === true) {
        returnDays.push(
          <FormControlLabel
            className=""
            control={
              <Checkbox
                color={pickDays.Saturday === true ? "secondary" : "primary"}
                className={pickDays.Saturday === true ? "isPickUpCheck" : null}
                onChange={(e) => handleCheckDays(e)}
                name="Saturday"
              />
            }
            label="lördag"
          />
        );
      }
      if (days.Sunday === true) {
        returnDays.push(
          <FormControlLabel
            className=""
            control={
              <Checkbox
                color={pickDays.Sunday === true ? "secondary" : "primary"}
                className={pickDays.Sunday === true ? "isPickUpCheck" : null}
                onChange={(e) => handleCheckDays(e)}
                name="Sunday"
              />
            }
            label="söndag"
          />
        );
      }
    }
    return returnDays;
  };

  // const getDayValue = (day) =>{
  //   deliveryDay.forEach((element, index) => {
  //     for (const [key, value] of Object.entries(element)) {
  //       if(key === day) {
  //         return value;
  //       }
  //     }
  //   })
  // }

  // const checkSelectedDate = (date) =>{
  //   const startDay = moment(date).format('dddd');
  //   // debugger
  //   deliveryDay.forEach((element, index) => {
  //     for (const [key, value] of Object.entries(element)) {
  //       if(key === startDay) {
  //         deliveryDay[index] = {[startDay]:true};
  //       }
  //       else{
  //         deliveryDay[index] = {[key]:false};
  //       }
  //     }
  //   });
  //   // multiDays(date);
  //   console.log(deliveryDay);
  // }

  return (
    <div>
      <Header />
      <Container
        fluid
        className="List-wrapper-style Wraper SaveMuliListWraper "
      >
        <Row className="alla-listor-section">
          <Col md={12} className="overflow-auto shadow-sm bg-white rounded">
            <div className="overflow-auto">
              <div className="list-header-listeler">Alla listor</div>
              <div className="alla-listor-parapgraph mt-2 center">
                Här finns dina sparade listor. För att ändra en lista, <br />
                klicka på den och välj att ta bort eller lägga till produkter
                direkt från produktsidan.
              </div>
              <div className="mt-2 listTop">
                {list &&
                  list.map((data) => (
                    <>
                      <button
                        type="button"
                        class="saveBtn btn btn-info"
                        onClick={() =>
                          history.push(`/SavedList?id=${data.PK_Listno}`)
                        }
                      >
                        {data.List_Descr}
                      </button>
                      &nbsp;
                    </>
                  ))}
              </div>
            </div>
          </Col>
        </Row>

        <FormControl
          component="fieldset"
          style={{ margin: "0 auto" }}
          className="w-100"
        >
          {item &&
            item.map((i, itemIndex) => (
              <div>
                <Row className="my-4 d-flex justify-content-center ">
                  <Col className="listWprnewSaid" key={itemIndex}>
                    <ListGroup className="SaveMultiListWp">
                      <ListGroupItem className="text-left pb-0">
                        <Col className="AutoScheduleText">
                          <h3>
                            {i.List_Descr} <br />
                          </h3>
                          <p className="antal-list-produkter-text">
                            ( antal produkter {i.Items && i.Items.length}){" "}
                          </p>
                        </Col>
                        {i.DeleteDetails ? null : (
                          <DeleteOutlineIcon
                            className="ListDeleteIcn"
                            onClick={() => deleteList(i.PK_Listno)}
                          />
                        )}
                        {i.SubscriptionDetails !== null ? (
                          <label className="text-danger">{`Efter schemalagd leverans kommer listan att ${
                            !i.scheduleActive ? "inaktiveras" : "aktiveras"
                          } från : ${
                            i.SubscriptionDetails[0].NextDate
                          }`}</label>
                        ) : i.DeleteDetails ? (
                          <label className="text-danger">{`Efter schemalagd leverans kommer listan att tas bort den : ${i.DeleteDetails[0].NextDate}`}</label>
                        ) : null}
                      </ListGroupItem>
                      <br />
                      <ListGroupItem
                        className="headerText"
                        id="hide-this-cmon-ineedtogo"
                      >
                        <Row>
                          <Col lg={1} className="text-left">
                            <span>
                              <ShoppingCartIcon className="ListAddIcnHead" />
                            </span>
                          </Col>
                          <Col lg={2} className="text-left">
                            <span> Produktbild </span>
                          </Col>
                          <Col lg={2} className="text-left">
                            <span> Artikel Namn </span>
                          </Col>
                          <Col lg={1}></Col>
                          <Col lg={3} className="text-left ">
                            <span> Antal/Vikt </span>
                          </Col>
                          <Col lg={1} className="text-left">
                            <span> Pris </span>
                          </Col>
                          <Col lg={1} className="text-left">
                            <span> Belopp </span>
                          </Col>
                          <Col lg={1} className="text-left">
                            <span> Radera </span>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      {i.Items &&
                        i.Items.map((item, index) =>
                          item.product_status == "5" ? (
                            <ListGroupItem key={index} className="text-left">
                              <Row>
                                <Col
                                  lg={1}
                                  md={1}
                                  className="Add-to-cart-list-item"
                                >
                                  <div>
                                    <span class="svara-bestall-slut">
                                      Tilfälligt Slut
                                      <span class="svara-bestall-hovertooltip">
                                        Vanligt vis är ledtiden 2-3 dagar för
                                        att leverera beställningsvaror. <br />
                                        Kontakta kontoret för mer information
                                      </span>
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  md={3}
                                  className="grayed-out-image image-list-picture-box pt-2"
                                >
                                  <div className="said-list-picture">
                                    {/* <img src={item.Small_Img}/> */}
                                    <img
                                      src={
                                        item.Pic_Small === ""
                                          ? missingImage
                                          : item.Pic_Small
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  md={3}
                                  className="grayed-out-image ListItemName my-3"
                                >
                                  <div className="product-line-info">
                                    {item.Descr}
                                  </div>
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="grayed-out-image antal-vikt-list-css"
                                >
                                  <Col
                                    lg={3}
                                    md={3}
                                    className="px-md-0 text-md-center my-3"
                                  >
                                    <p className="d-inline-cart">
                                      {item.Recipe_unit === "Kg"
                                        ? "VIKT"
                                        : item.Recipe_unit === "Kartplus" &&
                                          item.Standard_orderunit !== "Kg"
                                        ? "Kolli (" +
                                          (item.Conv_facor_CART + "  ST)")
                                        : item.Recipe_unit === "Styck"
                                        ? "1ST"
                                        : item.Recipe_unit === "Kart" &&
                                          item.Standard_orderunit !== "Kg"
                                        ? "Kolli (" +
                                          (item.Conv_facor_CART + "  ST)")
                                        : item.Conv_facor_CART + " kg"}
                                    </p>
                                  </Col>
                                  <Col
                                    md={6}
                                    xs={6}
                                    className="antal-vikt-list-css-2 my-2"
                                  ></Col>{" "}
                                </Col>
                                <Col
                                  lg={1}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="grayed-out-image text-left my-3 text-sm-center"
                                >
                                  <span className="spanPric ">
                                    Styck {Number(item.basePrice).toFixed(2)} kr
                                  </span>
                                </Col>
                                <Col
                                  lg={1}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="grayed-out-image text-left my-3 text-sm-center"
                                >
                                  <span className="spanPric ">
                                    Totalt {Number(item.newPrice).toFixed(2)} kr
                                  </span>
                                </Col>
                                <Col
                                  lg={1}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="my-3"
                                >
                                  <span className="not-grayed-out-image DeleteSpanWp">
                                    <DeleteOutlineIcon
                                      key={index}
                                      className="ListDeleteIcnItemStatus"
                                      onClick={() => {
                                        i.scheduleActive === null
                                          ? deleteItemSchedule(
                                              item.PK_ListLineNo,
                                              i.PK_Listno,
                                              custNo
                                            )
                                          : deleteItem(
                                              itemIndex,
                                              index,
                                              item.Quantity,
                                              item.basePrice,
                                              item.PK_ListLineNo,
                                              item.Max_Quantity,
                                              item.scheduleId
                                            );
                                      }}
                                    />
                                  </span>
                                </Col>
                                <Col></Col>
                              </Row>
                            </ListGroupItem>
                          ) : item.product_status == "3" ? (
                            <ListGroupItem key={index} className="text-left">
                              <Row>
                                <Col
                                  lg={1}
                                  md={1}
                                  className="Add-to-cart-list-item"
                                >
                                  <div>
                                    <span class="svara-bestall-slut-list-button">
                                      Beställning svara
                                      <span class="svara-bestall-hovertooltip">
                                        Vanligt vis är ledtiden 2-3 dagar för
                                        att leverera beställningsvaror. <br />
                                        Kontakta kontoret för mer information
                                      </span>
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  md={3}
                                  className="grayed-out-image image-list-picture-box pt-2"
                                >
                                  <div className="said-list-picture">
                                    {/* <img src={item.Small_Img}/> */}
                                    <img
                                      src={
                                        item.Pic_Small === ""
                                          ? missingImage
                                          : item.Pic_Small
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  md={3}
                                  className="grayed-out-image ListItemName my-3"
                                >
                                  <div className="product-line-info">
                                    {item.Descr}
                                  </div>
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="grayed-out-image antal-vikt-list-css"
                                >
                                  <Col
                                    lg={3}
                                    md={3}
                                    className="px-md-0 text-md-center my-3"
                                  >
                                    <p className="d-inline-cart">
                                      {item.Recipe_unit === "Kg"
                                        ? "VIKT"
                                        : item.Recipe_unit === "Kartplus" &&
                                          item.Standard_orderunit !== "Kg"
                                        ? "Kolli (" +
                                          (item.Conv_facor_CART + "  ST)")
                                        : item.Recipe_unit === "Styck"
                                        ? "1ST"
                                        : item.Recipe_unit === "Kart" &&
                                          item.Standard_orderunit !== "Kg"
                                        ? "Kolli (" +
                                          (item.Conv_facor_CART + "  ST)")
                                        : item.Conv_facor_CART + " kg"}
                                    </p>
                                  </Col>
                                  <Col
                                    md={6}
                                    xs={6}
                                    className="antal-vikt-list-css-2 my-2"
                                  ></Col>{" "}
                                </Col>
                                <Col
                                  lg={1}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="grayed-out-image text-left my-3 text-sm-center"
                                >
                                  <span className="spanPric ">
                                    Styck {Number(item.basePrice).toFixed(2)} kr
                                  </span>
                                </Col>
                                <Col
                                  lg={1}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="grayed-out-image text-left my-3 text-sm-center"
                                >
                                  <span className="spanPric ">
                                    Totalt {Number(item.newPrice).toFixed(2)} kr
                                  </span>
                                </Col>
                                <Col
                                  lg={1}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="my-3"
                                >
                                  <span className="not-grayed-out-image DeleteSpanWp">
                                    <DeleteOutlineIcon
                                      key={index}
                                      className="ListDeleteIcnItemStatus"
                                      onClick={() => {
                                        i.scheduleActive === null
                                          ? deleteItemSchedule(
                                              item.PK_ListLineNo,
                                              i.PK_Listno,
                                              custNo
                                            )
                                          : deleteItem(
                                              itemIndex,
                                              index,
                                              item.Quantity,
                                              item.basePrice,
                                              item.PK_ListLineNo,
                                              item.Max_Quantity,
                                              item.scheduleId
                                            );
                                      }}
                                    />
                                  </span>
                                </Col>
                                <Col></Col>
                              </Row>
                            </ListGroupItem>
                          ) : item.product_status !== "5" ? (
                            <ListGroupItem
                              disabled={
                                (item &&
                                  item.TempDate !== null &&
                                  item &&
                                  item.TempDate !== undefined &&
                                  item.StartingFrom !== null &&
                                  new Date(item.StartingFrom) >
                                    new Date(schStartDay)) ||
                                item.isDeleted === true ||
                                i.scheduleActive === false ||
                                i.DeleteDetails !== null
                                  ? true
                                  : false
                              }
                              key={index}
                              style={
                                (item &&
                                  item.TempDate !== null &&
                                  item &&
                                  item.TempDate !== undefined &&
                                  item.StartingFrom !== null &&
                                  new Date(item.StartingFrom) >
                                    new Date(schStartDay)) ||
                                item.isDeleted === true ||
                                i.scheduleActive === false ||
                                i.DeleteDetails !== null
                                  ? { opacity: "0.5" }
                                  : { opacity: "1" }
                              }
                              className="text-left"
                            >
                              <Row>
                                <Col
                                  lg={1}
                                  md={1}
                                  className="Add-to-cart-list-item"
                                >
                                  <div className="said-list-button-box">
                                    <Button
                                      className="btn btn-light"
                                      onClick={() => {
                                        saveToCart(item);
                                      }}
                                    >
                                      {/* Add to Cart */}
                                      <ShoppingCartCheckoutIcon className="ListAddIcnList" />
                                    </Button>
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  md={3}
                                  className="image-list-picture-box pt-2"
                                >
                                  <div className="said-list-picture">
                                    {/* <img src={item.Small_Img}/> */}
                                    <img
                                      src={
                                        item.Pic_Small === ""
                                          ? missingImage
                                          : item.Pic_Small
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  md={3}
                                  className="ListItemName my-3"
                                >
                                  <div className="product-line-info">
                                    <Link
                                      to={{
                                        pathname: `/ProductDetail`,
                                        search: `?id=${item.ProdNo}`,
                                        state: {
                                          productType:
                                            item.isKampanj === false
                                              ? "Product"
                                              : "Kampanj",
                                          pageToLoad: 1,
                                          customerNo: props.customerNo,
                                        },
                                      }}
                                      className="product_name"
                                      data-id_customization="0"
                                    >
                                      {item.Descr}
                                    </Link>
                                  </div>
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  className="antal-vikt-list-css"
                                >
                                  <Col
                                    lg={3}
                                    md={3}
                                    className="px-md-0 text-md-center my-3"
                                  >
                                    <p className="d-inline-cart">
                                      {item.Recipe_unit === "Kg"
                                        ? "VIKT"
                                        : item.Recipe_unit === "Kartplus" &&
                                          item.Standard_orderunit !== "Kg"
                                        ? "Kolli (" +
                                          (item.Conv_facor_CART + "  ST)")
                                        : item.Recipe_unit === "Styck"
                                        ? "1ST"
                                        : item.Recipe_unit === "Kart" &&
                                          item.Standard_orderunit !== "Kg"
                                        ? "Kolli (" +
                                          (item.Conv_facor_CART + "  ST)")
                                        : item.Conv_facor_CART + " kg"}
                                    </p>
                                  </Col>
                                  <Col
                                    md={12}
                                    xs={12}
                                    className="antal-vikt-list-css-2 "
                                    id="increment-list-buttons"
                                  >
                                    <ListGroup
                                      horizontal
                                      className="list-button-fix-1"
                                    >
                                      <ListGroupItem
                                        className="newlistbuttons"
                                        disabled={
                                          item.Min_Quantity === item.Quantity
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          i.scheduleActive === null
                                            ? DecreaseItemWithoutSchedule(
                                                itemIndex,
                                                index,
                                                item.Quantity,
                                                item.basePrice,
                                                item.PK_ListLineNo,
                                                item.scheduleId,
                                                item.TempData
                                              )
                                            : DecreaseItem(
                                                itemIndex,
                                                index,
                                                item.Quantity,
                                                item.basePrice,
                                                item.PK_ListLineNo,
                                                item.scheduleId,
                                                item.TempData
                                              );
                                        }}
                                      >
                                        -
                                      </ListGroupItem>
                                      {item.Recipe_unit === "Kg" ? (
                                        <>
                                          <Input
                                            type={"text"}
                                            className="incrementText-lists"
                                            id={index}
                                            data-min="1"
                                            value={
                                              item.hiddenText
                                                ? item.Quantity.toFixed(2) + ""
                                                : item.Quantity.toFixed(2)
                                            }
                                          />
                                        </>
                                      ) : (
                                        <Input
                                          type="text"
                                          data-min="1"
                                          autoComplete="off"
                                          className="incrementText-lists"
                                          size="4"
                                          id={index}
                                          value={
                                            item.hiddenText
                                              ? item.Quantity.toFixed(2) + " "
                                              : item.Quantity.toFixed(2)
                                          }
                                        />
                                      )}
                                      <ListGroupItem
                                        onClick={() => {
                                          i.scheduleActive === null
                                            ? IncrementItemWithoutSchedule(
                                                itemIndex,
                                                index,
                                                item.Quantity,
                                                item.basePrice,
                                                item.PK_ListLineNo,
                                                item.Max_Quantity,
                                                item.TempData
                                              )
                                            : IncrementItem(
                                                itemIndex,
                                                index,
                                                item.Quantity,
                                                item.basePrice,
                                                item.PK_ListLineNo,
                                                item.Max_Quantity,
                                                item.TempData
                                              );
                                        }}
                                      >
                                        +
                                      </ListGroupItem>
                                    </ListGroup>
                                  </Col>{" "}
                                </Col>
                                <Col
                                  lg={1}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="text-left my-3 text-sm-center"
                                >
                                  <span className="spanPric ">
                                    Styck {Number(item.basePrice).toFixed(2)} kr
                                  </span>
                                </Col>
                                <Col
                                  lg={1}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className="text-left my-3 text-sm-center"
                                >
                                  <span className="spanPric ">
                                    Totalt {Number(item.newPrice).toFixed(2)} kr
                                  </span>
                                </Col>
                                <Col
                                  lg={1}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                  className="my-3"
                                >
                                  <span className="DeleteSpanWp">
                                    <DeleteOutlineIcon
                                      key={index}
                                      className="ListDeleteIcnItem"
                                      onClick={() => {
                                        i.scheduleActive === null
                                          ? deleteItemSchedule(
                                              item.PK_ListLineNo,
                                              i.PK_Listno,
                                              custNo
                                            )
                                          : deleteItem(
                                              itemIndex,
                                              index,
                                              item.Quantity,
                                              item.basePrice,
                                              item.PK_ListLineNo,
                                              item.Max_Quantity,
                                              item.scheduleId
                                            );
                                      }}
                                    />
                                  </span>
                                </Col>
                                <Col></Col>
                              </Row>
                            </ListGroupItem>
                          ) : (
                            <div> error status </div>
                          )
                        )}
                      {i.totalAmount === undefined ? null : (
                        <ListGroupItem
                          style={
                            i.scheduleActive === false
                              ? { opacity: "0.5" }
                              : { opacity: "1" }
                          }
                        >
                          <Row className="justify-content-end">
                            <Col md={5} className="text-left">
                              {i.Items && i.Items.length > 0 ? (
                                <div className="SaveasListWp mt-3">
                                  <Button
                                    className="w-75 btnAddShop mt-1"
                                    onClick={() => {
                                      saveToCartMulti(i.Items);
                                    }}
                                  >
                                    {/* Add to Cart */}
                                    LÄGG ALLT I VARUKORGEN
                                  </Button>
                                </div>
                              ) : null}
                            </Col>
                            <Col md={7} className="text-right">
                              <Row className="justify-content-end">
                                <Col md={4} className="text-right">
                                  <FormControlLabel
                                    label={`Total Belopp: ${i.totalAmount.toFixed(
                                      2
                                    )} Kr `}
                                    control={
                                      <span className="float-left">
                                        {/* Total Amount- {i.totalAmount===undefined ? null : i.totalAmount.toFixed(2)} kr */}
                                      </span>
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row className="justify-content-end">
                                <Col md={4} className="text-right">
                                  <FormControlLabel
                                    label={`Moms tillkommer`}
                                    control={
                                      <span className="float-left">
                                        {/* Total Amount- {i.totalAmount===undefined ? null : i.totalAmount.toFixed(2)} kr */}
                                      </span>
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </Col>

                  <br />
                  {item.length < 0 || item === "" ? (
                    <Col className="AutoScheduleText text-center">
                      {/* Något gick fel */}
                      Något gick fel
                    </Col>
                  ) : null}
                </Row>
              </div>
            ))}
          <hr style={{ width: "100%" }} />
        </FormControl>
      </Container>

      {/* <ScheduleModal show={show} onHide={handleClose} /> */}
      <Footer />
    </div>
  );
};
const mapStoreToProps = (state) => {
  return {
    customerNo: state.customer.customerNo,
    refresh: state.customer.refresh,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setCustomer:(customerNo)=>dispatch(setCustomer(customerNo)),
  setRefTopNav: (refresh) => dispatch(setRefTopNav(refresh)),
  setCartCount: (count) => dispatch(setCartCount(count)),
  setTotalPrice: (price) => dispatch(setTotalPrice(price)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(SaveMultiList);
