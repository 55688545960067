import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CreateUser from "./Components/CreateUser";
import Grid from "@material-ui/core/Grid";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FormatIndentDecreaseIcon from "@material-ui/icons/FormatIndentDecrease";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import ListIcon from "@material-ui/icons/List";
import Auth from "../../ServerCall/Auth";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ShoppingCartSharpIcon from "@material-ui/icons/ShoppingCartSharp";
import CustomerList from "./Components/CustomerList";
import CustomerDetails from "./Components/CustomerDetails";
import Logo from "../../assets/images/GKLogoBg.png";
import Reports from "../AdminDashboard/Components/Reports";
import UserList from "./Components/UserList";
import ProductOrderDetailsAdmin from "./Components/ProductOrderDetailsAdmin";

import { Input, FormGroup, Nav } from "reactstrap";

import _ from "lodash";

import { URL } from "../../ServerCall/Config";
import axiosApiInstance from "../../ServerCall/Axios.Instance";
import {
  setAccessLevel,
  setAdminAccess,
  setClient,
  setCustomer,
} from "../../redux/action/customerAction";
import { connect } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // backgroundColor: theme.palette.background.paper
    "&$selected": {
      backgroundColor: "red",
      color: "white",
    },
  },
  drawer: {
    [theme.breakpoints.down("xl")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.down("xl")]: {
      width: `calc(100%)`,
      marginLeft: 0,
    },
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    float: "right",
    marginTop: 5,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("lg")]: {
      // position: 'relative',
    },
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [subSelectedIndex, setSubSelectedIndex] = useState();
  const [dynamicMenu, setDynamicMenu] = useState([]);
  //const [purchase, setPurchase] = useState();
  const [gkAdminAccessLevel, setGkAdminAccessLevel] = useState();
  const [showPurchase, setShowPurchase] = useState(false);
  const [custDetails, setCustomerDetails] = useState([]);
  const [cNo, setCustNo] = useState();
  const [customerName, setCustomerName] = useState();

  const access_level = Auth.getAccess_level();
  const acessToken = Auth.getToken();
  var accesslvl = "";
  const path = window.location.pathname;

  useEffect(async () => {
    if (
      (access_level[0] === 6 ||
        access_level[0] === 0 ||
        access_level[0] === 4) &&
      (path === "/" ||
        path === "/AboutUs" ||
        path === "/ProductOverview" ||
        path === "/ProductDetail" ||
        path === "/OrderDetail")
    ) {
      Auth.logout();
    }
    if (access_level !== undefined) {
      accesslvl = access_level.find((obj) => obj === 4);
      if (accesslvl === undefined) {
        accesslvl = access_level[0];
      }
      setGkAdminAccessLevel(accesslvl);
      if (accesslvl !== undefined) {
        await props.setAccessLevel(accesslvl);
        // await getMainMenu();
        await getCustomer();
        await getAccessMenu();
      }
    }
  }, [props.accesslvl, props.adminAccess, props.purchase]);

  const getCustomer = () => {
    let userId = Auth.getUserId();
    if (userId !== undefined) {
      axiosApiInstance
        .get(`${URL}/users/getCustomer/${userId}`)
        .then((res) => {
          if (res.data.status === 200) {
            let customerDetails = res.data.data;
            getCustomerAccessDD(customerDetails);
            customerDetails &&
              customerDetails.map(async (item) => {
                if (item.isDefault === 1) {
                  // setCustomerName(item.Name);
                  setCustNo(item.CustNo);
                  props.setCustomer(item.CustNo);
                  if (item.access_level.length > 1) {
                    let myObj = item.access_level.find(
                      (obj) => obj.access_level === 4
                    );
                    let myObjPurchase = item.access_level.find(
                      (obj) => obj.access_level === 3
                    );
                    if (myObjPurchase.access_level === 3) {
                      setShowPurchase(true);
                    }
                    props.setAdminAccess(myObj.access_level);
                  } else if (item.access_level[0].access_level === 4) {
                    props.setAdminAccess(item.access_level[0].access_level);
                  } else if (item.access_level[0].access_level === 3) {
                    setShowPurchase(true);
                  }
                }
              });
          }
          // else {
          //   console.log("Error");
          // }
        })
        .catch((err) => {
          console.log(err, "Error");
        });
    }
  };

  const getCustomerAccessDD = (custDetails) => {
    let DDValue = [];
    custDetails &&
      custDetails.map((customer) => {
        customer.access_level &&
          customer.access_level.map((level) => {
            if (level.access_level === 4) {
              DDValue.push(customer);
            }
            if (level.access_level === 3 || level.access_level === 1) {
              setShowPurchase(true);
            }
          });
      });
    setCustomerDetails(DDValue);
    setCustomerName(DDValue[0].Name);
  };

  // const getMainMenu = () => {
  //   if (accesslvl !== undefined && accesslvl !== 0 && accesslvl !== 1) {
  //     axiosApiInstance.get(`${URL}/users/getMainMenu/${[accesslvl]}`)
  //       .then(async(res) => {
  //         console.log(res,"res");
  //         if (res.data.status === 200) {
  //           let data = res.data.data;
  //           await setPurchase(data[0].menuId);

  //         } else {
  //           // this.setState({noTodayOrders: res.data.message});
  //         }
  //       }).catch(err => {
  //         console.log(err);
  //       })
  //   }
  // }

  const getAccessMenu = () => {
    if (accesslvl !== undefined) {
      axiosApiInstance
        .get(`${URL}/users/getMenusByAccessLevel/${accesslvl}`)
        .then((res) => {
          if (res.data.status === 200) {
            let data = res.data.data;
            const newData =
              data &&
              data.map((file) => {
                return { ...file, open: false };
              });
            setDynamicMenu(newData);
            // console.log("newData", newData);
          } else {
            setDynamicMenu([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleListItemClick = (event, index, toggleOpen, parentMenuId) => {
    let newArr = [...dynamicMenu];
    for (var j = 0; j < newArr.length; j++) {
      if (j === index) {
        newArr[j].open = !toggleOpen;
      } else {
        newArr[j].open = false;
      }
    }
    setDynamicMenu(newArr);
    setSelectedIndex(index);
    if (newArr[index].childMenu.length === 0) {
      setMobileOpen(!mobileOpen);
    }
    // if (parentMenuId === purchase) {
    //   window.location.href = "/";
    // }
  };

  const handleSubListItemClick = (event, index) => {
    setSubSelectedIndex(index);
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChange = (event) => {
    var custId = event.target.value;
    axiosApiInstance
      .post(`${URL}/users/setIsDefaultCustomer`, {
        UserNo: Auth.getUserId(),
        CustNo: custId,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            setCustNo(custId);
            let customerDetails = res.data.data;
            // debugger
            setCustomerDetails(customerDetails);
            customerDetails &&
              customerDetails.map((item) => {
                if (item.isDefault === 1) {
                  setCustomerName(item.Name);
                  setCustNo(item.CustNo);
                  props.setCustomer(item.CustNo);
                  if (item.access_level.length > 1) {
                    let myObj = item.access_level.find(
                      (obj) => obj.access_level === 4
                    );
                    let myObjPurchase = item.access_level.find(
                      (obj) => obj.access_level === 3
                    );
                    if (myObjPurchase.access_level === 3) {
                      setShowPurchase(true);
                    }
                    props.setAdminAccess(myObj.access_level);
                  } else if (item.access_level[0].access_level === 3) {
                    setShowPurchase(true);
                  } else if (item.access_level[0].access_level === 4) {
                    props.setAdminAccess(item.access_level[0].access_level);
                  }
                }
              });
          } else {
            console.log("Error");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePurchaseAcc = () => {
    let isAdmin = false;
    localStorage.setItem("isAdmin", isAdmin);
  };

  const drawer = (
    <div className="sideDrawerWp">
      <div className={(classes.toolbar, "text-center")}>
        <img src={Logo} style={{ width: 120 }} />
      </div>
      <Divider />
      <List>
        {/* {dynamicMenu.filter(menu => menu.parentMenuId < 60).map((text, index) => ( */}
        {dynamicMenu &&
          dynamicMenu.map((text, index) => (
            <>
              <ListItem
                key={index}
                component={text.childMenu.length > 0 ? null : Link}
                to={text.parentPageUrl}
                selected={selectedIndex === index}
                onClick={(event) =>
                  handleListItemClick(
                    event,
                    index,
                    text.open,
                    text.parentMenuId
                  )
                }
              >
                <ListItemIcon>
                  {text.parentName === "Dashboard" ? (
                    <DashboardIcon />
                  ) : text.parentName === "User" ? (
                    <PersonIcon />
                  ) : text.parentName === "Customer" ? (
                    <PermContactCalendarIcon />
                  ) : text.parentName === "Reports" ? (
                    <FormatIndentDecreaseIcon />
                  ) : null}
                </ListItemIcon>
                <ListItemText
                  primary={
                    text.parentName === "Dashboard"
                      ? "Panel"
                      : text.parentName === "User"
                      ? "Användare"
                      : text.parentName === "Customer"
                      ? "Kund"
                      : text.parentName === "Reports"
                      ? "Order"
                      : text.parentName
                  }
                />
                {text.childMenu.length > 0 ? (
                  <>{text.open ? <ExpandLess /> : <ExpandMore />}</>
                ) : null}
              </ListItem>
              {text.childMenu &&
                text.childMenu.map((sub, i) => (
                  <Collapse in={text.open} timeout="auto" unmountOnExit>
                    <List component="div" key={i} disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        selected={subSelectedIndex === i}
                        onClick={(event) => handleSubListItemClick(event, i)}
                        component={Link}
                        to={sub.childPageUrl}
                      >
                        <ListItemIcon>
                          {sub.childName === "Customer List" ? (
                            <ListIcon />
                          ) : sub.childName === "Create User" ? (
                            <PersonAddIcon />
                          ) : sub.childName === "User List" ? (
                            <ListAltIcon />
                          ) : null}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            sub.childName === "Create User"
                              ? "Skapa Användare"
                              : sub.childName === "User List"
                              ? "Användarlista"
                              : sub.childName === "Customer List"
                              ? "Kundlista"
                              : sub.childName
                          }
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                ))}
            </>
          ))}
      </List>
    </div>
  );

  return (
    <div
      className={
        props.accesslvl === 4 ||
        (gkAdminAccessLevel === 0 &&
          path !== "/" &&
          props.purchase !== "purchase") ||
        (gkAdminAccessLevel === 6 && path !== "/") ||
        (gkAdminAccessLevel === 4 && path !== "/")
          ? classes.root
          : null
      }
    >
      {(props.accesslvl === 4 && props.adminAccess === 4) ||
      (gkAdminAccessLevel === 0 &&
        path !== "/" &&
        props.purchase !== "purchase") ||
      (gkAdminAccessLevel === 6 && path !== "/") ||
      path === "/CreateUser" ||
      path === "/UserList" ||
      (gkAdminAccessLevel === 4 && path !== "/") ||
      path === "/CreateUser" ? (
        <>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <div className="TopNavWpr">
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap className="TypoWraper">
                  <Grid className="GridWpr">
                    <Grid lg={12}>
                      {/* <h4 className="d-inline admin-header">Admin Dashboard</h4> */}
                      <div className="d-inline float-right">
                        {acessToken ? (
                          <>
                            {custDetails !== undefined ? (
                              custDetails.length > 1 ? (
                                <div className="AdminSide categoryNavWp">
                                  <FormGroup>
                                    <Input
                                      type="select"
                                      name="cNo"
                                      onChange={handleChange}
                                      value={cNo}
                                      id="exampleSelect"
                                      className="inputselectWpr"
                                    >
                                      {custDetails &&
                                        custDetails.map((select) => {
                                          return (
                                            <option
                                              value={select.CustNo}
                                              data-key={select.CustNo}
                                              className="optionWp"
                                            >
                                              {select.Name}
                                            </option>
                                          );
                                        })}
                                    </Input>
                                  </FormGroup>
                                </div>
                              ) : null
                            ) : null}
                            {custDetails !== undefined ? (
                              custDetails.length === 1 ? (
                                <Button className="btnAddShop btnUsername">
                                  {customerName}
                                </Button>
                              ) : null
                            ) : null}
                          </>
                        ) : null}
                        <a
                          href="/"
                          onClick={handlePurchaseAcc}
                          className="headerLink px-2"
                        >
                          {showPurchase ? `Purchase Products` : null}
                        </a>
                        <Tooltip title="Logout">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              Auth.logout();
                            }}
                            className="float-right logoutBtn"
                          >
                            <ExitToAppIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </Typography>
              </Toolbar>
            </div>
          </AppBar>
        </>
      ) : null}
      <BrowserRouter>
        {(props.accesslvl === 4 && props.adminAccess === 4) ||
        (gkAdminAccessLevel === 0 &&
          path !== "/" &&
          props.purchase !== "purchase") ||
        (gkAdminAccessLevel === 6 && path !== "/") ||
        path === "/CreateUser" ||
        path === "/UserList" ||
        (gkAdminAccessLevel === 4 && path !== "/") ||
        path === "/CreateUser" ? (
          <>
            <nav
              className={[classes.drawer + " " + "sliderwrapspace"]}
              aria-label="mailbox folders"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden smDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
          </>
        ) : null}

        <main
          className={
            props.accesslvl === 4 ||
            (gkAdminAccessLevel === 0 &&
              path !== "/" &&
              props.purchase !== "purchase") ||
            (gkAdminAccessLevel === 6 && path !== "/") ||
            path === "/CreateUser" ||
            path === "/UserList" ||
            (gkAdminAccessLevel === 4 && path !== "/") ||
            path === "/CreateUser"
              ? "mainClass"
              : ""
          }
          // className={classes.content}
        >
          {props.accesslvl === 4 ||
          (gkAdminAccessLevel === 0 &&
            path !== "/" &&
            props.purchase !== "purchase") ||
          (gkAdminAccessLevel === 6 && path !== "/") ||
          path === "/CreateUser" ||
          path === "/UserList" ||
          (gkAdminAccessLevel === 4 && path !== "/") ||
          path === "/CreateUser" ? (
            <div className={classes.toolbar} />
          ) : null}
          {props.adminAccess === 4 ||
          (gkAdminAccessLevel === 0 &&
            path !== "/" &&
            props.purchase !== "purchase") ||
          (gkAdminAccessLevel === 6 && path !== "/") ||
          (gkAdminAccessLevel === 4 && path !== "/") ? (
            <Switch>
              {/* <Route exact path="/" component={AdminLogin}/> */}
              {/* {accesslvl === 4? <Route exact path="/" component= {HomePage} /> : null } */}
              <Route
                exact
                path="/Dashboard"
                render={() => (
                  <div>
                    <Dashboard />
                  </div>
                )}
              />
              <Route
                exact
                path="/Reports"
                render={() => (
                  <div>
                    <Reports />
                  </div>
                )}
              />
              <Route
                exact
                path="/UserList"
                render={() => (
                  <div>
                    <UserList />
                  </div>
                )}
              />
              <Route
                exact
                path="/CreateUser"
                render={() => (
                  <div>
                    <CreateUser />
                  </div>
                )}
              />
              <Route
                exact
                path="/CustomerList"
                render={() => (
                  <div>
                    <CustomerList />
                  </div>
                )}
              />
              <Route
                exact
                path="/CustomerDetails/:id"
                render={() => (
                  <div>
                    <CustomerDetails />
                  </div>
                )}
              />
              <Route
                path="/ProductOrderDetailsAdmin"
                component={ProductOrderDetailsAdmin}
              />
            </Switch>
          ) : null}
        </main>
      </BrowserRouter>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};
const mapStoreToprops = (state) => {
  return {
    accesslvl: state.customer.accesslvl,
    adminAccess: state.customer.adminAccess,
    purchase: state.customer.purchase,
    cutomerNo: state.customer.cutomerNo,
  };
};

const mapDispatchToprops = (dispatch) => ({
  setAccessLevel: (accesslvl) => dispatch(setAccessLevel(accesslvl)),
  setAdminAccess: (access) => dispatch(setAdminAccess(access)),
  setCustomer: (customerNo) => dispatch(setCustomer(customerNo)),
});

export default connect(mapStoreToprops, mapDispatchToprops)(ResponsiveDrawer);
