import React from "react";
const Loader = () => {
  return (
    <div className="inner-loader-container">
      <div className="inner-loader-wrap">
        <div className="inner-loader"></div>
      </div>
    </div>
  );
};
export default Loader;
