import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Container, Col, Row, Card } from "react-bootstrap";
import Logo from "../../assets/images/GKLogoBg.png";
import Uc from "../../assets/images/uc.svg";

import PhonePausedIcon from "@material-ui/icons/PhonePaused";
import RoomIcon from "@material-ui/icons/Room";
import EmailIcon from "@material-ui/icons/Email";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default class Footer extends Component {
  render() {
    return (
      <div className="footerWp">
        <div className="wrapper">
          <Container fluid className="Wraper">
            <footer className="pt-3">
              <Row>
                <Col md={3} sm={6} xs={12}>
                  <div className="GKlogoWp">
                    <img className="mb-2 logoClass" src={Logo} alt="" />
                  </div>
                </Col>
                <Col md={3} sm={6} xs={12} className="footerCol InfoCol">
                  <h5>Information</h5>
                  <ul className="list-unstyled text-small listWp">
                    <li>
                      <Link className="textFooterNew" to="/AboutUs">
                        <ArrowForwardIosIcon className="mr-2 forwrdArrow" />
                        Om Oss
                      </Link>
                    </li>
                    <li>
                      <Link className="textFooterNew" to="#">
                        <ArrowForwardIosIcon className="mr-2 forwrdArrow" />
                        Transport Information
                      </Link>
                    </li>
                    <li>
                      <Link className="textFooterNew" to="/ContactUs">
                        <ArrowForwardIosIcon className="mr-2 forwrdArrow" />
                        Kontakta oss
                      </Link>
                    </li>
                  </ul>
                  <h5>Vår Policy</h5>
                  <ul className="list-unstyled text-small listWp">
                    <li>
                      <Link className="textFooterNew" to="/PrivacyPolicy">
                        <ArrowForwardIosIcon className="mr-2 forwrdArrow" />
                        Integritetspolicy
                      </Link>
                    </li>
                    <li>
                      <Link className="textFooterNew" to="/TermsConditions">
                        <ArrowForwardIosIcon className="mr-2 forwrdArrow" />
                        Allmäna Villkor
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col md={2} sm={6} xs={12} className="footerCol">
                  <h5>Kontakta Oss</h5>
                  <ul className="list-unstyled text-small listWp">
                    <li>
                      <a
                        className="textFooterNew"
                        target="_blank"
                        href="https://goo.gl/maps/WrLrJYx3kYrhjZLX8"
                      >
                        {" "}
                        <RoomIcon />
                        Konsumentvägen 9 125 30 Älvsjö
                      </a>
                    </li>
                    <li>
                      <a
                        className="textFooterNew"
                        href="mailto:info@gronsakskompaniet.se"
                      >
                        <EmailIcon />
                        info@gronsakskompaniet.se
                      </a>
                    </li>
                    <li>
                      <a className="textFooterNew" href="tel:084648050">
                        <PhonePausedIcon /> 08-464 80 50
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md={3} sm={6} xs={12} className="footerCol">
                  <div className="ucLogo">
                    <h5>
                      {/* Our Policy */}
                      HÖGSTA KREDITVÄRDIGHET
                    </h5>
                    <p>GRABBARNAS GRÖNT AB</p>
                    <p>556913-4009 | 2022-09-07</p>
                    <img className="mb-2 logoClassUc" src={Uc} alt="" />
                  </div>
                </Col>
              </Row>
            </footer>
          </Container>
        </div>
      </div>
    );
  }
}
