import axios from 'axios';
import {URL} from './Config';
import Auth from './Auth';

const axiosApiInstance = axios.create();
let currUser = JSON.parse(localStorage.getItem("userData"));
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${currUser.AccessToken}`,
      'Content-Type': 'application/json'
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return refreshAccessToken()
    .then(response => {
      
      //console.log("response",response);
      currUser.AccessToken = response.data.accessToken;
      localStorage.setItem("userData", JSON.stringify(currUser));

      // Set default headers to have authorization the access token as authorization for future requests
      axios.defaults.headers.common["Authorization"] =
      "Bearer " + response.data.accessToken;

      // Get the original that failed due to 401 and resend it
      // with the new access token
      const config = error.config;
      config.headers.Authorization =
        "Bearer " + response.data.accessToken;

      // Resending original request
      return new Promise((resolve, reject) => {
        axios.request(originalRequest)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }).catch(error => {
      // if refresh token failed logout
      Promise.reject(error);
      Auth.logout();
    });
  }
  return Promise.reject(error);
});

export default axiosApiInstance;

const refreshAccessToken = () => {
  
  let body = JSON.stringify({
    refreshToken: currUser.RefreshToken
  });
  let headers = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return new Promise((resolve, reject) => {
    axios.post(`${URL}/token`, body, headers)
      .then(async response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}