import React from 'react'
import { Container ,Col,Row,Card} from 'react-bootstrap'

import Header from '../../components/header/Header'
import ProductOrderItemList from './Components/ProductOrderItemList'
import Footer from '../../components/footer/Footer';

export default class ProductOrderDetails extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            orderItems:''
        }
       // this.handleChangeList = this.handleChangeList.bind(this);
    }
    componentDidMount(){
      
      var orderData = this.props.location.state.Items;
      this.setState({orderItems:orderData});
    }
    render()
    {
        return(
            <div>
                 <Header/>
                <Container fluid className="Wraper mb-5" >
                  <Card className="pt-4 pb-4">
                    <Row>
                        <Col md={11} sm={12} xs={12} style={{margin:'0 auto'}}>
                            <ProductOrderItemList orderItems={this.state.orderItems}  />
                        </Col>
                    </Row>
                    </Card>
                </Container>
                <Footer/>
            </div>
        );
    }
}
   
