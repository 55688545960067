import React from 'react'
import { Label ,Form ,Row,Col,FormGroup,Input,Button} from 'reactstrap';

const Address =() =>{
    return(
        <div>
            <Form className="mt-5">
                <Row>
                    <Col>
                    <p>The selected address will be used both as your personal address (for invoice) and as your delivery address.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required">First name   </label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="firstname" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required">Last Name   </label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="Lastname" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required">Comapny  </label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="Company" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required">Address  </label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="Address" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required"> City </label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="City" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required">State  </label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="State" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required">Postal Code  </label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="Postal Code" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required"> Country</label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="Address" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <label className="required"> Phone</label>
                         </FormGroup>
                    </Col>
                    <Col md={8}>
                         <Input className="form-control" name="Address" type="text" value="" maxlength="255" required=""/>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={1}></Col>
                    <Col>
                        <input type="checkbox" id="agree"/>
                        <label htmlFor="agree" className="pl-2"> I agree to <b>terms and conditions</b></label>
                    </Col>
                </Row>
                <Row>
					<Col><Button className="float-right BtnPrimaryContact mt-4">Continue</Button></Col>
				</Row>
			</Form>
        </div>
    )
}
export default Address;