import React, { useEffect, useState, useContext } from "react";
import { Navbar, Nav, NavDropdown, Form } from "react-bootstrap";
import { withRouter, useHistory } from "react-router";
import { Link } from "react-router-dom";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Auth from "../../../ServerCall/Auth";
import { setClient } from "../../../redux/action/customerAction";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import { URL } from "../../../ServerCall/Config";
import LoginModal from "./LoginModal";
import SearchBar from "./SearchBar";
import { connect } from "react-redux";
const TopNav = (props) => {
  let history = useHistory();
  const [access, setAccess] = useState("");
  const [gkLevel, setGkAccessLevel] = useState("");
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const acessToken = Auth.getToken();
  const access_level = Auth.getAccess_level();
  var gkAdminAccessLevel = "";
  const id = Auth.getUserId();
  const customerNo = props.customerNo;

  useEffect(() => {
    let adminAccess = props.adminAccess;
    let isLoginMod = props.location.search;
    if (isLoginMod === "?login") {
      console.log("show login mod");
      handleShow();
    }
    if (access_level !== undefined) {
      gkAdminAccessLevel = access_level[0];
      let isAdmin = false;

      access_level &&
        access_level.map((level) => {
          if (level === 4) {
            isAdmin = true;
            setGkAccessLevel(level);
          }
        });
      if (!isAdmin) setGkAccessLevel(gkAdminAccessLevel);
      props.setClient("purchase");
      getSavedList();
    }
    setAccess(adminAccess);
  }, [props.adminAccess, gkLevel, props.customerNo, props.refresh]);

  const getSavedList = () => {
    if (customerNo !== "" && customerNo !== null && customerNo !== undefined)
      axiosApiInstance
        .get(`${URL}/list/${id}/${customerNo}`)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            let filterList = [];
            data &&
              data.map((detail) => {
                filterList.push(detail);
              });
            setList(filterList);
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {});
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handlePurchaseAcc = () => {
    let isAdmin = true;
    localStorage.setItem("isAdmin", isAdmin);
  };
  return (
    <div className="TopNav">
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="wrapper"
      >
        <Navbar.Brand style={{ width: 200 }} className="mobLogo"></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <div className="toplogowp dektoplogo">
              <Link to="/">
                <img src="https://gronsakskompaniet.se/gronsakslogo.png" />
              </Link>
            </div>
            <Nav.Link onClick={() => history.push("/")}>Hem</Nav.Link>
            <Nav.Link
              onClick={() =>
                history.push({
                  pathname: "/ProductOverview",
                  state: { default: "defaultId" },
                })
              }
            >
              Produkter
            </Nav.Link>
            <Nav.Link onClick={() => history.push("/AboutUs")}>Om Oss</Nav.Link>
            {acessToken ? (
              <NavDropdown
                title="Sparade Listor"
                id="collasible-nav-dropdown"
                className="list"
              >
                {list.length > 0 ? (
                  list &&
                  list.map((data, i) => (
                    <Link
                      key={data.PK_Listno}
                      to={`/SavedList?id=${data.PK_Listno}`}
                      className="dropdown-item"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      {data.List_Descr} ({data.Count})
                    </Link>
                  ))
                ) : (
                  <span className="ml-4">
                    {/* No List Saved */}
                    Ingen lista sparad
                  </span>
                )}
              </NavDropdown>
            ) : null}
          </Nav>
          <Nav>
            {acessToken ? (
              <Nav>
                <Form inline>
                  <SearchBar customerNo={props.customerNo} />
                </Form>
              </Nav>
            ) : null}
            <Nav>
              {acessToken ? (
                <NavDropdown
                  title={`Hej, ${Auth.getUserName()}`}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => history.push("/OrderDetail")}
                  >
                    Order Historik
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => history.push("/ChangePassword")}
                  >
                    Ändra lösenord
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      Auth.logout();
                    }}
                  >
                    Logga Ut
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link className="pl-3 ml-2" onClick={handleShow}>
                  Logga In
                </Nav.Link>
              )}
            </Nav>

            {!acessToken ? (
              <span>
                <div className="verticalDivider"></div>
              </span>
            ) : null}
            {(acessToken && access === 4) ||
            gkLevel === 4 ||
            gkLevel === 0 ||
            gkLevel === 6 ? (
              <>
                <Nav.Link
                  eventKey={2}
                  onClick={handlePurchaseAcc}
                  href={gkLevel === 0 ? `/Dashboard` : `/CreateUser`}
                >
                  <SupervisorAccountIcon className="mr-2" />
                  Admin Panel
                </Nav.Link>
                <div className="verticalDivider"></div>
              </>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <LoginModal show={show} onHide={handleClose} />
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    adminAccess: state.customer.adminAccess,
    customerNo: state.customer.customerNo,
    refresh: state.customer.refresh,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setClient: (purchase) => dispatch(setClient(purchase)),
});
export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(TopNav));
