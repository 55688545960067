import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Row, Col } from "react-bootstrap";

import axios from "axios";
import { URL } from "../../../ServerCall/Config";
import swal from "sweetalert";

import {
  setAccessLevel,
  setAdminSection,
} from "../../../redux/action/customerAction";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginModal = (props) => {
  const [loginAttempts, setLoginAttempts] = useState(0);
  const maxLoginAttempts = 3;
  useEffect(() => {
    return () => {
      setLoginAttempts(0);
    };
  }, []);
  const classes = useStyles();
  const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
  );

  const [errorPassword, formErrorsPassword] = React.useState("");
  const [errorEmail, formErrorsEmail] = React.useState("");
  const [state, setstate] = React.useState({
    password: "",
    email: "",
    newPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const formvalid = () => {
    validEmailRegex.test(state.email)
      ? formErrorsEmail("")
      : formErrorsEmail("Giltig E-postadress krävs!");

    if (state.email !== "" && errorEmail === "") {
      return true;
    } else {
      return false;
    }
  };

  const formvalidPassword = () => {
    state.password !== ""
      ? formErrorsPassword("")
      : formErrorsPassword("lösenord krävs!");

    if (state.password !== "") {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleNext = (e) => {
    e.preventDefault();

    const isValid = formvalid();
    let credential = {
      email: state.email,
    };
    if (isValid) {
      axios
        .post(`${URL}/users/login`, credential)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 400) {
              swal("E-post hittades inte! ", "", "warning");
            }
            if (res.data.data.PasswordHash === null) {
              props.onHide();
              swal(
                "Återställ ditt lösenord",
                "Länken skickas till din registrerade e-postadress",
                "info"
              );
            } else {
              setShowPassword(true);
            }
          }
        })
        .catch((err) => {
          console.log("Test", err);
        });
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (loginAttempts >= maxLoginAttempts) {
      swal(
        "För många misslyckade inloggningsförsök. Vänta och försök igen senare.",
        "",
        "error"
      );
      return;
    }

    const isValid = formvalidPassword();
    let credential = {
      email: state.email,
      password: state.password,
    };
    if (isValid) {
      axios
        .post(`${URL}/users/login`, credential)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            if (res.data.status === 400) {
              setLoginAttempts(loginAttempts + 1);
              swal("Felaktig e-postadress eller lösenord", "", "info");
            } else {
              let isAdmin = false;
              let path = "";
              localStorage.setItem("userData", JSON.stringify(res.data.data));
              if (props.productId !== undefined) {
                window.location.href = `/ProductDetail?id=${props.productId}`;
              } else if (props.ProductOverview !== undefined) {
                window.location.href = `/ProductOverview`;
              } else {
                if (data.Access_level1[0] === 0) {
                  isAdmin = true;
                  path = "/Dashboard";
                } else if (
                  data.Access_level1[0] === 6 ||
                  data.Access_level1[0] === 4
                ) {
                  isAdmin = true;
                  path = "/CreateUser";
                } else {
                  isAdmin = false;
                  path = "/";
                }
                localStorage.setItem("isAdmin", isAdmin);
                window.location.href = path;
              }
            }
          }
        })
        .catch((err) => {
          console.log("Test", err);
        });
    }
  };

  return (
    <Container
      className="LoginContainer formWraper"
      component="main"
      maxWidth="xs"
    >
      <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
        <form className="form-new-homepage" noValidate>
          <TextField
            className="mainpage-input"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email-homepage"
            onChange={(e) => {
              handleChange(e);
            }}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {errorEmail !== "" ? (
            <label
              style={{ fontSize: 16, fontWeight: "bolder", color: "#cb4d4d" }}
            >
              {errorEmail}
            </label>
          ) : null}
          {showPassword && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={(e) => {
                handleChange(e);
              }}
              name="password"
              label="Lösenord"
              type="password"
              id="password-homepage"
            />
          )}
          {errorPassword !== "" ? (
            <label
              style={{ fontSize: 16, fontWeight: "bolder", color: "#cb4d4d" }}
            >
              {errorPassword}
            </label>
          ) : null}
          <Row className="pt-2 pb-2">
            <Col></Col>
          </Row>
          {!showPassword ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mainpage-btn mt-0 mb-2"
              onClick={(e) => {
                handleNext(e);
              }}
            >
              Logga in
            </Button>
          ) : null}
          {showPassword ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mainpage-btn mt-0 mb-2"
              onClick={(e) => {
                handleLogin(e);
              }}
            >
              Logga in
            </Button>
          ) : null}
          <Row>
            <Col md={12} style={{ margin: "0 auto" }}>
              {showPassword && (
                <Link
                  to="/ForgotPassword"
                  className="signupText-homepage text-center"
                >
                  Glömt lösenordet?{" "}
                  <strong>{/* Click here */} Klicka här </strong>{" "}
                  {/* to reset Password. */}för att återställa lösenordet.
                </Link>
              )}
            </Col>
          </Row>
        </form>
      </div>
    </Container>
  );
};
const mapStoreToProps = (state) => {
  return {
    accesslvl: state.customer.accesslvl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAccessLevel: (accesslvl) => dispatch(setAccessLevel(accesslvl)),
  setAdminSection: (isAdmin) => dispatch(setAdminSection(isAdmin)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(LoginModal);
