import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { Row, Container, Col, ListGroup } from "react-bootstrap";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
  Input,
} from "reactstrap";

import { Modal } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import swal from "sweetalert";

import LoginModal from "../../../components/header/components/LoginModal";
import missingImage from "../../../assets/images/picture_missing_text.png";

import { URL } from "../../../ServerCall/Config";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import Auth from "../../../ServerCall/Auth";

import {
  setCartCount,
  setTotalPrice,
} from "../../../redux/action/customerAction";
import { connect } from "react-redux";

const TopSellingProduct = (props) => {
  const [type, setType] = useState("Kampanj");
  const [isRedirect, setIsRedirect] = useState(false);
  const [productId, setProductId] = useState(null);
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const handleClose = () => setShow(false);
  const [count, setCount] = useState([]);
  const [total, setTotal] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const redirect = (id) => {
    setIsRedirect(true);
    setProductId(id);
  };
  const acessToken = Auth.getToken();
  const [scrollYStore, setScrollYStore] = useState(0);

  let yAxis = window.localStorage.getItem("scrollYKam");

  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1500);
  };

  React.useEffect(async () => {
    setScrollYStore(yAxis);
    if (props.condition) {
      await window.scrollTo(0, scrollYStore);
      window.localStorage.removeItem("scrollYKam");
    }
  }, [props.condition, scrollYStore]);

  React.useEffect(() => {
    return async () => {
      await setScrollYStore(window.scrollY);
    };
  }, []);

  function saveToCart(product) {
    let variable = Number.NaN;
    var kgUnit = 0;
    var stUnit = 0;
    var boxUnit = 0;
    var pcPerBox = 0;
    let custNo = props.customerNo;
    if (
      product.newQuantity === variable ||
      parseFloat(product.newQuantity) === 0 ||
      product.newQuantity === ""
    ) {
      // swal("Please select Minimum quantity", '', 'info');
      swal("Vänligen välj minsta kvantitet", "", "info");
    } else {
      // if (product.purchased_Unit === 'Kg') {
      //     if (product.Standard_orderunit === 'KG') {
      //         var unitfactor = Math.round((product.newQuantity * product.Conv_facor_KG + Number.EPSILON) * 100) / 100;
      //     }
      //     else {

      //         unitfactor = Math.ceil(product.newQuantity * product.Conv_facor_KG);
      //         //  var pcPerBox = product.Conv_facor_CART;
      //     }
      //     product.stdOrder_Unit = unitfactor;
      //     // product.newPrice = product.newQuantity * Comp_Price;
      // }
      // else if (product.purchased_Unit === 'Styck') {
      //     if (product.Standard_orderunit === 'KG') {
      //         var unitfactor = Math.round((product.newQuantity * product.Conv_facor_ST + Number.EPSILON) * 100) / 100;
      //         //  product.stdOrder_Unit = unitfactor;
      //     }
      //     else {
      //         unitfactor = Math.ceil(product.newQuantity * product.Conv_facor_ST);
      //         product.stdOrder_Unit = unitfactor;
      //         var pcPerBox = product.Conv_facor_CART;
      //         //  if(product.Max_Quantity !== null){
      //         //     if(unitfactor > product.Max_Quantity){
      //         //         alert("The Maximum limit for this product is"+' '+product.Max_Quantity);
      //         //         product.stdOrder_Unit = product.Max_Quantity;
      //         //         product.newQuantity = 200;
      //         //     }
      //         //     else{
      //         //         product.stdOrder_Unit = unitfactor;
      //         //       }
      //         //   }
      //         //   else{
      //         //     product.stdOrder_Unit = unitfactor;
      //         //   }

      //     }
      // }
      // else if (product.purchased_Unit === 'Kart') {
      //     if (product.Standard_orderunit === 'KG') {
      //         var unitfactor = Math.round((product.newQuantity * product.Conv_facor_CART + Number.EPSILON) * 100) / 100;
      //         product.stdOrder_Unit = unitfactor;
      //         pcPerBox = product.Conv_facor_CART;
      //     }
      //     else {
      //         unitfactor = Math.ceil(product.newQuantity * product.Conv_facor_CART);
      //         product.Standard_orderunit === 'Styck' ? pcPerBox = product.Conv_facor_CART : pcPerBox = (1 / product.Conv_facor_ST);
      //         if (product.Max_Quantity !== null || product.Max_Quantity !== 0) {
      //             if (unitfactor > product.Max_Quantity && product.Max_Quantity > 0) {
      //                 swal("The Maximum limit for this product is" + ' ' + product.Max_Quantity, '', 'info');
      //                 product.stdOrder_Unit = product.Max_Quantity;
      //                 product.newQuantity = product.Max_Quantity;
      //             }
      //             else {
      //                 product.stdOrder_Unit = unitfactor;
      //             }
      //         }
      //         else {
      //             product.stdOrder_Unit = unitfactor;
      //         }

      //     }
      // }
      if (product.Standard_orderunit === "Kg") {
        pcPerBox = product.Conv_facor_CART;
        if (product.purchased_Unit === "Kg") {
          kgUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_KG + Number.EPSILON) *
                100
            ) / 100;
          if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
            product.stdOrder_Unit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            product.stdOrder_Unit = kgUnit;
          }
        }
        if (product.purchased_Unit === "Styck") {
          kgUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_ST + Number.EPSILON) *
                100
            ) / 100;
          if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
            product.stdOrder_Unit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            product.stdOrder_Unit = kgUnit;
          }
        }
        if (product.purchased_Unit === "Kart") {
          kgUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_CART + Number.EPSILON) *
                100
            ) / 100;
          if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
            product.stdOrder_Unit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            product.stdOrder_Unit = kgUnit;
          }
        }
      }
      if (product.Standard_orderunit === "Styck") {
        pcPerBox = product.Conv_facor_CART;
        if (product.purchased_Unit === "Kg") {
          stUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_KG + Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
        if (product.purchased_Unit === "Styck") {
          stUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_ST + Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
        if (product.purchased_Unit === "Kart") {
          stUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_CART + Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
      }
      if (product.Standard_orderunit === "Kart") {
        pcPerBox = product.Conv_facor_CART;
        if (product.purchased_Unit === "Kg") {
          boxUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_KG + Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product.purchased_Unit === "Styck") {
          boxUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_ST + Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product.purchased_Unit === "Kart") {
          boxUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_CART + Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
      }
      axiosApiInstance
        .get(`${URL}/getKampanjPriceFromPriceTable/${product.Prodno}/${custNo}`)
        .then((res) => {
          if (res.status === 200) {
            var customerWisePrice = res.data.data;
            const newPrice =
              product.purchased_Unit === "Kg"
                ? customerWisePrice.Comp_Price
                : product.purchased_Unit === "Styck"
                ? customerWisePrice.Price_broken
                : customerWisePrice.Price_cart;
            const prePrice =
              product.purchased_Unit === "Kg"
                ? product.Comp_Price
                : product.purchased_Unit === "Styck"
                ? product.Price_broken
                : product.Price_cart;
            let finalprice;
            if (prePrice === product.unite_price) {
              finalprice = newPrice * product.newQuantity;
            } else {
              finalprice =
                newPrice *
                customerWisePrice.Cart_to_pieces *
                product.newQuantity;
            }
            // let Cartdata = {
            //     Product_name: product.Descr,
            //     Product_id: product.Prodno,
            //     pcPerBox: pcPerBox,
            //     orderWeight_Unit: product.stdOrder_Unit,
            //     purchased_Unit: product.purchased_Unit,
            //     baseUnit: product.Standard_orderunit,
            //     Price: finalprice,
            //     Qty: parseFloat(product.newQuantity),
            //     Base_price: newPrice,
            //     Base_qty: product.Min_Quantity,
            //     User_id: Auth.getUserId(),
            //     Tax: product.Tax_rate===undefined || product.Tax_rate===null ? 0 : product.Tax_rate,
            //     CustNo: props.customerNo
            // }
            let Cartdata = {
              Product_name: product.Descr,
              Product_id: product.Prodno,
              BoxUnit: boxUnit,
              StUnit: stUnit,
              KgUnit: product.stdOrder_Unit,
              pcPerBox: pcPerBox,
              // orderWeight_Unit: product.stdOrder_Unit,
              purchased_Unit: product.purchased_Unit,
              baseUnit: product.Standard_orderunit,
              Price: finalprice,
              Qty: parseFloat(product.newQuantity),
              Base_price: newPrice,
              Base_qty: product.Min_Quantity,
              User_id: Auth.getUserId(),
              Tax:
                product.Tax_rate === undefined || product.Tax_rate === null
                  ? 0
                  : product.Tax_rate,
              CustNo: props.customerNo,
              isKampanj: 1,
            };
            // console.log("Cartdata",Cartdata);
            axiosApiInstance
              .post(`${URL}/addToCart`, Cartdata)
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.status === 200) {
                    let data = res.data.data;
                    // debugger
                    data &&
                      data.map((pro) => {
                        if (
                          pro.Product_id === product.Prodno &&
                          product.purchased_Unit === pro.purchased_Unit
                        ) {
                          setLatestData(pro);
                        }
                      });
                    let totalOrder = 0;
                    for (let i = 0; i < data.length; i++) {
                      if (data[i] !== undefined) {
                        totalOrder +=
                          ((data[i].Price * data[i].Tax) / 100 +
                            data[i].Price) *
                          data[i].Qty;
                      }
                    }
                    setCount(data.length);
                    props.setCartCount(data.length);
                    props.setTotalPrice(totalOrder);
                    var cartCount = props.cartCount;
                    let Count = data;
                    let value = 0;
                    for (let i = 0; i < Count.length; i++) {
                      value += Count[i].Price;
                      setTotal(value);
                    }
                    // setCount(cartCount + Count.length);
                    // props.setCartCount(cartCount + Count.length);
                    handleShow();
                  } else {
                    // swal(`Something Went Wrong`, 'Please try again', 'info');
                    swal(`Något gick fel`, "försök igen ", "info");
                  }
                } else {
                  console.log("else");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <Container fluid className="Wraper TopSellimgWp ">
      <Row>
        {/* <Col className=" mainHedingCol">
                    <h1 className="mainHeadingText">*/}
        {/* Kampanj */}
        {/*      Kampanj Varor */}
        {/*  </h1>
                </Col> */}
      </Row>
      <div className="col-xs-12 no-padding home-product-container component-product-list js-component-type-weekly">
        <Row className="mb-4 ProdRowWp">
          {props.products &&
            props.products.map((product, index) => (
              <Col
                lg={2}
                md={3}
                sm={6}
                xs={6}
                key={product.Prodno}
                className="topSellprodListwp text-center"
              >
                <Card style={{ border: "none" }}>
                  <div className="">
                    <div className="prodImgWp prodListImgWpr">
                      {/*<Link to={{
                                            pathname: `/ProductDetail`,
                                            search: `?id=${product.Prodno}`,
                                            state: { productType: type }
                                        }} onClick={() => {
                                            setScrollYStore(window.scrollY);
                                            window.localStorage.setItem('scrollYKam', window.scrollY);
                                        }}> */}
                      <CardImg
                        top
                        src={
                          product.Small_Img === "" ||
                          product.Small_Img === undefined
                            ? missingImage
                            : product.Small_Img
                        }
                        alt="Image"
                      />
                    </div>
                    <span className="newSpan">{product.Price_type_name}</span>
                    {/* <span className="circulrIcn"><AccountCircleIcon className="accountCirculrIcn"/></span> */}
                  </div>
                  <CardBody className="topsellingcardBody">
                    <CardTitle className="titleTxt">{product.Descr}</CardTitle>
                    {/* <CardSubtitle className="mb-2 greenText">{product.Comp_Price} kr</CardSubtitle>  */}
                    <CardText className="CommercialText text-center">
                      {product.BrandName || product.Size_Contains === undefined
                        ? null
                        : product.BrandName + " " + product.Size_Contains}
                    </CardText>
                    {/* <CardTitle className="PriceTag">{product.default_price +' / '+product.comp_unit}</CardTitle> */}
                    {
                      <CardTitle className="PriceTag">
                        {" "}
                        {product.newPrice === undefined
                          ? 0
                          : product.newPrice.toFixed(2)}{" "}
                        kr /{" "}
                        {product.Standard_orderunit === "Kg" &&
                        product.comp_unit !== "Liter"
                          ? "Kg"
                          : product.Standard_orderunit === "Styck"
                          ? "ST"
                          : product.Standard_orderunit === "Kg" &&
                            product.comp_unit === "Liter"
                          ? "l"
                          : "kolli"}
                      </CardTitle>
                    }
                    <CardText className="Compartiveprice">
                      Jmf.pris :{" "}
                      {product.Comp_Price === undefined
                        ? 0
                        : product.purchased_Unit === "Kart"
                        ? product.box_com_cart_price.toFixed(2)
                        : product.Comp_Price.toFixed(2)}
                      / {product.comp_unit}
                    </CardText>
                    <CardText className="priceValidText mb-1">
                      Pris gäller t.o.m{" "}
                      {moment(product.Valid_date).format("DD MM YYYY")}
                    </CardText>
                    {acessToken ? (
                      <>
                        {/* {product.Size_Contains.indexOf("Kg") >= 0 ?
                            <CardText className="kiloWp">   
                                <Row className="mx-auto">
                                    <Col md={6} xs={6} className="pr-0 text-center styckColWp" >
                                        <Input type="text" value="STYCK" disabled className="styckClass"></Input>
                                    </Col>
                                    <Col md={6} xs={6} className="pl-1 pr-0 text-center"><Button className="kiloBtnClass"><CheckIcon className="kilocheck mr-1"/>KILO</Button></Col>
                                </Row>
                            </CardText>:null
                            } */}
                        <Row>
                          {/* {product.Standard_orderunit!== null?product.Standard_orderunit !== 'Kart'  ?
                                    <Col className="pl-1 pr-1">
                                    <ToggleBtn baseUnit={product.Standard_orderunit} changesBaseUnit={changesBaseUnit} conv_kg={product.Conv_facor_KG} conv_st={product.Conv_facor_ST} />
                                    </Col>: */}
                          <Col>
                            <CardText className="text-center LadaSelectWp">
                              {/* <SelectDrop/> */}
                              {/* <Label for="exampleSelect">Select</Label> */}
                              {(product.Conv_facor_CART === null ||
                                product.Conv_facor_CART === 0 ||
                                product.Conv_facor_CART === undefined) &&
                              (product.Conv_facor_ST === null ||
                                product.Conv_facor_ST === 0 ||
                                product.Conv_facor_ST === undefined) &&
                              (product.Conv_facor_KG === null ||
                                product.Conv_facor_KG === 0 ||
                                product.Conv_facor_KG === undefined) ? null : (
                                <select
                                  className="minimal"
                                  name="select"
                                  id="exampleSelect"
                                  value={product.default_value}
                                  onChange={(e) =>
                                    props.onChangeUnit(
                                      e,
                                      index,
                                      product.Standard_orderunit,
                                      product.Comp_Price,
                                      product.Price_broken,
                                      product.Price_cart,
                                      product.Conv_facor_KG,
                                      product.Conv_facor_ST,
                                      product.Conv_facor_CART,
                                      product.newPrice
                                    )
                                  }
                                  disabled={
                                    (product.Conv_facor_CART === null ||
                                      product.Conv_facor_CART === 0) &&
                                    (product.Conv_facor_KG === null ||
                                      product.Conv_facor_KG === 0)
                                      ? true
                                      : (product.Conv_facor_ST === null ||
                                          product.Conv_facor_ST === 0) &&
                                        (product.Conv_facor_KG === null ||
                                          product.Conv_facor_KG === 0)
                                      ? true
                                      : (product.Conv_facor_ST === null ||
                                          product.Conv_facor_ST === 0) &&
                                        (product.Conv_facor_CART === null ||
                                          product.Conv_facor_CART === 0)
                                      ? true
                                      : false
                                  }
                                >
                                  {product.Conv_facor_KG === 0 ||
                                  product.Conv_facor_KG === null ? null : (
                                    <option
                                      value={product.Conv_facor_KG}
                                      keyid="Kg"
                                    >
                                      {product.comp_unit === "Liter"
                                        ? `Liter`
                                        : `KILO`}
                                    </option>
                                  )}
                                  {product.Conv_facor_ST === 0 ||
                                  product.Conv_facor_ST === null ? null : (
                                    <option
                                      value={product.Conv_facor_ST}
                                      keyid="Styck"
                                    >{`${product.Conv_facor_ST} ST`}</option>
                                  )}
                                  {product.Conv_facor_CART === 0 ||
                                  product.Conv_facor_CART === null ? null : (
                                    <option value={5} keyid="Kart">
                                      Kolli (
                                      {product.Standard_orderunit === "Kart" &&
                                      product.Conv_facor_ST === 0
                                        ? "1" + "ST"
                                        : product.Standard_orderunit ===
                                            "Kart" &&
                                          product.Conv_facor_ST !== 0
                                        ? product.Conv_facor_CART /
                                            product.Conv_facor_ST +
                                          " " +
                                          "ST"
                                        : product.Standard_orderunit === "Styck"
                                        ? product.Conv_facor_CART + " " + "ST"
                                        : product.Standard_orderunit === "Kg"
                                        ? product.Conv_facor_CART + " " + "KG"
                                        : null}
                                      )
                                    </option>
                                  )}
                                </select>
                              )}
                              {/* <div className="VerticalDevider"></div> */}
                            </CardText>
                          </Col>
                          {/* :null} */}
                        </Row>

                        {/* <CardText className="text-center" >   
                                <ListGroup  horizontal className="mt-2 text-center LadaListWp" style={{margin:'0 auto'}}>
                                    <ListGroup.Item className="pt-2  ListText">LADA (24 ST)</ListGroup.Item>
                                    <ListGroup.Item></ListGroup.Item>
                                </ListGroup>
                            </CardText> */}
                        <CardText className="text-center IncrementalInputWp">
                          <ListGroup
                            horizontal
                            className="my-2 text-center"
                            style={{ margin: "0 auto" }}
                          >
                            <ListGroup.Item
                              disabled={
                                product.Min_Quantity === product.newQuantity
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                props.DecreaseItem(
                                  product.newQuantity,
                                  index,
                                  product.newPrice,
                                  product.Min_Quantity
                                )
                              }
                            >
                              -
                            </ListGroup.Item>
                            {product.purchased_Unit === "Kg" &&
                            product.comp_unit !== "Liter" &&
                            product.Standard_orderunit === "Kg" ? (
                              <Input
                                type={"text"}
                                className="InputincrementClass"
                                // id="depositedAmount"
                                pattern="[+-]?\d+([.,]\d*)?"
                                id={index}
                                data-min="1"
                                onChange={(e) =>
                                  props.onChangeQty(
                                    e,
                                    index,
                                    product.newPrice,
                                    product.Min_Quantity,
                                    product.purchased_Unit,
                                    product.Max_Quantity
                                  )
                                }
                                value={
                                  product.hiddenText
                                    ? `${product.newQuantity} KG`
                                    : product.newQuantity
                                }
                                onClick={(e) => props.onclcikEvent(e, index)}
                                onBlur={(e) =>
                                  props.onMouseLeaveEvent(e, index)
                                }
                              />
                            ) : product.purchased_Unit === "Kg" &&
                              product.comp_unit === "Liter" ? (
                              /* A input field which is used to take
                                                            input from user. */
                              <Input
                                type={"text"}
                                className="InputincrementClass"
                                // id="depositedAmount"
                                pattern="[+-]?\d+([.,]\d*)?"
                                id={index}
                                data-min="1"
                                onChange={(e) =>
                                  props.onChangeQty(
                                    e,
                                    index,
                                    product.newPrice,
                                    product.Min_Quantity,
                                    product.purchased_Unit,
                                    product.Max_Quantity
                                  )
                                }
                                value={
                                  product.hiddenText
                                    ? `${product.newQuantity} L`
                                    : product.newQuantity
                                }
                                onClick={(e) => props.onclcikEvent(e, index)}
                                onBlur={(e) =>
                                  props.onMouseLeaveEvent(e, index)
                                }
                              />
                            ) : (
                              <Input
                                type="text"
                                data-min="1"
                                autoComplete="off"
                                className="InputincrementClass"
                                size="1"
                                name={product.newQuantity}
                                data-max="999"
                                id={index}
                                onChange={(e) =>
                                  props.onChangeQty(
                                    e,
                                    index,
                                    product.newPrice,
                                    product.Min_Quantity,
                                    product.purchased_Unit,
                                    product.Max_Quantity
                                  )
                                }
                                value={
                                  product.hiddenText
                                    ? product.newQuantity + ""
                                    : product.newQuantity
                                }
                                onClick={(e) => props.onclcikEvent(e, index)}
                                onBlur={(e) =>
                                  props.onMouseLeaveEvent(e, index)
                                }
                              />
                            )}
                            <ListGroup.Item
                              onClick={() =>
                                props.IncrementItem(
                                  product.newQuantity,
                                  index,
                                  product.ProdNo,
                                  product.newPrice,
                                  product.Min_Quantity,
                                  product.Max_Quantity
                                )
                              }
                            >
                              +
                            </ListGroup.Item>
                          </ListGroup>
                        </CardText>
                      </>
                    ) : null}

                    {/*   {acessToken?
                             <Button className="w-100 btnAddShop  absoluteBtn mt-1" onClick={()=>{saveToCart(product)}}>Add to Cart</Button> :
                             <Button className="w-100 btnAddShop  absoluteBtn mt-1" onClick={() => handleShowLogin()}>LOGIN TO SHOP</Button>
                            }                       */}
                  </CardBody>
                </Card>
                {isRedirect ? (
                  <Redirect
                    to={{
                      pathname: `/ProductDetail/${type}`,
                      search: `?id=${productId}`,
                    }}
                  />
                ) : null}
                <Row>
                  <Col md={12} className="absoluteBtnCol">
                    {acessToken ? (
                      <Button
                        className="w-100  btnAddShop  mt-1"
                        onClick={() => {
                          saveToCart(product);
                        }}
                      >
                        {/* Add to Cart */}
                        LÄGG I VARUKORGEN
                      </Button>
                    ) : (
                      <Button
                        className="w-100 btnAddShop  mt-1"
                        onClick={() => handleShowLogin()}
                      >
                        {/* LOGIN TO SHOP */}
                        LÄGG TILL I KUNDVAGN
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
          <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="ModaladdProdHeader">
                {/* Product successfully added to your shopping cart */}
                Produkten har lagts till i varukorgen
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={7}>
                  <Row>
                    <Col md={5} className="ProdListImgWp">
                      <img
                        src={
                          latestData.Pic_Small === ""
                            ? missingImage
                            : latestData.Pic_Small
                        }
                        alt=""
                      />
                    </Col>
                    <Col md={5}>
                      <h6 className="headingText">{latestData.Product_name}</h6>
                      <p className="PriceText"> Pris : {latestData.Price} kr</p>

                      <p className="QtyText">
                        {/* Quantity/Weight : */}
                        Antal/Vikt : {latestData.Qty} &nbsp;
                        {latestData.purchased_Unit === "Styck"
                          ? "ST"
                          : latestData.purchased_Unit === "Kart"
                          ? "Kolli "
                          : // + ((latestData.baseUnit === 'Kg' || latestData.baseUnit === 'Kilo') ? latestData.pcPerBox + 'Kg' : latestData.pcPerBox + 'ST')
                          latestData.purchased_Unit === "Kg" ||
                            latestData.baseUnit === "Kilo"
                          ? "KG"
                          : null}
                        {/* /{latestData.orderWeight_Unit}{latestData.baseUnit} */}
                      </p>
                      {/* Quantity/Weight : {latestData.Qty}/{latestData.purchased_Unit === "Styck" ? "ST" : latestData.purchased_Unit === "Kart" ? "Box" + (latestData.pcPerBox + latestData.baseUnit) : latestData.purchased_Unit === "Kg" ? latestData.orderWeight_Unit + ' ' + latestData.baseUnit : null}</p> */}
                      {/* <p className="QtyText"></p> */}
                      {/* /{latestData.orderWeight_Unit}{latestData.baseUnit} */}
                    </Col>
                    <Col md={1} className="">
                      <div className="verticalLine"></div>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <Row>
                    <Col className="cartContent">
                      <p class="cart-products-count">
                        Det finns {count} artiklar i din kundvagn.
                      </p>
                      {/* <p><strong>Total products:</strong>&nbsp;Kr {total.toLocaleString('en-US', {maximumFractionDigits:2})}</p> */}
                      {/* <p><strong>Total shipping:</strong>&nbsp;$0.00 </p>
                                        <p><strong>Taxes</strong>&nbsp;${tax}</p> */}
                      {/* <p><strong>Total:</strong>&nbsp;Kr {(total + tax).toLocaleString('en-US', {maximumFractionDigits:2})} (tax excl.)</p> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {count === 1 ? (
                <Row>
                  <Col className="my-auto">
                    <div className="cart-content-btn text-center mt-2 mb-2">
                      <Link to="/ProductOverview">
                        <Button onClick={handleClose}>Fortsätt handla</Button>
                      </Link>
                      <Link to="/cart">
                        <Button className="ml-2 viewBtn">
                          <CheckIcon className="ChekiconWhite" />
                          Fortsätt till utcheckningen
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Modal.Body>
          </Modal>
        </Row>
        {/* <Row>
        <button onClick={handleShowMorePosts}>Load more</button>
        </Row> */}
        <LoginModal show={showLogin} onHide={handleCloseLogin} />
      </div>
    </Container>
  );
};
const mapStoreToProps = (state) => {
  return {
    cartCount: state.customer.count,
    customerNo: state.customer.customerNo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCartCount: (count) => dispatch(setCartCount(count)),
  setTotalPrice: (price) => dispatch(setTotalPrice(price)),
});

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(TopSellingProduct)
);
