import * as React from "react";
import {Row,Col} from 'reactstrap';
import moment from 'moment';
import NavTabs from './NavTabs';
import CustomCharts from '../../../components/charts/CustomCharts';
import {URL} from '../../../ServerCall/Config';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';


class Googlecharts extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      pieChartData: [],
      noPieChartData: "",
      pieChartMonth: moment().format('MMMM YYYY'),
      areaChartYear: [],
      noAreaChartData: ""
    }
  }

  componentDidMount(){
    this.getPieChartByProductAmount("Overall");
    this.getBarGraphByProductAmount();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.pieChartMonth !== this.state.pieChartMonth){
      this.getPieChartByProductAmount("Month");
    }
  }

  getPieChartByProductAmount = (type) => {
    const {pieChartMonth} = this.state;
    let text = 0;
    if(type === "Year"){
      text = new Date().getFullYear();
    }else if(type === "Month"){
      text = pieChartMonth;
    }
    axiosApiInstance.get(`${URL}/products/admin/getPieChartByProductAmount/${type}/${text}`)
    .then(res => {
      if(res.data.status === 200){
        let data = res.data.data;
        const mappedToArray = data.map(d => Array.from(Object.values(d)));
        this.setState({pieChartData: mappedToArray, noPieChartData: res.data.message});
      }else{
        this.setState({pieChartData: res.data.data, noPieChartData: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  getBarGraphByProductAmount = () => {
    axiosApiInstance.get(`${URL}/product/admin/getBarGraphByProductAmount/${moment().format('YYYY')}`)
    .then(res => {
      if(res.data.status === 200){
        let data = res.data.data;
        const mappedToArray = data.map(d => Array.from(Object.values(d)));
        this.setState({areaChartYear: mappedToArray});
      }else{
        this.setState({noAreaChartData: res.data.message});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  render(){
    const {pieChartData, areaChartYear} = this.state;
    return (
      <Row>
        <Col md={6} sm={6} xs={12}>
          <h6 className="canvsTitle ">
            {/* Product Wise Sales */}
            Produktvis försäljningsstatistik
          </h6>
          <NavTabs label="Övergripande" label2="År" label3="Månad" type="graph"
            handleTabChange={(val) => this.getPieChartByProductAmount(val)} onMonthChange={(val) => this.setState({pieChartMonth: val})}/>
          <CustomCharts data={[['Product', 'Sale Percentage'], ...pieChartData]} chartType="PieChart"/>
        </Col>
        <Col md={6} sm={6} xs={12} >
          <h6 className="canvsTitle" style={{marginBottom:'100px'}}>
            {/* Month Wise Sales Statistics */}
            Månadsvis försäljningsstatistik
          </h6>
          <div className="bargraphCol">
            <CustomCharts data={[['Month', 'Sales'], ...areaChartYear]} chartType="Bar" colors="#659f2f" />
          </div>
        </Col>
      </Row>
    );
  }
};
 
export default Googlecharts;