import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import moment from "moment";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import { ListGroup } from "react-bootstrap";
import { Card, Row, Col, Table, Button, CardText, Input } from "reactstrap";
import FavoriteIcon from "@material-ui/icons/FavoriteBorder";
import SaveListModal from "./SaveListModalProductOverview";

import Breadimg from "../../../assets/images/picture_missing_text.png";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import Auth from "../../../ServerCall/Auth";
import { URL } from "../../../ServerCall/Config";

import LoginModal from "../../../components/header/components/LoginModal";

import {
  setCartCount,
  setTotalPrice,
  setRefTopNav,
} from "../../../redux/action/customerAction";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
const ProductDetailContent = (props) => {
  const [Recipe_amount, setRecipe_amount] = useState(0);
  const [chdt, setChdt] = useState(new Date().toISOString());
  const [chtm, setChtm] = useState("0000");
  const [Recipe_unit, setRecipe_unit] = useState("");
  const [DataList, setDataList] = useState([]);
  const [User_id, setUser_id] = useState(Auth.getUserId());
  const [close, setClose] = useState(true);
  const [options, setOptions] = useState([]);
  const [List_Descr, setList_Descr] = useState("");
  const [Active_List, setActive_List] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [Last_Buy, setLast_Buy] = useState(new Date().toISOString());
  const [showHide, setShowHide] = useState(false);

  React.useEffect(async () => {
    getList();
  }, [props.custNo, props.cartCount]);

  const getList = () => {
    if (User_id !== undefined) {
      let CustNo = props.custNo;
      // let CustNo = props.custNo;
      if (
        props.custNo !== "" &&
        props.custNo !== null &&
        props.custNo !== undefined
      )
        axiosApiInstance
          .get(`${URL}/list/${User_id}/${props.custNo}`)
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              let optionsList = [];
              setDataList(data);
              data &&
                data.map((item, i) => {
                  if (
                    (item.scheduleActiveList && !item.IsDeleted) ||
                    item.scheduleActiveList === null
                  ) {
                    optionsList.push({
                      value: item.PK_Listno,
                      label: item.List_Descr,
                      scheduleId: item.ScheduleId,
                    });
                  }
                });
              setOptions(optionsList);
            } else {
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
    } else {
      setDataList([]);
    }
  };
  const CreatehandleSubmit = (
    prodctNo,
    price,
    qty,
    purchased_Unit,
    base_Price,
    pcPerBox,
    UnitCount,
    BaseUnit,
    Kampanj,
    IsMultipleProduct
  ) => {
    let CustNo = props.custNo;
    if (
      List_Descr.trim() !== undefined &&
      List_Descr.trim() !== null &&
      List_Descr.trim() !== ""
    ) {
      axiosApiInstance
        .post(`${URL}/list`, {
          User_id,
          List_Descr,
          Recipe_amount,
          Recipe_unit,
          Active_List,
          Last_Buy,
          CustNo,
          chdt,
          chtm,
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setDataList(data);
            setList_Descr("");
            // swal("List created successfully!", "", "info").then(()=>props.setRefTopNac(!refresh));
            if (data.length > 0) {
              const listno = data.length - 1;
              const pkListNo = data[listno].PK_Listno;
              const listDesc = data[listno].List_Descr;
              const scheduleId = data[listno].scheduleId;
              onAnswerSelected(
                pkListNo,
                prodctNo,
                price,
                qty,
                purchased_Unit,
                base_Price,
                pcPerBox,
                UnitCount,
                BaseUnit,
                Kampanj,
                scheduleId,
                listDesc
              );
            }
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // swal("Please enter name for the list", "", "info")
      swal("Ange namn för listan", "", "info");
    }
  };
  const chekProductAdd = (listNo, product, item, scheduleId, listDesc) => {
    return new Promise(function (resolve, reject) {
      let saveListApiDatas = [];
      let message = ``;
      let title = ``;
      let icon = "";
      let say = 0;
      let isAdd = false;

      let insertIntoTemp = "";
      let isEndDate = "";
      let nearestDate = null;
      let isAlreadyExist = "";
      let isScheduleExist = "";

      let date = new Date().toISOString();

      let checkBeforeSaveData = {
        PK_Listno: listNo,
        Prodno: product[0].ProdNo,
        Recipe_unit: product[0].purchased_Unit,
        CustNo: props.custNo,
        UserId: User_id,
      };
      let checkDateData = {
        scheduleId: scheduleId,
        userId: User_id,
        custNo: props.custNo,
      };
      let saveListApiData = {
        UserId: User_id,
        PK_Listno: listNo,
        Prodno: product[0].productNo,
        StartingFrom: nearestDate,
        Recipe_amount: product[0].newPrice * product[0].newQuantity,
        Recipe_unit: product[0].purchased_Unit,
        Active_Product: 1,
        Alternativ_prodno: "",
        Last_Buy_Amount: 0,
        Quantity: parseFloat(product[0].newQuantity),
        Base_price: product[0].default_price,
        Last_Buy_Date: date,
        UnitCount: parseFloat(product[0].newQuantity),
        BaseUnit: product[0].Standard_orderunit,
        PurchaseUnitCount: parseFloat(product[0].newQuantity),
        isKampanj: 0,
      };
      axiosApiInstance
        .post(`${URL}/checkScheduleAndProductExistInList`, checkBeforeSaveData)
        .then((res) => {
          // console.log('Check Before Save',res.data)

          isAlreadyExist = res.data.isAlreadyExist;
          isScheduleExist = res.data.isScheduleExist;

          if (!isAlreadyExist && isScheduleExist) {
            // debugger
            axiosApiInstance
              .put(`${URL}/checkDatesForQtyTesting`, checkDateData)
              .then((res) => {
                // console.log('Check Dates',res.data)
                if (res.status === 200) {
                  say = say + 1;
                  insertIntoTemp = res.data.data.insertIntoTemp;
                  isEndDate = res.data.data.isEndDate;
                  nearestDate = res.data.data.nearestDate;

                  if (!insertIntoTemp && isEndDate && nearestDate) {
                    // title=`Product Added!`
                    // message=`Product added to list `
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      product[0].Descr +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  } else if (insertIntoTemp && !isEndDate && nearestDate) {
                    title = `Är du säker?`;
                    // message=`Product will be added to list on: ${nearestDate}`
                    message +=
                      `\n` +
                      product[0].Descr +
                      ` Produkten kommer att läggas till i listan på: ${nearestDate}`;
                    icon = "warning";
                  } else if (!insertIntoTemp && !isEndDate && nearestDate) {
                    // title=`Product Added!`
                    // message=`Product added to list `
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      product[0].Descr +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  } else if (
                    !insertIntoTemp &&
                    isEndDate &&
                    nearestDate === null
                  ) {
                    // title=`Can not add Product`
                    // message=`Product cannot be added to list End Date is passed for list`
                    title = `Kan inte lägga till produkt`;
                    message +=
                      `\n` +
                      product[0].Descr +
                      ` Produkten kan inte läggas till i listan. Slutdatumet har passerat för listan`;
                    icon = "error";
                  } else {
                    // title='Product Added!'
                    // message=`Product added to list`
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      product[0].Descr +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  }
                  // debugger
                  if (icon === "success") {
                    saveListApiData = {
                      ...saveListApiData,
                      StartingFrom: nearestDate,
                    };
                    saveListApiDatas.push(saveListApiData);
                    isAdd = true;
                  } else if (icon === "warning") {
                    saveListApiData = {
                      ...saveListApiData,
                      StartingFrom: nearestDate,
                    };
                    saveListApiDatas.push(saveListApiData);
                    isAdd = true;
                  } else {
                    // swal(title, message, icon)
                    //   .then(() => {props.setRefTopNav(!props.refresh);});
                  }
                } else {
                  //swal("Något gick fel","","error",{timer:1500,buttons:false})
                }
              });
          } else if (!isAlreadyExist && !isScheduleExist) {
            say = say + 1;
            message +=
              `\n` + product[0].Descr + ` Produkten har lagts till i listan`;
            saveListApiDatas.push(saveListApiData);
            isAdd = true;
          } else {
            say = say + 1;
            //message+=`\nProduct already exists !`
            message += `\n` + product[0].Descr + ` Produkten finns redan!`;
          }

          const result = {
            saveListApiData: saveListApiData,
            message: message,
            title: title,
            icon: icon,
            isAdd: isAdd,
          };
          console.log(`Resolving: ${result}`);
          resolve(result);
        });
    });
  };

  const onAnswerSelected = (
    listNo,
    prodctNo,
    price,
    qty,
    purchased_Unit,
    base_Price,
    pcPerBox,
    UnitCount,
    BaseUnit,
    Kampanj,
    scheduleId,
    listDesc
  ) => {
    setClose(true);
    setLoading(true);
    let insertIntoTemp = "";
    let isEndDate = "";
    let nearestDate = null;
    let isAlreadyExist = "";
    let isScheduleExist = "";
    let message = ``;
    let title = ``;
    let isKampanjPro = Kampanj === true ? 1 : 0;

    let date = new Date().toISOString();

    let checkBeforeSaveData = {
      PK_Listno: listNo,
      Prodno: prodctNo,
      Recipe_unit: purchased_Unit,
      CustNo: props.custNo,
      UserId: User_id,
    };
    let checkDateData = {
      scheduleId: scheduleId,
      userId: User_id,
      custNo: props.custNo,
    };
    let saveListApiData = {
      UserId: User_id,
      PK_Listno: listNo,
      Prodno: prodctNo,
      StartingFrom: nearestDate,
      Recipe_amount: price,
      Recipe_unit: purchased_Unit,
      Active_Product: 1,
      Alternativ_prodno: "",
      Last_Buy_Amount: 0,
      Quantity: qty,
      Base_price: base_Price,
      Last_Buy_Date: date,
      UnitCount: UnitCount,
      BaseUnit: BaseUnit,
      PurchaseUnitCount:
        purchased_Unit === "Styck"
          ? 1
          : purchased_Unit === "Kg"
          ? UnitCount
          : purchased_Unit === "Kart"
          ? UnitCount
          : purchased_Unit === "Kartplus"
          ? UnitCount
          : UnitCount,
      isKampanj: 0,
    };
    axiosApiInstance
      .post(`${URL}/checkScheduleAndProductExistInList`, checkBeforeSaveData)
      .then((res) => {
        // console.log('Check Before Save',res.data)

        isAlreadyExist = res.data.isAlreadyExist;
        isScheduleExist = res.data.isScheduleExist;

        if (!isAlreadyExist && isScheduleExist) {
          // debugger
          axiosApiInstance
            .put(`${URL}/checkDatesForQtyTesting`, checkDateData)
            .then((res) => {
              // console.log('Check Dates',res.data)
              if (res.status === 200) {
                insertIntoTemp = res.data.data.insertIntoTemp;
                isEndDate = res.data.data.isEndDate;
                nearestDate = res.data.data.nearestDate;
                let icon = "";

                if (!insertIntoTemp && isEndDate && nearestDate) {
                  // title=`Product Added!`
                  // message=`Product added to list `
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                } else if (insertIntoTemp && !isEndDate && nearestDate) {
                  title = `Är du säker?`;
                  // message=`Product will be added to list on: ${nearestDate}`
                  message = `Produkten kommer att läggas till i listan på: ${nearestDate}`;
                  icon = "warning";
                } else if (!insertIntoTemp && !isEndDate && nearestDate) {
                  // title=`Product Added!`
                  // message=`Product added to list `
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                } else if (
                  !insertIntoTemp &&
                  isEndDate &&
                  nearestDate === null
                ) {
                  // title=`Can not add Product`
                  // message=`Product cannot be added to list End Date is passed for list`
                  title = `Kan inte lägga till produkt`;
                  message = `Produkten kan inte läggas till i listan. Slutdatumet har passerat för listan`;
                  icon = "error";
                } else {
                  // title='Product Added!'
                  // message=`Product added to list`
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                }
                // debugger
                if (icon === "success") {
                  saveListApiData = {
                    ...saveListApiData,
                    StartingFrom: nearestDate,
                  };
                  axiosApiInstance
                    .post(`${URL}/list_lines`, saveListApiData)
                    .then((res) => {
                      if (res.status === 200) {
                        swal(title, message, icon).then(() => {
                          props.setRefTopNav(!props.refresh);
                        });
                      }
                    });
                } else if (icon === "warning") {
                  saveListApiData = {
                    ...saveListApiData,
                    StartingFrom: nearestDate,
                  };
                  swal(title, message, icon, { buttons: ["Nej", "Ja"] }).then(
                    (res) => {
                      if (res) {
                        axiosApiInstance
                          .post(`${URL}/list_lines`, saveListApiData)
                          .then((res) => {
                            if (res.status === 200) {
                              swal("Produkt tillagd!", message, "success").then(
                                () => {
                                  props.setRefTopNav(!props.refresh);
                                }
                              );
                            }
                          });
                      }
                    }
                  );
                } else {
                  swal(title, message, icon).then(() => {
                    props.setRefTopNav(!props.refresh);
                  });
                }
              } else {
                swal("Något gick fel", "", "error", {
                  timer: 1500,
                  buttons: false,
                });
              }
            });
          setLoading(false);
        } else if (!isAlreadyExist && !isScheduleExist) {
          axiosApiInstance
            .post(`${URL}/list_lines`, saveListApiData)
            .then((res) => {
              // console.log(`AFTER SAVE:::`,res)
              if (res.status === 200) {
                let data = res.data.data;
                if (listDesc) {
                  // swal("List created successfully!", "Product added!", "success").then(
                  swal(
                    "Listan har skapats",
                    "Produkten har lagts till",
                    "success"
                  ).then(() => {
                    props.setRefTopNav(!props.refresh);
                  });
                } else {
                  if (res.data.status === 200) {
                    // swal("Product added!", "", "success")
                    swal("Produkten har lagts till", "", "success").then(() => {
                      props.setRefTopNav(!props.refresh);
                    });
                  } else {
                    // swal("Product already exists !", "", "warning")
                    swal("Produkten finns redan!", "", "warning").then(() => {
                      props.setRefTopNav(!props.refresh);
                    });
                  }
                }
              } else {
                console.log("Error");
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setLoading(false);
        } else {
          swal("Produkten finns redan !", "", "warning").then(() => {
            props.setRefTopNav(!props.refresh);
          });
          setLoading(false);
        }
      });
  };

  const handlemodalClose = () => {
    setShow(!show);
  };

  const handleModalShowHide = () => {
    setShowHide(!showHide);
    setIsAvailable("");
  };
  const handleChangeList = (e) => {
    const { name, value } = e.target;
    setList_Descr(value);
  };
  const clearInput = () => {
    setList_Descr("");
  };

  const classes = useStyles();
  const [show, setShow] = useState(false);
  const acessToken = Auth.getToken();
  const handleClose = () => setShow(false);
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const [count, setCount] = useState([]);
  const [latestData, setLatestData] = useState("");
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1500);
  };

  function saveToCart(product) {
    //  let CustNo = localStorage.getItem("CustomerNo");
    let variable = Number.NaN;
    var kgUnit = 0;
    var stUnit = 0;
    var boxUnit = 0;
    var pcPerBox = 0;
    if (
      product[0].newQuantity === variable ||
      parseFloat(product[0].newQuantity) === 0 ||
      product[0].newQuantity === ""
    ) {
      // swal("Please select Minimum quantity", '', 'info');
      swal("Vänligen välj minsta kvantitet", "", "info");
    } else {
      if (product[0].Standard_orderunit === "Kg") {
        pcPerBox = product[0].Conv_facor_CART;
        if (product[0].purchased_Unit === "Kg") {
          kgUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_KG +
                Number.EPSILON) *
                100
            ) / 100;
          if (kgUnit > product.Max_Quantity && product[0].Max_Quantity !== 0) {
            product[0].stdOrder_Unit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            product[0].stdOrder_Unit = kgUnit;
          }
        }
        if (product[0].purchased_Unit === "Styck") {
          kgUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_ST +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            kgUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0
          ) {
            product[0].stdOrder_Unit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            product[0].stdOrder_Unit = kgUnit;
          }
        }
        if (product[0].purchased_Unit === "Kart") {
          kgUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_CART +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            kgUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0
          ) {
            product[0].stdOrder_Unit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            product[0].stdOrder_Unit = kgUnit;
          }
        }
      }
      if (product[0].Standard_orderunit === "Styck") {
        pcPerBox = product[0].Conv_facor_CART;
        if (product[0].purchased_Unit === "Kg") {
          stUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_KG +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            stUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
        if (product[0].purchased_Unit === "Styck") {
          stUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_ST +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            stUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
        if (product[0].purchased_Unit === "Kart") {
          stUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_CART +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            stUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
      }
      if (product[0].Standard_orderunit === "Kart") {
        pcPerBox = product[0].Conv_facor_CART;
        if (product[0].purchased_Unit === "Kg") {
          boxUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_KG +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            boxUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product[0].purchased_Unit === "Styck") {
          boxUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_ST +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            boxUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product[0].purchased_Unit === "Kart") {
          boxUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_CART +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            boxUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
      }
      if (product[0].Standard_orderunit === "Kartplus") {
        pcPerBox = product[0].Conv_facor_CART;
        if (product[0].purchased_Unit === "Kg") {
          boxUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_KG +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            boxUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product[0].purchased_Unit === "Styck") {
          boxUnit =
            Math.round(
              (product[0].newQuantity * product[0].Conv_facor_ST +
                Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            boxUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product[0].purchased_Unit === "Kartplus") {
          boxUnit =
            Math.round((product[0].newQuantity * 1 + Number.EPSILON) * 100) /
            100;
          if (
            boxUnit > product[0].Max_Quantity &&
            product[0].Max_Quantity !== 0 &&
            product[0].Max_Quantity !== null
          ) {
            boxUnit = product[0].Max_Quantity;
            product[0].newQuantity = product[0].Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
      }
      // if (product[0].purchased_Unit === 'Kg') {
      //     if (product[0].Standard_orderunit === 'KG') {
      //         var unitfactor = Math.round((product[0].newQuantity * product[0].Conv_facor_KG + Number.EPSILON) * 100) / 100;
      //     }
      //     else {
      //         unitfactor = Math.ceil(product[0].newQuantity * product[0].Conv_facor_KG);
      //     }
      //     product[0].stdOrder_Unit = unitfactor;
      // }
      // else if (product[0].purchased_Unit === 'Styck') {
      //     if (product[0].Standard_orderunit === 'KG') {
      //         var unitfactor = Math.round((product[0].newQuantity * product[0].Conv_facor_ST + Number.EPSILON) * 100) / 100;
      //         product[0].stdOrder_Unit = unitfactor;
      //     }
      //     else {
      //         unitfactor = Math.ceil(product[0].newQuantity * product[0].Conv_facor_ST);
      //         var pcPerBox = (1 / product[0].Conv_facor_ST);
      //         // if(product[0].Max_Quantity !== null){

      //         //    // 1/props.products[0].Conv_facor_ST
      //         //     if(unitfactor > data){
      //         //         alert("The Maximum limit for this product is"+' '+product[0].Max_Quantity);
      //         //         product[0].stdOrder_Unit = product[0].Max_Quantity;
      //         //         product[0].newQuantity = data;
      //         //     }
      //         //     else{
      //         //         product[0].stdOrder_Unit = unitfactor;
      //         //     }
      //         product[0].stdOrder_Unit = unitfactor;
      //     }

      // }
      // else if (product[0].purchased_Unit === 'Kart') {
      //     if (product[0].Standard_orderunit === 'KG') {
      //         var unitfactor = Math.round((product[0].newQuantity * product[0].Conv_facor_CART + Number.EPSILON) * 100) / 100;
      //         product[0].stdOrder_Unit = unitfactor;
      //         pcPerBox = product[0].Conv_facor_CART;
      //     }
      //     else {
      //         unitfactor = Math.ceil(product[0].newQuantity * product[0].Conv_facor_CART);
      //         product[0].Standard_orderunit === 'Styck' ? pcPerBox = product[0].Conv_facor_CART : pcPerBox = (1 / product[0].Conv_facor_ST);
      //         if (product[0].Max_Quantity !== null && product[0].Max_Quantity !== 0) {
      //             if (unitfactor > product[0].Max_Quantity && product[0].Max_Quantity > 0) {
      //                 alert("The Maximum limit for this product is" + ' ' + product[0].Max_Quantity);
      //                 product[0].stdOrder_Unit = product[0].Max_Quantity;
      //                 product[0].newQuantity = product[0].Max_Quantity;
      //             }
      //             else {
      //                 product[0].stdOrder_Unit = unitfactor;
      //             }
      //         }
      //         else {

      //             product[0].stdOrder_Unit = unitfactor;
      //         }

      //     }
      // }

      // debugger
      if (props.productType === "Kampanj") {
        axiosApiInstance
          .get(
            `${URL}/getKampanjPriceFromPriceTable/${product[0].Prodno}/${props.custNo}`
          )
          .then((res) => {
            if (res.status === 200) {
              var customerWisePrice = res.data.data;
              var newPrice =
                product[0].purchased_Unit === "Kg"
                  ? customerWisePrice.Comp_Price
                  : product[0].purchased_Unit === "Styck"
                  ? customerWisePrice.Price_broken
                  : customerWisePrice.Price_cart;
              let prePrice =
                product[0].purchased_Unit === "Kg"
                  ? product[0].Comp_Price
                  : product[0].purchased_Unit === "Styck"
                  ? product[0].Price_broken
                  : product[0].Price_cart;
              let finalprice;
              if (prePrice === product[0].unite_price) {
                finalprice = newPrice * product[0].newQuantity;
              } else {
                finalprice =
                  newPrice *
                  customerWisePrice.Cart_to_pieces *
                  product[0].newQuantity;
              }
              let productNo =
                product[0].ProdNo === undefined
                  ? product[0].Prodno.toString()
                  : product[0].ProdNo.toString();
              let Cartdata = {
                Product_name: product[0].Descr,
                Product_id:
                  product[0].ProdNo === undefined
                    ? product[0].Prodno.toString()
                    : product[0].ProdNo,
                pcPerBox: pcPerBox,
                BoxUnit: boxUnit,
                StUnit: stUnit,
                KgUnit: product[0].stdOrder_Unit,
                // "orderWeight_Unit": product[0].stdOrder_Unit,
                purchased_Unit: product[0].purchased_Unit,
                baseUnit: product[0].Standard_orderunit,
                Price: finalprice,
                Qty: parseFloat(product[0].newQuantity),
                Base_price: newPrice,
                Base_qty: product[0].Min_Quantity,
                User_id: Auth.getUserId(),
                Tax:
                  product[0].Tax_rate === null ||
                  product[0].Tax_rate === undefined
                    ? 0
                    : product[0].Tax_rate,
                CustNo: props.custNo,
                isKampanj: 1,
              };
              axiosApiInstance
                .post(`${URL}/addToCart`, Cartdata)
                .then((res) => {
                  if (res.status === 200) {
                    let data = res.data.data;
                    data &&
                      data.map((pro) => {
                        if (
                          pro.Product_id === parseInt(productNo) &&
                          product[0].purchased_Unit === pro.purchased_Unit
                        ) {
                          setLatestData(pro);
                        }
                      });
                    let totalOrder = 0;
                    for (let i = 0; i < data.length; i++) {
                      if (data[i] !== undefined) {
                        totalOrder +=
                          ((data[i].Price * data[i].Tax) / 100 +
                            data[i].Price) *
                          data[i].Qty;
                      }
                    }
                    setCount(data.length);
                    props.setCartCount(data.length);
                    props.setTotalPrice(totalOrder);
                    var cartCount = props.cartCount;
                    //var cartCount = parseInt(localStorage.getItem('cartCount'));
                    let Count = data;
                    let value = 0;
                    let Tax = 0;
                    for (let i = 0; i < Count.length; i++) {
                      value += Count[i].Price;
                      Tax += Count[i].Tax;
                      // setCount(cartCount + Count.length);
                      // props.setCartCount(cartCount + Count.length);
                      handleShow();
                      // localStorage.setItem('cartCount',cartCount+Count.length);
                    }
                  } else {
                    console.log("else");
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let Cartdata = {
          Product_name: product[0].Descr,
          Product_id: product[0].ProdNo,
          pcPerBox: pcPerBox,
          BoxUnit: boxUnit,
          StUnit: stUnit,
          KgUnit: product[0].stdOrder_Unit,
          // "orderWeight_Unit": product[0].stdOrder_Unit,
          purchased_Unit: product[0].purchased_Unit,
          baseUnit: product[0].Standard_orderunit,
          Price: product[0].newPrice * product[0].newQuantity,
          Qty: parseFloat(product[0].newQuantity),
          Base_price: product[0].default_price,
          Base_qty: product[0].Min_Quantity,
          User_id: Auth.getUserId(),
          Tax: product[0].Tax_rate === null ? 0 : product[0].Tax_rate,
          CustNo: props.custNo,
          isKampanj: 0,
        };
        axiosApiInstance
          .post(`${URL}/addToCart`, Cartdata)
          .then((res) => {
            setShow(true);
            if (res.status === 200) {
              let data = res.data.data;
              data &&
                data.map((pro) => {
                  if (
                    pro.Product_id === parseInt(product[0].ProdNo) &&
                    product[0].purchased_Unit === pro.purchased_Unit
                  ) {
                    setLatestData(pro);
                  }
                });
              let totalOrder = 0;
              for (let i = 0; i < data.length; i++) {
                if (data[i] !== undefined) {
                  totalOrder +=
                    ((data[i].Price * data[i].Tax) / 100 + data[i].Price) *
                    data[i].Qty;
                }
              }
              setCount(data.length);
              props.setCartCount(data.length);
              props.setTotalPrice(totalOrder);
              var cartCount = props.cartCount;
              let Count = data;
              let value = 0;
              let Tax = 0;
              for (let i = 0; i < Count.length; i++) {
                value += Count[i].Price;
                Tax += Count[i].Tax;
                // setCount(cartCount + Count.length);
                // props.setCartCount(cartCount + Count.length);
                handleShow();
              }
            } else {
              console.log("else");
            }
          })
          .catch((err) => {
            console.log(err);
            // swal('something went wrong',"","info")
            swal("Något gick fel", "", "info");
          });
      }
    }
  }
  return (
    <Card className="p-4">
      {props.products.length > 0 ? (
        <Row>
          <Col md={1}></Col>
          <Col md={5} className="productDetailContentWp">
            <div className="productDeatilWp">
              <img
                src={
                  props.products[0].Large_Img === ""
                    ? Breadimg
                    : props.products[0].Large_Img
                }
              />
            </div>
          </Col>
          {props.products[0].Price_type_name === undefined ? null : (
            <span className="KampanjSpan">
              {props.products[0].Price_type_name}
            </span>
          )}

          {acessToken ? (
            <span className="SavetoListSpan">
              <SaveListModal
                close={close}
                CreatehandleSubmit={CreatehandleSubmit}
                purchased_Unit={props.products[0].purchased_Unit}
                productNo={props.products[0].ProdNo}
                Qty={parseFloat(props.products[0].newQuantity)}
                price={
                  props.products[0].newPrice * props.products[0].newQuantity
                }
                pcPerBox={props.products[0].Standard_orderunit}
                onAnswerSelected={onAnswerSelected}
                handleChangeList={handleChangeList}
                List_Descr={List_Descr}
                User_id={User_id}
                // DataList={DataList}
                options={options}
                getList={getList}
                UnitCount={parseFloat(props.products[0].newQuantity)}
                BaseUnit={props.products[0].Standard_orderunit}
                clearInput={clearInput}
                Kampanj={0}
              />
            </span>
          ) : null}
          <Col md={5}>
            <Row>
              <Col className="ProductDetailColWp">
                <h4 className="RefNo text-center">
                  Produktnr:{props.products[0].ProdNo}
                </h4>
                <h4 className="RefNo text-center">
                  EAN:{props.products[0].EAN_product}
                </h4>
                {/* <CardText className="proddetailSubText">Coca-Cola Zero Burk</CardText>
                        <CardText  className="proddetailSubText">Coca-Cola  Zero 33 cl</CardText> */}
                <h3 className=" text-center proddetailSubText">
                  {props.products[0].Descr}
                </h3>
                <h4 className="text-center  proddetailSubText">
                  {props.products[0].BrandName}{" "}
                  {props.products[0].Size_Contains}
                </h4>

                {props.products[0].product_status === "3" ? (
                  <span className="svara-bestall-detailpage">
                    Beställningsvara
                    <span className="svara-bestall-detailpage-hovertooltip">
                      Vanligt vis är ledtiden 2-3 dagar för att leverera
                      beställningsvaror. <br />
                      Kontakta kontoret för mer information
                    </span>
                  </span>
                ) : null}
                {
                  <p
                    className="  validTxt text-center mt-2 mb-2"
                    style={{ color: "#222", fontWeight: "600" }}
                  >
                    {props.productType === "Product" && props.custNo !== 0 ? (
                      <span className="pricespan">
                        {props.products[0].newPrice === undefined
                          ? 0
                          : props.products[0].newPrice.toFixed(2)}{" "}
                        Kr
                        {/*  / {(props.products[0].Standard_orderunit === "Kartplus" || props.products[0].Standard_orderunit === "Kart")  ? ' kolli': (props.products[0].Standard_orderunit === "Kg") && props.products[0].comp_unit !== 'Liter' ? 'Kg' : (props.products[0].Standard_orderunit === "Kg") && props.products[0].comp_unit === 'Liter' ? 'l' : props.products[0].Standard_orderunit === "Styck" ? 'st' : 'kolli'} */}
                      </span>
                    ) : null}
                    {props.productType === "Kampanj" ? (
                      <span className="pricespan">
                        {props.products[0].newPrice === undefined
                          ? 0
                          : props.products[0].newPrice.toFixed(2)}{" "}
                        Kr
                        {/*  / {(props.products[0].Standard_orderunit === "Kg") && props.products[0].comp_unit !== 'Liter' ? 'Kg' : (props.products[0].Standard_orderunit === "Kg") && props.products[0].comp_unit === 'Liter' ? 'l' : props.products[0].Standard_orderunit === "Styck" ? 'st' : 'kolli'} */}
                      </span>
                    ) : null}
                  </p>
                }
                {props.products[0].Comp_Price === undefined ||
                isNaN(props.products[0].Comp_Price) ? null : (
                  <CardText className="Compartiveprice">
                    Jmf.pris :{" "}
                    {props.products[0].purchased_Unit === "Kart"
                      ? props.products[0].box_com_cart_price.toFixed(2)
                      : props.products[0].Comp_Price.toFixed(2)}{" "}
                    / {props.products[0].comp_unit}
                  </CardText>
                )}
                {/* <CardText className="priceValidText mb-2">Ord.price : SEK 00.00 / kg</CardText> */}
                {props.products[0].Valid_date !== undefined ? (
                  <CardText className="priceValidText mb-2">
                    Priset gäller t.o.m{" "}
                    {moment(props.products[0].Valid_date).format("DD MM YYYY")}
                  </CardText>
                ) : null}
                {acessToken ? (
                  <>
                    <CardText className="text-center LadaSelectWp">
                      {(props.products[0].Conv_facor_CART === null ||
                        props.products[0].Conv_facor_CART === 0 ||
                        props.products[0].Conv_facor_CART === undefined) &&
                      (props.products[0].Conv_facor_ST === null ||
                        props.products[0].Conv_facor_ST === 0 ||
                        props.products[0].Conv_facor_ST === undefined) &&
                      (props.products[0].Conv_facor_KG === null ||
                        props.products[0].Conv_facor_KG === 0 ||
                        props.products[0].Conv_facor_KG ===
                          undefined) ? null : (
                        <select
                          className="minimal"
                          name="select"
                          id="exampleSelect"
                          value={props.products[0].default_value}
                          onChange={(e) =>
                            props.onChangeUnit(
                              e,
                              0,
                              props.products[0].Standard_orderunit,
                              props.products[0].Comp_Price,
                              props.products[0].Price_broken,
                              props.products[0].Price_cart,
                              props.products[0].Conv_facor_KG,
                              props.products[0].Conv_facor_ST,
                              props.products[0].Conv_facor_CART,
                              props.products[0].default_price
                            )
                          }
                          disabled={
                            (props.products[0].Conv_facor_CART === null ||
                              props.products[0].Conv_facor_CART === 0) &&
                            (props.products[0].Conv_facor_KG === null ||
                              props.products[0].Conv_facor_KG === 0)
                              ? true
                              : (props.products[0].Conv_facor_ST === null ||
                                  props.products[0].Conv_facor_ST === 0) &&
                                (props.products[0].Conv_facor_KG === null ||
                                  props.products[0].Conv_facor_KG === 0)
                              ? true
                              : (props.products[0].Conv_facor_ST === null ||
                                  props.products[0].Conv_facor_ST === 0) &&
                                (props.products[0].Conv_facor_CART === null ||
                                  props.products[0].Conv_facor_CART === 0)
                              ? true
                              : false
                          }
                        >
                          {props.products[0].Conv_facor_KG !== 0 &&
                          props.products[0].Conv_facor_KG !== null ? (
                            <option
                              value={props.products[0].Conv_facor_KG}
                              keyid="Kg"
                            >
                              {props.products[0].comp_unit === "Liter"
                                ? `Liter`
                                : `KILO`}
                            </option>
                          ) : null}
                          {props.products[0].Conv_facor_ST !== 0 &&
                          props.products[0].Conv_facor_ST !== null ? (
                            <option
                              value={props.products[0].Conv_facor_ST}
                              keyid="Styck"
                            >{`1 ST`}</option>
                          ) : null}
                          {props.products[0].Conv_facor_CART !== 0 &&
                          props.products[0].Conv_facor_CART !== null ? (
                            <option value={5} keyid="Kart">
                              Kolli (
                              {(props.products[0].Standard_orderunit ===
                                "Kart" ||
                                props.products[0].Standard_orderunit ===
                                  "Kartplus") &&
                              props.products[0].Conv_facor_ST === 0
                                ? props.products[0].Conv_facor_CART + " " + "ST"
                                : (props.products[0].Standard_orderunit ===
                                    "Kart" ||
                                    props.products[0].Standard_orderunit ===
                                      "Kartplus") &&
                                  props.products[0].Conv_facor_ST !== 0
                                ? props.products[0].Conv_facor_CART /
                                    props.products[0].Conv_facor_ST +
                                  " " +
                                  "ST"
                                : props.products[0].Standard_orderunit ===
                                  "Styck"
                                ? props.products[0].Conv_facor_CART + " " + "ST"
                                : props.products[0].Standard_orderunit === "Kg"
                                ? props.products[0].Conv_facor_CART + " " + "KG"
                                : null}
                              )
                            </option>
                          ) : null}
                        </select>
                      )}
                    </CardText>
                    <CardText className="text-center">
                      <ListGroup
                        horizontal
                        className="CounterListWp text-center"
                        style={{ margin: "0 auto" }}
                      >
                        <ListGroup.Item
                          disabled={
                            props.products[0].Min_Quantity ===
                            props.products[0].newQuantity
                              ? true
                              : false
                          }
                          onClick={() =>
                            props.DecreaseItem(
                              props.products[0].newQuantity,
                              0,
                              props.products[0].newPrice,
                              props.products[0].Min_Quantity
                            )
                          }
                        >
                          -
                        </ListGroup.Item>
                        {props.products[0].purchased_Unit === "Kg" &&
                        props.products[0].comp_unit !== "Liter" ? (
                          <Input
                            type={"text"}
                            className="InputincrementClass"
                            // id="depositedAmount"
                            pattern="[+-]?\d+([.,]\d*)?"
                            id={0}
                            data-min="1"
                            onChange={(e) =>
                              props.onChangeQty(
                                e,
                                0,
                                props.products[0].newPrice,
                                props.products[0].Min_Quantity,
                                props.products[0].purchased_Unit,
                                props.products[0].Max_Quantity
                              )
                            }
                            value={
                              props.products[0].hiddenText
                                ? props.products[0].newQuantity + " KG"
                                : props.products[0].newQuantity
                            }
                            onClick={(e) => props.onclcikEvent(e, 0)}
                            onBlur={(e) => props.onMouseLeaveEvent(e, 0)}
                          />
                        ) : props.products[0].purchased_Unit === "Kg" &&
                          props.products[0].comp_unit === "Liter" ? (
                          <Input
                            type={"text"}
                            className="InputincrementClass"
                            // id="depositedAmount"
                            pattern="[+-]?\d+([.,]\d*)?"
                            id={0}
                            data-min="1"
                            onChange={(e) =>
                              props.onChangeQty(
                                e,
                                0,
                                props.products[0].newPrice,
                                props.products[0].Min_Quantity,
                                props.products[0].purchased_Unit,
                                props.products[0].Max_Quantity
                              )
                            }
                            value={
                              props.products[0].hiddenText
                                ? props.products[0].newQuantity + " L"
                                : props.products[0].newQuantity
                            }
                            onClick={(e) => props.onclcikEvent(e, 0)}
                            onBlur={(e) => props.onMouseLeaveEvent(e, 0)}
                          />
                        ) : props.products[0].purchased_Unit === "Kart" ||
                          props.products[0].purchased_Unit === "Kartplus" ? (
                          <Input
                            type={"text"}
                            className="InputincrementClass"
                            // id="depositedAmount"
                            pattern="[+-]?\d+([.,]\d*)?"
                            id={0}
                            data-min="1"
                            onChange={(e) =>
                              props.onChangeQty(
                                e,
                                0,
                                props.products[0].newPrice,
                                props.products[0].Min_Quantity,
                                props.products[0].purchased_Unit,
                                props.products[0].Max_Quantity
                              )
                            }
                            value={
                              props.products[0].hiddenText
                                ? props.products[0].newQuantity + " KOLLI"
                                : props.products[0].newQuantity
                            }
                            onClick={(e) => props.onclcikEvent(e, 0)}
                            onBlur={(e) => props.onMouseLeaveEvent(e, 0)}
                          />
                        ) : (
                          <Input
                            type="text"
                            data-min="1"
                            autoComplete="off"
                            className="InputincrementClass"
                            size="1"
                            name={props.products[0].newQuantity}
                            data-max="999"
                            id={0}
                            onChange={(e) =>
                              props.onChangeQty(
                                e,
                                0,
                                props.products[0].newPrice,
                                props.products[0].Min_Quantity,
                                props.products[0].purchased_Unit,
                                props.products[0].Max_Quantity
                              )
                            }
                            value={
                              props.products[0].hiddenText
                                ? props.products[0].newQuantity + " ST"
                                : props.products[0].newQuantity
                            }
                            onClick={(e) => props.onclcikEvent(e, 0)}
                            onBlur={(e) => props.onMouseLeaveEvent(e, 0)}
                          />
                        )}
                        <ListGroup.Item
                          onClick={() =>
                            props.IncrementItem(
                              props.products[0].newQuantity,
                              0,
                              props.products[0].newPrice,
                              props.products[0].Min_Quantity,
                              props.products[0].Max_Quantity,
                              props.products[0].ProdNo
                            )
                          }
                        >
                          +
                        </ListGroup.Item>
                      </ListGroup>
                    </CardText>
                  </>
                ) : null}
                {acessToken ? (
                  <Button
                    className="w-100 btnAddShop mt-1"
                    onClick={() => {
                      saveToCart(props.products);
                    }}
                  >
                    {/* Add to Cart */}
                    LÄGG I VARUKORGEN
                  </Button>
                ) : (
                  <Button
                    className="w-100 btnAddShop mt-1"
                    onClick={() => handleShowLogin()}
                  >
                    {/* LOGIN TO SHOP */}
                    LÄGG TILL I KUNDVAGN
                  </Button>
                )}
              </Col>
            </Row>

            <Row>
              <Col className="mt-4">
                {props.products[0].ingredients &&
                props.products[0].ingredients.length !== 0 ? (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Ingredienser
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <Row>
                          <Col>
                            <h3 className="IngredientText text-left">
                              {props.products[0].ingredients}
                            </h3>
                          </Col>
                        </Row>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                <Accordion
                  defaultExpanded={
                    props.products[0].Sales_information2 !== null &&
                    props.products[0].Sales_information2 !== ""
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <p>
                      {/* Description */}
                      Beskrivning
                    </p>
                    <Typography className={classes.heading}></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Row>
                        <Col>
                          <h3 className="beskrivning-text-sales">
                            {/* {props.products[0].Information_field1} */}
                            {props.products[0].Sales_information2}
                          </h3>
                        </Col>
                      </Row>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {" "}
                    <p>
                      {/* General Product Info */}
                      Generell Produkt Information
                    </p>
                    <Typography className={classes.heading}></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Row>
                        <Col>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>
                                  {/* Type */}
                                  Typ
                                </th>
                                <th>
                                  {/* Details */}
                                  Detaljer
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {/* Class */}
                                  Klass
                                </td>
                                <td>{props.products[0].Class}</td>
                              </tr>
                              <tr>
                                <td>
                                  {/* Brand Name */}
                                  Varumärke
                                </td>
                                <td>{props.products[0].BrandName}</td>
                              </tr>
                              <tr>
                                <td>
                                  {/* EAN Cart */}
                                  EAN nummer
                                </td>
                                <td>{props.products[0].EAN_Cart}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <p>
                      {/* Other */}
                      Övrigt
                    </p>
                    <Typography className={classes.heading}> </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Row>
                        <Col>
                          <h3 className="IngredientText text-left">
                            {/* Standard Order Unit */}
                            Standrad Order Enhet
                            <span> {props.products[0].Standard_orderunit}</span>
                          </h3>
                          {/* <h3 className="IngredientText text-left">Minimum sotrage temperature <span>0°C</span></h3> */}
                        </Col>
                      </Row>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            {/* <ProductSelectionAccordion products={products}  /> */}
          </Col>
          <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="ModaladdProdHeader">
                {/* Product successfully added to your shopping cart */}
                Produkten har lagts till i varukorgen
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={7}>
                  <Row>
                    <Col md={5} className="ProdListImgWp">
                      <img
                        src={
                          latestData.Pic_Small === ""
                            ? Breadimg
                            : latestData.Pic_Small
                        }
                        alt=""
                      />
                    </Col>
                    <Col md={5}>
                      <h6 className="headingText">{latestData.Product_name}</h6>
                      <p className="PriceText"> Pris : kr {latestData.Price}</p>
                      <p className="QtyText">
                        {/* Quantity/Weight : */}
                        Antal/Vikt : {latestData.Qty} &nbsp;
                        {latestData.purchased_Unit === "Styck"
                          ? "ST"
                          : latestData.purchased_Unit === "Kart"
                          ? "Kolli"
                          : // + ((latestData.baseUnit === 'Kg' || latestData.baseUnit === 'Kilo') ? latestData.pcPerBox + 'Kg' : latestData.pcPerBox + 'ST')
                          latestData.purchased_Unit === "Kg" ||
                            latestData.baseUnit === "Kilo"
                          ? "KG"
                          : null}
                        {/* /{latestData.orderWeight_Unit}{latestData.baseUnit} */}
                      </p>
                      {/* Quantity/Weight : {latestData.Qty}{latestData.purchased_Unit === "Styck" ? "ST" : latestData.purchased_Unit === "Kart" ? "Box" + (latestData.pcPerBox + ' pcs') : latestData.purchased_Unit === "Kart" ? "Weight" : null}/{latestData.orderWeight_Unit}{latestData.baseUnit}</p> */}
                      {/* <p className="QtyText">Quantity : {latestData.Qty}</p> */}
                    </Col>
                    <Col md={1} className="">
                      <div className="verticalLine"></div>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <Row>
                    <Col className="cartContent">
                      <p class="cart-products-count">
                        Det finns {count} artiklar i din kundvagn.
                      </p>
                      {/* <p><strong>Total products:</strong>&nbsp;Kr {total.toLocaleString('en-US', {maximumFractionDigits:2})}</p> */}
                      {/* <p><strong>Total shipping:</strong>&nbsp;$0.00 </p>
                                        <p><strong>Taxes</strong>&nbsp;${tax}</p> */}
                      {/* <p><strong>Total:</strong>&nbsp;Kr {(total + tax).toLocaleString('en-US', {maximumFractionDigits:2})} (tax excl.)</p> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {count === 1 ? (
                <Row>
                  <Col className="my-auto">
                    <div className="cart-content-btn text-center mt-2 mb-2">
                      <Link to="/ProductOverview">
                        <Button>Fortsätt handla</Button>
                      </Link>
                      <Link to="/cart">
                        <Button className="ml-2 viewBtn">
                          <CheckIcon className="ChekiconWhite" />
                          Fortsätt till utcheckningen
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Modal.Body>
          </Modal>
          <LoginModal
            show={showLogin}
            onHide={handleCloseLogin}
            productId={props.products[0].ProdNo}
          />
        </Row>
      ) : null}
    </Card>
  );
};
const mapStoreToProps = (state) => {
  return {
    cartCount: state.customer.count,
    refresh: state.customer.refresh,
    // customerNo: state.customer.customerNo
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCartCount: (count) => dispatch(setCartCount(count)),
  setTotalPrice: (price) => dispatch(setTotalPrice(price)),
  setRefTopNav: (refresh) => dispatch(setRefTopNav(refresh)),
});

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(ProductDetailContent)
);
