import React, { useState } from "react";
import axios from "axios";
import _, { set } from "lodash";
import { Row, Col, Container } from "reactstrap";

import SideBar from "../../components/header/components/Sidebar";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ProductListOverview from "./Components/ProductListOverview";
import Cackebg from "../../assets/images/gkbannerproduct.jpg";

import { URL } from "../../ServerCall/Config";
import Auth from "../../ServerCall/Auth";

import { connect } from "react-redux";
import {
  setProducts,
  setSearchProduct,
  setCustomer,
} from "../../redux/action/customerAction";

const config = {
  headers: {
    "content-type": "application/json",
  },
};

const ProductOverview = (props) => {
  const [productImage, setProductImage] = React.useState("");
  const [bannerText, setBannerText] = React.useState("");
  const [pageToLoad, setPageToLoad] = React.useState(1);
  const [customerNo, setCustomerNo] = React.useState(0);
  const [sortPage, setSortPage] = React.useState(1);
  const [page, setPage] = React.useState(1);

  const [products, setProduct] = React.useState([]);
  const [totalProducts, setTotalProducts] = React.useState(null);
  const [condition, setCondition] = React.useState(true);

  const [allProductLabel, setAllProductLabel] = useState([]);
  const [allpriceTypes, setAllpriceTypes] = useState([]);

  const [productFilter, setProductFilter] = useState([]);
  const [selectedOpt, setSelectedOpt] = useState([]);

  const [priceType, setPriceType] = useState([]);
  const [selPriceType, setSelPriceType] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showLabel, setShowLabel] = useState(false);
  const [showProductFilterLabel, setShowProductFilterLabel] = useState(false);
  const [showPriceTypeLabel, setShowPriceTypeLabel] = useState(false);

  const acessToken = Auth.getToken();
  const User_id = Auth.getUserId();
  const path = window.location.pathname;

  React.useEffect(() => {
    var user_token = JSON.parse(window.localStorage.getItem("preUrl"));
    if (
      props.location.state &&
      props.location.state.searchDetail !== undefined &&
      props.location.state.searchDetail !== "" &&
      props.location.state.ItemId === undefined
    ) {
      props.location.state.default = "searchId";
    }
    if (
      props.location.state &&
      (props.location.state.searchDetail === undefined ||
        props.location.state.searchDetail === "") &&
      props.location.state.ItemId === undefined &&
      props.location.state.subCategory === undefined &&
      props.location.state.childCategory === undefined
    ) {
      props.location.state.default = "defaultId";
    }
    if (
      user_token !== null &&
      props.location.state !== undefined &&
      user_token.state.productType !== "Kampanj"
    ) {
      if (
        user_token.state.customerNo !== "" &&
        props.location.state.searchDetail === undefined
      ) {
        setCustomerNo(user_token.state.customerNo);
        setProduct(user_token.state.Products);
        setTotalProducts(user_token.state.totalProducts);
        setPageToLoad(user_token.state.pageToLoad);
        setCondition(false);
      } else {
        let CustNo = props.customerNo;
        props.setCustomer(CustNo);
        if (CustNo === null) {
          setCustomerNo(0);
        } else {
          setCustomerNo(CustNo);
        }
        if (props.location.state !== undefined) {
          var ItemId = props.location.state.ItemId;
          var img = props.location.state.image;
          var titleHead = props.location.state.titleHead;
          var defaultId = props.location.state.default;
          var searchName = props.location.state.searchDetail;
          var subCat = props.location.state.subCategory;
          var childCat = props.location.state.childCategory;

          props.setSearchProduct(searchName);
          setProductImage(img);
          // setBannerText(titleHead);
          if (subCat !== undefined) {
            setBannerText(subCat);
            if (childCat !== undefined) {
              setBannerText(childCat);
            }
          } else {
            setBannerText(titleHead);
          }
        }
        var initial_url = window.location.href;
        var url = initial_url.split("?");
        if (url[1] !== undefined) {
          var Id = url[1];
        } else {
          var Id = props.location.search.slice(1);
        }
        getAllProducts(Id, ItemId, searchName, defaultId);
      }
    } else {
      let CustNo = props.customerNo;
      props.setCustomer(CustNo);
      if (CustNo === null) {
        setCustomerNo(0);
      } else {
        setCustomerNo(CustNo);
      }
      if (props.location.state !== undefined) {
        var ItemId = props.location.state.ItemId;
        var img = props.location.state.image;
        var titleHead = props.location.state.titleHead;
        var defaultId = props.location.state.default;
        var searchName = props.location.state.searchDetail;
        var subCat = props.location.state.subCategory;
        var childCat = props.location.state.childCategory;

        props.setSearchProduct(searchName);
        setProductImage(img);

        if (subCat !== undefined) {
          setBannerText(subCat);
          if (childCat !== undefined) {
            setBannerText(childCat);
          }
        } else {
          setBannerText(titleHead);
        }
      }
      var initial_url = window.location.href;
      var url = initial_url.split("?");
      if (url[1] !== undefined) {
        var Id = url[1];
      } else {
        var Id = props.location.search.slice(1);
      }
      getAllProducts(Id, ItemId, searchName, defaultId);
    }
    window.localStorage.removeItem("preUrl");
  }, [props.customerNo, pageToLoad, props.location.state, page, sortPage]);

  React.useEffect(async () => {
    setDropdownValues();
    handleClearFilter();
  }, [props.customerNo, props.location.state]);

  const allProducts = () => {
    let newProductFilter = productFilter;
    let newPriceType = priceType;
    let isProdFilSelc = newProductFilter.filter((data) => data.clear === false);
    let isPriceTypeSelc = newPriceType.filter((data) => data.clear === false);
    if (
      isProdFilSelc.length === newProductFilter.length &&
      isPriceTypeSelc.length === newPriceType.length
    ) {
      var user_token = JSON.parse(window.localStorage.getItem("preUrl"));
      if (
        props.location.state &&
        props.location.state.searchDetail !== undefined &&
        props.location.state.searchDetail !== "" &&
        props.location.state.ItemId === undefined
      ) {
        props.location.state.default = "searchId";
      }
      if (
        props.location.state &&
        (props.location.state.searchDetail === undefined ||
          props.location.state.searchDetail === "") &&
        props.location.state.ItemId === undefined &&
        props.location.state.subCategory === undefined &&
        props.location.state.childCategory === undefined
      ) {
        props.location.state.default = "defaultId";
      }
      if (
        user_token !== null &&
        props.location.state !== undefined &&
        user_token.state.productType !== "Kampanj"
      ) {
        if (
          user_token.state.customerNo !== "" &&
          props.location.state.searchDetail === undefined
        ) {
          setCustomerNo(user_token.state.customerNo);
          setProduct(user_token.state.Products);
          setTotalProducts(user_token.state.totalProducts);
          setPageToLoad(user_token.state.pageToLoad);
          setCondition(false);
        } else {
          let CustNo = props.customerNo;
          props.setCustomer(CustNo);
          if (CustNo === null) {
            setCustomerNo(0);
          } else {
            setCustomerNo(CustNo);
          }
          if (props.location.state !== undefined) {
            var ItemId = props.location.state.ItemId;
            var img = props.location.state.image;
            var titleHead = props.location.state.titleHead;
            var defaultId = props.location.state.default;
            var searchName = props.location.state.searchDetail;
            var subCat = props.location.state.subCategory;
            var childCat = props.location.state.childCategory;

            props.setSearchProduct(searchName);
            setProductImage(img);
            // setBannerText(titleHead);
            if (subCat !== undefined) {
              setBannerText(subCat);
              if (childCat !== undefined) {
                setBannerText(childCat);
              }
            } else {
              setBannerText(titleHead);
            }
          }
          var initial_url = window.location.href;
          var url = initial_url.split("?");
          if (url[1] !== undefined) {
            var Id = url[1];
          } else {
            var Id = props.location.search.slice(1);
          }
          getAllProducts(Id, ItemId, searchName, defaultId);
        }
      } else {
        let CustNo = props.customerNo;
        props.setCustomer(CustNo);
        if (CustNo === null) {
          setCustomerNo(0);
        } else {
          setCustomerNo(CustNo);
        }
        if (props.location.state !== undefined) {
          var ItemId = props.location.state.ItemId;
          var img = props.location.state.image;
          var titleHead = props.location.state.titleHead;
          var defaultId = props.location.state.default;
          var searchName = props.location.state.searchDetail;
          var subCat = props.location.state.subCategory;
          var childCat = props.location.state.childCategory;

          props.setSearchProduct(searchName);
          setProductImage(img);

          if (subCat !== undefined) {
            setBannerText(subCat);
            if (childCat !== undefined) {
              setBannerText(childCat);
            }
          } else {
            setBannerText(titleHead);
          }
        }
        var initial_url = window.location.href;
        var url = initial_url.split("?");
        if (url[1] !== undefined) {
          var Id = url[1];
        } else {
          var Id = props.location.search.slice(1);
        }
        getAllProducts(Id, ItemId, searchName, defaultId);
      }
      window.localStorage.removeItem("preUrl");
    } else {
      FilterApiCall(allProductLabel, allpriceTypes);
    }
  };

  //set product  labels dd
  const setProductLabels = () => {
    let apiURL = "";
    let custNo = props.customerNo;
    // console.log(":::::CUSTOMER::::::",custNo)
    if (custNo === "") {
      custNo = 0;
    }
    if (props.location.state && props.location.state.ItemId !== undefined) {
      let ItemId = props.location.state.ItemId;
      apiURL = `${URL}/getAllFilterLabels222/${custNo}/${ItemId}`;
    } else {
      var initial_url = window.location.href;
      var url = initial_url.split("?");
      if (url[1] !== undefined) {
        var Id = url[1];
        apiURL = `${URL}/getAllFilterLabels222/${custNo}/${Id}`;
      } else {
        var Id = 0;
        apiURL = `${URL}/getAllFilterLabels222/${custNo}/${Id}`;
      }
    }
    axios.get(`${apiURL}`).then((res) => {
      // console.log(res)
      let arr = [];
      if (res.status === 200) {
        // console.log("::::Product Labels::::",res)
        let newProdLabel =
          res.data &&
          res.data.map((data, index) => {
            arr.push({
              count: data.Count,
              label: data.LabelName.replace("_", " "),
              value: index,
              clear: false,
            });
          });
        // console.log("::::LABELS::::",arr)
        setProductFilter(arr);
        setAllProductLabel(arr);
      }
    });
  };

  //set pricetype dd
  const setPriceTypeLabels = () => {
    let apiURL = "";
    let custNo = props.customerNo;
    // console.log(":::::CUSTOMER::::::",custNo)
    if (custNo === "") {
      custNo = 0;
    }
    let ItemId = props.location.state && props.location.state.ItemId;

    if (ItemId && ItemId !== undefined) {
      apiURL = `${URL}/getAllPriceTypes/${custNo}/${ItemId}`;
    } else {
      var initial_url = window.location.href;
      var url = initial_url.split("?");
      if (url[1] !== undefined) {
        var Id = url[1];
        apiURL = `${URL}/getAllPriceTypes/${custNo}/${Id}`;
      } else {
        var Id = 0;
        apiURL = `${URL}/getAllPriceTypes/${custNo}/${Id}`;
      }
    }

    // console.log(apiURL)

    axios.get(`${apiURL}`).then((res) => {
      // console.log(res)
      let arr1 = [];
      if (res.status === 200) {
        // console.log("::::Price Type::::",res)
        let newPriceType =
          res.data &&
          res.data.map((data, index) => {
            arr1.push({
              count: data.count,
              label: data.Price_type_name.replace("_", " "),
              value: index,
              clear: false,
            });
          });
        // console.log("::::PRICETYPE::::",arr1)
        setPriceType(arr1);
        setAllpriceTypes(arr1);
      }
    });
  };

  //reset all filters
  const setDropdownValues = () => {
    setProductLabels();
    if (acessToken) {
      setPriceTypeLabels();
    }
    // console.log("Changed props:",props)
  };

  //clear all filters
  const handleClearFilter = () => {
    let newProductFilter = productFilter;
    let newPriceType = priceType;
    newProductFilter &&
      newProductFilter.map((opt) => {
        opt.clear = false;
      });
    newPriceType &&
      newPriceType.map((opt) => {
        opt.clear = false;
      });
    setDropdownValues();
    setSelectedOpt([]);
    setSelPriceType([]);
    setShowLabel(false);
    setShowPriceTypeLabel(false);
    setShowProductFilterLabel(false);
    setProductFilter(newProductFilter);
    setPriceType(newPriceType);
    setAllProductLabel(newProductFilter);
    setAllpriceTypes(newPriceType);
    allProducts();
  };

  //clear product labels filter
  const clearProdList = () => {
    let custNo = props.customerNo;
    if (custNo === "") {
      custNo = 0;
    }

    let newProductFilter = productFilter;
    let newPriceType = priceType;
    let changedPtype = allpriceTypes;
    let productFilterCount = productFilter.length;
    let priceTypeCount = priceType.length;
    let Id = props.location.state && props.location.state.ItemId;
    let reqBod = {};
    let reqBod1 = {};
    let changedPLabel = allProductLabel;
    newProductFilter &&
      newProductFilter.map((opt) => {
        opt.clear = false;
      });
    setAllProductLabel(newProductFilter);

    setShowProductFilterLabel(false);
    if (
      props.customerNo === "" ||
      props.customerNo === null ||
      props.customerNo === undefined
    ) {
      setShowLabel(false);
      setProductLabels();
      allProducts();
    } else {
      for (let i = 0; i < priceTypeCount; i++) {
        if (newPriceType[i].clear === true) {
          if (Id !== undefined) {
            reqBod1 = { custNo: custNo, priceTypes: [], sortNo: Id };
            reqBod = {
              custNo: custNo,
              pageNo: pageToLoad,
              one: null,
              two: null,
              three: null,
              four: null,
              five: null,
              six: null,
              seven: null,
              eight: null,
              nine: null,
              ten: null,
              sortNo: Id,
            };
          } else {
            var initial_url = window.location.href;
            var url = initial_url.split("?");
            if (url[1] !== undefined) {
              Id = url[1];
              reqBod1 = { custNo: custNo, priceTypes: [], sortNo: Id };
              reqBod = {
                custNo: custNo,
                pageNo: pageToLoad,
                one: null,
                two: null,
                three: null,
                four: null,
                five: null,
                six: null,
                seven: null,
                eight: null,
                nine: null,
                ten: null,
                sortNo: Id,
              };
            } else {
              reqBod1 = { custNo: custNo, priceTypes: [], sortNo: 0 };
              reqBod = {
                custNo: custNo,
                pageNo: pageToLoad,
                one: null,
                two: null,
                three: null,
                four: null,
                five: null,
                six: null,
                seven: null,
                eight: null,
                nine: null,
                ten: null,
                sortNo: 0,
              };
            }
          }
          newPriceType &&
            newPriceType.map((data, index) => {
              if (data.clear === true) {
                reqBod1.priceTypes = [...reqBod1.priceTypes, data.label];
              }
            });
          axios.post(`${URL}/getLabelsByPriceTypes`, reqBod1).then((res) => {
            if (res.status === 200) {
              // console.log("::::Product Labels::::",res)
              let newProdLabel =
                res.data &&
                res.data.map((data, index) => {
                  return {
                    count: data.Count,
                    label: data.LabelName.replace("_", " "),
                    value: index,
                    clear: false,
                  };
                });
              // let finalFilter = [];
              let finalFilter = changedPLabel.filter((o1) =>
                newProdLabel.some((o2) => o1.label === o2.label)
              );
              finalFilter &&
                finalFilter.map((data, i) => {
                  finalFilter[i].count = newProdLabel[i].count;
                });
              // console.log("::::LABELS::::",finalFilter,newProdLabel,allProductLabel)

              setProductFilter(finalFilter);
              FilterApiCall(finalFilter, priceType);
            }
          });

          setShowLabel(true);
          axios
            .post(`${URL}/getPriceTypesByLabels`, reqBod)
            .then((res) => {
              let arr1 = [];
              if (res.status === 200) {
                // console.log("::::Price Type::::",res)
                let newPriceType =
                  res.data &&
                  res.data.map((data, index) => {
                    return {
                      count: data.count,
                      label: data.Price_type_name.replace("_", " "),
                      value: index,
                      clear: false,
                    };
                  });
                let finalFilter = changedPtype.filter((o1) =>
                  newPriceType.some((o2) => o1.label === o2.label)
                );
                finalFilter &&
                  finalFilter.map((data, i) => {
                    finalFilter[i].count = newPriceType[i].count;
                  });
                setPriceType(finalFilter);
                FilterApiCall(productFilter, finalFilter);
              }
            })
            .catch((e) => console.log(e));
          break;
        } else {
          setShowLabel(false);
          setProductLabels();
          if (acessToken) {
            setPriceTypeLabels();
          }
          allProducts();
        }
      }
    }
  };
  const clearPriceType = () => {
    let newProductFilter = productFilter;
    let productFilterCount = productFilter.length;
    let priceTypeCount = priceType.length;
    let changedPLabel = allProductLabel;
    let changedPtype = allpriceTypes;
    let reqBod = {};
    let reqBod1 = {};
    let Id = props.location.state && props.location.state.ItemId;

    priceType &&
      priceType.map((type) => {
        type.clear = false;
      });

    setShowPriceTypeLabel(false);
    if (productFilterCount) {
      for (let i = 0; i < productFilterCount; i++) {
        if (newProductFilter[i].clear === true) {
          setShowLabel(true);
          setShowProductFilterLabel(true);
          if (Id !== undefined) {
            reqBod1 = { custNo: props.customerNo, priceTypes: [], sortNo: Id };
            reqBod = {
              custNo: props.customerNo,
              pageNo: pageToLoad,
              one: null,
              two: null,
              three: null,
              four: null,
              five: null,
              six: null,
              seven: null,
              eight: null,
              nine: null,
              ten: null,
              sortNo: Id,
            };
          } else {
            var initial_url = window.location.href;
            var url = initial_url.split("?");
            if (url[1] !== undefined) {
              Id = url[1];
              reqBod1 = {
                custNo: props.customerNo,
                priceTypes: [],
                sortNo: Id,
              };
              reqBod = {
                custNo: props.customerNo,
                pageNo: pageToLoad,
                one: null,
                two: null,
                three: null,
                four: null,
                five: null,
                six: null,
                seven: null,
                eight: null,
                nine: null,
                ten: null,
                sortNo: Id,
              };
            } else {
              reqBod1 = { custNo: props.customerNo, priceTypes: [], sortNo: 0 };
              reqBod = {
                custNo: props.customerNo,
                pageNo: pageToLoad,
                one: null,
                two: null,
                three: null,
                four: null,
                five: null,
                six: null,
                seven: null,
                eight: null,
                nine: null,
                ten: null,
                sortNo: 0,
              };
            }
          }
          newProductFilter &&
            newProductFilter.map((data, index) => {
              if (data.clear === true) {
                if (data.label === "Lactose free") {
                  reqBod.one = 1;
                }
                if (data.label === "Gluten free") {
                  reqBod.two = 1;
                }
                if (data.label === "ECO label") {
                  reqBod.three = 1;
                }
                if (data.label === "Krav label ") {
                  reqBod.four = 1;
                }
                if (data.label === "Key label") {
                  reqBod.five = 1;
                }
                if (data.label === "FSC label") {
                  reqBod.six = 1;
                }
                if (data.label === "EU Eco label") {
                  reqBod.seven = 1;
                }
                if (data.label === "Fairtrade label") {
                  reqBod.eight = 1;
                }
                if (data.label === "Rainfest label") {
                  reqBod.nine = 1;
                }
                if (data.label === "Swedish label") {
                  reqBod.ten = 1;
                }
              } else {
                setShowLabel(false);
              }
            });
          axios.post(`${URL}/getPriceTypesByLabels`, reqBod).then((res) => {
            let arr1 = [];
            if (res.status === 200) {
              // console.log("::::Price Type::::",res)
              let newPriceType =
                res.data &&
                res.data.map((data, index) => {
                  return {
                    count: data.count,
                    label: data.Price_type_name.replace("_", " "),
                    value: index,
                    clear: false,
                  };
                });
              let finalFilter = changedPtype.filter((o1) =>
                newPriceType.some((o2) => o1.label === o2.label)
              );
              finalFilter &&
                finalFilter.map((data, i) => {
                  finalFilter[i].count = newPriceType[i].count;
                });
              // console.log("::::NEW PRICETYPE::::",finalFilter,newPriceType,allpriceTypes)
              setPriceType(finalFilter);
              FilterApiCall(productFilter, finalFilter);
            }
          });
          axios.post(`${URL}/getLabelsByPriceTypes`, reqBod1).then((res) => {
            if (res.status === 200) {
              // console.log("::::Product Labels::::",res)
              let newProdLabel =
                res.data &&
                res.data.map((data, index) => {
                  return {
                    count: data.Count,
                    label: data.LabelName.replace("_", " "),
                    value: index,
                    clear: false,
                  };
                });
              // let finalFilter = [];
              let finalFilter = changedPLabel.filter((o1) =>
                newProdLabel.some((o2) => o1.label === o2.label)
              );
              finalFilter &&
                finalFilter.map((data, i) => {
                  finalFilter[i].count = newProdLabel[i].count;
                });
              // console.log("::::LABELS::::",finalFilter,newProdLabel,allProductLabel)

              setProductFilter(finalFilter);
              FilterApiCall(finalFilter, priceType);
            }
          });
          break;
        } else {
          setShowLabel(false);
          setProductLabels();
          if (acessToken) {
            setPriceTypeLabels();
          }
          allProducts();
        }
      }
    } else {
      setShowLabel(false);
      setProductLabels();
      if (acessToken) {
        setPriceTypeLabels();
      }
      allProducts();
    }
  };

  //handle product lable click
  const handleProductLable = async (event, index) => {
    setPageToLoad(1);
    setPage(1);
    setSortPage(1);
    let custNo = props.customerNo;
    // console.log(":::::CUSTOMER::::::",custNo)
    if (custNo === "") {
      custNo = 0;
    }
    let value = event.target.dataset.key;
    let clear = event.target.dataset.clear;
    let arr = [];
    let newArr = [];
    let newProductFilter = productFilter;
    let newAllProductLabels = allProductLabel;
    let newPriceType = priceType;
    let productFilterCount = productFilter.length;
    let priceTypeCount = priceType.length;
    if (clear === "false") {
      arr.push(...selectedOpt, value);
      newArr = [...new Set(arr.sort())];
      setSelectedOpt(newArr);
      newProductFilter[index].clear = true;
      allProductLabel[index].clear = true;
      setProductFilter(newProductFilter);
      // setAllProductLabel(allProductLabel)
      setShowLabel(true);
      setShowProductFilterLabel(true);
    } else {
      if (custNo === 0) {
        setShowLabel(false);
      }
      arr = selectedOpt;
      arr.splice(index, 1);
      newArr = [...new Set(arr.sort())];
      setSelectedOpt(newArr);
      newProductFilter[index].clear = false;
      allProductLabel[index].clear = false;
      setProductFilter(newProductFilter);
      // setAllProductLabel(allProductLabel)
      for (let i = 0; i < productFilterCount; i++) {
        if (newProductFilter[i].clear === true) {
          setShowLabel(true);
          setShowProductFilterLabel(true);
          break;
        } else {
          setShowProductFilterLabel(false);
          for (let i = 0; i < priceTypeCount; i++) {
            if (newPriceType[i].clear === true) {
              setShowLabel(true);
              break;
            } else {
              allProducts();
              setShowLabel(false);
            }
          }
        }
      }
    }

    let reqBod = {};

    if (props.location.state && props.location.state.ItemId !== undefined) {
      let Id = props.location.state.ItemId;
      reqBod = {
        custNo: custNo,
        pageNo: pageToLoad,
        one: null,
        two: null,
        three: null,
        four: null,
        five: null,
        six: null,
        seven: null,
        eight: null,
        nine: null,
        ten: null,
        sortNo: Id,
      };
    } else {
      var initial_url = window.location.href;
      var url = initial_url.split("?");
      let Id;
      if (url[1] !== undefined) {
        Id = url[1];
        reqBod = {
          custNo: custNo,
          pageNo: pageToLoad,
          one: null,
          two: null,
          three: null,
          four: null,
          five: null,
          six: null,
          seven: null,
          eight: null,
          nine: null,
          ten: null,
          sortNo: Id,
        };
      } else {
        reqBod = {
          custNo: custNo,
          pageNo: pageToLoad,
          one: null,
          two: null,
          three: null,
          four: null,
          five: null,
          six: null,
          seven: null,
          eight: null,
          nine: null,
          ten: null,
          sortNo: 0,
        };
      }
    }

    newProductFilter &&
      newProductFilter.map((data, index) => {
        if (data.clear === true) {
          if (data.label === "Lactose free") {
            reqBod.one = 1;
          }
          if (data.label === "Gluten free") {
            reqBod.two = 1;
          }
          if (data.label === "ECO label") {
            reqBod.three = 1;
          }
          if (data.label === "Krav label ") {
            reqBod.four = 1;
          }
          if (data.label === "Key label") {
            reqBod.five = 1;
          }
          if (data.label === "FSC label") {
            reqBod.six = 1;
          }
          if (data.label === "EU Eco label") {
            reqBod.seven = 1;
          }
          if (data.label === "Fairtrade label") {
            reqBod.eight = 1;
          }
          if (data.label === "Rainfest label") {
            reqBod.nine = 1;
          }
          if (data.label === "Swedish label") {
            reqBod.ten = 1;
          }
        }
      });

    axios.post(`${URL}/getPriceTypesByLabels`, reqBod).then((res) => {
      let changedPtype = allpriceTypes;
      if (res.status === 200) {
        // console.log("::::Price Type::::",res)
        let newPriceType =
          res.data &&
          res.data.map((data, index) => {
            return {
              count: data.count,
              label: data.Price_type_name.replace("_", " "),
              value: index,
              clear: false,
            };
          });
        let finalFilter = changedPtype.filter((o1) =>
          newPriceType.some((o2) => o1.label === o2.label)
        );
        finalFilter &&
          finalFilter.map((data, i) => {
            finalFilter[i].count = newPriceType[i].count;
          });
        // console.log("::::NEW PRICETYPE::::",finalFilter,newPriceType,allpriceTypes)
        setPriceType(finalFilter);
        FilterApiCall(allProductLabel, finalFilter);
      }
    });
    if (!acessToken) {
      FilterApiCall(allProductLabel, allpriceTypes);
    }
  };

  //handle price type click
  const handlePriceType = async (event, index) => {
    setPageToLoad(1);
    setPage(1);
    setSortPage(1);
    let custNo = props.customerNo;
    // console.log(":::::CUSTOMER::::::",custNo)
    if (custNo === "") {
      custNo = 0;
    }

    let value = event.target.dataset.key;
    let clear = event.target.dataset.clear;
    let arr = [];
    let newArr = [];
    let newPriceType = priceType;
    let newAllPriceType = allpriceTypes;
    let newProductFilter = productFilter;
    let productFilterCount = productFilter.length;
    let priceTypeCount = priceType.length;
    // debugger
    if (clear === "false") {
      arr.push(...selPriceType, value);
      newArr = [...new Set(arr.sort())];
      setSelPriceType(newArr);
      newPriceType[index].clear = true;
      newAllPriceType[index].clear = true;
      setPriceType(newPriceType);
      // setAllpriceTypes(newAllPriceType)
      setShowLabel(true);
      setShowPriceTypeLabel(true);
    } else {
      if (selPriceType) {
        setShowLabel(false);
      }
      arr = selPriceType;
      arr.splice(index, 1);
      newArr = [...new Set(arr.sort())];
      setSelPriceType(newArr);
      newPriceType[index].clear = false;
      newAllPriceType[index].clear = false;
      setPriceType(newPriceType);
      // setAllpriceTypes(newAllPriceType)
      for (let i = 0; i < priceTypeCount; i++) {
        if (newPriceType[i].clear === true) {
          setShowLabel(true);
          setShowPriceTypeLabel(true);
          break;
        } else {
          allProducts();
          setShowPriceTypeLabel(false);
          for (let i = 0; i < productFilterCount; i++) {
            if (newProductFilter[i].clear === true) {
              setShowLabel(true);
              break;
            } else {
              setShowLabel(false);
              setProductLabels();
            }
          }
        }
      }
    }
    let Id = props.location.state && props.location.state.ItemId;
    let reqBod = {};
    if (Id !== undefined) {
      reqBod = { custNo: custNo, sortNo: Id, priceTypes: [] };
    } else {
      var initial_url = window.location.href;
      var url = initial_url.split("?");
      if (url[1] !== undefined) {
        Id = url[1];
        reqBod = { custNo: custNo, priceTypes: [], sortNo: Id };
      } else {
        reqBod = { custNo: custNo, priceTypes: [], sortNo: 0 };
      }
    }
    newPriceType &&
      newPriceType.map((data, index) => {
        if (data.clear === true) {
          reqBod.priceTypes = [...reqBod.priceTypes, data.label];
        }
      });

    axios.post(`${URL}/getLabelsByPriceTypes`, reqBod).then((res) => {
      let arr = [];

      if (res.status === 200) {
        // console.log("::::Product Labels::::",res)
        let newProdLabel =
          res.data &&
          res.data.map((data, index) => {
            return {
              count: data.Count,
              label: data.LabelName.replace("_", " "),
              value: index,
              clear: false,
            };
          });
        let changedPLabel = allProductLabel;
        let finalFilter = changedPLabel.filter((o1) =>
          newProdLabel.some((o2) => o1.label === o2.label)
        );
        finalFilter &&
          finalFilter.map((data, i) => {
            finalFilter[i].count = newProdLabel[i].count;
          });
        // console.log("::::LABELS::::",finalFilter,newProdLabel,allProductLabel)
        setProductFilter(finalFilter);
        FilterApiCall(finalFilter, allpriceTypes);
      }
    });

    // console.log(productFilter);
  };

  //get filtered products
  const FilterApiCall = async (productLabel, priceT) => {
    let assorment = props.location.state && props.location.state.default;
    let newProductFilter = productLabel;
    let newPriceType = priceT;
    let Id = props.location.state && props.location.state.ItemId;
    let reqBody;
    let custNo = props.customerNo;
    // console.log(":::::CUSTOMER::::::",custNo)
    if (custNo === "") {
      custNo = 0;
    }

    if (Id !== undefined) {
      reqBody = {
        custNo: custNo,
        sortNo: Id,
        pageNo: pageToLoad,
        one: null,
        two: null,
        three: null,
        four: null,
        five: null,
        six: null,
        seven: null,
        eight: null,
        nine: null,
        ten: null,
        priceTypes: [],
      };
    } else {
      var initial_url = window.location.href;
      var url = initial_url.split("?");
      if (url[1] !== undefined) {
        Id = url[1];
        reqBody = {
          custNo: custNo,
          sortNo: Id,
          pageNo: pageToLoad,
          one: null,
          two: null,
          three: null,
          four: null,
          five: null,
          six: null,
          seven: null,
          eight: null,
          nine: null,
          ten: null,
          priceTypes: [],
        };
      } else {
        reqBody = {
          custNo: custNo,
          sortNo: 0,
          pageNo: pageToLoad,
          one: null,
          two: null,
          three: null,
          four: null,
          five: null,
          six: null,
          seven: null,
          eight: null,
          nine: null,
          ten: null,
          priceTypes: [],
        };
      }
    }

    let isAllClear = true;
    newPriceType &&
      newPriceType.map((data, index) => {
        if (data.clear === true) {
          reqBody.priceTypes = [...reqBody.priceTypes, data.label];
          isAllClear = false;
        }
      });
    // console.log(reqBody)
    newProductFilter &&
      newProductFilter.map((data, index) => {
        if (data.clear === true) {
          isAllClear = false;
          if (data.label === "Lactose free") {
            reqBody.one = 1;
          }
          if (data.label === "Gluten free") {
            reqBody.two = 1;
          }
          if (data.label === "ECO label") {
            reqBody.three = 1;
          }
          if (data.label === "Krav label ") {
            reqBody.four = 1;
          }
          if (data.label === "Key label") {
            reqBody.five = 1;
          }
          if (data.label === "FSC label") {
            reqBody.six = 1;
          }
          if (data.label === "EU Eco label") {
            reqBody.seven = 1;
          }
          if (data.label === "Fairtrade label") {
            reqBody.eight = 1;
          }
          if (data.label === "Rainfest label") {
            reqBody.nine = 1;
          }
          if (data.label === "Swedish label") {
            reqBody.ten = 1;
          }
        }
      });

    if (isAllClear === false) {
      axios.post(`${URL}/getProductsByFilter`, reqBody).then(async (res) => {
        // console.log("From filter:",reqBody)
        // console.log("RESPONSE FROM FILTER:",res.data)
        if (res.status === 200) {
          // if()
          // setProduct([])

          let newProduct =
            res.data.products &&
            res.data.products.map((file) => {
              if (file.Standard_orderunit === "Kg") {
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "Kg",
                  newCompPrice: file.Comp_Price,
                  Comp_Price: file.Comp_Price,
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  newPrice: file.Comp_Price,
                  default_price: file.Comp_Price,
                  default_value: file.Conv_facor_KG,
                  unite_price: file.Comp_Price,
                  box_com_cart_price: file.comp_Price_cart,
                };
              } else if (file.Standard_orderunit === "Kart") {
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "Kart",
                  newCompPrice: file.Comp_Price,
                  Comp_Price:
                    file.Conv_facor_KG !== 0 && file.Price_cart !== 0
                      ? file.Conv_facor_KG * file.Price_cart
                      : file.Comp_Price,
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  newPrice: file.Price_cart,
                  default_price: file.Price_cart,
                  default_value: file.Conv_facor_CART,
                  unite_price: file.Price_cart,
                  box_com_cart_price: file.comp_Price_cart,
                };
              } else if (file.Standard_orderunit === "Kartplus") {
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "Kartplus",
                  newCompPrice: file.Comp_Price,
                  Comp_Price:
                    file.Conv_facor_KG !== 0 && file.Price_cart !== 0
                      ? file.Conv_facor_KG * file.Price_cart
                      : file.Comp_Price,
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  newPrice: file.Price_cart,
                  default_price: file.Price_cart,
                  default_value: file.Conv_facor_CART,
                  unite_price: file.Price_cart,
                  box_com_cart_price: file.comp_Price_cart,
                };
              } else if (file.Standard_orderunit === "Styck") {
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "Styck",
                  Comp_Price: file.Comp_Price,
                  newCompPrice: file.Comp_Price,
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  newPrice: file.Price_broken,
                  default_price: file.Price_broken,
                  default_value: file.Conv_facor_ST,
                  unite_price: file.Price_broken,
                  box_com_cart_price: file.comp_Price_cart,
                };
              } else {
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "",
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  default_price: file.Comp_Price,
                  newPrice: file.Comp_Price,
                  unite_price: file.Comp_Price,
                  box_com_cart_price: file.comp_Price_cart,
                };
              }
            });

          if (
            newProduct.length > 0 &&
            pageToLoad > 1 &&
            assorment === "defaultId"
          ) {
            await setProduct((Products) => [...Products, ...newProduct]);
          } else if (
            newProduct.length > 0 &&
            sortPage > 1 &&
            assorment !== "defaultId"
          ) {
            await setProduct((Products) => [...Products, ...newProduct]);
          } else {
            await setProduct(newProduct);
            await setPageToLoad(1);
            await setPage(1);
            await setSortPage(1);
          }
          // console.log(newProduct,"new product");
          await setTotalProducts(res.data.totalProducts);
          await setCondition(false);
          await setProducts(products);
        } else {
          // console.log("Else");
          setCondition(false);
          // console.log(res)
        }
      });
    } else {
      allProducts();
    }
  };

  const getAllProducts = (Id, itemId, searchName, assorment) => {
    setLoading(true);
    let url = "";
    let customer = !acessToken ? 0 : props.customerNo;
    let currentCustomer = customer || 0;
    if (Id === "" && itemId === undefined) {
      url = `${URL}/getProductsWithPagination/${currentCustomer}/${User_id}/${pageToLoad}`;
    } else if (Id !== "" && itemId === undefined && searchName === undefined) {
      url = `${URL}/getProductBySortLevel/${Id}/${currentCustomer}/${User_id}/${sortPage}`;
      console.log(url);
    } else if (Id === "" && itemId !== undefined && searchName === undefined) {
      url = `${URL}/getProductBySortLevel/${itemId}/${currentCustomer}/${User_id}/${sortPage}`;
      console.log(url);
    } else if (searchName !== undefined && Id !== "") {
      url = `${URL}/getProductsBySearch/${currentCustomer}/${User_id}/${Id}/${page}`;
    }
    // debugger
    axios
      .get(url, config)
      .then(async (res) => {
        if (res.status === 200) {
          const newProduct =
            res.data.products &&
            res.data.products.map((file) => {
              if (file.Standard_orderunit === "Kg")
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "Kg",
                  newCompPrice: file.comp_Price_cart,
                  Comp_Price: file.comp_Price_cart,
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  newPrice: file.comp_Price_cart,
                  default_price: file.comp_Price_cart,
                  default_value: file.Conv_facor_KG,
                  unite_price: file.comp_Price_cart,
                  box_com_cart_price: file.Comp_Price,
                };
              else if (file.Standard_orderunit === "Kart")
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "Kart",
                  newCompPrice: file.Comp_Price,
                  Comp_Price:
                    file.Conv_facor_KG !== 0 && file.Price_cart !== 0
                      ? file.Conv_facor_KG * file.Price_cart
                      : file.Comp_Price,
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  newPrice: file.Price_cart,
                  default_price: file.Price_cart,
                  default_value: file.Conv_facor_CART,
                  unite_price: file.Price_cart,
                  box_com_cart_price: file.comp_Price_cart,
                };
              else if (file.Standard_orderunit === "Kartplus")
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "Kartplus",
                  newCompPrice: file.Comp_Price,
                  Comp_Price:
                    file.Conv_facor_KG !== 0 && file.Price_cart !== 0
                      ? file.Conv_facor_KG * file.Price_cart
                      : file.Comp_Price,
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  newPrice: file.Price_cart,
                  default_price: file.Price_cart,
                  default_value: file.Conv_facor_CART,
                  unite_price: file.Price_cart,
                  box_com_cart_price: file.comp_Price_cart,
                };
              else if (file.Standard_orderunit === "Styck")
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "Styck",
                  Comp_Price: file.Comp_Price,
                  newCompPrice: file.Comp_Price,
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  newPrice: file.Price_broken,
                  default_price: file.Price_broken,
                  default_value: file.Conv_facor_ST,
                  unite_price: file.Price_broken,
                  box_com_cart_price: file.comp_Price_cart,
                };
              else
                return {
                  ...file,
                  stdOrder_Unit: 0,
                  hiddenText: true,
                  purchased_Unit: "",
                  newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                  default_price: file.Comp_Price,
                  newPrice: file.Comp_Price,
                  unite_price: file.Comp_Price,
                  box_com_cart_price: file.comp_Price_cart,
                };
            });
          // console.log(newProduct,"new product");
          if (
            newProduct.length > 0 &&
            pageToLoad > 1 &&
            assorment === "defaultId"
          ) {
            await setProduct((Products) => [...Products, ...newProduct]);
          } else if (
            newProduct.length > 0 &&
            sortPage > 1 &&
            assorment !== "defaultId"
          ) {
            await setProduct((Products) => [...Products, ...newProduct]);
          } else if (
            newProduct.length > 0 &&
            page > 1 &&
            assorment === "searchId"
          ) {
            await setProduct((Products) => [...Products, ...newProduct]);
          } else {
            await setProduct(newProduct);
            await setPageToLoad(1);
            await setPage(1);
            await setSortPage(1);
          }
          setLoading(false);
          setTotalProducts(res.data.totalProducts);
          setCondition(false);
          props.setProducts(products);
        } else {
          // console.log("Else");
          setLoading(false);
          setCondition(false);
        }
      })
      .catch((err) => {
        console.log(err, "Error");
        setCondition(false);
      });
  };

  const loadMore = () => {
    if (props.location.state !== undefined) {
      if (
        props.location.state.searchDetail !== undefined &&
        props.location.state.searchDetail !== "" &&
        totalProducts > 24
      ) {
        setPage((prevState) => prevState + 1);
      } else if (props.location.state.default !== "defaultId") {
        setSortPage((prevState) => prevState + 1);
      } else {
        setPageToLoad((prevState) => prevState + 1);
      }
    } else {
      setPageToLoad((prevState) => prevState + 1);
    }
  };

  const onclcikEvent = (e, ProdNo) => {
    setTimeout(() => {
      e.target.select();
    }, 20);

    var newArr = [...products];
    var index = newArr.findIndex((obj) => obj.ProdNo === ProdNo);
    newArr[index].hiddenText = false;
    setProduct(newArr);
  };

  const onMouseLeaveEvent = (ProdNo) => {
    var newArr = [...products];
    var index = newArr.findIndex((obj) => obj.ProdNo === ProdNo);
    newArr[index].hiddenText = true;
    setProduct(newArr);
  };

  const onChangeUnit = (
    e,
    i,
    ProdNo,
    unit,
    kg,
    st,
    box,
    cKg,
    cSt,
    cBox,
    price
  ) => {
    const selectedIndex = e.target.options.selectedIndex;
    let purchased_Unit = e.target.options[selectedIndex].getAttribute("keyid");
    const { name, value } = e.target;

    var newArr = [...products];
    var index = newArr.findIndex((obj) => obj.ProdNo === ProdNo);

    if (purchased_Unit !== "Kart" && purchased_Unit !== "Kartplus") {
      newArr[index].Comp_Price = newArr[index].newCompPrice;
      kg = newArr[index].newCompPrice;
    }
    if (selectedIndex !== 0) {
      if (purchased_Unit === "Kg") {
        let changePrice = newArr[index].Comp_Price * cKg;
        newArr[index].newPrice = changePrice;
      } else if (purchased_Unit === "Styck") {
        let changePrice = newArr[index].Price_broken * cSt;
        newArr[index].newPrice = changePrice;
      } else {
        let changePrice = newArr[index].Price_cart * cBox;
        newArr[index].newPrice = changePrice;
      }
    } else {
      let prePrice =
        purchased_Unit === "Kg"
          ? newArr[index].Comp_Price
          : purchased_Unit === "Styck"
          ? newArr[index].Price_broken
          : newArr[index].Price_cart;
      newArr[index].newPrice = prePrice;
    }

    purchased_Unit === "Kg"
      ? (newArr[index].default_price = kg)
      : purchased_Unit === "Styck"
      ? (newArr[index].default_price = st)
      : (newArr[index].default_price = box);
    newArr[index].default_value = value;
    newArr[index].purchased_Unit = purchased_Unit;
    if (
      (purchased_Unit === "Kart" && newArr[index].Min_Quantity < 1) ||
      (purchased_Unit === "Kartplus" && newArr[index].Min_Quantity < 1)
    ) {
      newArr[index].newQuantity = 1;
    } else {
      newArr[index].newQuantity = newArr[index].Min_Quantity;
    }
    newArr[index].unite_price = newArr[index].newPrice;
    setProduct(newArr);
  };

  const IncrementItem = (
    qty,
    i,
    ProdNo,
    amt,
    minqnty,
    Max_Quantity,
    newQuantity
  ) => {
    let quantity = parseFloat(qty) + minqnty;
    var price = amt;
    var newArr = [...products];
    var index = newArr.findIndex((obj) => obj.ProdNo === ProdNo);
    let defaultAmt = newArr[index].unite_price;
    if (Max_Quantity === 0) {
    } else if (quantity > Max_Quantity) {
      quantity = Max_Quantity;
      newArr[index].hiddenText = true;
    } else if (quantity > 0) {
    } else {
      price = amt;
      quantity = minqnty;
    }

    if (minqnty < 1) {
      newArr[index].newPrice = price;
      newArr[index].newQuantity = quantity.toFixed(2);
    } else if (minqnty > 1) {
      newArr[index].newPrice = price;
      newArr[index].newQuantity = quantity;
    } else {
      newArr[index].newPrice = price;
      newArr[index].newQuantity = quantity;
    }

    setProduct(newArr);
  };

  const DecreaseItem = (qty, i, ProdNo, amt, minqnty) => {
    let quantity = parseFloat(qty) - minqnty;
    var price = amt;
    var newArr = [...products];
    var index = newArr.findIndex((obj) => obj.ProdNo === ProdNo);
    let defaultAmt = newArr[index].unite_price;
    if (quantity > 0) {
    } else {
      quantity = minqnty;
      price = amt;
    }
    if (minqnty < 1) {
      newArr[index].newPrice = price;
      newArr[index].newQuantity = quantity.toFixed(2);
    } else if (minqnty > 1) {
      newArr[index].newPrice = price;
      newArr[index].newQuantity = quantity;
    } else {
      newArr[index].newPrice = price;
      newArr[index].newQuantity = quantity;
    }
    setProduct(newArr);
  };

  const onChangeQty = (e, i, amt, minqnty, ProdNo, purchaseUnit, max) => {
    var price = amt;
    if (purchaseUnit === "Kg") {
      var quantity = e.target.value.replace(/KG/g, "").trim();
    } else {
      var quantity = e.target.value.replace(/ST/g, "").trim();
      quantity = parseFloat(Math.floor(quantity));
      if (isNaN(quantity) === true || quantity === 0) {
        quantity = 0;
      }
    }
    if (max === 0) {
    } else if (quantity > max) {
      quantity = max;
    } else if (quantity > 0) {
      price = amt * quantity;
    } else {
      price = amt;
    }

    var key = e.keyCode || e.charCode;
    var newArr = [...products];
    var index = newArr.findIndex((obj) => obj.ProdNo === ProdNo);
    const re = /^[0-9.,]+$/;

    if (quantity === "") {
      newArr[index].newQuantity = 0;
    }
    if (re.test(quantity)) {
      newArr[index].newQuantity = quantity;
    }
    setProduct(newArr);
  };

  const handleQuantity = (e, index, Max_Quantity) => {
    const { name, value } = e.target;
    var newArr = [...products];
    if (Max_Quantity === 0) {
      newArr[index].newQuantity = value;
      newArr[index].hiddenText = true;
      setProduct(newArr);
    } else if (value > Max_Quantity) {
      newArr[index].newQuantity = Max_Quantity;
      newArr[index].hiddenText = true;
      setProduct(newArr);
    }
  };

  return (
    <div>
      <Header />
      <Container fluid className="ProductOverview">
        <Row className="wrapper px-3">
          <Col lg={3} className="ProdsidebarWp padding-top-c">
            <SideBar
              resetPage={() => setPageToLoad(1)}
              resetSortPage={() => setSortPage(1)}
            />
          </Col>
          <Col lg={9} className="overviewRightCol">
            <Row>
              <Col className="">
                {productImage === "" || productImage === undefined ? (
                  path === "/ProductOverview" ? (
                    <div>
                      <div className="ProdBannerBg">
                        <img src={Cackebg} />
                      </div>
                      {/* <h2 className="BannerHeadText">{bannerText === '' || bannerText === undefined ? `VÅRT SORTIMENT ${totalProducts} items` : `${_.capitalize(bannerText)} ${totalProducts} items`}</h2> */}
                      {bannerText === "" || bannerText === undefined ? (
                        <h2 className="BannerHeadText">
                          {/*OUR RANGE*/} VÅRT SORTIMENT{" "}
                          <span className="Proditemcount">{` ${totalProducts} Artiklar`}</span>
                        </h2>
                      ) : (
                        <h2 className="BannerWithoutImgHeadText">
                          {_.capitalize(bannerText)}
                          <span className="Proditemcount">{` ${totalProducts} Artiklar`}</span>
                        </h2>
                      )}
                      <p className="detailText">
                        {bannerText !== "" && bannerText !== undefined
                          ? `Här hittar du grönsakskompaniets utbud inom sortimentet ${_.capitalize(
                              bannerText
                            )}.
                          Saknar du något kan du kontakta oss för mer information.  `
                          : "Välkommen till vårt sortiment! Bläddra bland våra kategorier och hitta de artiklar som passar er."}{" "}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="BannerWithoutImg">
                        {/* <img src={productImage === '' || productImage === undefined ? Cackebg : productImage} /> */}
                        {bannerText === "" || bannerText === undefined ? (
                          <h2 className="BannerWithoutImgHeadText">
                            {/*OUR RANGE*/} VÅRT SORTIMENT{" "}
                            <span className="CountWithoutImg">{` ${totalProducts} Artiklar`}</span>
                          </h2>
                        ) : (
                          <h2 className="BannerWithoutImgHeadText">
                            {_.capitalize(bannerText)}
                            <span className="CountWithoutImg">{` ${totalProducts} Artiklar`}</span>
                          </h2>
                        )}
                        <p className="BannerWithoutImgdetailText">
                          {bannerText !== "" && bannerText !== undefined
                            ? `Här hittar du grönsakskompaniets utbud inom sortimentet ${_.capitalize(
                                bannerText
                              )}.
                        Saknar du något kan du kontakta oss för mer information.  `
                            : "Välkommen till vårt sortiment! Bläddra bland våra kategorier och hitta de artiklar som passar er."}{" "}
                        </p>
                      </div>
                    </div>
                  )
                ) : (
                  <div>
                    <div className="ProdBannerBg">
                      <img src={productImage === undefined} />
                    </div>
                    {bannerText === "" || bannerText === undefined ? (
                      <h2 className="BannerHeadText">
                        {/*OUR RANGE*/} VÅRT SORTIMENT{" "}
                        <span className="Proditemcount">{` ${totalProducts} Artiklar`}</span>
                      </h2>
                    ) : (
                      <h2 className="BannerHeadText-withoutimg">
                        {_.capitalize(bannerText)}
                        <span className="Proditemcount">{` ${totalProducts} Artiklar`}</span>
                      </h2>
                    )}
                    <p className="detailText-withoutimg">
                      {bannerText !== "" && bannerText !== undefined
                        ? `Här hittar du grönsakskompaniets utbud inom sortimentet ${_.capitalize(
                            bannerText
                          )}.
                        Saknar du något kan du kontakta oss för mer information.  `
                        : "Välkommen till vårt sortiment! Bläddra bland våra kategorier och hitta de artiklar som passar er."}{" "}
                    </p>
                  </div>
                )}
              </Col>
            </Row>
            <Row id="box">
              <Col md={12} className=" ProdovrlistWpr">
                <ProductListOverview
                  pageToLoad={pageToLoad}
                  loadMore={loadMore}
                  loading={loading}
                  onMouseLeaveEvent={onMouseLeaveEvent}
                  onclcikEvent={onclcikEvent}
                  custNo={customerNo}
                  onChangeUnit={onChangeUnit}
                  productCount={products ? products.length : 0}
                  totalProducts={totalProducts}
                  products={products}
                  onChangeQty={onChangeQty}
                  DecreaseItem={DecreaseItem}
                  IncrementItem={IncrementItem}
                  condition={!condition}
                  handleQuantity={handleQuantity}
                  productFilter={productFilter}
                  PriceType={priceType}
                  ProductLabelHandler={handleProductLable}
                  PriceTypeHandler={handlePriceType}
                  clearFilter={clearProdList}
                  clearPriceType={clearPriceType}
                  clearAll={handleClearFilter}
                  showLabel={showLabel}
                  showProductFilterLabel={showProductFilterLabel}
                  showPriceTypeLabel={showPriceTypeLabel}
                  selPriceType={selPriceType}
                  selectedOpt={selectedOpt}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
const mapStoreToProps = (state) => {
  return {
    customerNo: state.customer.customerNo,
    products: state.customer.products,
    searchName: state.customer.searchName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProducts: (products) => dispatch(setProducts(products)),
  setSearchProduct: (searchName) => dispatch(setSearchProduct(searchName)),
  setCustomer: (customerNo) => dispatch(setCustomer(customerNo)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ProductOverview);
