import React from 'react'
import './Panel.css'
import {Accordion,Card ,Row,Col,Button,Tab,Tabs} from 'react-bootstrap'
import { FormGroup } from '@material-ui/core';
import { Label ,Form,Input} from 'reactstrap';
import OrderAsGuest from './OrderAsGuest'
import Address from './Address'
import {useState} from 'react'
import PaymentAccordion from './PaymentAccordion'

const Panel = () =>{
	//const [selectedValue, setSelectedValue] = React.useState('a');
	// const handleChange = (event) => {
	//   setSelectedValue(event.target.value);
	// };
	const [key, setKey] = useState('home');
return(
	<Accordion  className="AccordionWp  CheckoutWp">
  <Card>
    <Card.Header className="text-left">
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
       <h3 className="TitleTxt"> 1. Personal Information</h3>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
	  <Tabs defaultActiveKey="Guest"
	   activeKey={key}
	   onSelect={(k) => setKey(k)}
	 >
		<Tab eventKey="home" title="Order As Guest">
			<OrderAsGuest/>
		</Tab>
		<Tab eventKey="profile" title="Sign In">
			<div className="mx-auto">
			<Form className="mt-5">
					<Row>
						<Col md={1}></Col>
						<Col md={3}>
							<FormGroup>
								<Label>Email</Label>
							</FormGroup>
						</Col>
						<Col>
							<Input type="text" />
						</Col>
					</Row>
					<Row className="mt-2">
						<Col md={1}></Col>
						<Col md={3}>
							<FormGroup>
								<Label>Password</Label>
							</FormGroup>
						</Col>
						<Col>
						<FormGroup>
							<Input type="text" />
						</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col><Button className="float-right BtnPrimaryContact mt-4 pl-3">Continue</Button></Col>
					</Row>
				</Form>
				</div>
			</Tab>
			</Tabs>
		</Card.Body>
		</Accordion.Collapse>
	</Card>
	<Card className="mt-2">
		<Card.Header className="text-left">
		<Accordion.Toggle as={Button} variant="link" eventKey="1">
		<h3 className="TitleTxt"> 2. Address</h3>
		</Accordion.Toggle>
		</Card.Header>
		<Accordion.Collapse eventKey="1">
		<Card.Body>
			<Address/>
		</Card.Body>
		</Accordion.Collapse>
	</Card>
	<Card className="mt-2">
		<Card.Header className="text-left">
		<Accordion.Toggle as={Button} variant="link" eventKey="2">
		<h3 className="TitleTxt"> 3. Payment Methods</h3>
		</Accordion.Toggle>
		</Card.Header>
		<Accordion.Collapse eventKey="2">
		<Card.Body>
			<PaymentAccordion/>
		</Card.Body>
		</Accordion.Collapse>
	</Card>
</Accordion>

)

}
export default Panel;