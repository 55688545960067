import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Row, Col } from "react-bootstrap";

import axios from "axios";
import { Modal } from "react-bootstrap";
import { URL } from "../../../ServerCall/Config";
import swal from "sweetalert";

import {
  setAccessLevel,
  setAdminSection,
} from "../../../redux/action/customerAction";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginModal = (props) => {
  const [loginAttempts, setLoginAttempts] = useState(0);
  const maxLoginAttempts = 3;
  useEffect(() => {
    return () => {
      setLoginAttempts(0);
    };
  }, []);

  const classes = useStyles();
  const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
  );
  const [errorPassword, formErrorsPassword] = React.useState("");
  const [errorEmail, formErrorsEmail] = React.useState("");
  const [state, setstate] = React.useState({
    password: "",
    email: "",
    newPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const formvalid = () => {
    // validEmailRegex.test(state.email) ? formErrorsEmail('') : formErrorsEmail("Valid email address required!");
    validEmailRegex.test(state.email)
      ? formErrorsEmail("")
      : formErrorsEmail("Giltig E-postadress krävs!");
    // state.password !== '' ? formErrorsPassword('') : formErrorsPassword("password required!");

    if (state.email !== "" && errorEmail === "") {
      return true;
    } else {
      return false;
    }
  };

  const formvalidPassword = () => {
    //validEmailRegex.test(state.email) ? formErrorsEmail('') : formErrorsEmail("Valid email address required!");
    // state.password !== '' ? formErrorsPassword('') : formErrorsPassword("password required!");
    state.password !== ""
      ? formErrorsPassword("")
      : formErrorsPassword("lösenord krävs!");

    if (state.password !== "") {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleNext = (e) => {
    e.preventDefault();

    const isValid = formvalid();
    let credential = {
      email: state.email,
    };
    if (isValid) {
      axios
        .post(`${URL}/users/login`, credential)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 400) {
              // swal("Email Not Found !", "", "warning");
              swal("E-post hittades inte! ", "", "warning");
            }
            if (res.data.data.PasswordHash === null) {
              props.onHide();
              // swal("Reset Your Password",'Link send to your registered mail',"info");
              swal(
                "Återställ ditt lösenord",
                "Länken skickas till din registrerade e-postadress",
                "info"
              );
            } else {
              setShowPassword(true);
            }
          }
        })
        .catch((err) => {
          console.log("Test", err);
        });
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (loginAttempts >= maxLoginAttempts) {
      swal(
        "För många misslyckade inloggningsförsök. Vänta och försök igen senare.",
        "",
        "error"
      );
      return;
    }

    const isValid = formvalidPassword();
    let credential = {
      email: state.email,
      password: state.password,
    };
    if (isValid) {
      axios
        .post(`${URL}/users/login`, credential)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            if (res.data.status === 400) {
              setLoginAttempts(loginAttempts + 1);
              // swal("Incorrect email or password!", "", "info");
              swal("Felaktig e-postadress eller lösenord", "", "info");
            } else {
              let isAdmin = false;
              let path = "";
              localStorage.setItem("userData", JSON.stringify(res.data.data));
              if (props.productId !== undefined) {
                window.location.href = `/ProductDetail?id=${props.productId}`;
              } else if (props.ProductOverview !== undefined) {
                window.location.href = `/ProductOverview`;
              } else {
                //  debugger
                //  let myObj =  data.Access_level1.find(obj => obj === 4);
                //  props.setAccessLevel(myObj);
                if (data.Access_level1[0] === 0) {
                  isAdmin = true;
                  path = "/Dashboard";
                } else if (
                  data.Access_level1[0] === 6 ||
                  data.Access_level1[0] === 4
                ) {
                  isAdmin = true;
                  path = "/CreateUser";
                } else {
                  isAdmin = false;
                  path = "/";
                }
                // props.setAdminSection(isAdmin)
                localStorage.setItem("isAdmin", isAdmin);
                window.location.href = path;
              }
            }
          }
        })
        .catch((err) => {
          console.log("Test", err);
        });
    }
  };

  return (
    <Container
      className="LoginContainer formWraper"
      component="main"
      maxWidth="xs"
    >
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton className="text-center">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            {/* Login */}
            Logga In
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Card className="pb-5"> */}
          <CssBaseline />
          <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
            {/* <Avatar >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography> */}
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                onChange={(e) => {
                  handleChange(e);
                }}
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                // onBlur={(e) => { onBlurEvent(e) }}
              />
              {errorEmail !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {errorEmail}
                </label>
              ) : null}
              {showPassword && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="password"
                  label="Lösenord"
                  type="password"
                  id="password"
                  //  autoComplete="current-password"
                  // onBlur={(e) => { onBlurEvent(e) }}
                />
              )}
              {errorPassword !== "" ? (
                <label style={{ fontSize: 12, color: "red" }}>
                  {errorPassword}
                </label>
              ) : null}
              {/* <FormControlLabel className="frmLbl text-left"
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              <Row className="pt-2 pb-2">
                <Col></Col>
                {/* <Col>
                <Link href="#" variant="body2" className="float-right forgetText">
                  Forgot password?
                </Link>
              </Col> */}
              </Row>
              {!showPassword ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="mt-0 mb-2"
                  onClick={(e) => {
                    handleNext(e);
                  }}
                >
                  {/* Next */}
                  Nästa
                </Button>
              ) : null}
              {showPassword ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="mt-0 mb-2"
                  onClick={(e) => {
                    handleLogin(e);
                  }}
                >
                  {/* Sign In */}
                  Logga in
                </Button>
              ) : null}
              <Row>
                <Col md={12} style={{ margin: "0 auto" }}>
                  {/* <Link to="/Registration" className="signupText text-center">Not a customer? <strong>Click here</strong> to apply to become a customer today.</Link><br/> */}
                  {showPassword && (
                    <Link
                      to="/ForgotPassword"
                      className="signupText text-center"
                    >
                      {/* Forgot Password?  */}
                      Glömt lösenordet?{" "}
                      <strong>{/* Click here */} Klicka här </strong>{" "}
                      {/* to reset Password. */}för att återställa lösenordet.
                    </Link>
                  )}
                </Col>
              </Row>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="Signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </form>
          </div>

          {/* </Card> */}
        </Modal.Body>
      </Modal>
    </Container>
  );
};
const mapStoreToProps = (state) => {
  return {
    accesslvl: state.customer.accesslvl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAccessLevel: (accesslvl) => dispatch(setAccessLevel(accesslvl)),
  setAdminSection: (isAdmin) => dispatch(setAdminSection(isAdmin)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(LoginModal);
