class Auth {
    constructor() {
        this.user_token = JSON.parse(localStorage.getItem('userData'))||{}
    }
    getToken() {
        return this.user_token.AccessToken
    }
    getUserId() {
        return this.user_token.UsrNo
    }
    getFirmNo(){
        return this.user_token.FrimNo
    }
    getUserName(){
        return this.user_token.SureName + ' ' + this.user_token.LastName
    }
    getEmail(){
        return this.user_token.Mailad
    }
    getAccess_level(){
        return this.user_token.Access_level1
    }
    setUserToken(new_token) {
        this.user_token = new_token
        localStorage.setItem('auth', JSON.stringify(new_token))
    }
    logout() {
        localStorage.clear('userData');
        window.location.href = "/";
    }
}
export default new Auth()