import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { CardTitle } from "reactstrap";
import missingImage from "../../../assets/images/picture_missing_text.png";

const OrderItemList = (props) => {
  // console.log(props);
  const [Taxes, setTaxes] = useState([]);
  useEffect(() => {
    let order_items = props.orderProduct.order_items;
    let orderDetails = [];
    let Taxes = [];
    order_items &&
      order_items.map((data) => {
        orderDetails.push({
          tax: data.OrderTaxPerc,
          taxPrice: "",
          price: data.OrderPrice,
        });
      });
    // console.log(orderDetails,'thsi is ');
    orderDetails &&
      orderDetails.map((data) => {
        let exist = Taxes.filter((v, i) => {
          return v.Tax === data.tax;
        });
        if (exist.length) {
          let index = Taxes.indexOf(exist[0]);
          // console.log(index,"this is index");
          Taxes[index].Price = Taxes[index].Price + data.price;
          Taxes[index].taxPrice = (Taxes[index].Price * Taxes[index].Tax) / 100;
        } else {
          if (data.tax !== 0) {
            let obj = {
              Tax: data.tax,
              Price: data.price,
              taxPrice: (data.price * data.tax) / 100,
            };
            Taxes.push(obj);
          }
        }
      });
    if (Taxes.length > 0) {
      setTaxes(Taxes);
    } else {
      let obj = {
        Tax: 0,
        taxPrice: "Taxes are not applicable",
      };
      Taxes.push(obj);
      setTaxes(Taxes);
    }
  }, [props]);
  // console.log(props.orderProduct.order_items,"this is orderList");

  // console.log(Taxes,"this is tax");
  return (
    <div>
      <Card style={{ border: "none" }}>
        <CardTitle className="card-block ">
          {/* Order Items */}
          Order Artiklar
        </CardTitle>
        <div className="cart-overview">
          <ul className="CartItems orderconfList">
            <li>
              <Row className="headerTextRow">
                <Col md={2} xs={12} style={{ margin: "0 auto" }}>
                  <p className="text-center font-weight-bold">
                    {/* Product */}
                  </p>
                </Col>
                <Col md={2} xs={12}>
                  <p className="text-center font-weight-bold">Artiklar Namn </p>
                </Col>
                <Col md={8} xs={12}>
                  <Row>
                    <Col md={12} xs={12}>
                      <Row>
                        {/* <Col md={2} xs={2} className="Qty">
                                                    <p class=" text-center  font-weight-bold">
                                                        Base Unit
                                                    </p>
                                                </Col> */}
                        <Col md={2} xs={2} className="Qty">
                          <p class=" text-center  font-weight-bold">
                            {/* Purchase Unit */}
                            Inköpsenhet
                          </p>
                        </Col>
                        {/* <Col md={2} xs={2} className="Qty">
                                                    <p class=" text-center  font-weight-bold">
                                                        Unit Count
                                                    </p>
                                                </Col> */}
                        <Col md={2} xs={2} className="Qty">
                          <p class=" text-center  font-weight-bold">
                            {/* Base Price */}
                            Grundpris
                          </p>
                        </Col>
                        <Col md={2} xs={2} className="Qty">
                          <p className=" text-center  font-weight-bold">
                            {/* Quantity */}
                            Antal
                          </p>
                        </Col>

                        <Col md={2} xs={2} className="Qty">
                          <p class=" text-center  font-weight-bold">
                            {/* Price (tax excl.) */}
                            Pris (exkl. moms)
                          </p>
                        </Col>
                        <Col md={2} xs={2} className="Qty">
                          <p class=" text-center  font-weight-bold">
                            {/* Tax */}
                            Moms
                          </p>
                        </Col>
                        {/* <Col md={2} xs={2} className="Qty">
                                                    <p class=" text-center  font-weight-bold">
                                                        Tax Ammount
                                                    </p>
                                                </Col> */}
                        <Col md={2} xs={2} className="Qty">
                          <p class=" text-center  font-weight-bold">
                            {/* Price(tax incl.) */}
                            Pris (inkl. moms)
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </li>
            {props.orderProduct.order_items &&
              props.orderProduct.order_items.map((item) => (
                <li className="cartItem">
                  <Row>
                    <Col md={2} xs={12} className="product-line-grid-left">
                      <span className="product-image media-middle">
                        {/* <img src={item.Small_Img}/> */}
                        <img
                          src={
                            item.Pic_Small === ""
                              ? missingImage
                              : item.Pic_Small
                          }
                        />
                      </span>
                    </Col>
                    <Col md={2} xs={12} className="product-line-grid-body">
                      <div class="product-line-info prodordInfo">
                        <a
                          className="product_name text-center"
                          href=""
                          data-id_customization="0"
                        >
                          {item.Descr}
                        </a>
                      </div>
                      {/* <div class="product-line-info product-weight">
                                            <span class="productweight">Weight : {}</span>
                                        </div> */}
                    </Col>
                    <Col md={8} xs={12}>
                      <Row>
                        <Col md={12} xs={12}>
                          <Row>
                            {/* <div class="col-md-4 col-xs-12 price text-center prodordInfo ">
                                                            <span class="product-price">
                                                                <strong>
                                                                {item.OrderPrice} kr
                                                                </strong>
                                                            </span>
                                                        </div> */}
                            {/* <Col md={2} xs={2} className="Qty">
                                                        <p className="ProductQty text-center"> {item.BaseUnit}</p>
                                                    </Col> */}
                            <Col md={2} xs={4} className="Qty">
                              <p className="ProductQty text-center">
                                {" "}
                                {item.OrderUnit}
                              </p>
                            </Col>
                            {/* <Col md={2} xs={2} className="Qty">
                                                        <p className="ProductQty text-center"> {
                                                            item.BaseUnit==='Kart' && item.OrderUnit==='Kart' ? `${item.UnitCount} Pcs` :
                                                                item.BaseUnit==='Kart' && item.OrderUnit==='Styck' ? `${item.UnitCount} ST` :
                                                                    item.BaseUnit==='Kart' && item.OrderUnit==='Kg' ? `${item.UnitCount} Kg` :

                                                                        item.BaseUnit==='Styck' && item.OrderUnit==='Kart' ? `${item.UnitCount} Pcs` :
                                                                            item.BaseUnit==='Styck' && item.OrderUnit==='Styck' ? `${item.UnitCount} ST` :
                                                                                item.BaseUnit==='Styck' && item.OrderUnit==='Kg' ? `${item.UnitCount} ST` :

                                                                                    item.BaseUnit==='Kg' && item.OrderUnit==='Kart' ? `${item.UnitCount} Kg` :
                                                                                        item.BaseUnit==='Kg' && item.OrderUnit==='Styck' ? `${item.UnitCount} Kg` :
                                                                                            item.BaseUnit==='Kg' && item.OrderUnit==='Kg' ? `${item.UnitCount} Kg` :
                                                                                                null
                                                        }</p>
                                                    </Col> */}
                            <Col md={2} xs={4} className="Qty">
                              <p className="ProductQty text-center">
                                {" "}
                                {item.BasePrice} kr
                              </p>
                            </Col>
                            <Col md={2} xs={4} className="Qty">
                              <p className="ProductQty text-center">
                                {" "}
                                {item.OrderQty}
                              </p>
                            </Col>

                            <Col md={2} xs={4} className="Qty">
                              <p class="ProductQty text-center">
                                {Number(item.OrderPrice).toFixed(2)} kr
                              </p>
                            </Col>
                            <Col md={2} xs={4} className="Qty">
                              <p class="ProductQty text-center">
                                {item.OrderTaxPerc}%
                              </p>
                            </Col>
                            {/* <Col md={2} xs={2} className="Qty">
                                                        <p class="ProductQty text-center">
                                                        {(((item.BasePrice) * (item.OrderTaxPerc)) / 100).toFixed(2)} Kr
                                                        </p>
                                                    </Col> */}
                            <Col md={2} xs={4} className="Qty">
                              <p class="ProductQty text-center">
                                {Number(item.LineTotal).toFixed(2)} kr
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </li>
              ))}
          </ul>
        </div>
        <Row>
          <Col xl={9} lg={8} md={7}></Col>
          <Col
            xl={3}
            lg={4}
            md={5}
            sm={12}
            xs={12}
            className="float-right text-right"
          >
            <Row className="pt-3 ">
              <Col md={7} xs={8} className="orderDetailHeading">
                {/* Sub-Total (Tax Excl.) */}
                Netto (Exkl.moms)
              </Col>
              <Col md={5} xs={4} className=" ">
                <span className="prm10">
                  {Number(props.orderProduct.OrderAmount).toFixed(2)} kr
                </span>
              </Col>
            </Row>
            {/* <Row>
                                <Col>
                                    <p className="orderDetailHeading">Item Value:</p>
                                </Col>
                                <Col>
                                    <p>{props.orderProduct.OrderAmount} kr</p>
                                </Col>
                            </Row> */}
            {/* <Row>
                                <Col>
                                    <p className="orderDetailHeading">SubTotal(Tax excl.):</p>
                                </Col>
                                <Col>
                                    <p>{props.orderProduct.OrderAmount} kr</p>
                                </Col>
                            </Row> */}
            {/* <Row>
                                <Col>
                                    <p className="orderDetailHeading">Discount :</p>
                                </Col>
                                <Col>
                                    <p>{props.orderProduct.OrderDiscAmt} kr</p>
                                </Col>
                            </Row> */}
            {Taxes &&
              Taxes.map((data, i) => (
                <Row className="pt-2 ">
                  <Col md={7} xs={8} className="orderDetailHeading">
                    {/*Tax*/}Moms {data.Tax}%
                  </Col>
                  <Col md={5} xs={4}>
                    <span className="prm10">
                      {data.Tax === 0
                        ? "Skatt är inte tillämplig"
                        : `${data.taxPrice.toFixed(2)} kr`}
                    </span>
                  </Col>
                  {/*"Tax is not applicable"*/}
                </Row>
              ))}

            {/* <Row>
                                <Col className="orderDetailHeading">Tax :</Col>
                                <Col> {props.orderProduct.OrderTaxAmt} kr</Col>
                            </Row> */}

            <Row className="pt-3 ">
              <Col md={7} xs={8} className="orderDetailHeading h6">
                {/* TOTAL (Tax Incl.) */}
                Brutto (Inkl. moms)
              </Col>
              <Col md={5} xs={4} className="h6">
                <span className="prm10">
                  {Number(props.orderProduct.OrderTotalAmt).toFixed(2)} kr
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="mt-3 mb-3">
          <Col md={12} className="text-center">
            <h3>
              {/* Order Details */}
              Order Information
            </h3>
          </Col>
        </Row>
        <Row className="text-center">
          <Col md={12}>
            {" "}
            <p>
              {" "}
              {/*Order Reference*/} Orderreferens :{" "}
              <span>{props.orderProduct.OrderNo}</span>
            </p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col md={12} className="mb-4">
            {/* Your Order on grönsakskompaniet Is Complete */}
            Din beställning på grönsakskompaniet är komplett
          </Col>
          {/* <Col md={12} className="mb-4">You have chosen the cash on delivery method. </Col> */}
          <Col md={12} className="mb-4">
            {/* Your order will be sent very soon. */}
            Din order kommmer att skickas.
          </Col>
          <Col md={12} className="mb-4">
            {/* For any query or for further information, please contact our customer support. */}
            För eventuella frågor eller ytterligare information, vänligen
            kontakta vår Kundsupport.
            <p>
              <a href="tel:+4684648050">08 464 80 50</a>
            </p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default OrderItemList;
