import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  let location = useLocation();

  useEffect(() => {
    if (pathname !== "/ProductOverview") {
      window.scrollTo(0, 0);
    } else {
      // var element = document.getElementById('box');
      // element.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      // });
      window.scrollTo(0, 0);
    }
    // document.querySelector('body').scrollTo(0,0);
  }, [pathname]);

  return children;
}

export default ScrollToTop;
