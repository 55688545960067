import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../Common/Table';
import { BrowserRouter,Redirect,Route, Switch, useHistory} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import { URL } from '../../../ServerCall/Config';
import { useEffect } from 'react';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import { Col, Form, Row } from 'reactstrap';

const headerStyle = {
    backgroundColor: '#659f2f',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 'normal',
    padding: '5px',
    position: 'sticky', 
    top: 0 ,
}

const CustomerList = (props) =>{
    const history = useHistory();
    const [data, setData] = useState([]);
    const [column, setColumn] = useState([
        { title: "Sr no", field: 'tableData.id', render:rowData => <p>{rowData.tableData.id+1}</p>},
        { title: "Företagsnummer", field: "Bsno"},
        { title: "Företagsnamn", field: "Name"},
        { title: "E-postadress", field: "mail1"},
    ]);

    useEffect(()=>{
        getCustomerList();
    }, []);

    const getCustomerList = () =>{
        axiosApiInstance.get(`${URL}/users/getCustomerEnq`)
        .then(res=>{
            // console.log(res);
            if(res.status === 200){    
                let customersData = res.data.data;
                setData(customersData);
            }
            else{

            }
        })
        .catch(err=>{console.log(err);})
    }

    return(
        <Container fluid className="p-0 customerlistwrap" component="main">
            <Card className="shadow cardHeight">
                <Row>
                    <Col>
                    <h3 className="text-center blueText mt-2">
                        {/* Customer List */}
                        Kundlista
                    </h3>
                    </Col>
                </Row>
                <Form>
                    <CssBaseline />
                    <Col>
                        <Table data={data} 
                        columns={column} 
                        maxBodyHeight="700px" 
                        pageSize={20} 
                        paging={true} 
                        search={true}
                        headerStyle={headerStyle}
                        actions={[
                            {
                              icon: "visibility",
                              tooltip: "View",
                              onClick: (event, rowData) => {
                                history.push(
                                  `/CustomerDetails/${rowData.Actno}`
                                );
                                // window.location.href = `/CustomerDetails/${rowData.Actno}`;
                              },
                            },
                        ]}
                        />
                    </Col>
                </Form>
            </Card>  
        </Container>
    );
}

export default CustomerList;