import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// import VisibleItemList from '../containers/VisibleItemList'
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import { Nav } from "reactstrap";

import { setClient } from "../../../redux/action/customerAction";
import Auth from "../../../ServerCall/Auth";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import { URL } from "../../../ServerCall/Config";
import SubMenu from "./SubMenu";

const config = {
  headers: {
    "content-type": "application/json",
  },
};
const drawerWidth = 240;
const acessToken = Auth.getToken();
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "&$selected": {
      backgroundColor: "red",
      color: "white",
    },
  },
  drawer: {
    [theme.breakpoints.down("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.down("lg")]: {
      width: `calc(100%)`,
      marginLeft: 0,
    },
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    float: "right",
    marginTop: 5,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("md")]: {
      // position: 'relative',
    },
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function MobViewDrawer(props) {
  //   const dummyCategories = ['Hokusai', 'Hiroshige', 'Utamaro', 'Kuniyoshi', 'Yoshitoshi']

  const classes = useStyles();

  // const theme = useTheme();

  const { url } = useRouteMatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSec, setOpenSec] = React.useState(false);
  const [openThir, setOpenThir] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [list, setList] = React.useState([]);
  const [savedList, setSavedList] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(1);
  const id = Auth.getUserId();
  const customerNo = props.customerNo;
  let location = useLocation();

  React.useEffect(() => {
    //  var updated_url= document.location.hostname + '/' + url[ url.length - 2 ] + '/' + url[ url.length - 1 ];
    sortmentList();
    if (id !== undefined && customerNo !== "") {
      getSavedList();
    }
  }, [customerNo, props.refresh]);

  const sortmentList = () => {
    axios
      .get(`${URL}/getSortList`, config)
      .then((res) => {
        if (res.status === 200) {
          //console.log(location, "url");
          let data = res.data.data;
          const newData = data.map((file) => {
            return { ...file, colapseId: true };
          });

          if (
            location.state !== undefined &&
            location.state.titleHead !== undefined
          ) {
            //console.log(location.state, "location.state");

            var index = newData.findIndex(
              (obj) => obj.name === location.state.titleHead
            );
            newData[index].colapseId = false;
            if (location.state.subCategory !== undefined) {
              if (newData[index].Items !== undefined) {
                var childIndex = newData[index].Items.findIndex(
                  (obj) => obj.child_name === location.state.subCategory
                );
                newData[index].Items[childIndex].activeIndex = true;
                if (
                  newData[index].Items[childIndex].Sub_items !== undefined &&
                  location.state.childCategory !== undefined
                ) {
                  var subChildIndex = newData[index].Items[
                    childIndex
                  ].Sub_items.findIndex(
                    (obj) => obj.subChildName === location.state.childCategory
                  );
                  newData[index].Items[childIndex].Sub_items[
                    subChildIndex
                  ].childActiveIndex = true;
                }
              }
            }
          }
          // console.log("data", newData);
          setList(newData);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getSavedList = () => {
    if (customerNo !== "" && customerNo !== null && customerNo !== undefined)
      axiosApiInstance
        .get(`${URL}/list/${id}/${customerNo}`)
        .then((res) => {
          // debugger
          if (res.status === 200) {
            let data = res.data.data;
            let filterList = [];
            data &&
              data.map((detail) => {
                // if (detail.scheduleActiveList===1 || detail.scheduleActiveList===null) {
                filterList.push(detail);
                // }
              });
            setSavedList(filterList);
            // console.log(filterList);

            // let toggleKey = [];
            // data && data.map(detail => {
            //   toggleKey.push({ key: detail.PK_Listno, value: true })
            // });
            // setTogArray(toggleKey);
            // console.log(toggleKey, 'j');
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const toggleMenu = (colapseId, id) => {
    let newArr = [...list];
    var index = newArr.findIndex((obj) => obj.parentId === id);
    for (var j = 0; j < newArr.length; j++) {
      if (j === index) {
        newArr[j].colapseId = !colapseId;
      } else {
        newArr[j].colapseId = true;
      }
      if (newArr[j].Items !== undefined) {
        for (var k = 0; k < newArr[j].Items.length; k++) {
          newArr[j].Items[k].activeIndex = false;
          if (newArr[j].Items[k].Sub_items !== undefined) {
            for (var l = 0; l < newArr[j].Items[k].Sub_items.length; l++) {
              newArr[j].Items[k].Sub_items[l].childActiveIndex = false;
            }
          }
        }
      }
    }
    setList(newArr);
  };

  const intentFunct = (id) => {
    setActiveIndex(id);
  };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(index === 1 ? !open : open);
    setOpenSec(index === 2 ? !openSec : openSec);
    setOpenThir(index === 3 ? !openThir : openThir);
  };

  const drawer = (
    <div className={classes.toolbar}>
      <List className="MobNavigation">
        <ListItem button onClick={handleDrawerToggle} component={Link} to={"/"}>
          <ListItemText primary="Hem" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
          component={Link}
          to={{ pathname: "/ProductOverview", state: { default: "defaultId" } }}
        >
          <ListItemText primary="Produkter" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Nav vertical className="list-unstyled pb-3 sideNv MobView ">
              {list &&
                list.map((sortItem, index) => (
                  <SubMenu
                    key={sortItem.parentId}
                    colapseId={sortItem.colapseId}
                    toggleMenu={toggleMenu}
                    title={sortItem.name}
                    linkItem={`${
                      url === "/ProductDetail"
                        ? "/ProductOverview"
                        : "/ProductOverview"
                    }/${sortItem.name}/${sortItem.parentId}`}
                    id={sortItem.parentId}
                    img={sortItem.Img}
                    items={sortItem.Items}
                    className="mr-4"
                    onClick={() => {
                      intentFunct(2);
                      handleDrawerToggle();
                    }}
                    // className={activeIndex === 2 ? "setActiveTab" : ""}
                  />
                ))}
            </Nav>
          </List>
        </Collapse>
        <ListItem
          button
          onClick={(event) => handleListItemClick(event, 2)}
          selected={selectedIndex === 2}
        >
          <ListItemText primary="Information" />
          {openSec ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSec} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/TermsConditions"}
            >
              <ListItemText primary="Allmäna Villkor" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/PrivacyPolicy"}
            >
              <ListItemText primary="Integritetspolicy" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/AboutUs"}
            >
              <ListItemText primary="Om Oss" />
            </ListItem>
          </List>
        </Collapse>
        {acessToken ? (
          <span>
            <ListItem
              button
              onClick={(event) => handleListItemClick(event, 3)}
              selected={selectedIndex === 3}
            >
              <ListItemText primary="Sparade Listor" />
              {openThir ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openThir} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {savedList.length > 0 ? (
                  savedList &&
                  savedList.map((data, i) => (
                    <ListItem
                      button
                      className={classes.nested}
                      component={Link}
                      to={`/SavedList?id=${data.PK_Listno}`}
                      key={i}
                    >
                      <ListItemText
                        primary={`${data.List_Descr} (${data.Count})`}
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem className={classes.nested}>
                    <ListItemText primary="Ingen lista sparad" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <ListItem
              button
              onClick={handleDrawerToggle}
              component={Link}
              to={"/OrderDetail"}
            >
              <ListItemText primary="Order Historik" />
            </ListItem>
            <ListItem
              button
              onClick={handleDrawerToggle}
              component={Link}
              to={"/ChangePassword"}
            >
              <ListItemText primary="Ändra lösenord" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                handleDrawerToggle();
                Auth.logout();
              }}
              component={Link}
            >
              <ListItemText primary="Logga Ut" />
            </ListItem>
          </span>
        ) : null}
      </List>
    </div>
  );
  return (
    <div className={[classes.root + " " + "MenuDrawer"]}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <span style={{ width: 200 }} className="mobLogo mt-2">
            <Link to="/">
              <img src="https://gronsakskompaniet.se/gronsakslogo.png" />
            </Link>
          </span>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              className={[classes.closeMenuButton + " " + "DrawerCloseBtn"]}
            >
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={[classes.drawer + " " + "DeskDrawer"]}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <div className={[classes.toolbar + " " + "DrawerToolBar"]} />
        {/* <VisibleItemList /> */}
      </div>
    </div>
  );
}
MobViewDrawer.propTypes = {
  container: PropTypes.object,
};

const mapStoreToProps = (state) => {
  //console.log(state,"state");
  return {
    adminAccess: state.customer.adminAccess,
    customerNo: state.customer.customerNo,
    refresh: state.customer.refresh,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setClient: (purchase) => dispatch(setClient(purchase)),
});
export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(MobViewDrawer)
);
