import * as React from "react";
import { Chart } from "react-google-charts";

const CustomCharts = ({data, chartType, colors}) => {
    return(
        <Chart
            width={'100%'}
            height={'300px'}
            chartType={chartType}
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
                colors: colors
            }}
            rootProps={{ 'data-testid': '1' }}
        />
    )
}

export default CustomCharts;
