
import React from 'react';
import {
    Container ,Row,Col,Button, Card, CardImg, CardBody,
    CardTitle, CardSubtitle
  } from 'reactstrap';
  import Header from '../../components/header/Header'
  import grocerryClub from '../../assets/images/grocerryClub.png'
  import Map from './Components/Map'
  import Footer from '../../components/footer/Footer'
const FindStore = () => {
    return(
        <div>
             <Header/>
        <Container fluid>
            <Row>
                <Col className="mt-5 mb-3">
                    <h3>Find Store</h3>
                    <p>We are located in various locations around Sweden. Choose your nearest store or click on the map.</p>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <Map/>
                </Col>
            </Row>
            <Row>
                <Col>
                <div>
                        <div className="InnerWp"></div>
                        <div className="clubdetailImgwp">
                                <img src={grocerryClub} className="clubDetailImg" alt={"Club"}/>
                        </div>
                    </div>
                    <Row>
                        <Col className="BannerTextWp">
                            <p className="bannerHdTxt">WELCOME TO Store </p>
                            <p>
                                    Now you can become a member and get access to a store that was previously only open to restaurants
                            </p>
                            <Button>Sign Up</Button>
                        </Col>
                        </Row>
                </Col>
            </Row>
            </Container>
            <Container>
            <Row className="mt-5">
                <Col>
                    <Card>
                        <CardImg top width="100%" src={grocerryClub} alt="Card image cap" className="CardclubDetail" />
                        <CardBody>
                        <CardTitle tag="h5">About Store</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Read about membership and club here.</CardSubtitle>
                        <Button className="btnAddShop">Checkout</Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardImg top width="100%" src={grocerryClub} alt="Card image cap" className="CardclubDetail" />
                        <CardBody>
                        <CardTitle tag="h5">About Store</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Read about membership and club here.</CardSubtitle>
                        <Button className="btnAddShop">Checkout</Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardImg top width="100%" src={grocerryClub} alt="Card image cap" className="CardclubDetail" />
                        <CardBody>
                        <CardTitle tag="h5">About Store</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Read about membership and club here.</CardSubtitle>
                        <Button className="btnAddShop">Checkout</Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </Container>
            <Footer/>
            </div>
    );
}

export default FindStore;