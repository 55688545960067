import React from 'react';
import Container from '@material-ui/core/Container';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import axiosApiInstance from '../../../ServerCall/Axios.Instance';
import { useLocation } from 'react-router-dom';
import { URL } from '../../../ServerCall/Config';

const CustomerDetails = (props) =>{
    let location = useLocation();

    const [state, setState] = useState({
        CorporateNumber: "",
        BusinessName: "",
        PostalCode: "",
        Address: "",
        postalAddress: "",
        PhoneBusiness: "",
        FirstNameContactPerson: "",
        Email: "",
        LastNameContactPerson: "",
        MobileNumberContactPerson: "",
        NearestTown: "",
        TypeOfBusiness: "",
        ECommerce: false,
        ViaMail: false,
        ViaText: false
    });

    const [nearestTown, setNearestTown] = React.useState([
        {id: 1, label: "Södertälje"},
        {id: 2, label: "Solna"},
        {id: 3, label: "Stockholm"},
        {id: 4, label: "Uppsala"},
    ]);

    const [typesBusiness, setTypesBusiness] = React.useState([
        {id: 1, label: "Sole trader"},
        {id: 2, label: "Limited company"},
        {id: 3, label: "Trading partnership"},
        {id: 4, label: "Economic association"},
        {id: 5, label: "Non-profit association"},
        {id: 6, label: "Community association"},
    ]);

    useEffect(()=>{
        let ActNo = location.pathname.split("/").slice(-2)[1];
        getCustomerDetails(ActNo);
    }, []);

    const getCustomerDetails = (ActNo) =>{
        axiosApiInstance.get(`${URL}/users/getCustomerEnqById/${ActNo}`)
        .then(res =>{
            // console.log(res);
            let customer = res.data.data;
            
            let townID = customer.townId;
            let bussinessID = customer.bussinessId;

            let town;
            let type;

            nearestTown && nearestTown.map(data=>{
                if(data.id === townID){
                    town = data.label;
                }
            });

            typesBusiness && typesBusiness.map(data=>{
                if(data.id === bussinessID){
                    type = data.label;
                }
            });

            setState({
                CorporateNumber: customer.Bsno,
                BusinessName: customer.Name,
                PostalCode: customer.Post_dress,
                Address: customer.Addressrow_1,
                postalAddress: customer.Addressrow_2,
                PhoneBusiness: customer.Phone_1,
                FirstNameContactPerson: customer.firstNameContactPerson,
                Email: customer.mail1,
                LastNameContactPerson: customer.lastNameContactPerson,
                MobileNumberContactPerson: customer.MobilePh_1,
                NearestTown: town,
                TypeOfBusiness: type,
                ECommerce: customer.isEcommerceCust,
                ViaMail: customer.offerViaEmail,
                ViaText: customer.offerViaTextMessage,

            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const {CorporateNumber, BusinessName, PostalCode, Address, postalAddress, PhoneBusiness, FirstNameContactPerson,
         Email, LastNameContactPerson, MobileNumberContactPerson, NearestTown,
         TypeOfBusiness, ECommerce, ViaMail, ViaText,} = state;
    return(
        <Container className="" component="main" maxWidth="md">
            <Card className="shadow p-4 col-md-12 cardHeight">
                <Row>
                    <Col className="mb-4">
                    <h2 className="text-center blueText"><u>
                        {/* Customer Details */}
                        Kunddetaljer
                    </u></h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-4">
                        {/* Corporate Number : */}
                        Företagsnummer :
                    </Col>
                    <Col>
                    {CorporateNumber}
                    </Col>

                    <Col></Col>
                    <Col></Col>
                </Row>
                <Row className="text-left">
                    <Col className="mb-4">
                        {/* Business Name : */}
                        Företagsnamn :
                    </Col>
                    <Col>
                    {BusinessName}
                    </Col>

                    <Col className="mb-4">
                        {/* Address : */}
                        Adress :
                    </Col>
                    <Col>
                    {Address}
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-4">
                        {/* Postal Address : */}
                        Postadress :
                    </Col>
                    <Col>
                    {postalAddress}
                    </Col>

                    <Col className="mb-4">
                        {/* Postal Code : */}
                        Postnummer :
                    </Col>
                    <Col>
                    {PostalCode}
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-4">
                        {/* Nearest Town : */}
                        Närmsta stad :
                    </Col>
                    <Col>
                    {NearestTown}
                    </Col>

                    <Col className="mb-4">
                        {/* Type of Business : */}
                        Typ av verksamhet :
                    </Col>
                    <Col>
                    {TypeOfBusiness}
                    </Col>
                </Row>
                {/* <Row>Business</Row> */}
                <Row>
                    <Col className="mb-4">
                        {/* Business Phone No : */}
                        Företagsnummer :
                    </Col>
                    <Col>
                    {PhoneBusiness}
                    </Col>

                    <Col></Col> 
                    <Col></Col>                    
                </Row>
                <Row>
                    <Col className="mb-4">
                        {/* Email : */}
                        E-postadress :
                    </Col>
                    <Col>
                    {Email}
                    </Col>

                    <Col></Col>                    
                    <Col></Col>                    
                </Row>
                
                <hr/>
                <div className="text-center"><h5>
                    {/* Contact Person */}
                    Kontaktperson
                </h5></div>
                <br/>
                <Row>
                    <Col className="mb-4">
                        {/* First name : */}
                        Förnamn :
                    </Col>
                    <Col>
                    {FirstNameContactPerson}
                    </Col>

                    <Col className="mb-4">
                        {/* Last Name : */}
                        Efternamn :
                    </Col>
                    <Col>
                    {LastNameContactPerson}
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-4">
                        {/* Mobile No : */}
                        Mobilnummer :
                    </Col>
                    <Col>
                    {MobileNumberContactPerson}
                    </Col>
                    
                    <Col></Col>
                    <Col></Col>
                </Row>
                <hr/>
                
                {/* <div className="text-center">Want Update Via</div><br/> */}
                <div className="text-center"><h5>
                    {/* Want Update Via */}
                    Vill ha uppdateringar via
                </h5></div>
                <br/>
                <Row>
                    <Col className="mb-4">
                        {/* Email : */}
                        E-postadress :
                    </Col>
                    <Col>
                    {ViaMail === true? "Ja" : "Nej"}
                    </Col>

                    <Col className="mb-4">
                        {/* Text Message : */}
                        Textmeddelande :
                    </Col>
                    <Col>
                    {ViaText === true? "Ja" : "Nej"}
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-4">
                        {/* Want an E-Commerce Customer : */}
                        Vill ansluta till e-handelsplattform :
                    </Col>
                    <Col>
                    {ECommerce === true? "Ja" : "Nej"}
                    </Col>
                    
                    <Col></Col>
                    <Col></Col>
                </Row>
            </Card>
        </Container>
    );
}

export default CustomerDetails;