import React from "react";
import Container from "@material-ui/core/Container";
import { Card, Col, Row } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { URL } from "../../ServerCall/Config";
import Loader from "../Common/Loader";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let isValid = true;
    const validEmailRegex = RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
    );

    if (!email) {
      // setErrorEmail('Please Enter Email Address');
      setErrorEmail("Vänligen ange e-postadress");
      isValid = false;
    } else if (!validEmailRegex.test(email)) {
      // setErrorEmail('Enter Valid Email Address');
      setErrorEmail("Ange en giltig e-postadress");
      isValid = false;
    } else {
      setErrorEmail("");
    }

    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = validate();
    if (isValid) {
      let credential = {
        email: email,
      };
      setLoading(true);
      axios
        .post(`${URL}/users/forgotPassword`, credential)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setLoading(false);
            if (res.data.status === 400) {
              // swal("Email Not Found !", "", "warning");
              swal("E-post hittades inte! ", "", "warning");
            }
            if (res.data.status === 200) {
              // swal("Reset Your Password",'Link send to your registered mail',"info");
              swal(
                "Återställ ditt lösenord",
                "Länken skickas till din registrerade e-postadress",
                "info"
              ).then(() => {
                window.location.replace("/");
              });
            }
          }
        })
        .catch((err) => {
          console.log("Test", err);
          setLoading(false);
        });
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <Container
        className="LoginContainer formWraper forgotPassword-cont"
        component="main"
        maxWidth="md"
      >
        <Card className="p-4 forgot-card">
          <Row>
            <Col className="text-center">
              <LockRoundedIcon fontSize="large" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="text-center">
                {/* Forgot Password */}
                Glömt Lösenord
              </h4>
            </Col>
          </Row>
          <hr />
          <form>
            <Row>
              <Col>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="email"
                  // label="Email Address"
                  label="E-postadress"
                  type="email"
                  id="email"
                  autoComplete="current-email"
                />
                {errorEmail !== "" ? (
                  <label style={{ fontSize: 12, color: "red" }}>
                    {errorEmail}
                  </label>
                ) : null}
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="mt-0 mb-3"
                  onClick={(e) => onSubmit(e)}
                >
                  {/* Submit */}
                  Skicka in
                </Button>
              </Col>
            </Row>
          </form>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
