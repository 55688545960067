import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from '../../components/header/Header';
import InfoBnr from '../../assets/images/infoBanner.jpg';
import Footer from '../../components/footer/Footer';

const TermsConditions = () => {
    return(
        <div>
        <Header />
        <Container fluid className="InfoWrap wrapper">
            <Row>
            {/* <Col md={3} className="ProdsidebarWp">
                <Sidebar />
            </Col> */}
            <Col md={12}>
                <Row>
                <Col className="">
                    <div className="InfoBannerBg">
                    <img src={InfoBnr} />
                    </div>
                    <h2 className="InfoBannerHeadText">{"Allmänna Villkor"}</h2>
                    {/* <p className="detailText">{"Here you will find gk selection from the range within Bread, Pastries &amp; Ice Cream.If you are missing something, you can contact your store.We lcome to our range! Browse our categories and find the items that suit you."} </p> */}
                </Col>
                </Row>
                <Row id="box">
                <Col md={12} className="InfoTxt">
                    <p>Allmänna villkor gällandes Grabbarnas Grönt AB org.nr: 556913-4009 Adress: Konsumentvägen 9, 125 30 Älvsjö.</p>
                    
                    <p><strong>1. Allmänt</strong><br />
                       Avtalet mellan parterna utgörs av dessa allmänna villkor och tillhörande dokument som särskilt har upprättas mellan parterna, om så inget annat skriftligen har överenskommit. Avtalshandlingarna är avsedda att komplettera varandra samtliga tidigare muntliga och skriftliga avtal mellan parterna. Skulle innehållet i en vis handling vara motstridigt i förhållande till innehållet skall detta regleras specifikt enligt enighet till båda parter. Grönsakskompaniet förbehåller sig rätten att fortlöpande ensidigt ändra de allmänna villkoren och bilagor i syfte att nya villkor skall tillämpas mot samtliga kunder. Sker någon förändring i allmänna villkor kommer detta att publiceras minst två veckor innan förändringen träder i kraft. Vid förändring kommer Grönsakskompaniet även skicka ut uppdateringen till den e-postadress som angivits vid kundregisteringen. Genom att försätta att handla efter uppdaterade villkor har trätt i kraft anses detta att kunden har godkänt förändringarna.</p>
                    
                    <p><strong>2. Kundnummer </strong><br />
                       För att kunna genomföra köp från Grönsakskompaniet krävs det att kunden skall inneha ett giltigt kundnummer. Kunden kommer att ha möjlighet att använda kundnumret för beställning i både e-handelssidan samt för beställning via telefon eller e-post och sms. </p>
                    
                    <p><strong>3. Köp/Beställning av varor e-handel</strong><br />
                       Köp av varor igenom e-handelssidan kommer att vara möjligt efter ett godkännande från Grönsakskompaniet i samband till anslutningen av e-handelssidan. Vid beställningar via e-handel, anses ett köp föreligga då beställningen lagts och kunden har fått en orderbekräftelse samt en bekräftelse på att beställningen är klar för leverans eller upphämtning.</p>
                    
                    <p><strong>4. Ansvar för uppgifter</strong><br />
                       Alla ändringar som sker som lämnats i samband med kundansökan skall kunden meddela till Grönsakskompaniet. Detta inkluderar bland annat kontaktuppgifter, leveransadresser, betalningsförmåga och ändrad bolagsform. Grönsakskompaniet ansvarar inte för brister som uppstår på grund av felaktiga eller inaktuell information.</p>
                    
                    <p><strong>5. Personuppgifter</strong><br />
                       Grönsakskompaniet behandlar uppgifterna för att genomföra vår verksamhet och strävar alltid efter att ge dig som registrerad den bästa möjliga personuppgiftshanteringen. Personuppgifter är alla uppgifter som direkt eller indirekt, ensam eller i kombination med andra, kan identifiera en fysisk person. Sådana uppgifter kan exempelvis vara namn, kontaktuppgifter eller lokaliseringsuppgifter, även företags- mail och telefonnummer utgör personuppgifter om de kan kopplas till en fysisk person. Personuppgifterna kan även komma att användas för marknasföringssyfte, vilket sker på grundval av Grönsakskompaniet berättigade intresse av att kommunicera med våra kunder.</p>
                    <p>Grönsakskompaniet lämnar inte ut personuppgifter till utomstående annat än i dem nedanstående fallen.</p>
                    <ul>
                        <li>då levererande restaurang eller budfirma behöver uppgifterna för att fullgöra sitt uppdrag till följd av avtal mellan kunden och Grönsakskompaniet,</li>
                        <li>då utomstående tjänsteleverantörer utför uppdrag för Grönsakskompaniet räkning,</li>
                        <li>då det särskilt överenskommits mellan Grönsakskompaniet och dig, </li>
                        <li>då det är nödvändigt för att tillvarata dina rättigheter eller</li>
                        <li>då det är nödvändigt för att vi skall fullgöra lagstadgad skyldighet eller efterkomma myndighetsbeslut eller beslut av domstol.</li>
                    </ul>
                    <p>Grönsakskompaniet är personuppgiftsansvarig för sådan behandling och ansvarar för att behandlingen sker i enlighet med vid var tid gällande dataskyddslagstiftning. Grönsakskompaniet tillämpar sin vid vad tid gällande integritetspolicy vid behandlingen av personuppgifter. Integritetspolicyn finns att hämta via vår hemsida ”länk”</p>
                    
                    <p><strong>6. Retur/Reklamationer </strong><br />
                       Grönsakskompaniet godkänner reklamationer där det föreligger ett produktfel som påtalas i samband med godsmottagning, eller fel av sådant fall där kunde inte rimligen borde ha upptäckt felet i samband med leverans eller köp. En reklamation skall vara tillhanda så snart felet upptäckt utan något dröjsmål. Reklamationer godkänns ej om utgångsdatum/bäst före datum har passerat. Kunden skall också kunna styrka att felet inte uppstått på grund av kunden hantering om produkten returneras efter mottagning av gods.</p>
                    <p>Retur av varor skall följa Grönsakskompaniet riktlinjer. Detta skall efterföljas för att Grönsakskompaniet skall kunna garantera produktsäkerhet och lagstadga hantering kring produkterna.</p>
                    <ul>
                        <li>Varan skall vara i originalförpackning,</li>
                        <li>Ej brutna förpackningar,</li>
                        <li>Ej Frysvaror, </li>
                    </ul>

                    <p><strong>7. Överlåtelse/ Avslut av kundnummer</strong><br />
                       Kunden har ingen rätt att överlåta sitt kundnummer samt rättigheter och/eller skyldigheter enligt avtalet till någon annan. Det gäller även vid överlåtelse av kundens verksamhet. Den nya innehavaren av verksamheten måste igenom gå en egen ansökan om att få ett nytt kundnummer. Även andra väsentliga förändringar av bolaget eller verksamheten kan innebära att kunden måste ansöka om ett nytt kundnummer. Kunden har ett eget ansvar att informera Grönsakskompaniet om en förändring av större karaktär samt avsluta sitt kundnummer i de fall där verksamheten skall avyttras eller avslutas hel eller delvis.</p>
                    <p>Grönsakskompaniet har rätt att avsluta omgående kundens kundnummer om kunden har brutit mot de allmänna villkoren och dess bilagor, om kunden inte använt sitt kundnummer på tolv månader, eller om kunden eller representanten brutit mot lagen. Om uppsägning sker skall samtliga överenskommelser mellan parterna upphöra, inklusive avtalade bonusbilagor och e-handelsöverenskommelser. </p>
 

                    <p><strong>8. Immateriella rättigheter</strong><br />
                       Kunden erhåller inga immateriella rättigheter från Grönsakskompaniet genom kundnumret eller genom att handla eller någon rätt att vare sig indirekt eller direkt, att för egen eller annans räkning använda Grönsakskompaniet varumärken som Grönsakskompaniet förfogar över.</p>

                    <p><strong>9. Ansvarsbegränsning </strong><br />
                       Grönsakskompaniet ansvarar för sina eventuella fel och brister i sitt åtagande enligt detta eller andra avtal med kunden är begränsat till varans värde. Grönsakskompaniet har under inga omständigheter ansvar för kundens indirekta skador som uppstår till flöjd av fel eller brister i varor eller andra förbindelser.</p>

                    <p><strong>10.	Force Majeure</strong><br />
                       Om Grönsakskompaniet arbete omöjliggörs eller märkbart försvåras på grund av omständigheter som är bortom Grönsakkompaniets kontroll och som Grönsakskompaniet intet rimligen kunnat förutse (”Force Majure”) ska Grönsakskompaniet befrias från alla sina skyldigheter enligt avtal så länge hindret föreligger. Grönsakskompaniet skall utan dröjsmål informera kunden om hinder enligt denna punkt föreligger. Grönsakskompaniet ansvarar inte för eventuella brister i Grönsakskompaniet åtaganden som uppstår till följd av ”Force Majure”.</p>

                    <p><strong>11.	Lag och tvist </strong><br /> 
                       Svensk lag skall tillämpas på avtalsförhållanden mellan parterna. Tvist som uppstår i anledning av avtalet mellan kund och Grönsakskompaniet skall slutligt avgöras genom skiljedom enligt skiljedomsregler för (”Stockholms handelskammares skiljedomsinstitut”). Skiljenämnden ska bestå av en skiljeman. Skiljeförfarandets säte ska vara Stockholm och skiljeförfarandet ska ske på det svenska språket. Skiljeförfarande som påkallats med hänvisning till denna skiljeklausul omfattas av sekretess.</p>
                </Col>
                </Row>
            </Col>
            </Row>
            {/* <Row >
            <Col>
                <SubscribeOffers />
            </Col>
            </Row> */}
        </Container>
        <Footer />
        </div>
    );
}

export default TermsConditions;