import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/FavoriteBorder";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Container, Input } from "reactstrap";
const useStyles = makeStyles({
  avatar: {
    backgroundColor: "#659f2f",
    // color: blue[600],
  },
});

const SaveListModal = (props) => {
  const [show, setShow] = useState(false);
  const [saveList, setSaveList] = useState();
  const { onClose, selectedValue, open } = props;
  const [addForm, setaddForm] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setShow(false);
    setaddForm(false);
    setSaveList("");
    props.clearInput();
  };
  const handleShow = () => {
    props.getList();
    setShow(true);
  };
  const [state, setState] = React.useState({
    list: false,
  });

  const onClick = () => {
    setShow(false);
    handleClose();
    setaddForm(false);
    props.CreatehandleSubmit(
      props.productNo,
      props.price,
      props.Qty,
      props.purchased_Unit,
      props.Base_price,
      props.pcPerBox,
      props.UnitCount,
      props.BaseUnit,
      props.Kampanj,
      props.IsMultipleProduct
    );
  };
  const { Week1, Week2, Week3 } = state;
  const error = [Week1, Week2, Week3].filter((v) => v).length !== 2;

  // let options = props.options;

  const handelSavedList = () => {
    setShow(false);
    const PK_Listno = saveList.selected.value;
    const schId = saveList.selected.scheduleId;
    if (props.IsMultipleProduct) {
      props.onAnswerSelectedAll(PK_Listno, props.IsMultipleProduct, schId);
    } else {
      props.onAnswerSelected(
        PK_Listno,
        props.productNo,
        props.price,
        props.Qty,
        props.purchased_Unit,
        props.Base_price,
        props.pcPerBox,
        props.UnitCount,
        props.BaseUnit,
        props.Kampanj,
        props.IsMultipleProduct,
        schId
      );
    }
  };

  const handleToggle = () => {
    if (addForm === false) {
      setaddForm(true);
    } else {
      setaddForm(false);
    }
  };
  return (
    <div>
      {/* Save As List */}
      <FavoriteIcon onClick={handleShow} onChange={props.getList} />
      <Modal show={show} onHide={handleClose} className="m-0 p-0 " centered>
        <Modal.Header className="pt-3 pb-2" closeButton>
          <Modal.Title className="pb-0">
            {addForm !== true ? `LÄGG TILL I LISTA ` : `SKAPA EN NY LISTA`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="align-self-center">
          <Container>
            <Row>
              {addForm !== true ? (
                <Col xl={12} md={12}>
                  <Select
                    options={props.options}
                    className="savedListMod"
                    onChange={(selected) => {
                      setSaveList({ selected });
                    }}
                    placeholder="Välj befintlig lista"
                  />
                </Col>
              ) : (
                <Col>
                  <Input
                    type="text"
                    name="List_Descr"
                    id=""
                    placeholder="Ange listans namn"
                    onChange={props.handleChangeList}
                    value={props.List_Descr}
                    className="savedListMod"
                  />
                </Col>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer id="saveListFooter " className="justify-content-center">
          <div className="m-0 p-0">
            {addForm !== true ? (
              <Button
                id="savebtn1"
                className="ml-3 btn btn-success"
                onClick={handelSavedList}
                disabled={saveList ? false : true}
                centered
              >
                Spara
              </Button>
            ) : props.List_Descr.trim() !== undefined &&
              props.List_Descr.trim() !== null &&
              props.List_Descr.trim() !== "" ? (
              <Button
                id="savebtn2"
                className="ml-3 btn btn-success"
                onClick={onClick}
              >
                Spara
              </Button>
            ) : (
              <Button
                id="savebtn3"
                className="ml-3 btn btn-success disabled px-5"
              >
                Spara
              </Button>
            )}
          </div>
          <Button onClick={handleToggle} className="ml-3 btn btn-success">
            {addForm !== true
              ? `Eller skapa en ny`
              : `Eller lägg till befintliga`}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default SaveListModal;
SaveListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
