import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Sidebar from "../../components/header/components/Sidebar";
import Header from "../../components/header/Header";
import ProductDetailContent from "./Components/ProductDetailContent";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import { URL } from "../../ServerCall/Config";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const config = {
  headers: {
    "content-type": "application/json",
  },
};

// class ProductDetail extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state ={
//           products: [],
//           customerNo:0,
//           reletdProducts:[
//             {id:1,ProductName:"Product Name",Price:'$34'},{id:2,ProductName:"Coconut",Price:'$36'},
//             {id:3,ProductName:"Coconut",Price:'$36'},{id:4,ProductName:"Coconut",Price:'$36'},{id:5,ProductName:"Coconut",Price:'$36'}
//           ]
//         }
//     }

const ProductDetail = (props) => {
  const [customerNo, setCustomerNo] = React.useState();
  const [products, setProducts] = React.useState([]);
  const [preProduct, setPreProduct] = React.useState([]);
  const [productType, setProductType] = React.useState("");
  //const history = useHistory();

  React.useEffect(async () => {
    let productId = props.location.search.slice(4);
    let CustNo = props.customerNo;
    let type = "";
    if (props.location.state !== undefined) {
      type = props.location.state.productType;
      localStorage.setItem("preUrl", JSON.stringify(props.location));
      localStorage.setItem("sortUrl", JSON.stringify(props.location));

      setPreProduct(props.location.state.Products);
      setProductType(type);
    }
    await getProductDetails(CustNo, productId, type);
  }, [props.customerNo]);

  //  async componentDidMount(){
  //     let productId = this.props.location.search.slice(4);
  //     let type = '';
  //     if(this.props.location.state !== undefined){
  //      type = this.props.location.state.productType;
  //     }
  //     let CustNo =  this.props.customerNo;
  //     if(CustNo === null){
  //       await this.setState({customerNo:0});
  //     }
  //     else{
  //     await this.setState({customerNo:CustNo});
  //     }
  //    await this.getProductDetails(productId,type);
  // }

  const getProductDetails = (CustNo, id, type) => {
    if (CustNo === null || CustNo === "") {
      setCustomerNo(0);
      CustNo = 0;
    } else {
      setCustomerNo(CustNo);
    }
    axios
      .get(`${URL}/products/${id}/${CustNo}/${type}`, config)
      .then(async (res) => {
        if (res.status === 200) {
          const newProduct = res.data.map((file) => {
            if (file.Standard_orderunit === "Kg") {
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "Kg",
                newCompPrice: file.comp_Price_cart,
                Comp_Price: file.comp_Price_cart,
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                newPrice: file.comp_Price_cart,
                default_price: file.comp_Price_cart,
                default_value: file.Conv_facor_KG,
                unite_price: file.comp_Price_cart,
                box_com_cart_price: file.Comp_Price,
              };
            } else if (file.Standard_orderunit === "Kart")
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "Kart",
                newCompPrice: file.Comp_Price,
                Comp_Price: file.Conv_facor_KG * file.Price_cart,
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                newPrice: file.Price_cart,
                default_price: file.Price_cart,
                default_value: file.Conv_facor_CART,
                unite_price: file.Price_cart,
                box_com_cart_price: file.comp_Price_cart,
              };
            else if (file.Standard_orderunit === "Kartplus") {
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "Kartplus",
                newCompPrice: file.Comp_Price,
                Comp_Price:
                  file.Conv_facor_KG !== 0 && file.Price_cart !== 0
                    ? file.Conv_facor_KG * file.Price_cart
                    : file.Comp_Price,
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                newPrice: file.Price_cart,
                default_price: file.Price_cart,
                default_value: file.Conv_facor_CART,
                unite_price: file.Price_cart,
                box_com_cart_price: file.comp_Price_cart,
              };
            } else if (file.Standard_orderunit === "Styck")
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "Styck",
                Comp_Price: file.Comp_Price,
                newCompPrice: file.Comp_Price,
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                newPrice: file.Price_broken,
                default_price: file.Price_broken,
                default_value: file.Conv_facor_ST,
                unite_price: file.Price_broken,
                box_com_cart_price: file.comp_Price_cart,
              };
            else
              return {
                ...file,
                stdOrder_Unit: 0,
                hiddenText: true,
                purchased_Unit: "",
                newQuantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                Min_Quantity: file.Min_Quantity === 0 ? 1 : file.Min_Quantity,
                default_price: file.Comp_Price,
                newPrice: file.Comp_Price,
                unite_price: file.Comp_Price,
                box_com_cart_price: file.comp_Price_cart,
              };
          });
          await setProducts(newProduct);
        } else {
          console.log("else");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onclcikEvent = (e, i) => {
    setTimeout(() => {
      e.target.select();
    }, 20);
    let newArr = [...products];
    newArr[i].hiddenText = false;
    setProducts(newArr);
  };

  const onMouseLeaveEvent = (e, i) => {
    let newArr = [...products];
    newArr[i].hiddenText = true;
    setProducts(newArr);
  };

  const IncrementItem = (qty, i, amt, minqnty, Max_Quantity, ProdNo) => {
    let quantity = parseFloat(qty) + minqnty;
    var price = amt;
    var newArr = [...products];
    var index = newArr.findIndex((obj) => obj.ProdNo === ProdNo);

    // let defaultAmt = newArr[i].unite_price;
    if (Max_Quantity === 0) {
      // console.log('this is 1');
    } else if (quantity > Max_Quantity) {
      quantity = Max_Quantity;
      // price = newArr[index].unite_price * Max_Quantity;
      newArr[index].hiddenText = true;
    } else if (quantity > 0) {
      // if (quantity === 1) {
      //   price = amt * 2;
      // } else {
      //   price = defaultAmt * quantity;
      // }
    } else {
      price = amt;
      quantity = minqnty;
    }

    if (minqnty < 1) {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity.toFixed(2);
    } else if (minqnty > 1) {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity;
    } else {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity;
    }
    setProducts(newArr);
  };

  const DecreaseItem = (qty, i, amt, minqnty) => {
    let quantity = parseFloat(qty) - minqnty;
    var price = amt;

    let newArr = [...products];
    // let defaultAmt = newArr[i].unite_price;
    //let fact_qty = 0;
    if (quantity > 0) {
      // price = amt - defaultAmt;
    } else {
      quantity = minqnty;
      price = amt;
    }

    if (minqnty < 1) {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity.toFixed(2);
    } else if (minqnty > 1) {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity;
    } else {
      newArr[i].newPrice = price;
      newArr[i].newQuantity = quantity;
    }
    // products[i].stdOrder_Unit = fact_qty;
    setProducts(newArr);
  };
  const onChangeQty = (e, i, amt, minqnty, purchaseUnit, max) => {
    var price = amt;
    if (purchaseUnit === "Kg") {
      var quantity = e.target.value.replace(/KG/g, "").trim();
    } else {
      var quantity = e.target.value.replace(/ST/g, "").trim();
      quantity = parseFloat(Math.floor(quantity));
      if (isNaN(quantity) === true || quantity === 0) {
        quantity = 0;
      }
    }
    if (max === 0) {
    } else if (quantity > max) {
      quantity = max;
    } else if (quantity > 0) {
      price = amt * quantity;
    } else {
      price = amt;
    }

    var key = e.keyCode || e.charCode;
    var newArr = [...products];
    // newArr[i].newPrice = price;
    const re = /^[0-9.]+$/;
    if (quantity === "") {
      newArr[i].newQuantity = 0;
    }
    if (re.test(quantity)) {
      newArr[i].newQuantity = quantity;
    }
    // newArr[i].newPrice = price;
    setProducts(newArr);
  };

  const onChangeUnit = (e, i, unit, kg, st, box, cKg, cSt, cBox, price) => {
    let newArr = [...products];
    const selectedIndex = e.target.options.selectedIndex;
    let purchased_Unit = e.target.options[selectedIndex].getAttribute("keyid");
    const { name, value } = e.target;
    if (purchased_Unit !== "Kart") {
      newArr[i].Comp_Price = newArr[i].newCompPrice;
      kg = newArr[i].newCompPrice;
    } else {
      newArr[i].Comp_Price = newArr[i].Price_cart;
    }
    // else if(unit === "KG" && purchased_Unit === "Kart" ){
    //   newArr[i].Comp_Price = box/cBox;
    // }
    // else if(unit === "Styck" && purchased_Unit === "Kart" ){
    //   let stWeight =  cSt/cKg;
    //   let weightPerBox = cBox*stWeight;
    //   newArr[i].Comp_Price = box/weightPerBox;
    // }
    // else if(unit === "Kart" && purchased_Unit === "Kart" ){

    //   newArr[i].Comp_Price = cKg*box;
    // }
    // debugger
    if (selectedIndex !== 0) {
      if (purchased_Unit === "Kg") {
        let changePrice = newArr[i].comp_Price_cart * cKg;
        newArr[i].newPrice = changePrice;
      } else if (purchased_Unit === "Styck") {
        let changePrice = newArr[i].Price_broken * cSt;
        newArr[i].newPrice = changePrice;
      } else {
        let changePrice = newArr[i].Price_cart * cBox;
        newArr[i].newPrice = changePrice;
      }
    } else {
      let prePrice =
        purchased_Unit === "Kg"
          ? newArr[i].comp_Price_cart
          : purchased_Unit === "Styck"
          ? newArr[i].Price_broken
          : newArr[i].Price_cart;
      newArr[i].newPrice = prePrice;
    }

    purchased_Unit === "Kg"
      ? (newArr[i].default_price = kg)
      : purchased_Unit === "Styck"
      ? (newArr[i].default_price = st)
      : (newArr[i].default_price = box);
    newArr[i].default_value = value;
    newArr[i].purchased_Unit = purchased_Unit;
    if (
      (purchased_Unit === "Kart" && newArr[i].Min_Quantity < 1) ||
      (purchased_Unit === "Kartplus" && newArr[i].Min_Quantity < 1)
    ) {
      newArr[i].newQuantity = 1;
      newArr[i].Min_Quantity = 1;
    } else {
      newArr[i].newQuantity = newArr[i].Min_Quantity;
    }

    // newArr[i].newPrice = newArr[i].default_price;
    // newArr[i].default_price = newArr[i].newPrice;
    newArr[i].unite_price = newArr[i].newPrice;
    setProducts(newArr);
  };

  return (
    <div>
      <Header />
      <Container fluid className="ProductDetailWp wrapper">
        <Row>
          <Col lg={3} sm={3} className="ProdsidebarWp padding-top-class">
            <Sidebar />
          </Col>
          <Col lg={9} sm={12} xs={12} className="pl-0 pr-0">
            <Row>
              <Col>
                {products.length > 0 ? (
                  <ProductDetailContent
                    onMouseLeaveEvent={onMouseLeaveEvent}
                    productType={productType}
                    onclcikEvent={onclcikEvent}
                    custNo={customerNo}
                    onChangeUnit={onChangeUnit}
                    onChangeQty={onChangeQty}
                    DecreaseItem={DecreaseItem}
                    IncrementItem={IncrementItem}
                    products={products}
                  />
                ) : null}
              </Col>
            </Row>
            {/* <Row className="mt-5">
                          <Col>
                            <RelatedProducts reletdProducts={reletdProducts}/>
                          </Col>
                        </Row> */}
            {/* <Row className="mt-5">
                          <Col>
                            <SameCategoryProduct reletdProducts={reletdProducts}/>
                          </Col>
                        </Row> */}
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

const mapStoreToProps = (state) => {
  return {
    customerNo: state.customer.customerNo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setCustomer:(customerNo)=>dispatch(setCustomer(customerNo)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ProductDetail);
