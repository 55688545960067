import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import swal from "sweetalert";
import { ListGroup, Dropdown, DropdownButton } from "react-bootstrap";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
  Input,
} from "reactstrap";
import Auth from "../../../ServerCall/Auth";
import { Modal } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import bkry from "../../../assets/images/picture_missing_text.png";

import LoginModal from "../../../components/header/components/LoginModal";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import { URL } from "../../../ServerCall/Config";

import {
  setCartCount,
  setTotalPrice,
  setRefTopNav,
} from "../../../redux/action/customerAction";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import SaveListModal from "./SaveListModalProductOverview";

const ProductListOverview = (props) => {
  const [Recipe_amount, setRecipe_amount] = useState(0);
  const [chdt, setChdt] = useState(new Date().toISOString());
  const [chtm, setChtm] = useState("0000");
  const [Recipe_unit, setRecipe_unit] = useState("");
  const [DataList, setDataList] = useState([]);
  const [User_id, setUser_id] = useState(Auth.getUserId());
  const [close, setClose] = useState(true);
  const [options, setOptions] = useState([]);
  const [List_Descr, setList_Descr] = useState("");
  const [Active_List, setActive_List] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [Last_Buy, setLast_Buy] = useState(new Date().toISOString());
  const [showHide, setShowHide] = useState(false);

  const [type, setType] = useState("Product");
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const [show, setShow] = useState(false);
  const acessToken = Auth.getToken();
  const handleClose = () => setShow(false);
  const [count, setCount] = useState([]);
  const [tax, setTax] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const [scrollYStore, setScrollYStore] = useState(0);

  let yAxis = window.localStorage.getItem("scrollY");

  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1800);
  };

  React.useEffect(async () => {
    getList();
    setScrollYStore(yAxis);
    if (props.condition) {
      await window.scrollTo(0, scrollYStore);
      window.localStorage.removeItem("scrollY");
    }
  }, [props.condition, scrollYStore]);

  React.useEffect(() => {
    return async () => {
      await setScrollYStore(window.scrollY);
    };
  }, []);

  const getList = () => {
    if (User_id !== undefined) {
      let CustNo = props.customerNo;
      // let CustNo = props.customerNo;
      if (
        props.customerNo !== "" &&
        props.customerNo !== null &&
        props.customerNo !== undefined
      )
        axiosApiInstance
          .get(`${URL}/list/${User_id}/${props.customerNo}`)
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              let optionsList = [];
              setDataList(data);
              data &&
                data.map((item, i) => {
                  if (
                    (item.scheduleActiveList && !item.IsDeleted) ||
                    item.scheduleActiveList === null
                  ) {
                    optionsList.push({
                      value: item.PK_Listno,
                      label: item.List_Descr,
                      scheduleId: item.ScheduleId,
                    });
                  }
                });
              setOptions(optionsList);
            } else {
              console.log("Error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
    } else {
      setDataList([]);
    }
  };
  const CreatehandleSubmit = (
    prodctNo,
    price,
    qty,
    purchased_Unit,
    base_Price,
    pcPerBox,
    UnitCount,
    BaseUnit,
    Kampanj,
    IsMultipleProduct
  ) => {
    let CustNo = props.customerNo;
    if (
      List_Descr.trim() !== undefined &&
      List_Descr.trim() !== null &&
      List_Descr.trim() !== ""
    ) {
      axiosApiInstance
        .post(`${URL}/list`, {
          User_id,
          List_Descr,
          Recipe_amount,
          Recipe_unit,
          Active_List,
          Last_Buy,
          CustNo,
          chdt,
          chtm,
        })
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            setDataList(data);
            setList_Descr("");
            // swal("List created successfully!", "", "info").then(()=>props.setRefTopNac(!refresh));
            if (data.length > 0) {
              const listno = data.length - 1;
              const pkListNo = data[listno].PK_Listno;
              const listDesc = data[listno].List_Descr;
              const scheduleId = data[listno].scheduleId;
              onAnswerSelected(
                pkListNo,
                prodctNo,
                price,
                qty,
                purchased_Unit,
                base_Price,
                pcPerBox,
                UnitCount,
                BaseUnit,
                Kampanj,
                scheduleId,
                listDesc
              );
            }
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // swal("Please enter name for the list", "", "info")
      swal("Ange namn för listan", "", "info");
    }
  };
  const chekProductAdd = (listNo, product, item, scheduleId, listDesc) => {
    return new Promise(function (resolve, reject) {
      let saveListApiDatas = [];
      let message = ``;
      let title = ``;
      let icon = "";
      let say = 0;
      let isAdd = false;

      let insertIntoTemp = "";
      let isEndDate = "";
      let nearestDate = null;
      let isAlreadyExist = "";
      let isScheduleExist = "";

      let date = new Date().toISOString();

      let checkBeforeSaveData = {
        PK_Listno: listNo,
        Prodno: product.ProdNo,
        Recipe_unit: product.purchased_Unit,
        CustNo: props.customerNo,
        UserId: User_id,
      };
      let checkDateData = {
        scheduleId: scheduleId,
        userId: User_id,
        custNo: props.customerNo,
      };
      let saveListApiData = {
        UserId: User_id,
        PK_Listno: listNo,
        Prodno: product.productNo,
        StartingFrom: nearestDate,
        Recipe_amount: product.newPrice * product.newQuantity,
        Recipe_unit: product.purchased_Unit,
        Active_Product: 1,
        Alternativ_prodno: "",
        Last_Buy_Amount: 0,
        Quantity: parseFloat(product.newQuantity),
        Base_price: product.default_price,
        Last_Buy_Date: date,
        UnitCount: parseFloat(product.newQuantity),
        BaseUnit: product.Standard_orderunit,
        PurchaseUnitCount: parseFloat(product.newQuantity),
        isKampanj: 0,
      };
      axiosApiInstance
        .post(`${URL}/checkScheduleAndProductExistInList`, checkBeforeSaveData)
        .then((res) => {
          // console.log('Check Before Save',res.data)

          isAlreadyExist = res.data.isAlreadyExist;
          isScheduleExist = res.data.isScheduleExist;

          if (!isAlreadyExist && isScheduleExist) {
            // debugger
            axiosApiInstance
              .put(`${URL}/checkDatesForQtyTesting`, checkDateData)
              .then((res) => {
                // console.log('Check Dates',res.data)
                if (res.status === 200) {
                  say = say + 1;
                  insertIntoTemp = res.data.data.insertIntoTemp;
                  isEndDate = res.data.data.isEndDate;
                  nearestDate = res.data.data.nearestDate;

                  if (!insertIntoTemp && isEndDate && nearestDate) {
                    // title=`Product Added!`
                    // message=`Product added to list `
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      product.Descr +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  } else if (insertIntoTemp && !isEndDate && nearestDate) {
                    title = `Är du säker?`;
                    // message=`Product will be added to list on: ${nearestDate}`
                    message +=
                      `\n` +
                      product.Descr +
                      ` Produkten kommer att läggas till i listan på: ${nearestDate}`;
                    icon = "warning";
                  } else if (!insertIntoTemp && !isEndDate && nearestDate) {
                    // title=`Product Added!`
                    // message=`Product added to list `
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      product.Descr +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  } else if (
                    !insertIntoTemp &&
                    isEndDate &&
                    nearestDate === null
                  ) {
                    // title=`Can not add Product`
                    // message=`Product cannot be added to list End Date is passed for list`
                    title = `Kan inte lägga till produkt`;
                    message +=
                      `\n` +
                      product.Descr +
                      ` Produkten kan inte läggas till i listan. Slutdatumet har passerat för listan`;
                    icon = "error";
                  } else {
                    // title='Product Added!'
                    // message=`Product added to list`
                    title = `Produkt tillagd!`;
                    message +=
                      `\n` +
                      product.Descr +
                      ` Produkten har lagts till i listan`;
                    icon = "success";
                  }
                  // debugger
                  if (icon === "success") {
                    saveListApiData = {
                      ...saveListApiData,
                      StartingFrom: nearestDate,
                    };
                    saveListApiDatas.push(saveListApiData);
                    isAdd = true;
                  } else if (icon === "warning") {
                    saveListApiData = {
                      ...saveListApiData,
                      StartingFrom: nearestDate,
                    };
                    saveListApiDatas.push(saveListApiData);
                    isAdd = true;
                  } else {
                    // swal(title, message, icon)
                    //   .then(() => {props.setRefTopNav(!props.refresh);});
                  }
                } else {
                  //swal("Något gick fel","","error",{timer:1500,buttons:false})
                }
              });
          } else if (!isAlreadyExist && !isScheduleExist) {
            say = say + 1;
            message +=
              `\n` + product.Descr + ` Produkten har lagts till i listan`;
            saveListApiDatas.push(saveListApiData);
            isAdd = true;
          } else {
            say = say + 1;
            //message+=`\nProduct already exists !`
            message += `\n` + product.Descr + ` Produkten finns redan!`;
          }

          const result = {
            saveListApiData: saveListApiData,
            message: message,
            title: title,
            icon: icon,
            isAdd: isAdd,
          };
          console.log(`Resolving: ${result}`);
          resolve(result);
        });
    });
  };

  const onAnswerSelected = (
    listNo,
    prodctNo,
    price,
    qty,
    purchased_Unit,
    base_Price,
    pcPerBox,
    UnitCount,
    BaseUnit,
    Kampanj,
    scheduleId,
    listDesc
  ) => {
    setClose(true);
    setLoading(true);
    let insertIntoTemp = "";
    let isEndDate = "";
    let nearestDate = null;
    let isAlreadyExist = "";
    let isScheduleExist = "";
    let message = ``;
    let title = ``;
    let isKampanjPro = Kampanj === true ? 1 : 0;

    let date = new Date().toISOString();

    let checkBeforeSaveData = {
      PK_Listno: listNo,
      Prodno: prodctNo,
      Recipe_unit: purchased_Unit,
      CustNo: props.customerNo,
      UserId: User_id,
    };
    let checkDateData = {
      scheduleId: scheduleId,
      userId: User_id,
      custNo: props.customerNo,
    };
    let saveListApiData = {
      UserId: User_id,
      PK_Listno: listNo,
      Prodno: prodctNo,
      StartingFrom: nearestDate,
      Recipe_amount: price,
      Recipe_unit: purchased_Unit,
      Active_Product: 1,
      Alternativ_prodno: "",
      Last_Buy_Amount: 0,
      Quantity: qty,
      Base_price: base_Price,
      Last_Buy_Date: date,
      UnitCount: UnitCount,
      BaseUnit: BaseUnit,
      PurchaseUnitCount:
        purchased_Unit === "Styck"
          ? 1
          : purchased_Unit === "Kg"
          ? UnitCount
          : purchased_Unit === "Kart"
          ? UnitCount
          : purchased_Unit === "Kartplus"
          ? UnitCount
          : UnitCount,
      isKampanj: 0,
    };
    axiosApiInstance
      .post(`${URL}/checkScheduleAndProductExistInList`, checkBeforeSaveData)
      .then((res) => {
        // console.log('Check Before Save',res.data)

        isAlreadyExist = res.data.isAlreadyExist;
        isScheduleExist = res.data.isScheduleExist;

        if (!isAlreadyExist && isScheduleExist) {
          // debugger
          axiosApiInstance
            .put(`${URL}/checkDatesForQtyTesting`, checkDateData)
            .then((res) => {
              // console.log('Check Dates',res.data)
              if (res.status === 200) {
                insertIntoTemp = res.data.data.insertIntoTemp;
                isEndDate = res.data.data.isEndDate;
                nearestDate = res.data.data.nearestDate;
                let icon = "";

                if (!insertIntoTemp && isEndDate && nearestDate) {
                  // title=`Product Added!`
                  // message=`Product added to list `
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                } else if (insertIntoTemp && !isEndDate && nearestDate) {
                  title = `Är du säker?`;
                  // message=`Product will be added to list on: ${nearestDate}`
                  message = `Produkten kommer att läggas till i listan på: ${nearestDate}`;
                  icon = "warning";
                } else if (!insertIntoTemp && !isEndDate && nearestDate) {
                  // title=`Product Added!`
                  // message=`Product added to list `
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                } else if (
                  !insertIntoTemp &&
                  isEndDate &&
                  nearestDate === null
                ) {
                  // title=`Can not add Product`
                  // message=`Product cannot be added to list End Date is passed for list`
                  title = `Kan inte lägga till produkt`;
                  message = `Produkten kan inte läggas till i listan. Slutdatumet har passerat för listan`;
                  icon = "error";
                } else {
                  // title='Product Added!'
                  // message=`Product added to list`
                  title = `Produkt tillagd!`;
                  message = `Produkten har lagts till i listan`;
                  icon = "success";
                }
                // debugger
                if (icon === "success") {
                  saveListApiData = {
                    ...saveListApiData,
                    StartingFrom: nearestDate,
                  };
                  axiosApiInstance
                    .post(`${URL}/list_lines`, saveListApiData)
                    .then((res) => {
                      if (res.status === 200) {
                        swal(title, message, icon).then(() => {
                          props.setRefTopNav(!props.refresh);
                        });
                      }
                    });
                } else if (icon === "warning") {
                  saveListApiData = {
                    ...saveListApiData,
                    StartingFrom: nearestDate,
                  };
                  swal(title, message, icon, { buttons: ["Nej", "Ja"] }).then(
                    (res) => {
                      if (res) {
                        axiosApiInstance
                          .post(`${URL}/list_lines`, saveListApiData)
                          .then((res) => {
                            if (res.status === 200) {
                              swal("Produkt tillagd!", message, "success").then(
                                () => {
                                  props.setRefTopNav(!props.refresh);
                                }
                              );
                            }
                          });
                      }
                    }
                  );
                } else {
                  swal(title, message, icon).then(() => {
                    props.setRefTopNav(!props.refresh);
                  });
                }
              } else {
                swal("Något gick fel", "", "error", {
                  timer: 1500,
                  buttons: false,
                });
              }
            });
          setLoading(false);
        } else if (!isAlreadyExist && !isScheduleExist) {
          axiosApiInstance
            .post(`${URL}/list_lines`, saveListApiData)
            .then((res) => {
              // console.log(`AFTER SAVE:::`,res)
              if (res.status === 200) {
                let data = res.data.data;
                if (listDesc) {
                  // swal("List created successfully!", "Product added!", "success").then(
                  swal(
                    "Listan har skapats",
                    "Produkten har lagts till",
                    "success"
                  ).then(() => {
                    props.setRefTopNav(!props.refresh);
                  });
                } else {
                  if (res.data.status === 200) {
                    // swal("Product added!", "", "success")
                    swal("Produkten har lagts till", "", "success").then(() => {
                      props.setRefTopNav(!props.refresh);
                    });
                  } else {
                    // swal("Product already exists !", "", "warning")
                    swal("Produkten finns redan!", "", "warning").then(() => {
                      props.setRefTopNav(!props.refresh);
                    });
                  }
                }
              } else {
                console.log("Error");
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setLoading(false);
        } else {
          swal("Produkten finns redan !", "", "warning").then(() => {
            props.setRefTopNav(!props.refresh);
          });
          setLoading(false);
        }
      });
  };

  const handlemodalClose = () => {
    setShow(!show);
  };

  const handleModalShowHide = () => {
    setShowHide(!showHide);
    setIsAvailable("");
  };
  const handleChangeList = (e) => {
    const { name, value } = e.target;
    setList_Descr(value);
  };
  const clearInput = () => {
    setList_Descr("");
  };

  function saveToCart(product) {
    let variable = Number.NaN;
    var kgUnit = 0;
    var stUnit = 0;
    var boxUnit = 0;
    var pcPerBox = 0;
    if (
      product.newQuantity === variable ||
      parseFloat(product.newQuantity) === 0 ||
      product.newQuantity === ""
    ) {
      // swal("Please select Minimum quantity", '', 'info');
      swal("Vänligen välj minsta kvantitet", "", "info");
    } else {
      if (product.Standard_orderunit === "Kg") {
        pcPerBox = product.Conv_facor_CART;
        if (product.purchased_Unit === "Kg") {
          kgUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_KG + Number.EPSILON) *
                100
            ) / 100;
          if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
            product.stdOrder_Unit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            product.stdOrder_Unit = kgUnit;
          }
        }
        if (product.purchased_Unit === "Styck") {
          kgUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_ST + Number.EPSILON) *
                100
            ) / 100;
          if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
            product.stdOrder_Unit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            product.stdOrder_Unit = kgUnit;
          }
        }
        /*  */
        if (product.purchased_Unit === "Kart") {
          kgUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_CART + Number.EPSILON) *
                100
            ) / 100;
          if (product.Max_Quantity !== null && product.Max_Quantity > 0) {
            /*  */
            if (kgUnit > product.Max_Quantity && product.Max_Quantity !== 0) {
              product.stdOrder_Unit = product.Max_Quantity;
              product.newQuantity = product.Max_Quantity;
            } else {
              product.stdOrder_Unit = kgUnit;
            }
          }
        }
      }

      if (product.Standard_orderunit === "Styck") {
        pcPerBox = product.Conv_facor_CART;
        if (product.purchased_Unit === "Kg") {
          stUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_KG + Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
        if (product.purchased_Unit === "Styck") {
          stUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_ST + Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
        if (product.purchased_Unit === "Kart") {
          stUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_CART + Number.EPSILON) *
                100
            ) / 100;
          if (
            stUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            stUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            stUnit = stUnit;
          }
        }
      }

      if (product.Standard_orderunit === "Kart") {
        pcPerBox = product.Conv_facor_CART;
        if (product.purchased_Unit === "Kg") {
          boxUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_KG + Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product.purchased_Unit === "Styck") {
          boxUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_ST + Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product.purchased_Unit === "Kart") {
          boxUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_CART + Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
      }
      if (product.Standard_orderunit === "Kartplus") {
        pcPerBox = product.Conv_facor_CART;
        if (product.purchased_Unit === "Kg") {
          boxUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_KG + Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product.purchased_Unit === "Styck") {
          boxUnit =
            Math.round(
              (product.newQuantity * product.Conv_facor_ST + Number.EPSILON) *
                100
            ) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
        if (product.purchased_Unit === "Kartplus") {
          boxUnit =
            Math.round((product.newQuantity * 1 + Number.EPSILON) * 100) / 100;
          if (
            boxUnit > product.Max_Quantity &&
            product.Max_Quantity !== 0 &&
            product.Max_Quantity !== null
          ) {
            boxUnit = product.Max_Quantity;
            product.newQuantity = product.Max_Quantity;
          } else {
            boxUnit = boxUnit;
          }
        }
      }
      let Cartdata = {
        Product_name: product.Descr,
        Product_id: product.ProdNo,
        BoxUnit: boxUnit,
        StUnit: stUnit,
        KgUnit: product.stdOrder_Unit,
        pcPerBox: pcPerBox,
        purchased_Unit: product.purchased_Unit,
        baseUnit: product.Standard_orderunit,
        Price: product.newPrice * product.newQuantity,
        Qty: parseFloat(product.newQuantity),
        Base_price: product.default_price,
        Base_qty: product.Min_Quantity,
        User_id: Auth.getUserId(),
        Tax: product.Tax_rate === null ? 0 : product.Tax_rate,
        CustNo: props.customerNo,
        BrandName: product.BrandName,
        Descr: product.Size_Contains,
        isKampanj: 0,
      };
      axiosApiInstance
        .post(`${URL}/addToCart`, Cartdata)
        .then((res) => {
          if (res.status === 200) {
            let data = res.data.data;
            data &&
              data.map((pro) => {
                if (
                  pro.Product_id === parseInt(product.ProdNo) &&
                  product.purchased_Unit === pro.purchased_Unit
                ) {
                  setLatestData(pro);
                }
              });
            var cartCount = props.cartCount;
            let Count = data;
            let value = 0;
            let Tax = 0;
            for (let i = 0; i < Count.length; i++) {
              value += Count[i].Price;
              Tax += Count[i].Tax;
              // setCount(cartCount + Count.length);
              setTax(Tax);
            }
            let totalOrder = 0;
            for (let i = 0; i < data.length; i++) {
              if (data[i] !== undefined) {
                totalOrder +=
                  ((data[i].Price * data[i].Tax) / 100 + data[i].Price) *
                  data[i].Qty;
              }
            }
            setCount(data.length);
            props.setCartCount(data.length);
            props.setTotalPrice(totalOrder);
            handleShow();
          } else {
          }
        })
        .catch((err) => {
          swal("Något gick fel", "", "info");
          console.log(err);
        });
    }
  }

  return (
    <div className="prodctListOverviewp">
      <Row>
        {/*         <Col md={12}>
          <h3 className="FilterText pt-2 d-inline-block">Filtrera:</h3>
          <DropdownButton
            disabled={props.productFilter.length === 0 ? true : false}
            name={
              props.showProductFilterLabel === false
                ? "somebutton"
                : "ddButtonChangeColor"
            }
            className={"btn SelectClass"}
            title="Varumärke"
          >
            {props.productFilter &&
              props.productFilter.map((opt, index) => {
                return (
                  <Dropdown.Item
                    onClick={(e) => props.ProductLabelHandler(e, index)}
                    data-clear={opt.clear}
                    key={index}
                    data-key={opt.value}
                  >
                    {opt.label} ({opt.count}){" "}
                    {opt.clear === true ? (
                      <i className="ClearIcon">
                        &nbsp;&nbsp;&nbsp;
                        <HighlightOffIcon />
                      </i>
                    ) : null}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
          {props.showProductFilterLabel === true ? (
            <i className="ClearIcon" onClick={props.clearFilter}>
              <HighlightOffIcon />
            </i>
          ) : null}
          {props.customerNo !== 0 ? (
            <DropdownButton
              disabled={
                props.PriceType.length === 0 || props.FirmNo === 89
                  ? true
                  : false
              }
              name={
                props.showPriceTypeLabel === false
                  ? "somebutton"
                  : "ddButtonChangeColor"
              }
              id=""
              value={props.selPriceType}
              className="SelectClass"
              title="Pristyp"
            >
              {props.PriceType &&
                props.PriceType.map((opt, index) => {
                  return (
                    <Dropdown.Item
                      onClick={(e) => props.PriceTypeHandler(e, index)}
                      data-clear={opt.clear}
                      key={index}
                      data-key={opt.value}
                    >
                      {opt.label} ({opt.count}){" "}
                      {opt.clear === true ? (
                        <i className="ClearIcon">
                          &nbsp;&nbsp;&nbsp;
                          <HighlightOffIcon />
                        </i>
                      ) : null}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          ) : null}{" "}
          {props.showPriceTypeLabel === true ? (
            <i className="ClearIcon" onClick={props.clearPriceType}>
              <HighlightOffIcon />
            </i>
          ) : null}
          {props.showLabel ? (
            <span
              className="ClearText pt-2 d-inline-block"
              onClick={props.clearAll}
            >
              Rensa Filter
            </span>
          ) : null}
        </Col> */}
        <Col md={3} sm={6} xs={12} className="filterList"></Col>
        <Col md={3} sm={6} xs={12} className="filterList"></Col>
        <Col md={3} sm={6} xs={12} className="filterList"></Col>
        <Col md={3} sm={6} xs={12} className="filterList"></Col>
      </Row>
      <div className="col-xs-12 no-padding home-product-container component-product-list js-component-type-weekly">
        <Row className="">
          {props.products &&
            props.products.map((p, index) =>
              p.product_status == "5" ? (
                <Col md={3} sm={6} xs={6} className="productOverviewCol">
                  <Card
                    className="grayed-out-image pt-3 pb-3 prodOverviewCard"
                    id={p.ProdNo}
                  >
                    <div className="bkggrey">
                      <div className="prodImgWp">
                        <CardImg
                          top
                          src={
                            p.Small_Img === "" || p.Small_Img === undefined
                              ? bkry
                              : p.Small_Img
                          }
                          alt="grayed-out-image Image"
                        />
                      </div>
                      {p.Price_type_name !== undefined &&
                      p.Price_type_name !== "Kundpris" ? (
                        <span className="BestDealSpan">
                          {p.Price_type_name}
                        </span>
                      ) : null}
                      {acessToken ? (
                        <span className="SavetoListSpan">
                          <SaveListModal
                            close={close}
                            CreatehandleSubmit={CreatehandleSubmit}
                            purchased_Unit={p.purchased_Unit}
                            productNo={p.ProdNo}
                            Qty={parseFloat(p.newQuantity)}
                            price={p.newPrice * p.newQuantity}
                            pcPerBox={p.Standard_orderunit}
                            onAnswerSelected={onAnswerSelected}
                            handleChangeList={handleChangeList}
                            List_Descr={List_Descr}
                            User_id={User_id}
                            // DataList={DataList}
                            options={options}
                            getList={getList}
                            UnitCount={parseFloat(p.newQuantity)}
                            BaseUnit={p.Standard_orderunit}
                            clearInput={clearInput}
                            Kampanj={0}
                          />
                        </span>
                      ) : null}
                    </div>

                    <CardBody
                      className="ProductOverviewCardBody"
                      id="product-overview-card-body-adjustment"
                    >
                      <CardTitle className="titleTxt my-3">{p.Descr}</CardTitle>

                      <CardText className="CommercialText text-center pb-1">
                        {p.BrandName + " " + p.Size_Contains}
                      </CardText>
                      {acessToken ? (
                        <>
                          <CardSubtitle className="mb-2 greenText">
                            {p.newPrice !== null && p.newPrice !== undefined
                              ? p.newPrice.toFixed(2)
                              : null}{" "}
                            kr
                          </CardSubtitle>
                          <CardText className="Compartiveprice">
                            Jmf.pris :{" "}
                            <strong>
                              {p.Comp_Price !== null &&
                              p.Comp_Price !== undefined
                                ? p.purchased_Unit === "Kart"
                                  ? p.box_com_cart_price.toFixed(2)
                                  : p.Comp_Price.toFixed(2)
                                : null}
                              /{p.comp_unit}{" "}
                            </strong>
                          </CardText>
                        </>
                      ) : null}
                      {p.Campaign === 1 ? (
                        <CardText className="priceValidText mb-1">
                          Pris gäller t.o.m {p.CampaignEndDate}
                        </CardText>
                      ) : (
                        <CardText className="priceValidText mb-1"></CardText>
                      )}
                      {acessToken ? (
                        <>
                          <Row>
                            <Col>
                              <CardText className="text-center LadaSelectWp">
                                {(p.Conv_facor_CART === null ||
                                  p.Conv_facor_CART === 0) &&
                                (p.Conv_facor_ST === null ||
                                  p.Conv_facor_ST === 0) &&
                                (p.Conv_facor_KG === null ||
                                  p.Conv_facor_KG === 0) ? null : (
                                  <select
                                    className="minimal"
                                    name="select"
                                    id="exampleSelect"
                                    value={p.default_value}
                                    disabled={true}
                                  >
                                    {p.Conv_facor_KG === 0 ? null : (
                                      <option
                                        value={p.Conv_facor_KG}
                                        keyid="Kg"
                                      >
                                        {p.comp_unit === "Liter"
                                          ? `Liter`
                                          : `KILO`}
                                      </option>
                                    )}
                                    {p.Conv_facor_ST === 0 ? null : (
                                      <option
                                        value={p.Conv_facor_ST}
                                        keyid="Styck"
                                      >{`1 ST`}</option>
                                    )}
                                    {p.Conv_facor_CART === 0 ? null : (
                                      <option value={5} keyid="Kart">
                                        {" "}
                                        Kolli (
                                        {(p.Standard_orderunit === "Kart" ||
                                          p.Standard_orderunit ===
                                            "Kartplus") &&
                                        p.Conv_facor_ST === 0
                                          ? p.Conv_facor_CART + " " + "ST"
                                          : (p.Standard_orderunit === "Kart" ||
                                              p.Standard_orderunit ===
                                                "Kartplus") &&
                                            p.Conv_facor_ST !== 0
                                          ? p.Conv_facor_CART /
                                              p.Conv_facor_ST +
                                            " " +
                                            "ST"
                                          : p.Standard_orderunit === "Styck"
                                          ? p.Conv_facor_CART + " " + "ST"
                                          : p.Standard_orderunit === "Kg"
                                          ? p.Conv_facor_CART + " " + "KG"
                                          : null}
                                        ){" "}
                                      </option>
                                    )}
                                  </select>
                                )}
                              </CardText>
                            </Col>
                          </Row>
                          <Row
                            className="IncrementList-Said-Product-Overview"
                            id="incrementlistitem-product-overview-row-style"
                          >
                            <Col
                              className="IncrementListItemWpr"
                              id="incrementlistitem-product-overview-style"
                            >
                              <CardText className="text-center">
                                <ListGroup
                                  horizontal
                                  className="my-2 text-center"
                                  style={{ margin: "0 auto" }}
                                >
                                  <ListGroup.Item disabled={true}>
                                    -
                                  </ListGroup.Item>
                                  <Input
                                    type={"text"}
                                    className="InputincrementClassProductOverview"
                                    pattern="[+-]?\d+([.,]\d*)?"
                                    id={index}
                                    data-min="1"
                                    disabled={true}
                                  />
                                  <ListGroup.Item disabled={true}>
                                    +
                                  </ListGroup.Item>
                                </ListGroup>
                              </CardText>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </CardBody>
                    <Row>
                      <Col
                        md={12}
                        className="absoluteBtnCol"
                        id="button-under-product-list-overview"
                      >
                        <Button
                          className="grayed-out-image said-product-overview-list"
                          id="button-product-overview"
                        >
                          TILLFÄLLIGT SLUT
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : p.product_status == "3" ? (
                <Col md={3} sm={6} xs={6} className="productOverviewCol">
                  <Card
                    className="yellowed-out-image pt-3 pb-3 prodOverviewCard"
                    id={p.ProdNo}
                  >
                    <div className="bkggrey">
                      <span className="svara-bestall-overimage">
                        BESTÄLLNINGSVARA
                        <br />
                        Vanligt vis är ledtiden 2-3 dagar för att leverera
                        beställningsvaror. <br />
                        Kontakta kontoret för mer information
                      </span>
                      <div className="prodImgWp image-opacity-bestll">
                        <CardImg
                          top
                          src={
                            p.Small_Img === "" || p.Small_Img === undefined
                              ? bkry
                              : p.Small_Img
                          }
                          alt="grayed-out-image Image"
                        />
                      </div>
                      {p.Price_type_name !== undefined &&
                      p.Price_type_name !== "Kundpris" ? (
                        <span className="BestDealSpan">
                          {p.Price_type_name}
                        </span>
                      ) : null}
                      {acessToken ? (
                        <span className="SavetoListSpan">
                          <SaveListModal
                            close={close}
                            CreatehandleSubmit={CreatehandleSubmit}
                            purchased_Unit={p.purchased_Unit}
                            productNo={p.ProdNo}
                            Qty={parseFloat(p.newQuantity)}
                            price={p.newPrice * p.newQuantity}
                            pcPerBox={p.Standard_orderunit}
                            onAnswerSelected={onAnswerSelected}
                            handleChangeList={handleChangeList}
                            List_Descr={List_Descr}
                            User_id={User_id}
                            // DataList={DataList}
                            options={options}
                            getList={getList}
                            UnitCount={parseFloat(p.newQuantity)}
                            BaseUnit={p.Standard_orderunit}
                            clearInput={clearInput}
                            Kampanj={0}
                          />
                        </span>
                      ) : null}
                    </div>

                    <CardBody
                      className="ProductOverviewCardBody"
                      id="product-overview-card-body-adjustment"
                    >
                      <CardTitle className="titleTxt my-3">{p.Descr}</CardTitle>

                      <CardText className="CommercialText text-center pb-1">
                        {p.BrandName + " " + p.Size_Contains}
                      </CardText>
                      {acessToken ? (
                        <>
                          <CardSubtitle className="mb-2 greenText">
                            {p.newPrice !== null && p.newPrice !== undefined
                              ? p.newPrice.toFixed(2)
                              : null}{" "}
                            kr
                          </CardSubtitle>
                          <CardText className="Compartiveprice">
                            Jmf.pris :{" "}
                            <strong>
                              {p.Comp_Price !== null &&
                              p.Comp_Price !== undefined
                                ? p.purchased_Unit === "Kart"
                                  ? p.box_com_cart_price.toFixed(2)
                                  : p.Comp_Price.toFixed(2)
                                : null}
                              /{p.comp_unit}{" "}
                            </strong>
                          </CardText>
                        </>
                      ) : null}
                      {p.Campaign === 1 ? (
                        <CardText className="priceValidText mb-1">
                          Pris gäller t.o.m {p.CampaignEndDate}
                        </CardText>
                      ) : (
                        <CardText className="priceValidText mb-1"></CardText>
                      )}
                      {acessToken ? (
                        <>
                          <Row>
                            <Col>
                              <CardText className="text-center LadaSelectWp">
                                {(p.Conv_facor_CART === null ||
                                  p.Conv_facor_CART === 0) &&
                                (p.Conv_facor_ST === null ||
                                  p.Conv_facor_ST === 0) &&
                                (p.Conv_facor_KG === null ||
                                  p.Conv_facor_KG === 0) ? null : (
                                  <select
                                    className="minimal"
                                    name="select"
                                    id="exampleSelect"
                                    value={p.default_value}
                                    disabled={true}
                                  >
                                    {p.Conv_facor_KG === 0 ? null : (
                                      <option
                                        value={p.Conv_facor_KG}
                                        keyid="Kg"
                                      >
                                        {p.comp_unit === "Liter"
                                          ? `Liter`
                                          : `KILO`}
                                      </option>
                                    )}
                                    {p.Conv_facor_ST === 0 ? null : (
                                      <option
                                        value={p.Conv_facor_ST}
                                        keyid="Styck"
                                      >{`1 ST`}</option>
                                    )}
                                    {p.Conv_facor_CART === 0 ? null : (
                                      <option value={5} keyid="Kart">
                                        {" "}
                                        Kolli (
                                        {(p.Standard_orderunit === "Kart" ||
                                          p.Standard_orderunit ===
                                            "Kartplus") &&
                                        p.Conv_facor_ST === 0
                                          ? p.Conv_facor_CART + " " + "ST"
                                          : (p.Standard_orderunit === "Kart" ||
                                              p.Standard_orderunit ===
                                                "Kartplus") &&
                                            p.Conv_facor_ST !== 0
                                          ? p.Conv_facor_CART /
                                              p.Conv_facor_ST +
                                            " " +
                                            "ST"
                                          : p.Standard_orderunit === "Styck"
                                          ? p.Conv_facor_CART + " " + "ST"
                                          : p.Standard_orderunit === "Kg"
                                          ? p.Conv_facor_CART + " " + "KG"
                                          : null}
                                        ){" "}
                                      </option>
                                    )}
                                  </select>
                                )}
                              </CardText>
                            </Col>
                          </Row>
                          <Row
                            className="IncrementList-Said-Product-Overview"
                            id="incrementlistitem-product-overview-row-style"
                          >
                            <Col
                              className="IncrementListItemWpr"
                              id="incrementlistitem-product-overview-style"
                            >
                              <CardText className="text-center">
                              <ListGroup
                                  horizontal
                                  className="my-2 text-center"
                                  style={{ margin: "0 auto" }}
                                >
                                  <ListGroup.Item
                                    disabled={
                                      p.Min_Quantity === p.newQuantity
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      props.DecreaseItem(
                                        p.newQuantity,
                                        index,
                                        p.ProdNo,
                                        p.newPrice,
                                        p.Min_Quantity
                                      )
                                    }
                                  >
                                    -
                                  </ListGroup.Item>
                                  {p.purchased_Unit === "Kg" &&
                                  p.comp_unit !== "Liter" ? (
                                    <Input
                                      type={"text"}
                                      className="InputincrementClassProductOverview"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      data-min="1"
                                      onfocus=""
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity + " KG"
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : (p.purchased_Unit === "Kg" &&
                                      p.Min_Quantity < 1) ||
                                    (p.purchased_Unit === "Kg" &&
                                      p.comp_unit === "Liter" &&
                                      p.Min_Quantity < 1) ? (
                                    <Input
                                      type={"text"}
                                      className="InputincrementClassProductOverview"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      data-min="1"
                                      onfocus=""
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity.toFixed(2) + " KG"
                                          : p.newQuantity.toFixed(2)
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : p.purchased_Unit === "Kg" &&
                                    p.comp_unit === "Liter" ? (
                                    <Input
                                      type={"text"}
                                      className="InputincrementClassProductOverview"
                                      // id="depositedAmount"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      data-min="1"
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? `${p.newQuantity} L`
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : p.purchased_Unit === "Kart" ||
                                    (p.purchased_Unit === "Kartplus" &&
                                      p.newQuantity < 1) ? (
                                    <Input
                                      type={"text"}
                                      data-min="1"
                                      autoComplete="off"
                                      className="InputincrementClassProductOverview"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity + " KOLLI"
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : p.purchased_Unit === "Kart" ||
                                    p.purchased_Unit === "Kartplus" ? (
                                    <Input
                                      type={"text"}
                                      data-min="1"
                                      autoComplete="off"
                                      className="InputincrementClassProductOverview"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity + " KOLLI"
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      data-min="1"
                                      autoComplete="off"
                                      className="InputincrementClassProductOverview"
                                      size="1"
                                      name={p.newQuantity}
                                      data-max="999"
                                      id={index}
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity + " ST"
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  )}
                                  <ListGroup.Item
                                    onClick={(e) =>
                                      props.IncrementItem(
                                        p.newQuantity,
                                        index,
                                        p.ProdNo,
                                        p.newPrice,
                                        p.Min_Quantity,
                                        p.Max_Quantity
                                      )
                                    }
                                  >
                                    +
                                  </ListGroup.Item>
                                </ListGroup>
                              </CardText>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </CardBody>
                    <Row>
                      <Col
                        md={12}
                        className="absoluteBtnCol"
                        id="button-under-product-list-overview"
                      >
                        <Button
                        className ="said-product-overview-list"
                          /* className="grayed-out-image said-product-overview-list" */
                          id="button-product-overview"
                          onClick={() => {
                            saveToCart(p);
                          }}
                        >
                          FÖRBESTÄLL
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : p.product_status !== "5" ? (
                <Col md={3} sm={6} xs={6} className="productOverviewCol">
                  <Card className="pt-3 pb-3 prodOverviewCard" id={p.ProdNo}>
                    <div className="bkggrey">
                      <div className="prodImgWp">
                        <Link
                          to={{
                            pathname: `/ProductDetail`,
                            search: `?id=${p.ProdNo}`,
                            state: {
                              productType: type,
                              Products: props.products,
                              pageToLoad: props.pageToLoad,
                              customerNo: props.custNo,
                              totalProducts: props.totalProducts,
                            },
                          }}
                          onClick={() => {
                            setScrollYStore(window.scrollY);
                            window.localStorage.setItem(
                              "scrollY",
                              window.scrollY
                            );
                          }}
                        >
                          <CardImg
                            top
                            src={
                              p.Small_Img === "" || p.Small_Img === undefined
                                ? bkry
                                : p.Small_Img
                            }
                            alt="Image"
                          />
                        </Link>
                      </div>
                      {p.Price_type_name !== undefined &&
                      p.Price_type_name !== "Kundpris" ? (
                        <span className="BestDealSpan">
                          {p.Price_type_name}
                        </span>
                      ) : null}
                      {p.product_status == "3" ? (
                        <span className="svara-bestall">
                          Beställningsvara
                          <span className="svara-bestall-hovertooltip">
                            Vanligt vis är ledtiden 2-3 dagar för att leverera
                            beställningsvaror. <br />
                            Kontakta kontoret för mer information
                          </span>
                        </span>
                      ) : null}

                      {acessToken ? (
                        <span className="SavetoListSpan">
                          <SaveListModal
                            close={close}
                            CreatehandleSubmit={CreatehandleSubmit}
                            purchased_Unit={p.purchased_Unit}
                            productNo={p.ProdNo}
                            Qty={parseFloat(p.newQuantity)}
                            price={p.newPrice * p.newQuantity}
                            pcPerBox={p.Standard_orderunit}
                            onAnswerSelected={onAnswerSelected}
                            handleChangeList={handleChangeList}
                            List_Descr={List_Descr}
                            User_id={User_id}
                            // DataList={DataList}
                            options={options}
                            getList={getList}
                            UnitCount={parseFloat(p.newQuantity)}
                            BaseUnit={p.Standard_orderunit}
                            clearInput={clearInput}
                            Kampanj={0}
                          />
                        </span>
                      ) : null}
                    </div>
                    <CardBody
                      className="ProductOverviewCardBody"
                      id="product-overview-card-body-adjustment"
                    >
                      <CardTitle className="titleTxt my-3">{p.Descr}</CardTitle>

                      <CardText className="CommercialText text-center pb-1">
                        {p.BrandName + " " + p.Size_Contains}
                      </CardText>
                      {acessToken ? (
                        <>
                          <CardSubtitle className="mb-2 greenText">
                            {p.newPrice !== null && p.newPrice !== undefined
                              ? p.newPrice.toFixed(2)
                              : null}{" "}
                            kr
                          </CardSubtitle>
                          <CardText className="Compartiveprice">
                            Jmf.pris :{" "}
                            <strong>
                              {p.Comp_Price !== null &&
                              p.Comp_Price !== undefined
                                ? p.purchased_Unit === "Kart"
                                  ? p.box_com_cart_price.toFixed(2)
                                  : p.Comp_Price.toFixed(2)
                                : null}
                              /{p.comp_unit}{" "}
                            </strong>
                          </CardText>
                        </>
                      ) : null}
                      {p.Campaign === 1 ? (
                        <CardText className="priceValidText mb-1">
                          Pris gäller t.o.m {p.CampaignEndDate}
                        </CardText>
                      ) : (
                        <CardText className="priceValidText mb-1"></CardText>
                      )}
                      {acessToken ? (
                        <>
                          <Row>
                            <Col>
                              <CardText className="text-center LadaSelectWp">
                                {(p.Conv_facor_CART === null ||
                                  p.Conv_facor_CART === 0) &&
                                (p.Conv_facor_ST === null ||
                                  p.Conv_facor_ST === 0) &&
                                (p.Conv_facor_KG === null ||
                                  p.Conv_facor_KG === 0) ? null : (
                                  <select
                                    className="minimal"
                                    name="select"
                                    id="exampleSelect"
                                    value={p.default_value}
                                    onChange={(e) =>
                                      props.onChangeUnit(
                                        e,
                                        index,
                                        p.ProdNo,
                                        p.Standard_orderunit,
                                        p.Comp_Price,
                                        p.Price_broken,
                                        p.Price_cart,
                                        p.Conv_facor_KG,
                                        p.Conv_facor_ST,
                                        p.Conv_facor_CART,
                                        p.default_price
                                      )
                                    }
                                    disabled={
                                      p.Conv_facor_CART === 0 &&
                                      p.Conv_facor_KG === 0
                                        ? true
                                        : p.Conv_facor_ST === 0 &&
                                          p.Conv_facor_KG === 0
                                        ? true
                                        : p.Conv_facor_ST === 0 &&
                                          p.Conv_facor_CART === 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {p.Conv_facor_KG === 0 ? null : (
                                      <option
                                        value={p.Conv_facor_KG}
                                        keyid="Kg"
                                      >
                                        {p.comp_unit === "Liter"
                                          ? `Liter`
                                          : `KILO`}
                                      </option>
                                    )}
                                    {p.Conv_facor_ST === 0 ? null : (
                                      <option
                                        value={p.Conv_facor_ST}
                                        keyid="Styck"
                                      >{`1 ST`}</option>
                                    )}
                                    {p.Conv_facor_CART === 0 ? null : (
                                      <option value={5} keyid="Kart">
                                        {" "}
                                        Kolli (
                                        {(p.Standard_orderunit === "Kart" ||
                                          p.Standard_orderunit ===
                                            "Kartplus") &&
                                        p.Conv_facor_ST === 0
                                          ? p.Conv_facor_CART + " " + "ST"
                                          : (p.Standard_orderunit === "Kart" ||
                                              p.Standard_orderunit ===
                                                "Kartplus") &&
                                            p.Conv_facor_ST !== 0
                                          ? p.Conv_facor_CART /
                                              p.Conv_facor_ST +
                                            " " +
                                            "ST"
                                          : p.Standard_orderunit === "Styck"
                                          ? p.Conv_facor_CART + " " + "ST"
                                          : p.Standard_orderunit === "Kg"
                                          ? p.Conv_facor_CART + " " + "KG"
                                          : null}
                                        ){" "}
                                      </option>
                                    )}
                                  </select>
                                )}
                              </CardText>
                            </Col>
                          </Row>
                          <Row
                            className="IncrementList-Said-Product-Overview"
                            id="incrementlistitem-product-overview-row-style"
                          >
                            <Col
                              className="IncrementListItemWpr"
                              id="incrementlistitem-product-overview-style"
                            >
                              <CardText className="text-center">
                                <ListGroup
                                  horizontal
                                  className="my-2 text-center"
                                  style={{ margin: "0 auto" }}
                                >
                                  <ListGroup.Item
                                    disabled={
                                      p.Min_Quantity === p.newQuantity
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      props.DecreaseItem(
                                        p.newQuantity,
                                        index,
                                        p.ProdNo,
                                        p.newPrice,
                                        p.Min_Quantity
                                      )
                                    }
                                  >
                                    -
                                  </ListGroup.Item>
                                  {p.purchased_Unit === "Kg" &&
                                  p.comp_unit !== "Liter" ? (
                                    <Input
                                      type={"text"}
                                      className="InputincrementClassProductOverview"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      data-min="1"
                                      onfocus=""
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity + " KG"
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : (p.purchased_Unit === "Kg" &&
                                      p.Min_Quantity < 1) ||
                                    (p.purchased_Unit === "Kg" &&
                                      p.comp_unit === "Liter" &&
                                      p.Min_Quantity < 1) ? (
                                    <Input
                                      type={"text"}
                                      className="InputincrementClassProductOverview"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      data-min="1"
                                      onfocus=""
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity.toFixed(2) + " KG"
                                          : p.newQuantity.toFixed(2)
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : p.purchased_Unit === "Kg" &&
                                    p.comp_unit === "Liter" ? (
                                    <Input
                                      type={"text"}
                                      className="InputincrementClassProductOverview"
                                      // id="depositedAmount"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      data-min="1"
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? `${p.newQuantity} L`
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : p.purchased_Unit === "Kart" ||
                                    (p.purchased_Unit === "Kartplus" &&
                                      p.newQuantity < 1) ? (
                                    <Input
                                      type={"text"}
                                      data-min="1"
                                      autoComplete="off"
                                      className="InputincrementClassProductOverview"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity + " KOLLI"
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : p.purchased_Unit === "Kart" ||
                                    p.purchased_Unit === "Kartplus" ? (
                                    <Input
                                      type={"text"}
                                      data-min="1"
                                      autoComplete="off"
                                      className="InputincrementClassProductOverview"
                                      pattern="[+-]?\d+([.,]\d*)?"
                                      id={index}
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity + " KOLLI"
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      data-min="1"
                                      autoComplete="off"
                                      className="InputincrementClassProductOverview"
                                      size="1"
                                      name={p.newQuantity}
                                      data-max="999"
                                      id={index}
                                      onChange={(e) =>
                                        props.onChangeQty(
                                          e,
                                          index,
                                          p.newPrice,
                                          p.Min_Quantity,
                                          p.ProdNo,
                                          p.purchased_Unit,
                                          p.Max_Quantity
                                        )
                                      }
                                      value={
                                        p.hiddenText
                                          ? p.newQuantity + " ST"
                                          : p.newQuantity
                                      }
                                      onClick={(e) =>
                                        props.onclcikEvent(e, p.ProdNo)
                                      }
                                      onBlur={(e) =>
                                        props.onMouseLeaveEvent(p.ProdNo)
                                      }
                                    />
                                  )}
                                  <ListGroup.Item
                                    onClick={(e) =>
                                      props.IncrementItem(
                                        p.newQuantity,
                                        index,
                                        p.ProdNo,
                                        p.newPrice,
                                        p.Min_Quantity,
                                        p.Max_Quantity
                                      )
                                    }
                                  >
                                    +
                                  </ListGroup.Item>
                                </ListGroup>
                              </CardText>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </CardBody>
                    <Row>
                      <Col
                        md={12}
                        className="absoluteBtnCol"
                        id="button-under-product-list-overview"
                      >
                        {acessToken ? (
                          <Button
                            className="said-product-overview-list"
                            id="button-product-overview"
                            onClick={() => {
                              saveToCart(p);
                            }}
                          >
                            {/* Add to Cart */}
                            LÄGG I VARUKORGEN
                          </Button>
                        ) : (
                          <Button
                            className="said-product-overview-list"
                            id="button-product-overview"
                            onClick={() => handleShowLogin()}
                          >
                            {/* LOGIN TO SHOP */}
                            LÄGG TILL I KUNDVAGN
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                <Col> no status error</Col>
              )
            )}
        </Row>

        {props.loading ? (
          <label></label>
        ) : props.productCount === 0 ? (
          <Row className="justify-content-end">
            <Col xs={12} className="my-3 text-center">
              Inget resultat för visning
            </Col>
          </Row>
        ) : props.productCount === props.totalProducts ? null : (
          <Row className="justify-content-end">
            <Col xs={12} className="my-3 text-center">
              <button onClick={props.loadMore} className="btn loadingbtn">
                {`LADDA ${
                  Number(props.totalProducts) - Number(props.productCount) >= 24
                    ? 24
                    : Number(props.totalProducts) - Number(props.productCount)
                } AV ${
                  Number(props.totalProducts) - Number(props.productCount)
                } PRODUKTER`}
              </button>
            </Col>
          </Row>
        )}
      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ModaladdProdHeader">
            Produkten har lagts till i varukorgen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={7}>
              <Row>
                <Col md={5} className="ProdListImgWp my-3 text-center">
                  <img
                    src={
                      latestData.Pic_Small === "" ||
                      latestData.Pic_Small === undefined
                        ? bkry
                        : latestData.Pic_Small
                    }
                    alt="Image"
                  />
                </Col>
                <Col md={5}>
                  <h6 className="headingText">{latestData.Product_name}</h6>
                  <p className="PriceText"> Pris : {latestData.Price} kr</p>
                  <p className="QtyText">
                    Antal/Vikt : {latestData.Qty} &nbsp;
                    {latestData.purchased_Unit === "Styck"
                      ? "ST"
                      : latestData.purchased_Unit === "Kart"
                      ? "Kolli"
                      : latestData.purchased_Unit === "Kg" ||
                        latestData.baseUnit === "Kilo"
                      ? "KG"
                      : null}
                  </p>
                </Col>
                <Col md={1} className="">
                  <div className="verticalLine"></div>
                </Col>
              </Row>
            </Col>
            <Col md={5}>
              <Row>
                <Col className="cartContent">
                  <p class="cart-products-count">
                    Det finns {count} artiklar i din kundvag.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          {count === 1 ? (
            <Row>
              <Col className="my-auto">
                <div className="cart-content-btn text-center mt-2 mb-2">
                  <Link to="/ProductOverview">
                    <Button onClick={handleClose}>Fortsätt handla</Button>
                  </Link>
                  <Link to="/cart">
                    <Button className="ml-2 viewBtn">
                      <CheckIcon className="ChekiconWhite" />
                      Fortsätt till utcheckningen
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
      </Modal>
      <LoginModal
        show={showLogin}
        onHide={handleCloseLogin}
        ProductOverview={"overviewpage"}
      />
    </div>
  );
};
const mapStoreToProps = (state) => {
  return {
    cartCount: state.customer.count,
    customerNo: state.customer.customerNo,
    refresh: state.customer.refresh,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setCartCount: (count) => dispatch(setCartCount(count)),
  setTotalPrice: (price) => dispatch(setTotalPrice(price)),
  setRefTopNav: (refresh) => dispatch(setRefTopNav(refresh)),
});
export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(ProductListOverview)
);
