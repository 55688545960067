import React from 'react'
import {Row,Col,Container,Card,Button} from 'reactstrap'
import Header from '../../components/header/Header'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import swal from 'sweetalert';
import { URL } from '../../ServerCall/Config';
import axios from 'axios';
import Footer from '../../components/footer/Footer';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        // marginTop: theme.spacing(2),
      },
  }));
  
const ContactUs = () => {
    const classes = useStyles();
    const [details,setDetails] = React.useState({
        companyName:"",
        contactPerson:"",
        // companyPh:"",
        mobile:"",
        email:"",
        comment:"",
    })
    const [error,setError] = React.useState({
        companyName:"",
        contactPerson:"",
        // companyPh:"",
        mobile:"",
        email:"",
        comment:"",
    })

    const Validate=()=>{
        let formErr={companyName:"",contactPerson:"",companyPh:"",mobile:"",email:"",comment:""}
        let isValid=false
        const validName=RegExp(/[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/);
        const validEmail = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);
        const validPhone = RegExp(/^\d{10}$/);
        // const validMobile = RegExp('/A\+?(?:0{0,2}[46]*){1}7{1}[0-9]{10}/z');

        // if(details.companyName===""){
        //     formErr.companyName="Please Enter Company Name"
        //     isValid=true
        // }
        // else if(!validName.test(details.companyName)){
        //     formErr.companyName="Please Enter Valid Company Name"
        //     isValid=true
        // }
        // else{
        //     formErr.companyName=""
        //     isValid=false
        // }

        if(details.contactPerson===""){
            // formErr.contactPerson="Please Enter Contact Person Name"
            formErr.contactPerson="Vänligen ange kontaktpersons namn"
            isValid=true
        }
        else if(!validName.test(details.contactPerson)){ 
            // formErr.contactPerson="Please Enter Valid Contact Person Name"
            formErr.contactPerson="Vänligen ange giltig kontaktpersons namn"
            isValid=true
        }
        else{
            formErr.contactPerson=""
            isValid=false
        }

        // if(details.companyPh===""){
        //     formErr.companyPh="Please Enter Company phone Number"
        //     isValid=true
        // }
        // else if(!validPhone.test(details.companyPh)){
        //     formErr.companyPh="Please Enter valid Company phone Number"
        //     isValid=true
        // }
        // else{
        //     formErr.companyPh=""
        //     isValid=false
        // }
        if(details.email===""){
            // formErr.email="Please Enter Email"
            formErr.email="Vänligen ange E-postadress"
            isValid=true
        }
        else if(!validEmail.test(details.email)){
            // formErr.email="Please Enter Valid Email"
            formErr.email="Vänligen ange giltig E-postadress"
            isValid=true
        }
        else{
            formErr.email=""
            isValid=false
        }

        if(details.mobile===""){
            // formErr.mobile="Please Enter Mobile Number"
            formErr.mobile="Vänligen ange Mobilnummer"
            isValid=true
        }
        else if(!validPhone.test(details.mobile)){
            // formErr.mobile="Please Enter Valid Mobile Number"
            formErr.mobile="Vänligen ange giltigt Mobilnummer"
            isValid=true
        }
        else{
            formErr.mobile=""
            isValid=false
        }

        // if(details.comment===""){
        //     formErr.comment="Please Enter Comment"
        //     isValid=true
        // }
        // else if(details.comment.length<200){
        //     formErr.comment="Please Enter More than 200 characters"
        //     isValid=true
        // }
        // else{
        //     formErr.comment=""
        //     isValid=false
        // }

        // console.log(formErr)
        setError(formErr)
        return isValid;
        
    }

    const clearForm=()=>{
        setDetails({companyName:"",
            contactPerson:"",
            // companyPh:"",
            mobile:"",
            email:"",
            comment:"",})
    }

    const submitAction=(e)=>{
        e.preventDefault()
        const isValid=Validate();
        if(isValid===false){
            // console.log("Submitted",details)
            const reqBody = {
                "companyName":details.companyName,
                "name":details.contactPerson,
                "email":details.email,
                "phoneNo":details.mobile,
                "message":details.comment
            }
            axios.post(`${URL}/users/createContactUs`, reqBody)
            .then(res=>{
                if(res.status === 200){
                    if(res.data.status === 200){
                        swal("Din kontaktförfrågan har skickats","","success");
                        clearForm();
                    }
                    else{
                        swal('Something Went Wrong!', '', 'info');
                    }
                }
            })
            .catch(err=>{console.log(err);})
        }
    }

    // const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        const { name, value }= event.target;
        setDetails({ ...details, [name]: value});
    };
     return(
         <div>
             <Header/>
                <Container fluid className=" formWraper">
                        <Row>
                         <Col lg={6} className="ContactUsWp">
                            <Card className="col-md-12 conatctUSCard contactpg">   
                                <Row>
                                    <Col>
                                        <h3>
                                            {/* Contact Us */}
                                            Kontakta oss
                                        </h3>
                                        <p className="storeDetailText">
                                            {/* If you have questions or comments about gronsakskompaniet, do not hesitate to contact us here.
                                            Do not forget that you are always welcome to contact us directly in store. */}
                                            Funderar du över något . Tveka inte att kontakta oss via telefon eller E-post.
                                            Vi gör vårt yttersta för att svara på alla dina frågor
                                        </p>
                                        {/* <p className="storeDetailText">Go to <a href="#" className="FindStoreLink font-weight-bold">Find stores </a>
                                         for more info about all our stores</p> */}
                                    </Col>
                                </Row>
                                <hr/>
                                {/* <Row>
                                    <Col>
                                        <h3 className="FormheadText">
                                            // Fill In The Form To Contact Us
                                            Fyll i formuläret för att kontakta oss
                                        </h3>
                                    </Col>
                                </Row> */}
                                <form className={classes.form} noValidate>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            // autoComplete="Company Name"
                                            name="companyName"
                                            variant="outlined"
                                            // required
                                            onChange={(e) => { handleChange(e)}}
                                            value={details.companyName}
                                            fullWidth
                                            id="Company Name"
                                            label="Företagsnamn"
                                            // autoFocus
                                        />
                                        {
                                            error.companyName !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { error.companyName}</label>: null
                                        }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            onChange={(e) => { handleChange(e)}}
                                            fullWidth
                                            value={details.contactPerson}
                                            name="contactPerson"
                                            id="contactPerson"
                                            label="Kontaktperson namn"
                                        />
                                        {
                                            error.contactPerson !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { error.contactPerson}</label>: null
                                        }
                                        </Grid>
                                        {/* <Grid item  xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            name="companyPh"
                                            onChange={(e) => { handleChange(e)}}
                                            fullWidth
                                            value={details.companyPh}
                                            id="companyPh"
                                            label="Company Phone number"
                                        />
                                        {
                                            error.companyPh !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { error.companyPh}</label>: null
                                        }
                                        </Grid> */}
                                        {/* <Grid item xs={12} sm={6} className="SelectGrid"> */}
                                        {/* <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            onChange={(e) => { handleChange(e)}}
                                            >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                        {/* </Grid> */}
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            // autoComplete="Email"
                                            value={details.email}
                                            name="email"
                                            onChange={(e) => { handleChange(e)}}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            label="E-post"
                                            // autoFocus
                                        />
                                        {
                                            error.email !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { error.email}</label>: null
                                        }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <TextField
                                            // autoComplete="PhNo"
                                            name="mobile"
                                            onChange={(e) => { handleChange(e)}}
                                            value={details.mobile}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="PhNo"
                                            label="Telefonnummer"
                                            // autoFocus
                                        />
                                        {
                                            error.mobile !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            { error.mobile}</label>: null
                                        }
                                        </Grid>
                                        {/* <Grid item xs={12} sm={6} className="SelectGrid"> */}
                                        {/* <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            onChange={(e) => { handleChange(e)}}
                                            >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                        {/* </Grid> */}
                                    </Grid>
                                    <hr/>
                                    <h3 className="MsgHdText mb-4">
                                        {/* Write Your Meassge Here */}
                                        Skriv ditt meddelande här
                                    </h3>
                                    <Grid item xs={12} sm={12} className="SelectGrid">
                                        <TextareaAutosize name="comment" onChange={(e) => { handleChange(e)}} value={details.comment} aria-label="minimum height" rowsMin={3} placeholder="Ange meddelande" />
                                        {/* {
                                            error.comment !== "" ?
                                            <label style={{ fontSize: 12, color: "red" }}>
                                            {error.comment}</label>: null
                                        } */}
                                    </Grid>
                                    <hr/>
                                    <Button type="submit" onClick={submitAction}  className="BtnPrimaryContact pl-4 pr-4"  > 
                                        {/* Send  */}
                                        Skicka
                                    </Button>
                            </form>
                             </Card>
                        </Col>
                        
                        </Row>
                </Container>
                <Footer/>
        </div>
        
     );
}
export default ContactUs;
