import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from '../../components/header/Header';
import InfoBnr from '../../assets/images/infoBanner.jpg';
import Footer from '../../components/footer/Footer';

const AboutUs = () => {
    return(
        <div>
        <Header />
        <Container fluid className="InfoWrap wrapper">
            <Row>
            {/* <Col md={3} className="ProdsidebarWp">
                <Sidebar />
            </Col> */}
            <Col md={12}>
                <Row>
                <Col className="">
                    <div className="InfoBannerBg">
                    <img src={InfoBnr} />
                    </div>
                    <h2 className="InfoBannerHeadText">{"OM OSS"}</h2>
                    {/* <p className="detailText">{"Here you will find gk selection from the range within Bread, Pastries &amp; Ice Cream.If you are missing something, you can contact your store.We lcome to our range! Browse our categories and find the items that suit you."} </p> */}
                </Col>
                </Row>
                <Row id="box">
                <Col md={12} className="InfoTxt">
                <p><strong>VI ÄR INTE RIKTIGT SOM ALLA ANDRA</strong><br />
                   På Grönsakskompaniet är vi stolta över vårt stora utbud av högkvalitativa produkter och råvaror. Men inte bara det. När du arbetar med oss kommer du snart märka att vi är lite annorlunda än många andra grossister. Vi är liksom lite enklare att arbeta med. Du slipper till exempel binda upp dig i långa volymavtal, får dina varor inburna till restaurangen och – kanske det bästa – om du missar något i en beställning kör vi ut din tilläggsbeställning med nästa bil utan kostnad. Vi ser helt enkelt till att göra din vardag som restaurangägare lite enklare.</p>

                <p><strong>NÄR KVALITEN ÄR HÖGRE ÄN PRISET</strong><br />
                   Vi är ett familjeföretag, precis som många av de restauranger vi arbetar med. Vi är också lite mindre än de allra största grossisterna och månar om att ha en personlig och nära relation med våra kunder. Med oss får du också en kunnig partner som kan livsmedel och hjälper dig välja rätt produkter, levererar snabbt på sena beställningar och hjälper dig utveckla ditt sortiment. Kontakta oss så berättar vi mer – välkommen till Grönsakskompaniet</p>

                </Col>
                </Row>
            </Col>
            </Row>
            {/* <Row >
            <Col>
                <SubscribeOffers />
            </Col>
            </Row> */}
        </Container>
        <Footer />
        </div>
    );
}

export default AboutUs;